import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import io from "socket.io-client";
import { toast } from "react-toastify";

import "./App.less";
import {
  BASE_URL,
  BOURSE,
  CHEST,
  INVENT,
  isProduction,
  MAP,
  MOB,
  SKILLS,
} from "./Global/Constantes";
import { usePlaySound } from "./Global/CustomHooks";
import Inscription from "./Components/Inscription/Inscription";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import Skills from "./Components/Skills/Skills";
import Menu from "./Components/Menu/Menu";
import MapWrapper from "./Components/Map/MapWrapper";
import Inventaire from "./Components/Inventaire/Inventaire";
import UserInfos from "./Components/UserInfos/UserInfos";

const onLeavePage = (e) => {
  e.returnValue = "Prevent unwanted reload";
  return "Prevent unwanted reload";
};

const App = () => {
  // Socket
  const [socket, setSocket] = useState(null);

  const playSound = usePlaySound();
  useEffect(() => {
    if (!!socket) {
      socket.off("playSound");
      socket.on("playSound", playSound);
    }
  }, [playSound, socket]);

  // Menu badges
  const [badges, setBadges] = useState({ [INVENT]: 0, [MAP]: 0, [SKILLS]: 0 });

  // Local id :
  const localId = localStorage.getItem("_id") || null;

  const [id, setId] = useState(localId);
  const [name, setName] = useState("");
  const [user, setUser] = useState(null);
  const [skills, setSkills] = useState(null);
  const [isLoading, setIsLoading] = useState(!!localId);
  const [index, setIndex] = useState(0);
  const [others, setOthers] = useState([]);

  // Active zone in :
  const [zone, setZone] = useState(null);

  //Invent
  const [invent, setInvent] = useState([]);
  const [equiped, setEquiped] = useState([]);

  // Objects
  const [bourses, setBourses] = useState([]);
  const [chests, setChests] = useState([]);
  const [mobs, setMobs] = useState([]);

  useEffect(() => {
    // Get de l'utilisateur
    if (!!id && !!socket) {
      socket.emit("user.get", id, ({ code, user }) => {
        if (code === 200) {
          localStorage.setItem("_id", id);
          setUser(user);
          setSkills(user?.skills);
          setInvent(user?.invent);
          setEquiped(user?.equiped);
        } else if (code === 404) {
          toast.error(
            "Aucun compte ne correspond à votre id ! Envoyez votre id (copié dans votre presse-papier) à Sébastien ;)"
          );
          navigator.clipboard.writeText(id);
        } else {
          toast.error(
            "Erreur inconnue lors de la récupération de votre compte"
          );
        }
        setIsLoading(false);
      });
    }
  }, [id, socket]);

  const deadActionPrevented = (a) => {
    playSound("error");
    toast.error("Cette action n'est possible ... Que pour les vivants !", {
      toastId: "UrdeadToMe",
    });
  };

  const updateItems = (itemList) => {
    console.log("### updateItems : ", itemList);
    setBourses(itemList[BOURSE] || []);
    setChests(itemList[CHEST] || []);
    setMobs(itemList[MOB] || []);
  };

  const updateEquiped = (equipedList) => {
    console.log("### updateEquiped : ", equipedList);
    setEquiped(equipedList || []);
  };
  const updateSkills = (newSkills) => {
    console.log("### updateSkills : ", newSkills);
    setSkills(newSkills);
  };
  const updateInvent = (newInvent) => {
    console.log("### updateInvent : ", newInvent);
    setInvent(newInvent);
  };
  const updateUser = (newUser) => {
    console.log("### updateUser (mainStats): ", newUser);
    setUser(newUser);
  };
  const updateOthers = (newOthers) => {
    console.log("### updateOthers : ", newOthers);
    setOthers(newOthers);
  };
  const updateZone = (newZone) => {
    console.log("### updateZone : ", newZone);
    setZone(newZone);
  };

  useEffect(() => {
    if (isProduction) {
      // Pop-up lorsque la personne quitterais la page sans faire expres
      window.addEventListener("beforeunload", onLeavePage);
      // Empêche le menu contextuel sur mobile au longPress
      // and btw le click droit aussi donc que en prod ( et désactivé pour les input pour pouvoir c/c)
      window.oncontextmenu = function (event) {
        if (event.target.tagName === "INPUT") {
          return true;
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }

    const socketInit = io.connect(BASE_URL, { secure: isProduction });

    // socket events from server
    socketInit.on("disconnect", (reason) => {
      console.log("MAYDAY MAYDAY SERVER LOST : " + reason);
    });

    setSocket(socketInit);
    socketInit.on("user.update", updateUser);
    socketInit.on("items.update", updateItems);
    socketInit.on("skills.update", updateSkills);
    socketInit.on("invent.update", updateInvent);
    socketInit.on("equiped.update", updateEquiped);
    socketInit.on("user.isDead", deadActionPrevented);
    socketInit.on("others.update", updateOthers);
    socketInit.on("zone.update", updateZone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeIndex = (newIndex) => {
    setIndex(newIndex);
    playSound("click");
    if (newIndex === 0) {
      setBadges((old) => ({ ...old, [INVENT]: 0 }));
    } else if (newIndex === 1) {
      setBadges((old) => ({ ...old, [MAP]: 0 }));
    } else {
      setBadges((old) => ({ ...old, [SKILLS]: 0 }));
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div
      className="AppWrapper"
      style={{ maxWidth: document.documentElement.clientHeight }}
    >
      {id === null || user === null ? (
        <Inscription
          setName={setName}
          name={name}
          setId={setId}
          socket={socket}
        />
      ) : (
        <>
          <UserInfos user={user} socket={socket} />
          <SwipeableViews
            className="swipeablesContainer"
            resistance
            index={index}
            disabled={false}
            onChangeIndex={onChangeIndex}
          >
            <div className="swipeView">
              <Inventaire
                user={user}
                socket={socket}
                invent={invent}
                setInvent={setInvent}
                equiped={equiped}
                others={others}
              />
            </div>
            <div className="swipeView">
              <MapWrapper
                zone={zone}
                setZone={setZone}
                user={user}
                socket={socket}
                bourses={bourses}
                setBourses={setBourses}
                mobs={mobs}
                setMobs={setMobs}
                chests={chests}
                setChests={setChests}
                skills={skills}
                setBadges={setBadges}
                invent={invent}
                equiped={equiped}
                others={others}
              />
            </div>
            <div className="swipeView">
              <Skills user={user} socket={socket} skills={skills} />
            </div>
          </SwipeableViews>
          <Menu
            setIndex={onChangeIndex}
            user={user}
            index={index}
            badges={badges}
          />
        </>
      )}
    </div>
  );
};

export default App;
