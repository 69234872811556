import { Checkbox, IconButton, Menu, MenuItem } from "@mui/material";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";

import {
  BOURSE,
  CHEST,
  CONCENTRATION,
  HEAL,
  MAX_ZOOM,
  MIN_ZOOM,
  MOB,
  OTHERS,
} from "../../Global/Constantes";
import SVGZoomIN from "../../SVG/zoomIN.svg";
import SVGZoomOUT from "../../SVG/zoomOUT.svg";
import SVGEnlarge from "../../SVG/enlarge.svg";
import "./Controls.less";
import { useState } from "react";
import SkillButton from "../SkillButton/SkillButton";
import { usePlaySound } from "../../Global/CustomHooks";

const LAYERS = [
  { id: CHEST, label: "Coffre" },
  { id: BOURSE, label: "Bourses" },
  { id: HEAL, label: "Totem de vie" },
  { id: MOB, label: "Monstres" },
  { id: OTHERS, label: "Joueurs" },
];

const Controls = ({
  socket,
  zoom,
  setZoom,
  skills,
  hiddenLayers,
  setHiddenLayers,
}) => {
  const playSound = usePlaySound();
  const [anchor, setAnchor] = useState(null);
  const closeMenu = () => {
    setAnchor(null);
  };

  const changeZoom = (delta) => {
    if (zoom + delta >= MIN_ZOOM && zoom + delta <= MAX_ZOOM) {
      setZoom(zoom + delta);
      playSound("click");
    }
  };

  const toggleLayer = (l) => {
    setHiddenLayers((old) => ({ ...old, [l.id]: !old[l.id] }));
    playSound("click");
  };

  const openLayerMenu = (e) => {
    setAnchor(e.currentTarget);
    playSound("click");
  };

  return (
    <>
      <div className="zooms">
        <IconButton
          className={`actionButton ${zoom === MAX_ZOOM && "disabled"}`}
          color="info"
          onClick={() => changeZoom(1)}
          disabled={zoom === MAX_ZOOM}
          size="large"
        >
          <img src={SVGZoomIN} alt="jumelle+" className="actionButtonSVG" />
        </IconButton>
        <IconButton
          className={`actionButton ${zoom === MIN_ZOOM && "disabled"}`}
          color="info"
          onClick={() => changeZoom(-1)}
          disabled={zoom === MIN_ZOOM}
          size="large"
        >
          <img src={SVGZoomOUT} alt="jumelle-" className="actionButtonSVG" />
        </IconButton>
      </div>
      <div className="layers">
        <IconButton
          className="actionButton"
          color="info"
          onClick={openLayerMenu}
          size="large"
        >
          <LayersOutlinedIcon className="actionButtonSVG layerSVG" />
        </IconButton>
      </div>
      <div className="skills">
        {skills.concentration.level > 0 && (
          <SkillButton
            socket={socket}
            svg={SVGEnlarge}
            skillKey={CONCENTRATION}
            skill={skills[CONCENTRATION]}
          />
        )}
      </div>
      <Menu
        anchorEl={anchor}
        open={!!anchor}
        onClose={closeMenu}
        className="itemMenuWrapper"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        hideBackdrop={false}
      >
        {LAYERS.map((l) => (
          <MenuItem key={l.id} onClick={() => toggleLayer(l)}>
            <Checkbox checked={!hiddenLayers[l.id]} />
            {l.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Controls;
