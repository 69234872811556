import { useEffect, useState } from "react";
import { INVENT } from "../../../../../Global/Constantes";

import SVGFlask from "../../../../../SVG/flask_black_bg.svg";
import FillingPotionSound from "../../../../../Sounds/fillingPotion.mp3";
import "./HealerButton.less";

let timerID = null;
const MIN_TIME_POPO = 5;
const PENALTY = 10;
const fillPotion = new Audio(FillingPotionSound);
fillPotion.loop = true;

const HealerButton = ({ user, socket, closeModal, setBadges }) => {
  const [timeForPopo, setTimeForPopo] = useState(MIN_TIME_POPO);
  const [classe, setClasse] = useState("");

  useEffect(() => {
    const lifePercent = user.pv / user.maxPv;
    const newTime = lifePercent * PENALTY + MIN_TIME_POPO;
    setTimeForPopo(newTime);
  }, [user.pv, user.maxPv]);

  useEffect(() => {
    return () => {
      console.log("-- Clear potion timeout --");
      clearTimeout(timerID);
      timerID = null;
      fillPotion.pause();
    };
  }, []);

  useEffect(() => {
    if (classe) {
      fillPotion.currentTime = 0;
      fillPotion.play();
    } else {
      fillPotion.pause();
    }
  }, [classe]);

  const getPotion = () => {
    stopFill();
    setBadges((old) => ({
      ...old,
      [INVENT]: old[INVENT] + 1,
    }));
    socket.emit("item.potion.add");
    closeModal();
  };

  const beginFill = () => {
    setClasse("active");
    clearTimeout(timerID);
    timerID = null;
    timerID = setTimeout(() => {
      getPotion();
    }, timeForPopo * 1000);
  };

  const stopFill = () => {
    setClasse("");
    clearTimeout(timerID);
    timerID = null;
  };

  return (
    <div className="healAction">
      <div className="wrapperFlask">
        <img
          src={SVGFlask}
          alt="flask"
          className={`flaskIcon ${classe}`}
          onPointerDown={beginFill}
          onPointerUp={stopFill}
          onPointerLeave={stopFill}
          style={{
            transition: `${timeForPopo}s cubic-bezier(0, 0, 0.5, 1)`,
          }}
        />
      </div>
    </div>
  );
};

export default HealerButton;
