import React, { createContext, useState, useContext } from "react";

export const SettingsContext = createContext();

export const useSettingsContext = () => useContext(SettingsContext);

const SettingsContextProvider = ({ children }) => {
  const [canMusic, setCanMusic] = useState(true);
  const [canSound, setCanSound] = useState(true);

  return (
    <SettingsContext.Provider
      value={{ canMusic, setCanMusic, canSound, setCanSound }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
