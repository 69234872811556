import { Drawer } from "@mui/material";

import "./BottomModalStyles.less";

const BottomModal = ({
  isOpen,
  onClose = () => {},
  children,
  classNameWrapper = "ModalWrapper",
}) => {
  return (
    <Drawer
      className={classNameWrapper}
      anchor={"bottom"}
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "transparent",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default BottomModal;
