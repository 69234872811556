import { LinearProgress } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

import NameTag from "../NameTag/NameTag";
import SVGPV from "../../SVG/HUD/hp.svg";
import iconATK from "../../SVG/HUD/atk.svg";
import iconDEF from "../../SVG/HUD/def.svg";
import "./UserInfos.less";
import { useSlowUpdateValue } from "../../Global/CustomHooks";

// hsl values
const Hmax = 110; // GREEN
const Hmin = -20; // RED

const Stats = ({ user, socket }) => {
  const updateServerName = (newName) => {
    socket.emit("user.updateField", user._id, "username", newName, (isOk) => {
      if (isOk) {
        toast.success("Pseudo changé avec succès");
      } else {
        toast.error("Erreur lors du changement de pseudo");
      }
    });
  };

  const ATK = useSlowUpdateValue(user.atk, 1000).current;
  const DEF = useSlowUpdateValue(user.def, 1000).current;

  const lifePercent = (user.pv / user.maxPv) * 100;
  const hsl = ((Hmax - Hmin) * lifePercent) / 100;
  return (
    <>
      <div className="hpxpAndName">
        <div className="levelAndName">
          <NameTag
            initValue={user?.username || ""}
            id={user._id}
            setServerName={updateServerName}
          />
          <div className="level">lvl {user.level}</div>
        </div>
        <div className="hpAndxp">
          <div>
            <img src={SVGPV} alt="pv" className="HUDIcon pvIcon" />
          </div>
          <div className="progressBarWrapper">
            <div className="xp">
              <LinearProgress
                variant="determinate"
                value={user.xp}
                sx={{
                  backgroundColor: "#383838",
                  height: "2px",
                  borderRadius: 60,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: `#4b86ff`,
                  },
                }}
              />
            </div>
            <div className="hp">
              <div className="hpBar">
                <LinearProgress
                  variant="determinate"
                  value={lifePercent}
                  sx={{
                    backgroundColor: "#383838",
                    borderRadius: 60,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: `hsl(${hsl}deg 100% 29%)`,
                    },
                  }}
                />
              </div>
              <div className="pvTxt">
                {user.pv}/{user.maxPv}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stats">
        <div className="statWrapper">
          <img src={iconATK} alt="atk" className="HUDIcon" />
          <span className="statTxt">{ATK}</span>
        </div>
        <div className="statWrapper">
          <img src={iconDEF} alt="def" className="HUDIcon" />{" "}
          <span className="statTxt">{DEF}</span>
        </div>
      </div>
    </>
  );
};

export default Stats;
