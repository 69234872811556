import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";

import "./Inventaire.less";
import GoldSVG from "../../SVG/gold.svg";
import {
  GLOVE,
  HELMET,
  INVENT_LINE_SIZE,
  SHIELD,
  SWORD,
} from "../../Global/Constantes";
import AdminModal from "../Debug/AdminModal";
import { toast } from "react-toastify";
import { formatNumber } from "../../Global/Utils";
import { ITEMS_METADATAS } from "../../Global/MetaDatas";
import ItemMenu from "./ItemMenu";
import { usePlaySound } from "../../Global/CustomHooks";
import SkinsSelector, { getUserSkin } from "./SkinsSelector";
import ReviveBtn from "./ReviveBtn";
import ConfirmModal from "../Modals/ConfirmModal";

const Inventaire = ({ user, socket, invent, equiped, others }) => {
  const playSound = usePlaySound();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isSkinsOpen, setIsSkinsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEquiped, setIsEquiped] = useState(false);

  // Handle delete
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);

  const openMenu = (event, item, isEquiped = false) => {
    // add class with vanilla js (refactor ?...)
    event?.currentTarget?.parentNode?.classList.add("selectedSlot");

    // data for menu item
    setMenuAnchor(event.currentTarget);
    setIsEquiped(isEquiped);
    setSelectedItem(item);

    // Sound click
    playSound("click");
  };

  const closeMenu = () => {
    if (!isDeleting) {
      const all = document.getElementsByClassName("selectedSlot");
      for (const selectedSlot of all) {
        selectedSlot.classList.remove("selectedSlot");
      }
      setSelectedItem(null);
    }
  };

  const deleteItem = () => {
    playSound("pickUp");
    setIsDeleting(true);
    socket.emit("item.delete", dataToDelete.item.id, () => {
      toast.success(`L'object "${dataToDelete.title}" a bien été supprimé`);
      closeMenu();
      setIsDeleting(false);
      setDataToDelete(null);
    });
  };

  const buyInvent = () => {
    if (user.gold < priceNextInvent) {
      playSound("error");
      toast.error("Pas assez de fric !", { toastId: "needMoreMoney" });
    } else {
      socket.emit("invent.enlarge");
      playSound("buy");
    }
  };

  const getItemIcon = (item) => {
    const metas = ITEMS_METADATAS[item.type][item.level];
    return metas.icon;
  };

  const renderEquipedSlot = (type) => {
    const found = equiped.find((i) => i.type === type);
    if (found) {
      return (
        <img
          src={getItemIcon(found)}
          alt="obj"
          className="itemIcon"
          onClick={(event) => openMenu(event, found, true)}
        />
      );
    }
  };

  const priceNextInvent = Math.pow(2, user.inventLevel) * 100;
  return (
    <div className="inventaireContainer">
      <div className="player">
        <div className="equipement">
          <div className="slot">{renderEquipedSlot(HELMET)}</div>
          <div className="slot"></div>
          <div className="slot">{renderEquipedSlot(GLOVE)}</div>
          <div className="slot"></div>
        </div>
        <div className="playerIconWrapper">
          <img
            src={getUserSkin(user)}
            alt="personnage"
            className="playerIcon"
            onClick={() => setIsSkinsOpen(true)}
          />
          {user.pv === 0 && <ReviveBtn user={user} socket={socket} />}
        </div>
        <div className="armes">
          {user.isAdmin && (
            <IconButton color="primary" onClick={() => setIsAdminOpen(true)}>
              <LockTwoToneIcon />
            </IconButton>
          )}
          <div className="slot">{renderEquipedSlot(SWORD)}</div>
          <div className="slot">{renderEquipedSlot(SHIELD)}</div>
        </div>
      </div>
      <div className="inventaire">
        {[...Array(user.inventLevel * INVENT_LINE_SIZE || 1).keys()].map(
          (_, i) => (
            <div key={i} className="slot">
              {!!invent[i] && (
                <img
                  src={getItemIcon(invent[i])}
                  alt="obj"
                  className="itemIcon"
                  onClick={(event) => openMenu(event, invent[i])}
                />
              )}
            </div>
          )
        )}
        <Button
          variant="outlined"
          className="moreInventPlace"
          onClick={buyInvent}
        >
          Agrandir pour
          <span
            className={
              user.gold >= priceNextInvent ? "canAfford" : "cantAfford"
            }
          >
            {formatNumber(priceNextInvent)}
          </span>
          <img src={GoldSVG} alt="coin" className="goldPriceIcon" />
        </Button>
      </div>
      <ItemMenu
        socket={socket}
        item={selectedItem}
        closeMenu={closeMenu}
        setDataToDelete={setDataToDelete}
        menuAnchor={menuAnchor}
        isEquiped={isEquiped}
        user={user}
      />
      <ConfirmModal
        isOpen={!!dataToDelete}
        onConfirm={deleteItem}
        onCancel={() => setDataToDelete(null)}
        confirmTxt="Yep"
        cancelTxt="Annuler"
        confirmText={() => (
          <div>
            Tu veux vraiment détruire l'objet "
            <span className="hilightDelete">{dataToDelete?.title}</span>" ?
          </div>
        )}
      />
      {user.isAdmin && (
        <AdminModal
          setIsModalOpen={setIsAdminOpen}
          isModalOpen={isAdminOpen}
          user={user}
          socket={socket}
          others={others}
        />
      )}
      <SkinsSelector
        user={user}
        socket={socket}
        isModalOpen={isSkinsOpen}
        setIsModalOpen={setIsSkinsOpen}
      />
    </div>
  );
};

export default Inventaire;
