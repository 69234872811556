import React, { useState } from "react";
import { IconButton } from "@mui/material";

import SVGCogs from "../../SVG/cogs.svg";
import SettingsModal from "./SettingsModal";
import "./Skills.less";

const Skills = ({ user, socket, skills }) => {
  const [areSettingsOpen, setAreSettingsOpen] = useState(false);

  return (
    <div className="skillsWrapper">
      <IconButton
        className="settings"
        color="info"
        onClick={() => setAreSettingsOpen(true)}
        size="large"
      >
        <img src={SVGCogs} alt="Settings" className="settingSVG" />
      </IconButton>

      <SettingsModal
        areSettingsOpen={areSettingsOpen}
        setAreSettingsOpen={setAreSettingsOpen}
      />
    </div>
  );
};

export default Skills;
