import React, { useState } from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import { usePlaySound } from "../../Global/CustomHooks";
import ItemMenu from "../Inventaire/ItemMenu";
import { ITEMS_METADATAS } from "../../Global/MetaDatas";
import { formatNumber } from "../../Global/Utils";
import "./Shop.less";

const Shop = ({
  socket,
  user,
  items,
  setBadges,
  label = "Qu'est-ce qui te ferait plaisir ?",
}) => {
  const playSound = usePlaySound();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const openMenu = (event, item) => {
    if (!item.hasAlready) {
      // add class with vanilla js (refactor ?...)
      event?.currentTarget?.parentNode?.classList.add("selectedSlot");

      // data for menu item
      setMenuAnchor(event.currentTarget);
      setSelectedItem(item);

      // Sound click
      playSound("click");
    }
  };

  const closeMenu = () => {
    const all = document.getElementsByClassName("selectedSlot");
    for (const selectedSlot of all) {
      selectedSlot.classList.remove("selectedSlot");
    }
    setSelectedItem(null);
  };

  return (
    <div className="shop">
      {label && <div className="shopLabel">{label}</div>}
      <div className="shopWrapper">
        {items.map((item, index) => {
          const price =
            item.price || ITEMS_METADATAS[item.type][item.level]?.price;
          const icon = ITEMS_METADATAS[item.type][item.level]?.icon;
          return (
            <div className="slotWrapper" key={index}>
              <div className="slot">
                <img
                  src={icon}
                  alt="obj"
                  className="itemIcon"
                  onClick={(event) => openMenu(event, item)}
                />
              </div>
              {item.hasAlready ? (
                <CheckCircleOutlineOutlinedIcon className="green" />
              ) : (
                <div
                  className={`goldPriceWrapper ${
                    user.gold >= price ? "okGold" : "koGold"
                  }`}
                >
                  {formatNumber(price)}
                </div>
              )}
            </div>
          );
        })}
        <ItemMenu
          socket={socket}
          item={selectedItem}
          closeMenu={closeMenu}
          menuAnchor={menuAnchor}
          setBadges={setBadges}
          isShop
          user={user}
        />
      </div>
    </div>
  );
};

export default Shop;
