import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";

import "./ConfirmModalStyles.less";
import { usePlaySound } from "../../Global/CustomHooks";

const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({
  isOpen,
  onConfirm = () => {},
  onCancel = () => {},
  confirmText = () => {},
  confirmTxt = "Oui",
  cancelTxt = "Annuler",
}) => {
  const playSound = usePlaySound();

  const cancel = () => {
    playSound("click");
    onCancel();
  };
  const confirm = () => {
    playSound("click");
    onConfirm();
  };
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={TransitionUp}
      keepMounted
      onClose={onCancel}
      className="deleteDialog"
    >
      <DialogContent>{confirmText()}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancel}>
          {cancelTxt}
        </Button>
        <Button variant="red" onClick={confirm}>
          {confirmTxt}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
