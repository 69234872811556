import React from "react";

import "./LoadingScreen.less";
import LoadingGIF from "../../SVG/loading.gif";

const LoadingScreen = () => {
  return (
    <div className="LoadingFullSize">
      <img src={LoadingGIF} className="loadingGIF" alt="loading..." />
    </div>
  );
};

export default LoadingScreen;
