import L from "leaflet";

import SVGChest0 from "../SVG/Chests/chest0.svg";
import SVGChest1 from "../SVG/Chests/chest1.svg";

import SVGSword0 from "../SVG/Swords/sword0.svg";
import SVGSword1 from "../SVG/Swords/sword1.svg";
import SVGSword2 from "../SVG/Swords/sword2.svg";
import SVGSword3 from "../SVG/Swords/sword3.svg";
import SVGSword4 from "../SVG/Swords/sword4.svg";

import SVGShield0 from "../SVG/Shields/shield0.svg";
import SVGShield1 from "../SVG/Shields/shield1.svg";
import SVGShield2 from "../SVG/Shields/shield2.svg";
import SVGShield3 from "../SVG/Shields/shield3.svg";
import SVGShield4 from "../SVG/Shields/shield4.svg";
import SVGShield5 from "../SVG/Shields/shield5.svg";

import SVGHelmet0 from "../SVG/Helmets/helmet0.svg";
import SVGHelmet1 from "../SVG/Helmets/helmet1.svg";
import SVGHelmet2 from "../SVG/Helmets/helmet2.svg";
import SVGHelmet3 from "../SVG/Helmets/helmet3.svg";
import SVGHelmet4 from "../SVG/Helmets/helmet4.svg";
import SVGHelmet5 from "../SVG/Helmets/helmet5.svg";

import SVGGlove0 from "../SVG/Gloves/Glove0.svg";
import SVGGlove1 from "../SVG/Gloves/Glove1.svg";
import SVGGlove2 from "../SVG/Gloves/Glove2.svg";
import SVGGlove3 from "../SVG/Gloves/Glove3.svg";
import SVGGlove4 from "../SVG/Gloves/Glove4.svg";
import SVGGlove5 from "../SVG/Gloves/Glove5.svg";

import SVGJunk0 from "../SVG/Junk/junk0.svg";
import SVGJunk1 from "../SVG/Junk/junk1.svg";
import SVGJunk2 from "../SVG/Junk/junk2.svg";
import SVGJunk3 from "../SVG/Junk/junk3.svg";
import SVGJunk4 from "../SVG/Junk/junk4.svg";
import SVGJunk5 from "../SVG/Junk/junk5.svg";
import SVGJunk6 from "../SVG/Junk/junk6.svg";
import SVGJunk7 from "../SVG/Junk/junk7.svg";
import SVGJunk8 from "../SVG/Junk/junk8.svg";
import SVGJunk9 from "../SVG/Junk/junk9.svg";

import SVGMob0 from "../SVG/Mobs/mob0.svg";
// import SVGMob1 from "../SVG/Mobs/mob1.svg";
// import SVGMob2 from "../SVG/Mobs/mob2.svg";

import SVGPotionSoin from "../SVG/flask_life_full.svg";
import SVGBourse from "../SVG/moneyBag0.svg";
import SVGHeal from "../SVG/PNJ/healPoint.svg";
import SVGCarpenter from "../SVG/PNJ/carpenter.svg";
import SVGSpy from "../SVG/PNJ/spy.svg";

import {
  BOURSE,
  CHEST,
  JUNK,
  PHARMACY,
  PNJ,
  POTION,
  VERT_POSITIF,
  EQUIPEMENT,
  SWORD,
  CARPENTER,
  SPY,
  SHIELD,
  MOB,
  HELMET,
  GLOVE,
  OTHERS,
  SKIN,
} from "./Constantes";
import { ALL_SKINS_IMG } from "../Components/Inventaire/SkinsSelector";

const iconLeafletBourse = L.icon({ iconUrl: SVGBourse, iconSize: [20, 20] });

const iconLeafletChest0 = L.icon({ iconUrl: SVGChest0, iconSize: [30, 30] });
const iconLeafletChest1 = L.icon({ iconUrl: SVGChest1, iconSize: [30, 30] });

// Mobs
const iconLeafletMob0 = L.icon({ iconUrl: SVGMob0, iconSize: [30, 30] });

const iconLeafletHeal = L.icon({ iconUrl: SVGHeal, iconSize: [30, 30] });
const iconLeafletSpy = L.icon({ iconUrl: SVGSpy, iconSize: [30, 30] });
const iconLeafletCarpenter = L.icon({
  iconUrl: SVGCarpenter,
  iconSize: [30, 30],
});
const COLOR_EFFECT = "#a23eff";

export const ITEMS_METADATAS = {
  [OTHERS]: {
    type: OTHERS,
    labelTooFar: "Ce joueur",
    clickType: "player",
  },
  [BOURSE]: {
    type: BOURSE,
    labelTooFar: "Cette bourse est",
    clickType: "recolte",
    0: {
      icon: SVGBourse,
      iconLeaflet: iconLeafletBourse,
    },
  },
  [CHEST]: {
    type: CHEST,
    labelTooFar: "Ce coffre est",
    clickType: "pick",
    effects: [{ txt: "Type: Consommable", color: COLOR_EFFECT }],
    0: {
      title: "Coffre basique",
      icon: SVGChest0,
      iconLeaflet: iconLeafletChest0,
      description:
        "Un coffre assez simple à trouver qui n'offrira que de faibles trésors... Mais sympa quand même !",
    },
    1: {
      title: "Coffre enchantée",
      icon: SVGChest1,
      iconLeaflet: iconLeafletChest1,
      description:
        "Un coffre magique qui recelle bien des secrets... Sauras-tu seulement l'ouvrir ?",
    },
  },
  [JUNK]: {
    type: JUNK,
    labelTooFar: "Cet objet est",
    clickType: "pick",
    effects: [{ txt: "Type: Déchet", color: COLOR_EFFECT }],
    0: {
      title: "Brosse à dent",
      icon: SVGJunk0,
      description:
        "Une brosse à dent (sûrement) usagée. Elle ne te servira probablement à rien",
    },
    1: {
      title: "Botte usée",
      icon: SVGJunk1,
      description:
        "La botte gauche du clodo du coin... Mais POURQUOI lui avoir volé ? Non mais vraiment ?...",
    },
    2: {
      title: "Parapluie cassé",
      icon: SVGJunk2,
      description: "Parapluie qui s'est fait retourner ! ... Comme ta soeur !",
    },
    3: {
      title: "Carton éclaté",
      icon: SVGJunk3,
      description:
        "Une vielle boite toute déchirée. Provient sûrement d'un colis amazon ! Eclaté au sol",
    },
    4: {
      title: "Menu japoanais",
      icon: SVGJunk4,
      description:
        "Le menu du restaurant japonais du coin... Tout en japonais ! Parfaitement inutile ! (sauf si tu veux des yakitori boeuf fromage... 2,80€ seulement)",
    },
    5: {
      title: "Crayon cassé",
      icon: SVGJunk5,
      description:
        "Crayon à papier de type mine grasse HB ... ou bien c'est peut-être un 2B ? Osef il est cassé !  ",
    },
    6: {
      title: "Ampoule brisée",
      icon: SVGJunk6,
      description:
        "Une ampoule classique à culot E27. Cependant ... Bah elle est cassée ! Elle n'éclairera plus rien",
    },
    7: {
      title: "Mégot de cigarette",
      icon: SVGJunk7,
      description:
        "Encore un fumeur qui a laissé trainé son mégot ! Merci de l'avoir ramassé en tout cas c'est cool pour la planète !",
    },
    8: {
      title: "Canette vide",
      icon: SVGJunk8,
      description:
        "Une canette de soda déjà bue. Elle avait sûrement bon gout. C'est fou ce que les gens peuvent laisser dans ces coffres quand même !",
    },
    9: {
      title: "Boîte de thon",
      icon: SVGJunk9,
      description:
        "Une boîte de conserve de thon pas totalement vide. Elle aurait régalée les chats errants du quartier si tu ne l'avais pas ramassée !",
    },
  },
  [POTION]: {
    type: POTION,
    labelTooFar: "Cette potion est",
    clickType: "pick",
    effects: [{ txt: "Type: Consommable", color: COLOR_EFFECT }],
    0: {
      title: "Potion de vie",
      icon: SVGPotionSoin,
      description: "Une potion de santé : Simple et efficace",
      effects: [{ txt: "+100 PV", color: VERT_POSITIF }],
    },
  },
  [PHARMACY]: {
    type: PNJ,
    labelTooFar: "Ce lieu est",
    clickType: "talk",
    0: {
      icon: SVGHeal,
      iconLeaflet: iconLeafletHeal,
    },
  },
  [CARPENTER]: {
    type: PNJ,
    labelTooFar: "Ce lieu est",
    clickType: "talk",
    0: {
      icon: SVGCarpenter,
      iconLeaflet: iconLeafletCarpenter,
    },
  },
  [SPY]: {
    type: PNJ,
    labelTooFar: "Ce lieu est",
    clickType: "talk",
    0: {
      icon: SVGSpy,
      iconLeaflet: iconLeafletSpy,
    },
  },
  [SWORD]: {
    type: EQUIPEMENT,
    effects: [{ txt: "Type: Arme", color: COLOR_EFFECT }],
    0: {
      title: "Petite dague",
      icon: SVGSword0,
      description:
        "Petite dague très maniable. De courte portée elle sera néanmoins très utile en combat au corps à corps",
    },
    1: {
      title: "Épée",
      icon: SVGSword1,
      description:
        "Épée toute simple mais diablement efficace contre les ennemis. Peut aussi s'utiliser comme couteau à beurre",
    },
    2: {
      title: "Épée magique",
      icon: SVGSword2,
      description:
        "Une épée enchantée par une sirène... Oui oui les sirène ont des pouvoirs magique ! Cependant n'est pas très efficace sous l'eau",
    },
    3: {
      title: "Épée longue",
      icon: SVGSword3,
      description:
        "Une épée à deux mains assez lourde et longue qui donne une portée excellente. Attention tout de même au ralentissement des mouvements",
    },
    4: {
      title: "Épée légendaire",
      icon: SVGSword4,
      description:
        "Il n'est fait mention de cette épée que dans les légendes ! Elle est sertie d'une pierre magique qui protège son possesseur contre la defaite... Enfin il parait",
    },
  },
  [SHIELD]: {
    type: EQUIPEMENT,
    effects: [{ txt: "Type: Bouclier", color: COLOR_EFFECT }],
    0: {
      title: "Bouclier basique",
      icon: SVGShield0,
      description:
        "Une simple planche de bois avec un contour métalisé pour protéger quand même un peu son utilisateur",
    },
    1: {
      title: "Bouclier Viking",
      icon: SVGShield1,
      description:
        "Un bouclier qu'a utilisé jadis Ragnar Lothbrok. Léger et très maniable il te sera utile dans beaucoup de situation !",
    },
    2: {
      title: "Bouclier bling-bling",
      icon: SVGShield2,
      description:
        "Oui c'est bien de l'or ! Fait en bois d'acacia, ce bouclier est orné d'une bordure en or massif 24 carats. Il est donc magnifique ... Mais du coup il est très lourd et pas évident à manier...",
    },
    3: {
      title: "Bouclier en fer forgé",
      icon: SVGShield3,
      description:
        "Finit le bois ! Passons aux choses sérieuses... Ce bouclier fait en fer forgé te protégera de tout coup d'arme blanche et de n'importe quel flèche. Néanmoins n'oubliez pas le passage à la salle de sport pour pouvoir le porter.",
    },
    4: {
      title: "Holy shi..eld !",
      icon: SVGShield4,
      description:
        "Le saint-Graal des bouclier. Fait dans un acier des plus nobles. Il est à la fois très léger et parfaitement équilibré. En plus de tout ça un certificat officiel de protection divine est fournit dans l'emballage !",
    },
    5: {
      title: "Scutum",
      icon: SVGShield5,
      description:
        '"Ave Caesar morituri te salutant" clamait bravement les détenteur de ces boucliers. Antique mais bien pratique, ce bouclier n\'a pas perdu la moindre efficacité depuis près de 2000 ans.',
    },
  },
  [HELMET]: {
    type: EQUIPEMENT,
    effects: [{ txt: "Type: Casque", color: COLOR_EFFECT }],
    0: {
      title: "Casque de chantier",
      icon: SVGHelmet0,
      description:
        "Simple bout de plastique qui ne couvre que le sommet du crâne. Si ce casque te sauve la vie c'est que t'es chanceux de base",
    },
    1: {
      title: "Casque de la 1ère Guerre Mondiale",
      icon: SVGHelmet1,
      description:
        "Casque millitaire surtout conçu pour eviter qu'un impact d'obus ne te pourfende la tête ! Il résiste cependant bien aussi aux armes blanches",
    },
    2: {
      title: "Casque gaulois",
      icon: SVGHelmet2,
      description:
        "C'est le casque bien connu ayant apartenu à Astérix en personne ! Très léger il permet une liberté de mouvement impressionante. Son éclat or pourrait même éblouir les ennemis",
    },
    3: {
      title: "Heaume de chevalier",
      icon: SVGHelmet3,
      description:
        "Avec ça plus de crainte de prendre une flèche dans l'oeil ! ( Attention à tes genoux tout de même ;) ). Par contre qu'est-ce que ça doit être lourd !",
    },
    4: {
      title: "Kabuto de Samuraï",
      icon: SVGHelmet4,
      description:
        "Casque traditionel des Samuraï. Fait à base de mettalurgie fine il est léger et très résistant",
    },
    5: {
      title: "Casque légendaire",
      icon: SVGHelmet5,
      description:
        "Le mythique et incassable casque légendaire. En or massif il ne pèse pourtant pas plus lourd qu'une plume et résisterais à la charge d'un buffle. Très belle pèce que voici",
    },
  },
  [GLOVE]: {
    type: EQUIPEMENT,
    effects: [{ txt: "Type: Gants", color: COLOR_EFFECT }],
    0: {
      title: "Gant de cuisine",
      icon: SVGGlove0,
      description:
        "Vachement pratique pour sortir les plats du four ! Par contre pour se protéger lors d'un combat il laisse à désirer !",
    },
    1: {
      title: "Mouffles de ski",
      icon: SVGGlove1,
      description:
        "Même si elles n'en ont pas l'air ces mouffles sont assez efficace pour absorber quelques chocs !",
    },
    2: {
      title: "Gant de baseball",
      icon: SVGGlove2,
      description:
        'Un "must have" pour une relation épanouie entre un père et son fils aux Etats-Unis. Ce gant vous protégera cependant plus que correctement contre les projectiles',
    },
    3: {
      title: "Gant de boxe",
      icon: SVGGlove3,
      description:
        "Avec ces gants impossible de tenir la moindre épée eficacement ! Mais bon c'est pas comme si ce jeu était logique hein !",
    },
    4: {
      title: "Gants en latex",
      icon: SVGGlove4,
      description:
        "Ces gants isolants sont parfait pour de la maintenance électrique ! Ils assurent aussi un très bon grip sur le manche de votre arme",
    },
    5: {
      title: "Gants légendaire",
      icon: SVGGlove5,
      description:
        "Ces magnifiques pièces vous iront COMME UN GANT ! HAHAHAHAHAHA Tu l'as ? T'as compris ?! COMME UN GANT !!",
    },
  },
  [SKIN]: {
    type: SKIN,
    effects: [
      { txt: "Type: Skin", color: COLOR_EFFECT },
      { txt: "+10 en beauté", color: VERT_POSITIF },
    ],
    1: {
      title: "Superman",
      icon: ALL_SKINS_IMG[1],
      price: 200,
      description:
        "Ne donne malheureusement ni le vol ni la super force ! Et la kryptonite vous tueras quand même ! En bref: une jolie cape",
    },
    2: {
      title: "Batman",
      icon: ALL_SKINS_IMG[2],
      description: "I'M BATMAN",
      price: 200,
    },
    3: {
      title: "Carl",
      icon: ALL_SKINS_IMG[3],
      description: "Votre maison aura aussi le droit à des ballons !",
      price: 200,
    },
    4: {
      title: "Flash",
      icon: ALL_SKINS_IMG[4],
      description: "Barry Allen a dû mettre son costume au clou !",
      price: 200,
    },
    5: {
      title: "Robocop",
      icon: ALL_SKINS_IMG[5],
      price: 200,
      description:
        "Cette armure / machine à tuer pourra sans doute disuader vos ennemis de vous attaquer",
    },
    6: {
      title: "Wolverine",
      icon: ALL_SKINS_IMG[6],
      price: 200,
      description:
        "Ces lames tranchantes ne sont pas des jouets ! Attention à vos amis quand vous leur serrez la main !",
    },
    7: {
      title: "Mario",
      icon: ALL_SKINS_IMG[7],
      price: 200,
      description:
        "Ecoutez votre medecin traitant ! Arrêtez de bouffer des champi ! Des tortues volantes ? Non ce n'est pas la réalité",
    },
    8: {
      title: "Thor",
      icon: ALL_SKINS_IMG[8],
      price: 200,
      description: "Vous ne maitriserez malheureusement pas la foudre !",
    },
    9: {
      title: "?",
      icon: ALL_SKINS_IMG[9],
      price: 200,
      description:
        "Si vous savez qui cela représente, merci de me faire parvenir cette infos :D",
    },
    10: {
      title: "Hulk",
      icon: ALL_SKINS_IMG[10],
      price: 200,
      description: "",
    },
    11: {
      title: "E.T",
      icon: ALL_SKINS_IMG[11],
      price: 200,
      description: "Zulkeberhg zlatonus iotus melapochi nituzil kromi bup !",
    },
    12: {
      title: "Spartiate",
      icon: ALL_SKINS_IMG[12],
      price: 200,
      description: "Très beau déguisement, mais n'attrapez pas froid !",
    },
    13: {
      title: "Policier",
      icon: ALL_SKINS_IMG[13],
      price: 200,
      description:
        "Vous avez toujours révé de jouer au policier ! ... En revanche aucun passe-droit avec ce costume !",
    },
    14: {
      title: "Jigsaw",
      icon: ALL_SKINS_IMG[14],
      price: 200,
      description: "Wanna play a game ?",
    },
    15: {
      title: "Luigi",
      icon: ALL_SKINS_IMG[15],
      price: 200,
      description: "Mario is overated",
    },
    16: {
      title: "Chicken little",
      icon: ALL_SKINS_IMG[16],
      price: 200,
      description: "",
    },
    17: {
      title: "Power ranger",
      icon: ALL_SKINS_IMG[17],
      price: 200,
      description: "Force MAUVE !",
    },
    18: {
      title: "Mr Pringles",
      icon: ALL_SKINS_IMG[18],
      price: 200,
      description: "Vous-voulez être l'érégie de chips ?",
    },
    19: {
      title: "Medecin",
      icon: ALL_SKINS_IMG[19],
      price: 200,
      description:
        "Utiles contre le COVID 19... Moins contre les Xylax en furie !",
    },
    20: {
      title: "Kobe Bryant",
      icon: ALL_SKINS_IMG[20],
      price: 200,
      description: "Vous avez le basket dans l'âme ?",
    },
    21: {
      title: "Pizzaïolo",
      icon: ALL_SKINS_IMG[21],
      price: 200,
      description: "Pizza, pasta y fragola ! Mama mia !",
    },
    22: {
      title: "Kyle Broflovski",
      icon: ALL_SKINS_IMG[22],
      price: 200,
      description: "Ne me dîtes pas qu'ils ont encore tué Kenny ?",
    },
    23: {
      title: "Cyclope",
      icon: ALL_SKINS_IMG[23],
      price: 200,
      description:
        "Ces yeux étant beaucoup trop overpowered ils ont été bannis des armes existantes",
    },
  },
  [MOB]: {
    type: MOB,
    labelTooFar: "Ce monstre est",
    clickType: "fight",
    0: {
      title: "Xylax",
      icon: SVGMob0,
      iconLeaflet: iconLeafletMob0,
      description: "",
    },
  },
};
