import React from "react";

import "./UserInfos.less";
import Gold from "./Gold";
import Stats from "./Stats";

const UserInfos = ({ user, socket }) => {
  return (
    <div className="infosWrapper">
      <Stats socket={socket} user={user} />
      <Gold user={user} />
    </div>
  );
};

export default UserInfos;
