import React, { useEffect, useRef, useState } from "react";
import TypeWriterEffect from "react-typewriter-effect";

import SVGFight from "../../../SVG/fight.svg";
import SVGLeave from "../../../SVG/exit.svg";
import BottomModal from "../../Modals/BottomModal";
import { rand } from "../../../Global/Utils";
import { Button, Divider, LinearProgress } from "@mui/material";
import iconATK from "../../../SVG/HUD/atk.svg";
import iconDEF from "../../../SVG/HUD/def.svg";
import "./ModalFight.less";
import { ITEMS_METADATAS } from "../../../Global/MetaDatas";
import {
  AUTO_ATK,
  BLOCK,
  COLOR_CRITIQUE,
  INVENT,
  ROUGE_NEGATIF,
  SHIELD,
  SWORD,
  VERT_POSITIF,
} from "../../../Global/Constantes";
import SkillButton from "../../SkillButton/SkillButton";
import SVGMain from "../../../SVG/Swords/hand.svg";
import { getUserSkin } from "../../Inventaire/SkinsSelector";
import { usePlaySound } from "../../../Global/CustomHooks";

const ALL_PHRASES = [
  "Un {m} sauvage apparait",
  "Un {m} sauvage apparait",
  "Un {m} sauvage apparait",
  "Tu viens de tomber nez à nez avec un {m}",
  "Attention ! Qui se frotte à un {m} s'y pique !",
  "Ce {m} n'a vraiment pas l'air amical !",
  "Le combat commence entre toi et ce {m}",
  "5€ sur la victoire de ce {m} contre toi",
  "Rien ne t'effraie pour oser combattre un {m}",
  "Leeeeeeeet's go ! Toi VS {m}",
  "Round 1 : {m} VS toi !",
  "{m} n. m. Mamifère agressif qui malgré sa mignonerie ne fera qu'une bouchée de vous.",
];

const getColoredNumber = (nb, isPositif, isCC) => {
  let color = isPositif ? VERT_POSITIF : ROUGE_NEGATIF;
  if (isCC) {
    color = COLOR_CRITIQUE;
  }
  return (
    <span style={{ color: color }} className={`number ${isCC && "critique"}`}>
      {nb}
    </span>
  );
};

let intervalID = null;
let music = null;
const ModalFight = ({
  socket,
  fightData,
  setFightData,
  user,
  setBadges,
  equiped,
  skills,
}) => {
  const monster = fightData?.monster;
  const [index, setIndex] = useState(0);
  const logsEndRef = useRef();
  const [logs, setLogs] = useState([]);
  const [monsterPV, setMonsterPV] = useState(monster?.maxPv || 0);
  const [isShielding, setIsShielding] = useState(false);
  const [isPlayerDead, setIsPlayerDead] = useState(false);
  const [isMonsterDead, setIsMonsterDead] = useState(false);
  const [isNRV, setIsNRV] = useState(false);
  const [atkCounter, setAtkCounter] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const userReplace = <span className="logUsername">{user.username}</span>;
  const monsterReplace = (
    <span className="logMonstername">{monster.title}</span>
  );

  const playSound = usePlaySound();

  useEffect(() => {
    if (fightData.startImmediatly) {
      startFight();
    }

    // clean
    return () => {
      clearInterval(intervalID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Force scrolling down on logs div each time it updates (200ms delta)
   */
  useEffect(() => {
    setTimeout(() => {
      logsEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, [logs]);

  /**
   * Déclenché par l'interval : Déclenche l'attaque du monstre à chaque fois
   * si le joueur et le monstre sont encore en vie
   */
  useEffect(() => {
    if (atkCounter > 0 && (!isMonsterDead || !isPlayerDead)) {
      monsterATK();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atkCounter]);

  /**
   *  Détection de fin de combat (player death)
   */
  useEffect(() => {
    if (user.pv <= 0) {
      endFight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.pv]);

  /**
   *  Détection de fin de combat (monster death) + entrée en mode NRV
   */
  useEffect(() => {
    if (monsterPV < 0) {
      setMonsterPV(0);
    } else if (monsterPV === 0) {
      endFight();
    } else {
      // 1 chance sur 3 à chaque fois que le monstre a - de 30% de vie
      // de passer en mode furie / NRV ( x1.5 damage )
      if ((monsterPV / monster?.maxPv) * 100 < 30 && !isNRV) {
        const chance = rand(0, 3);
        if (chance === 0) {
          setIsNRV(true);
          const log = {
            text: [monsterReplace, " passe en mode furie "],
            class: "badLog",
          };
          setLogs((old) => [...old, log]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monsterPV]);

  const monsterATK = () => {
    let log;
    if (isShielding) {
      log = {
        text: [userReplace, " bloque le coup de ", monsterReplace, " !"],
        class: "goodLog",
      };
    } else {
      const efficacite = rand(0, 21) - 10; // [-10%, +10%] par coup
      let dmg = monster.atk * (100 / (100 + user.def));
      dmg += (dmg * efficacite) / 100;
      if (isNRV) {
        dmg = 1.5 * dmg;
      }
      dmg = Math.round(dmg);
      console.log(
        "[Monster] Efficacité : ",
        efficacite,
        "% | Damage : ",
        dmg,
        " | cc:",
        isNRV,
        " | speed :",
        monster.atkSpeed
      );
      log = {
        text: [
          monsterReplace,
          isNRV ? " inflige un coup critique à " : " mord ",
          userReplace,
          " et lui enlève ",
          getColoredNumber(dmg, false, isNRV),
          " pv",
        ],
        class: "badLog",
      };
      socket.emit("fight.mobATK", dmg);
    }
    setLogs((old) => [...old, log]);
  };

  const closeModal = () => setFightData(false);

  const getRandomOpeningPhrase = (monsterName) => {
    return ALL_PHRASES[rand(0, ALL_PHRASES.length)].replace(
      /{m}/g,
      monsterName
    );
  };

  /**
   * START THE FIGHT : Triggers the interval
   */
  const triggerRemoteFight = () => {
    setIsLoading(true);
    socket.emit("user.startFight", fightData, () => {
      setIsLoading(false);
      startFight();
    });
  };

  const startFight = () => {
    setIndex(index + 1);
    if (user.pv > 0) {
      music = playSound("fightTheme", true, 0.5);
    }
    intervalID = setInterval(
      () => setAtkCounter((old) => old + 1),
      monster.atkSpeed
    );
  };

  const endFight = () => {
    clearInterval(intervalID);
    music?.pause();
    const monsterDeathLog = {
      text: [userReplace, " a achevé ", monsterReplace],
      class: "goodLog",
    };
    const playerDeathLog = {
      text: [monsterReplace, " vous a dévoré... Vous êtes mort."],
      class: "badLog",
    };
    const endLog = monsterPV === 0 ? monsterDeathLog : playerDeathLog;

    const isWin = monsterPV === 0;
    if (isWin) {
      setIsMonsterDead(true);
      playSound("monsterDeath");
    } else {
      setIsPlayerDead(true);
      playSound("playerDeath");
    }

    setLogs((old) => [...old, endLog]);
  };

  const finishFightModal = () => {
    setIsLoading(true);
    socket.emit("user.endFight", (nbLoots) => {
      setFightData(null);
      setIsLoading(false);

      /** LOOT BADGE */
      setBadges((old) => ({
        ...old,
        [INVENT]: old[INVENT] + nbLoots,
      }));
    });
  };

  const executeSkill = (skillKey) => {
    let logTxt = [];
    switch (skillKey) {
      case AUTO_ATK:
        const isCC = rand(0, 101) > 100 - user.cc;
        const efficacite = rand(0, 21) - 10; // [-10%, +10%] par coup
        let dmg = user.atk * (100 / (100 + monster.def));
        dmg += (dmg * efficacite) / 100;
        if (isCC) {
          dmg = 2 * dmg;
        }
        dmg = Math.round(dmg);
        console.log(
          "[User] Efficacité : ",
          efficacite,
          "% | Damage : ",
          dmg,
          " | cc:",
          isCC
        );
        setMonsterPV(monsterPV - dmg);

        logTxt = [
          userReplace,
          isCC ? " met un coup critique à " : " frappe ",
          monsterReplace,
          " et lui enlève ",
          getColoredNumber(dmg, true, isCC),
          " pv",
        ];
        break;
      case BLOCK:
        const skill = skills[skillKey];
        logTxt = [
          userReplace,
          " utilise son bouclier pour ",
          getColoredNumber(skill.duration / 1000, true),
          "s",
        ];
        setIsShielding(true);
        setTimeout(() => setIsShielding(false), skill.duration);
        break;
      default:
    }

    setLogs((old) => [...old, { text: logTxt, class: "goodLog" }]);
  };

  const renderSkill = (skillKey, slotType) => {
    const found = equiped.find((i) => i.type === slotType);
    let icon;
    if (!!found) {
      icon = ITEMS_METADATAS[slotType][found.level].icon;
    } else if (slotType === SWORD) {
      icon = SVGMain;
    }
    let notLearned = false;
    if (slotType === SHIELD && !found) {
      notLearned = true;
    }
    if (notLearned) {
      return null;
    }

    return (
      <SkillButton
        key={skillKey}
        socket={socket}
        svg={icon}
        skillKey={skillKey}
        skill={skills[skillKey]}
        disabled={isPlayerDead || isMonsterDead}
        onClick={() => executeSkill(skillKey)}
      />
    );
  };

  const shieldEquiped = equiped.find((i) => i.type === SHIELD);
  return (
    <BottomModal
      isOpen={!!fightData}
      classNameWrapper="fightRootModal"
      onClose={closeModal}
    >
      <div className="fightModalWrapper">
        <div className="modalHeader">
          <div className="playerSide">
            <img
              src={getUserSkin(user)}
              alt="Me"
              className={`fightProtagonists ${isPlayerDead ? "dead" : ""}`}
            />
            {isShielding && (
              <img
                alt="shield"
                src={ITEMS_METADATAS[SHIELD][shieldEquiped.level].icon}
                className="equipedShield"
              />
            )}
            <div className="name">{user.username}</div>
          </div>
          <div className={`monsterSide ${isNRV ? "nrv" : ""}`}>
            <div className="name monsterName">
              <div>{monster.title}</div>
            </div>
            <img
              src={monster.icon}
              alt="monster"
              className={`fightProtagonists monsterSize ${
                isMonsterDead ? "dead" : ""
              }`}
            />
          </div>
        </div>
        <div className="fightModalContent">
          {index === 0 ? (
            <div className="txtAndNextWrapper">
              <TypeWriterEffect
                textStyle={{ fontSize: 16 }}
                startDelay={100}
                cursorColor="white"
                text={getRandomOpeningPhrase(monster.title)}
                typeSpeed={40}
                hideCursorAfterText
              />
              <Button
                className="svgBtnWrapper"
                variant="red"
                color="secondary"
                onClick={closeModal}
                disabled={isLoading}
              >
                <img src={SVGLeave} alt=">" className="svgFight" />
                Fuir <span className="coward"> (comme un lâche)</span>
              </Button>
              <Button
                className="svgBtnWrapper"
                variant="outlined"
                onClick={triggerRemoteFight}
                disabled={isLoading}
              >
                <img src={SVGFight} alt=">" className="svgFight" />
                Combattre
              </Button>
            </div>
          ) : (
            <>
              <div className="monsterSpecs">
                <div className="stamina"></div>
                <div className="datas">
                  <div className="line">
                    <div className="label">PV</div>
                    <LinearProgress
                      variant="determinate"
                      value={(monsterPV / monster?.maxPv) * 100}
                      sx={{
                        backgroundColor: "#383838",
                        height: "4px",
                        width: "100%",
                        borderRadius: 60,
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: `red`,
                        },
                      }}
                    />
                  </div>
                  <div className="line">
                    <div className="label">{monster.atk}</div>
                    <img src={iconATK} alt="atk" className="dataFightIcon" />
                    <div className="label middle">{monster.def}</div>
                    <img src={iconDEF} alt="atk" className="dataFightIcon" />
                  </div>
                </div>
              </div>
              <div className="logs custom-scrollbar">
                {logs.map((l, i) => (
                  <div className={`logLine ${l.class || ""}`} key={i}>
                    <div>{l.text}</div>
                    {i !== logs.length - 1 && (
                      <div className="logLineDivider">
                        <Divider />
                      </div>
                    )}
                  </div>
                ))}
                <div ref={logsEndRef} />
              </div>
              <div className="userActions">
                {isMonsterDead || isPlayerDead ? (
                  <Button
                    className="svgBtnWrapper"
                    variant="outlined"
                    onClick={finishFightModal}
                    disabled={isLoading}
                  >
                    {isPlayerDead ? "Vous êtes mort" : "Retour à la carte"}
                  </Button>
                ) : (
                  <>
                    {renderSkill(BLOCK, SHIELD)}
                    {renderSkill(AUTO_ATK, SWORD)}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </BottomModal>
  );
};

export default ModalFight;
