import { createTheme } from "@mui/material/styles";
import { ROUGE_NEGATIF } from "./Global/Constantes";

const palette = {
  mode: "dark",
  primary: {
    main: "#eebc19",
  },
  secondary: {
    main: "#0074aa",
  },
};
const components = {
  MuiButton: {
    variants: [
      {
        props: { variant: "red" },
        style: {
          border: `1px solid ${ROUGE_NEGATIF}`,
          color: ROUGE_NEGATIF,
        },
      },
      {
        props: { variant: "dashed", color: "secondary" },
        style: {
          border: `4px dashed red`,
        },
      },
    ],
  },
};
const themeName = "MyTheme";
export default createTheme({ palette, themeName, components });
