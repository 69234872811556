export const allPharmacies = [
  {
    type: "node",
    id: 82669415,
    x: 48.8491703,
    y: 2.2010502,
    tags: {
      alt_name: "Pharmacie Adida",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Golf",
      "ref:FR:FINESS": "920014446",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 89404309,
    x: 48.934061,
    y: 2.20899,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lawson",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950005751",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 155382699,
    x: 48.799096,
    y: 2.0692698,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Cyrienne",
      "ref:FR:FINESS": "780013025",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 163768581,
    x: 48.8950807,
    y: 2.2218977,
    tags: {
      "addr:city": "Nanterre",
      "addr:housenumber": "56",
      "addr:postcode": "92000",
      "addr:street": "Rue Salvador Allende",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Préfecture",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-13:00",
      "ref:FR:FINESS": "920018124",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 195458122,
    x: 48.7655832,
    y: 2.1624395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "780008793",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 195460246,
    x: 48.7644058,
    y: 2.1681079,
    tags: {
      alt_name: "Pharmacie Panelli",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Jouy-en-Josas",
      name: "Pharmacie Oberkampf",
      "name:br": "Apotikerezh Oberkampf",
      phone: "+33 1 39 56 43 74",
      "ref:FR:FINESS": "780008827",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 204885796,
    x: 48.7961064,
    y: 2.1544137,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "4",
      "addr:postcode": "78000",
      "addr:street": "Rue Coste",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Porchefontaine",
      opening_hours:
        "Mo,Tu,Th,Fr 09:00-12:30,14:30-20:00; We 09:00-20:00; Sa 09:00-19:30",
      phone: "+33 1 39 50 04 02",
      "ref:FR:FINESS": "780014940",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 206125580,
    x: 48.806783,
    y: 2.1309203,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "68",
      "addr:postcode": "78000",
      "addr:street": "Rue de la Paroisse",
      amenity: "pharmacy",
      atm: "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      internet_access: "no",
      name: "Pharmacie Dupont Michel",
      opening_hours:
        "Mo 10:00-20:00, Tu,Fr 08:30-20:00, We,Th 09:00-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "780015442",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 206150277,
    x: 48.8071612,
    y: 2.1325466,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours:
        "Mo 10:00-20:00; Tu,Fr 08:30-20:00; We,Th 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "780015194",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 206195642,
    x: 48.7848572,
    y: 2.1824139,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lemieux",
      "ref:FR:FINESS": "780014460",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 240317187,
    x: 48.7951178,
    y: 2.0661962,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Épi d'Or",
      "ref:FR:FINESS": "780012944",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 243455230,
    x: 48.7988456,
    y: 2.1277538,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Royale",
      old_name: "Pharmacie Gare Rive-Gauche",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "780015574",
      source: "survey 2013;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 251652819,
    x: 48.8527413,
    y: 2.3333559,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Citypharma",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750012692",
      source: "Celtipharm - 10/2014;survey",
      toilets: "no",
      website: "https://pharmacie-citypharma.fr/",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 251774849,
    x: 48.8468043,
    y: 2.3696423,
    tags: {
      alt_name: "Pharmacie Tran",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-09-22",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Rapée",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      phone: "+33143430996",
      "ref:FR:FINESS": "750021586",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 252091502,
    x: 48.6598176,
    y: 2.2423224,
    tags: {
      "addr:city": "Nozay",
      "addr:housenumber": "2",
      "addr:postcode": "91620",
      "addr:street": "Place de la Mairie",
      alt_name: "SELAS Pharmacie de Nozay",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 80 85 89",
      "contact:phone": "+33 1 69 01 44 98",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Nozay",
      "ref:FR:FINESS": "910011295",
      since: "2007-10-01",
      website: "https://www.facebook.com/Pharmacie-de-Nozay-91-110965823979978",
    },
  },
  {
    type: "node",
    id: 259546329,
    x: 48.8427695,
    y: 2.3027722,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "112",
      "addr:postcode": "75015",
      "addr:street": "Rue Lecourbe",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lecourbe Cambronne",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:30",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30; Sa 09:00-20:30",
      phone: "+33 1 47 34 05 52",
      "ref:FR:FINESS": "750026437",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 259546504,
    x: 48.8416259,
    y: 2.3030314,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Kries",
      "ref:FR:FINESS": "750026122",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 264848086,
    x: 48.9414154,
    y: 2.2127719,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "950004721",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 264848197,
    x: 48.9405247,
    y: 2.2244706,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Blachère Baude",
      "ref:FR:FINESS": "950004655",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 266097260,
    x: 48.8598508,
    y: 2.3183041,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Assemblée",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 10:00-13:00",
      "ref:FR:FINESS": "750013674",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-18",
    },
  },
  {
    type: "node",
    id: 266632039,
    x: 48.854037,
    y: 2.324019,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Varenne",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750013542",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 268339327,
    x: 49.0280141,
    y: 2.4734618,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 273196993,
    x: 48.8445701,
    y: 2.5258247,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du RER",
      "ref:FR:FINESS": "940020506",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 275221795,
    x: 48.9540923,
    y: 2.3029359,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benhamida",
      opening_hours: "Mo 15:00-20:00; Tu-Sa 9:30-12:30,15:00-20:00",
      phone: "+33148295104",
      "ref:FR:FINESS": "930008537",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 275727716,
    x: 48.8384257,
    y: 2.7122071,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "770014454",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 276946165,
    x: 48.8604062,
    y: 2.4392463,
    tags: {
      "addr:city": "Montreuil",
      "addr:housenumber": "32",
      "addr:postcode": "93100",
      "addr:street": "Boulevard Rouget de Lisle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Frydman",
      opening_hours: "09:00-19:30",
      "ref:FR:FINESS": "930011184",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 276946166,
    x: 48.8623664,
    y: 2.4429578,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 09:00-19:30, Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "930011267",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 280142703,
    x: 48.8528444,
    y: 2.4236233,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie N Guyen",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "930011101",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 281915646,
    x: 48.4511237,
    y: 2.7495542,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Samois",
      "ref:FR:FINESS": "770011625",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 283197146,
    x: 48.8997475,
    y: 2.4973937,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "171",
      "addr:postcode": "93140",
      "addr:street": "Avenue Henri Barbusse",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Souffir Salama",
      "ref:FR:FINESS": "930006887",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "48904563300018",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 287609382,
    x: 48.4084371,
    y: 2.7038012,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "129",
      "addr:postcode": "77300",
      "addr:street": "Rue Grande",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gely",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 22 42 69",
      "ref:FR:FINESS": "770006815",
      source: "Celtipharm - 10/2014",
      "survey:date": "2019-12-24",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 287609383,
    x: 48.4070326,
    y: 2.7033173,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "126",
      "addr:postcode": "77300",
      "addr:street": "Rue Grande",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Courtin",
      "ref:FR:FINESS": "770006781",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 287609384,
    x: 48.4058447,
    y: 2.7021733,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "78",
      "addr:postcode": "77300",
      "addr:street": "Rue Grande",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Salamandre",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 22 22 40",
      "ref:FR:FINESS": "770006765",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 287609385,
    x: 48.4049379,
    y: 2.7009401,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "37",
      "addr:postcode": "77300",
      "addr:street": "Rue Grande",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "770006724",
      source: "Celtipharm - 10/2014",
      "survey:date": "2019-12-26",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 287609387,
    x: 48.4057685,
    y: 2.7002476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770006864",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 287609388,
    x: 48.4053866,
    y: 2.6992981,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours: "Tu-Sa 09:00-12:30,14:00-19:00; PH,Su,Mo off",
      "ref:FR:FINESS": "770006880",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 291934205,
    x: 48.7791591,
    y: 2.2825956,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
    },
  },
  {
    type: "node",
    id: 291934759,
    x: 48.778667,
    y: 2.2801237,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Sceaux",
      "contact:housenumber": "5",
      "contact:phone": "+33 1 46 61 16 12",
      "contact:postcode": "92330",
      "contact:street": "Avenue des 4 chemins",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Quatre Chemins",
      official_name: "Pharmacie Des 4 Chemins",
      "ref:FR:FINESS": "920020583",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 291935494,
    x: 48.8622352,
    y: 2.3395906,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Palais Royal",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750008831",
      source: "Celtipharm - 10/2014",
      "toilets:wheelchair": "yes",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 291935940,
    x: 48.8621947,
    y: 2.3416613,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "38",
      "addr:postcode": "75001",
      "addr:street": "Rue du Louvre",
      alt_name: "Pharmacie Mezzanotte",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Louvre",
      "ref:FR:FINESS": "750008591",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 292104832,
    x: 48.771158,
    y: 2.413947,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gorlier",
      "ref:FR:FINESS": "940007917",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 292111932,
    x: 48.7643686,
    y: 2.4051019,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rouget l'Isle",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-12:30,14:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008055",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 292257380,
    x: 48.7676754,
    y: 2.4162917,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940008154",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 293982741,
    x: 48.8664563,
    y: 2.3643496,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place République",
      opening_hours: "24/7",
      phone: "+33 1 47 00 18 08",
      "ref:FR:FINESS": "750038218",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 293986500,
    x: 48.8666829,
    y: 2.3679194,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie République",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      phone: "+33148052578",
      "ref:FR:FINESS": "750019796",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 293986553,
    x: 48.8633561,
    y: 2.3882344,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place Métivier",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750035404",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 294038639,
    x: 48.8782025,
    y: 2.373445,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Avenir",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035370",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 294137022,
    x: 48.877532,
    y: 2.3738783,
    tags: {
      alt_name: "Pharmacie Bolivar",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750035271",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 294292710,
    x: 48.8463128,
    y: 2.3945522,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sirinelli",
      opening_hours: "Tu-Su 07:00-20:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750021917",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 304766151,
    x: 49.0328867,
    y: 2.019702,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Bussie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950013292",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 304768373,
    x: 49.0305985,
    y: 2.0205073,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chan",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950009449",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 304778232,
    x: 48.94803,
    y: 1.9225391,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie d'Ecquevilly",
      "ref:FR:FINESS": "780007381",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 304778362,
    x: 48.7679964,
    y: 2.4060961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "940008022",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 306513298,
    x: 48.9922501,
    y: 2.2806476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Tilleuls",
      opening_hours:
        "Tu-Fr 09:00-12:30, 14:30-19:30; Mo 14:30-19:30; Sa 09:00-12:30",
      "ref:FR:FINESS": "950007187",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 307759982,
    x: 49.0482829,
    y: 2.0106145,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des 3 Gares",
      "opening_hours:covid19": "Mo-Fr 07:30-20:30; Sa 08:30-20:30",
      "ref:FR:FINESS": "950006387",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 308020589,
    x: 48.7801029,
    y: 2.2801661,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chambon",
      "ref:FR:FINESS": "920011343",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 308090480,
    x: 48.8978334,
    y: 2.0897038,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "52",
      "addr:postcode": "78100",
      "addr:street": "Rue de Poissy",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "780013280",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 308775959,
    x: 48.8241184,
    y: 2.2726421,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.amar@orange.fr",
      name: "Pharmacie Amar",
      phone: "+33 1 46 42 16 51",
      "ref:FR:FINESS": "920015195",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 309044791,
    x: 48.8468474,
    y: 2.2861719,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Charles Michels",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025371",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 310045016,
    x: 48.8370582,
    y: 2.2963339,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Hahnemann",
      opening_hours: "Mo-Fr 08:30-22:00; Sa 09:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750027252",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 310508412,
    x: 48.7542269,
    y: 2.1871974,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Migneau-Seigneuret",
      "ref:FR:FINESS": "780008777",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 313185841,
    x: 48.7930959,
    y: 2.4610953,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Edard",
      "ref:FR:FINESS": "940008782",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 315739787,
    x: 48.7630399,
    y: 2.4005576,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Soleil",
      "ref:FR:FINESS": "940014442",
      source: "Celtipharm - 10/2014;survey 11/2018",
    },
  },
  {
    type: "node",
    id: 315780108,
    x: 48.8276087,
    y: 2.3346707,
    tags: {
      "addr:housenumber": "10",
      "addr:postcode": "75014",
      "addr:street": "Rue d'Alésia",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours:
        "Mo 14:00-20:00; Tu-Sa 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025116",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 315969269,
    x: 48.8114861,
    y: 2.0458096,
    tags: {
      alt_name: "Pharmacie Kesler Webber",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007845",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 321003277,
    x: 48.8317439,
    y: 2.3618766,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bichet",
      "ref:FR:FINESS": "750022550",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 321512292,
    x: 48.9703976,
    y: 2.1956606,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "950006536",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 324436193,
    x: 48.8718988,
    y: 2.3720288,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Eczane",
      opening_hours: "Mo-Sa 09:00-20:00; PH off",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018277",
      source: "survey 2013;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 325731611,
    x: 48.8412432,
    y: 2.3252996,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Paris",
      "addr:housenumber": "43",
      "addr:postcode": "75014",
      "addr:street": "Rue Delambre",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaedgar@gmail.com",
      healthcare: "pharmacy",
      name: "Pharmacie Edgar Quinet",
      opening_hours: "Mo-Su 08:30-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 20 70 12",
      "ref:FR:FINESS": "750024085",
      source: "survey;Celtipharm - 10/2014",
      website:
        "https://pharmacie.parispharma.com/454462-pharmacie-edgar-quinet",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 328978286,
    x: 48.8701219,
    y: 2.3796681,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande pharmacie de Belleville",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Fr 10:00-18:00; Sa off",
      "ref:FR:FINESS": "750035685",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 329022188,
    x: 48.8689687,
    y: 2.3854808,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 43",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036154",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 330415517,
    x: 48.8431992,
    y: 2.3244536,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 143207022",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Aprium Pharmacie Montparnasse",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024705",
      source: "Celtipharm - 10/2014",
      website: "https://montparnasse.aprium-pharmacie.fr/aprium-express",
    },
  },
  {
    type: "node",
    id: 331229668,
    x: 48.76982,
    y: 2.0401593,
    tags: {
      alt_name: "Pharmacie Virot",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Sourderie",
      "ref:FR:FINESS": "780010914",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 331273028,
    x: 48.795029,
    y: 2.3662559,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Schull",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940014830",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 332490642,
    x: 48.7765266,
    y: 1.9911208,
    tags: {
      "addr:housenumber": "8",
      "addr:street": "Rue Paul Langevin",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pons",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014163",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 333247934,
    x: 48.6493269,
    y: 2.3038004,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "4",
      "addr:postcode": "91700",
      "addr:street": "Rue de la Boële",
      alt_name: "SELARL Pharmacie de la Boële",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 25 06 90",
      "contact:phone": "+33 1 60 16 07 04",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Boële",
      "ref:FR:FINESS": "910012327",
    },
  },
  {
    type: "node",
    id: 335621669,
    x: 48.8533987,
    y: 2.406081,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Maraichers",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036535",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 335622346,
    x: 48.8573212,
    y: 2.403899,
    tags: {
      alt_name: "Pharmacie Mamane",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale des Pyrénées",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036550",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 341303404,
    x: 48.808878,
    y: 2.2985799,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      medical_supply: "yes",
      name: "Pharmacie Maison Blanche",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "920025194",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 343639421,
    x: 48.786653,
    y: 2.4453401,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "SELARL Pharmacie du Palais",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008733",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 343639787,
    x: 48.7862499,
    y: 2.4525514,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bembaron",
      "ref:FR:FINESS": "940008253",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 344527001,
    x: 48.7905156,
    y: 2.4541995,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bauer",
      "ref:FR:FINESS": "940008683",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 344527028,
    x: 48.7934723,
    y: 2.4559292,
    tags: {
      "addr:housename": "Immeuble Champeval",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "940008600",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 344527144,
    x: 48.7896617,
    y: 2.4494617,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Levrière",
      "ref:FR:FINESS": "940008667",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 344527180,
    x: 48.791932,
    y: 2.4442357,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benais",
      "ref:FR:FINESS": "940008717",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 344603373,
    x: 48.7794348,
    y: 2.4554692,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Soumet Cap Santé 2",
      opening_hours: "Mo-Fr 08:30-18:30; Sa 09:30-18:30",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 344603387,
    x: 48.779697,
    y: 2.4567857,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Soumet",
      opening_hours: "Mo-Sa 09:00-18:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008337",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 346276375,
    x: 48.7811185,
    y: 2.4642023,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rehana",
      "ref:FR:FINESS": "940008386",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 355515922,
    x: 48.7715674,
    y: 2.1236131,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jolie Ferme",
      "ref:FR:FINESS": "780005443",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 358071662,
    x: 48.6368308,
    y: 2.4419295,
    tags: {
      "addr:city": "Évry",
      "addr:postcode": "91000",
      "addr:street": "Rue Mathilde",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 79 45 28",
      "contact:phone": "+33 1 60 77 31 10",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "910008416",
      since: "2006-09-01",
    },
  },
  {
    type: "node",
    id: 358124446,
    x: 48.6334027,
    y: 2.4430025,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "4",
      "addr:postcode": "91000",
      "addr:street": "Avenue Nowy Targ",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 17 46",
      "contact:phone": "+33 1 60 77 17 36",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de l'Aguado",
      "ref:FR:FINESS": "910008655",
      since: "2007-09-17",
    },
  },
  {
    type: "node",
    id: 358124448,
    x: 48.628113,
    y: 2.4528022,
    tags: {
      "addr:city": "Évry",
      "addr:postcode": "91000",
      "addr:street": "Centre Commercial du Mousseau",
      alt_name: "SNC Pharmacie du Mousseau",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 97 09 64",
      "contact:phone": "+33 1 60 77 33 95",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Mousseau",
      opening_hours:
        "Mo-Fr 09:30-13:00,14:30-19:30; Sa 09:30-13:00,15:00-19:30",
      "ref:FR:FINESS": "910008333",
      since: "2007-10-01",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 358124451,
    x: 48.6295674,
    y: 2.4261493,
    tags: {
      "addr:city": "Évry",
      "addr:country": "FR",
      "addr:full": "Centre Commercial Evry 2 91000 Evry",
      "addr:postcode": "91000",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Évry 2",
      "ref:FR:FINESS": "910008507",
      "ref:FR:NAF": "4773Z",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 358124453,
    x: 48.6279395,
    y: 2.4260583,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "204 bis",
      "addr:postcode": "91000",
      "addr:street": "Centre Commercial Régional Évry 2",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 94 00",
      "contact:phone": "+33 1 60 77 32 15",
      dispensing: "yes",
      level: "2",
      name: "Pharmacie Russo",
      "ref:FR:FINESS": "910008622",
      start_date: "1988-03-11",
    },
  },
  {
    type: "node",
    id: 358124456,
    x: 48.6354379,
    y: 2.4380055,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "9",
      "addr:postcode": "91000",
      "addr:street": "Allée Victor Hugo",
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 77 12 02",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Pierre Duguet",
      "ref:FR:FINESS": "910008556",
      since: "1989-04-27",
    },
  },
  {
    type: "node",
    id: 358124460,
    x: 48.6332221,
    y: 2.4133188,
    tags: {
      "addr:city": "Courcouronnes",
      "addr:postcode": "91080",
      "addr:street": "Allée Jacques Monod",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 79 05 65",
      "contact:phone": "+33 1 60 79 05 65",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Moungang Mejionang",
      "ref:FR:FINESS": "910007202",
      since: "1984-11-02",
    },
  },
  {
    type: "node",
    id: 361256154,
    x: 48.8346489,
    y: 2.3330683,
    tags: {
      amenity: "pharmacy",
      "contact:email": "pharmacie.parisdenfert@gmail.com",
      "contact:phone": "+33 143542971",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Sarl Pharmacie Place Denfert-Rochereau",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750024101",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2009;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 371137474,
    x: 48.8226711,
    y: 2.2678792,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carrefour de Weiden",
      opening_hours: "Mo-Sa 08:00-20:00",
      "ref:FR:FINESS": "920015575",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2009;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 389138461,
    x: 48.8044586,
    y: 2.4981647,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:00-20:00; Tu-Sa 08:30-13:00,14:00-20:00",
      "ref:FR:FINESS": "940013543",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 390448167,
    x: 48.6967812,
    y: 2.186769,
    tags: {
      "addr:city": "Orsay",
      "addr:country": "FR",
      "addr:postcode": "91400",
      "addr:street": "Rue de Paris",
      alt_name: "SELARL Pharmacie Dahan",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 18 83 90",
      "contact:phone": "+33 1 69 28 61 77",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dahan",
      note: "a déménagé dans les nouveaux bâtiments de l'autre côté de la rue fin octobre/début novembre 2020 (le chantier est en cours d’achèvement)",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00,14:00-19:00",
      "ref:FR:FINESS": "910011410",
      "ref:FR:SIREN": "491044301",
      "ref:FR:SIRET": "49104430100027",
      since: "2006-09-18",
      "survey:date": "2020-11-20",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 390448178,
    x: 48.6968075,
    y: 2.1847589,
    tags: {
      "addr:city": "Orsay",
      "addr:housenumber": "2",
      "addr:postcode": "91400",
      "addr:street": "Place de la République",
      alt_name: "SELARL Pharmacie Guyon Chadoutaud",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 28 26 78",
      "contact:phone": "+33 1 69 28 40 50",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Guyon Chadoutaud",
      opening_hours: "Tu-Sa 09:00-13:30,14:30-20:00; Mo 14:30-20:00",
      "opening_hours:covid19": "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "910011477",
      since: "1989-03-23",
    },
  },
  {
    type: "node",
    id: 393172737,
    x: 48.8519996,
    y: 2.4016737,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie d'Avron",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035453",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 409472615,
    x: 48.7928925,
    y: 2.1437318,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "64",
      "addr:postcode": "78000",
      "addr:street": "Rue des Chantiers",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont Colbert",
      "ref:FR:FINESS": "780014924",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 414708216,
    x: 48.8542034,
    y: 2.4314571,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Juliette Kinde",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "930011200",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 416532539,
    x: 48.8697016,
    y: 2.3714823,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Goncourt",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750019663",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 416548855,
    x: 48.8663599,
    y: 2.3735117,
    tags: {
      alt_name: "Pharmacie Journo",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Cliniques",
      "ref:FR:FINESS": "750019614",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 416550027,
    x: 48.8651585,
    y: 2.3741886,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boutron",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-12:00,13:30-19:00",
      "ref:FR:FINESS": "750019846",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 416560239,
    x: 48.8658968,
    y: 2.3774054,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Maur Oberkampf",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750020117",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 416560242,
    x: 48.8672886,
    y: 2.3755842,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Clément",
      "opening_hours:covid19": "Mo-Sa 10:00-13:00,15:00-19:30",
      "ref:FR:FINESS": "750020133",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 416690073,
    x: 48.8736618,
    y: 2.3646917,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75010",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôpital Saint-Louis",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017741",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 416707360,
    x: 48.8701624,
    y: 2.3706958,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tomasini Cohen",
      "ref:FR:FINESS": "750018244",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 416709828,
    x: 48.8706026,
    y: 2.3727062,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mimouni",
      opening_hours: "Tu-Fr 09:00-20:00; Mo 10:30-20:00; Sa 09:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017394",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 417237165,
    x: 48.4469005,
    y: 2.7638029,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Planells",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770007177",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 418049522,
    x: 48.8663019,
    y: 2.3713203,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Choumin",
      "opening_hours:covid19": "open",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "750020240",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 418073934,
    x: 48.8666671,
    y: 2.3837961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du 20ème",
      opening_hours: "Mo-Sa 09:00-20:30, Su 09:00-14:00,15:00-20:00",
      "ref:FR:FINESS": "750036204",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 418142038,
    x: 48.8687118,
    y: 2.3798647,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      noname: "yes",
      "ref:FR:FINESS": "750019093",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 420593028,
    x: 48.868034,
    y: 2.3867035,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036253",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 428033233,
    x: 48.8463393,
    y: 2.3794911,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Diderot",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021271",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 428034295,
    x: 48.8468005,
    y: 2.3839097,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Froux",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "750021305",
      source: "knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 428039202,
    x: 48.8483419,
    y: 2.3941371,
    tags: {
      "addr:housenumber": "2",
      "addr:street": "Place de la Nation",
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Benhamou",
      "name:de": "Pharmacie Benhamou",
      opening_hours: "Mo-Su 08:30-21:00",
      "ref:FR:FINESS": "750021784",
      source: "survey 2011-04;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 428131275,
    x: 48.8505616,
    y: 2.3818961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Steuer",
      "ref:FR:FINESS": "750018947",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 428131708,
    x: 48.8499708,
    y: 2.3841427,
    tags: {
      alt_name: "Pharmacie Carrier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Chaligny",
      opening_hours: "Mo-Sa 08:45-20:00",
      "ref:FR:FINESS": "750020794",
      source: "knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 428132373,
    x: 48.8471042,
    y: 2.3871676,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "750022022",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 428157651,
    x: 48.8489281,
    y: 2.3761561,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "750021404",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 429289607,
    x: 48.8995122,
    y: 2.2618668,
    tags: {
      "addr:housenumber": "36",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 68 72 79",
      dispensing: "yes",
      name: "Pharmacie Sun",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      operator: "Christophe Sun",
      "ref:FR:FINESS": "920013976",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 429616759,
    x: 48.8332943,
    y: 2.3549487,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 431622742,
    x: 48.8975245,
    y: 2.2403557,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 88 94 33",
      dispensing: "yes",
      name: "Pharmacie du Faubourg de l'Arche",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-19:30",
      "ref:FR:FINESS": "920014024",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 431830447,
    x: 48.8329627,
    y: 2.3315938,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Lion",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:00",
      "ref:FR:FINESS": "750024283",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 433257476,
    x: 48.870061,
    y: 2.3963739,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Haut Mesnil",
      opening_hours: "Mo-Sa 09:00-13:00; Mo-Fr 14:00-20:00",
      "ref:FR:FINESS": "750036337",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 434148277,
    x: 48.7455821,
    y: 2.2953586,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "920007713",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 439885063,
    x: 48.8707002,
    y: 2.3614113,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Magenta",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017980",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 441170121,
    x: 48.8212635,
    y: 2.3034673,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Progrès",
      opening_hours: "Mo-Fr 09:00-19:45; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "Mo-Fr 09:00-19:45; Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "920016276",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 441170207,
    x: 48.8219578,
    y: 2.304105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Provence",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016490",
    },
  },
  {
    type: "node",
    id: 441389214,
    x: 48.8015967,
    y: 2.2642871,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours:
        "Mo 09:00-12:00,14:00-19:30;Tu-Fr 09:00-12:30,14:00-19:30;Sa 09:00-13:00,14:00-19:30;PH off",
      "ref:FR:FINESS": "920011962",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 441390743,
    x: 49.062792,
    y: 2.0921271,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kennedy",
      "ref:FR:FINESS": "950011163",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 441390748,
    x: 49.057663,
    y: 2.0890727,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Louvrais",
      "ref:FR:FINESS": "950011064",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 445633494,
    x: 48.8505446,
    y: 2.4430771,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Morssi",
      opening_hours:
        "Mo-Fr 10:00-12:30, 15:00-19:00; Sa 10:30-13:00, 15:30-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 28 13 94",
      "ref:FR:FINESS": "940015944",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 445774573,
    x: 48.9485137,
    y: 2.0368188,
    tags: {
      "addr:city": "Carrières-sous-Poissy",
      "addr:housenumber": "70",
      "addr:postcode": "78955",
      "addr:street": "Rue Claude Monet",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Griveau",
      opening_hours: "Mo-Sa 09:00-12:30,14:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 39 74 99 30",
      "ref:FR:FINESS": "780016523",
    },
  },
  {
    type: "node",
    id: 446879840,
    x: 48.8151374,
    y: 2.2863067,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Sud",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016540",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 447773451,
    x: 48.8170386,
    y: 2.3185417,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "62",
      "contact:street": "Avenue de la République",
      dispensing: "yes",
      fax: "+33 1 42 53 09 44",
      name: "Pharmacie du Rond-Point",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00; Sa 09:00-13:00,14:00-19:00",
      phone: "+33 1 42 53 01 53",
      "ref:FR:FINESS": "920017324",
      "ref:FR:SIRET": "32995632000033",
      source: "survey",
      "type:FR:FINESS": "620",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 448666242,
    x: 48.7621255,
    y: 1.9177143,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Julien de Zélicourt",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780010443",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 450721543,
    x: 48.8389201,
    y: 2.2828876,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lourmel",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026106",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 450722212,
    x: 48.8364681,
    y: 2.2819452,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Grand Pavois",
      opening_hours: "Mo-Sa 08:30-19:45",
      "ref:FR:FINESS": "750026585",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 451010339,
    x: 48.8224407,
    y: 2.4125856,
    tags: {
      "addr:city": "Charenton-le-Pont",
      "addr:housenumber": "68 bis",
      "addr:postcode": "94220",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      phone: "+33 1 43 68 00 38",
      "ref:FR:FINESS": "940007404",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 451966108,
    x: 48.8647712,
    y: 2.4045352,
    tags: {
      "addr:housenumber": "15",
      "addr:street": "Rue Belgrand",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rivaux et Arbaut",
      "opening_hours:covid19": "open",
      phone: "+33140305256",
      "ref:FR:FINESS": "750035636",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 451966113,
    x: 48.864397,
    y: 2.409669,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Leadersanté",
      opening_hours: "Mo-Fr 08:00-21:00; Sa,Su 09:00-20:00",
      phone: "+33143614309",
      "ref:FR:FINESS": "750036451",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 456805772,
    x: 48.8221546,
    y: 2.252384,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Phamacie Aux Enfants",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920018579",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 457491690,
    x: 48.8489207,
    y: 2.5567122,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gallieni",
      "ref:FR:FINESS": "930011820",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 457491693,
    x: 48.8463605,
    y: 2.55266,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie de la Médiathèque",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930019062",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 457491702,
    x: 48.8475446,
    y: 2.5532545,
    tags: {
      "addr:city": "Noisy-le-Grand",
      "addr:housenumber": "15",
      "addr:postcode": "93160",
      "addr:street": "Avenue Aristide Briand",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930011754",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciedumarcheanoisy.fr/",
    },
  },
  {
    type: "node",
    id: 459811668,
    x: 48.8410092,
    y: 2.1813105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pasteur",
      "ref:FR:FINESS": "920014362",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2009;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 460680932,
    x: 48.824728,
    y: 2.2729613,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "mairie.issy@gmail.com",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      phone: "+33 1 46 42 20 95",
      "ref:FR:FINESS": "920015443",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 461712029,
    x: 48.9469002,
    y: 2.6615063,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 463712980,
    x: 48.8856296,
    y: 2.3199522,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du village Batignolles",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750029720",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 469287863,
    x: 48.80219,
    y: 2.3314323,
    tags: {
      "addr:housenumber": "39",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.raspail@outlook.fr",
      fax: "+33 1 46 65 40 81",
      name: "Pharmacie Raspail",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:00-19:30",
      operator: "Blond-Perillaud",
      phone: "+33 1 45 47 20 99",
      "ref:FR:FINESS": "940005721",
      source: "Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 469751083,
    x: 48.9748013,
    y: 2.0502255,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ganachaud",
      "ref:FR:FINESS": "780004875",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 469974421,
    x: 48.8365312,
    y: 2.3932203,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      entrance: "yes",
      name: "Grande Pharmacie de Bercy",
      opening_hours: "Mo,Sa 09:00-20:00; Tu-Fr 08:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022253",
      source: "survey 2014;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 469974780,
    x: 48.8361512,
    y: 2.3875057,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "14",
      "addr:postcode": "75012",
      "addr:street": "Place Lachambeaudie",
      alt_name: "Pharmacie Tordjman",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "750021537",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 471041989,
    x: 48.8435459,
    y: 2.2377436,
    tags: {
      alt_name: "Pharmavance Escudier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Dahan",
      opening_hours: "Mo-Sa 08:30-20:30",
      phone: "+33 1 46 05 03 91",
      "ref:FR:FINESS": "920009842",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471504113,
    x: 48.8779979,
    y: 2.2517483,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Bagatelle",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00, 14:00-19:30",
      "ref:FR:FINESS": "920018645",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471508132,
    x: 48.8811759,
    y: 2.2559599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bois de Boulogne",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-19:30",
      "ref:FR:FINESS": "920018843",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471545002,
    x: 48.8357478,
    y: 2.4730519,
    tags: {
      "addr:city": "Nogent sur Marne",
      "addr:housenumber": "9",
      "addr:postcode": "94130",
      "addr:street": "Avenue Georges Clemenceau",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bedoucha-Rossi",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011745",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471549757,
    x: 48.8366731,
    y: 2.4755239,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Moreau-Quiet",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011976",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471845844,
    x: 48.880387,
    y: 2.2770882,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Horloge",
      "ref:FR:FINESS": "920018793",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 471857726,
    x: 48.8833363,
    y: 2.2661219,
    tags: {
      "addr:housenumber": "130",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du 130",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00",
      operator: "Dahlia Handelsman",
      "ref:FR:FINESS": "920018397",
    },
  },
  {
    type: "node",
    id: 472345381,
    x: 48.4764755,
    y: 2.5553723,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Medromi",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770010262",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 473429888,
    x: 48.8046559,
    y: 2.4852004,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Louvière",
      "ref:FR:FINESS": "940013444",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-06",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 473457287,
    x: 49.0089045,
    y: 2.2406175,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Diablots",
      "ref:FR:FINESS": "950011676",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 473496748,
    x: 48.7944671,
    y: 2.3358135,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "10",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaciedesarcades.94@leadersante.fr",
      healthcare: "pharmacy",
      name: "Pharmacie des Arcades",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "940006471",
      "ref:FR:SIRET": "50759174100016",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 473838080,
    x: 48.8374325,
    y: 2.3912706,
    tags: {
      "addr:housenumber": "237",
      "addr:postcode": "75012",
      "addr:street": "Rue de Charenton",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dang Trung",
      "ref:FR:FINESS": "750020927",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 474616366,
    x: 48.8751889,
    y: 2.7451163,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Payen",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770009314",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 475006999,
    x: 48.9284429,
    y: 2.042247,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "25 bis",
      "addr:postcode": "78300",
      "addr:street": "Avenue du Cep",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780012142",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 477197099,
    x: 49.0111388,
    y: 2.028904,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Merisiers",
      opening_hours: "Mo 14:30-20:00; Tu-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo 14:30-19:00; Tu-Sa 09:00-19:00",
      "ref:FR:FINESS": "950009522",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2009",
    },
  },
  {
    type: "node",
    id: 477197102,
    x: 49.011932,
    y: 2.0436651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sabah",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950009472",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 477197149,
    x: 49.0060397,
    y: 2.04077,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vast",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "950009555",
      shop: "chemist",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2009;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 480680109,
    x: 48.8305282,
    y: 2.3779956,
    tags: {
      alt_name: "Pharmacie Wirth Clairet",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Bibliothèque",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 83 95 53",
      "ref:FR:FINESS": "750023483",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 487110198,
    x: 48.8837401,
    y: 2.2712006,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Levy-Timsit",
      "ref:FR:FINESS": "920018744",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 487117015,
    x: 48.8831102,
    y: 2.2739145,
    tags: {
      alt_name: "Pharmacie Pariente",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920018710",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 487151069,
    x: 48.8822111,
    y: 2.2772346,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sellam-Fitoussi",
      "ref:FR:FINESS": "920018546",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489590747,
    x: 48.8917668,
    y: 2.2891128,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Trebois",
      "ref:FR:FINESS": "920016144",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489591274,
    x: 48.8900399,
    y: 2.288116,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bardol",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920015724",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 489592685,
    x: 48.8875644,
    y: 2.2855303,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vasram",
      "ref:FR:FINESS": "920015963",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489594127,
    x: 48.8887214,
    y: 2.2873413,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Thomasset",
      "ref:FR:FINESS": "920016011",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489597222,
    x: 48.8916898,
    y: 2.2866375,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carnot",
      "ref:FR:FINESS": "920015740",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489614993,
    x: 48.8920002,
    y: 2.2852899,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Luporsi",
      "ref:FR:FINESS": "920015591",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 489702531,
    x: 48.8940611,
    y: 2.276556,
    tags: {
      "addr:housenumber": "9",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 58 76 81",
      dispensing: "yes",
      name: "Pharmacie Château-Villiers",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-19:00",
      operator: "Bruno Fellous",
      "ref:FR:FINESS": "920015765",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 492901179,
    x: 48.770159,
    y: 2.316422,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie La Fontaine",
      "ref:FR:FINESS": "920008224",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 493182910,
    x: 48.8146533,
    y: 2.4154358,
    tags: {
      "addr:city": "Alfortville",
      "addr:housenumber": "15",
      "addr:postcode": "94140",
      "addr:street": "Rue Paul Vaillant Couturier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie PARO",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 43 75 15 62",
      "ref:FR:FINESS": "940005473",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 494154191,
    x: 48.9410207,
    y: 2.5775526,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Poiré",
      "ref:FR:FINESS": "930016233",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 494415223,
    x: 48.9427328,
    y: 2.5705781,
    tags: {
      alt_name: "Pharmacie BASQUE LAVAUD",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pasteur",
      "ref:FR:FINESS": "930016217",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 494658847,
    x: 48.8315542,
    y: 2.3147243,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Plaisance",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023772",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 494660447,
    x: 48.8332152,
    y: 2.3169841,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 495671064,
    x: 48.8321178,
    y: 2.3204396,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Didot-Pernety",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024135",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 496117323,
    x: 48.8615019,
    y: 2.3742714,
    tags: {
      alt_name: "Pharmacie Bergeron",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bleue",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750020356",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 496122163,
    x: 48.8591105,
    y: 2.376008,
    tags: {
      alt_name: "Pharmacie Saada",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Popincourt",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:30, 15:30-19:30",
      "ref:FR:FINESS": "750019747",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 496145291,
    x: 48.892088,
    y: 2.2935233,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie AMAR",
      "ref:FR:FINESS": "920015864",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 497887226,
    x: 48.8615812,
    y: 2.3729669,
    tags: {
      alt_name: "Pharmacie Sebban",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Deux Boulevards",
      opening_hours: "Mo-Fr 8:30-19:30;Sa 9:00-19:00",
      "ref:FR:FINESS": "750020620",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 500317672,
    x: 48.8648604,
    y: 2.3686851,
    tags: {
      alt_name: "Pharmacie Khalfa",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Voltaire",
      "ref:FR:FINESS": "750020281",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 501569215,
    x: 48.9017763,
    y: 2.155939,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Knadjian",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780006193",
      source: "local knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 502259632,
    x: 48.4690073,
    y: 2.7070355,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Cité",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770003846",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 506312605,
    x: 48.6317918,
    y: 2.4273035,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "303",
      "addr:postcode": "91000",
      "addr:street": "Allée du Dragon",
      amenity: "pharmacy",
      "contact:fax": "+33 9 75 51 21 21",
      "contact:phone": "+33 1 60 77 50 34",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Fathalla",
      "ref:FR:FINESS": "910008382",
      since: "1976-01-15",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2009",
    },
  },
  {
    type: "node",
    id: 506314137,
    x: 48.7121753,
    y: 2.4019344,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "99",
      "addr:postcode": "91200",
      "addr:street": "Rue Édouard Vaillant",
      alt_name: "SELARL Pharmacie de la Gare",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 00 63",
      "contact:phone": "+33 1 69 38 10 52",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie de la Gare",
      phone: "+33 1 69 38 10 52",
      "ref:FR:FINESS": "910005305",
      since: "2008-05-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre;mise à jour : 2009",
    },
  },
  {
    type: "node",
    id: 506368912,
    x: 48.8296212,
    y: 2.3650676,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Nationale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023301",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2009;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 517570647,
    x: 48.698219,
    y: 2.5040091,
    tags: {
      "addr:city": "Brunoy",
      "addr:housenumber": "9",
      "addr:postcode": "91800",
      "addr:street": "Place de la Mairie",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-03-28",
      "contact:phone": "+33 1 60 46 03 13",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 08:30-19:30",
      "ref:FR:FINESS": "910006352",
      since: "2009-11-02",
      wheelchair: "limited",
      "wheelchair:description": "Portes automatiques. Petite marche.",
    },
  },
  {
    type: "node",
    id: 517589994,
    x: 48.6908463,
    y: 2.4943816,
    tags: {
      "addr:city": "Brunoy",
      "addr:housenumber": "7",
      "addr:postcode": "91800",
      "addr:street": "Rue de Montgeron",
      alt_name: "SELURL Pharmacie Baudoin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 43 85 69",
      "contact:phone": "+33 1 60 46 86 33",
      dispensing: "yes",
      name: "Pharmacie Baudoin",
      opening_hours: "Mo-Sa 14:30-19:00, Mo-Fr 08:30-12:30, Sa 09:00-12:30",
      "ref:FR:FINESS": "910006386",
      since: "2008-01-07",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 517590024,
    x: 48.7086679,
    y: 2.5223882,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Hautes Mardelles",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 519073608,
    x: 48.8444753,
    y: 2.4055101,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Netter",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022121",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 520290730,
    x: 48.8367822,
    y: 2.4038793,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Badré",
      opening_hours: "Mo-Sa 08:30-19:45",
      "opening_hours:covid19": "Mo-Su 08:30-19:30",
      "ref:FR:FINESS": "750021206",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 520290733,
    x: 48.8411703,
    y: 2.4049568,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Morchoisne Chhun",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021321",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 523275883,
    x: 48.8898012,
    y: 2.1974995,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33147212933",
      name: "Pharmacie de Carbon",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33147212831",
      "ref:FR:FINESS": "920017597",
      source: "survey;Celtipharm - 10/2014",
      website: "http://www.pharmaciedecarbon.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 523275907,
    x: 48.8897692,
    y: 2.1948278,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Well & Well",
      "note:fr": "Changement de nom mi sept 2013",
      old_name: "Pharmacie du Marché",
      phone: "+33147241225",
      "ref:FR:FINESS": "920017845",
      "ref:FR:SIRET": "78861550800017",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 523275910,
    x: 48.8919044,
    y: 2.1955522,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie-centrale5@wanadoo.fr",
      fax: "+33147214340",
      name: "Pharmacie Sol et Touton",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      phone: "+33147211197",
      "ref:FR:FINESS": "920017860",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2017-09-22",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 523275913,
    x: 48.8901346,
    y: 2.1954893,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ganter-Oulié",
      opening_hours:
        "Mo 09:30-13:00,14:30-20:00; Tu-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19":
        "Mo 09:30-13:00,14:30-20:00; Tu-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:30",
      phone: "+33147210673",
      "ref:FR:FINESS": "920016227",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2017-09-22",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 529286147,
    x: 48.9875594,
    y: 2.245444,
    tags: {
      "addr:housenumber": "7",
      "addr:postcode": "95120",
      "addr:street": "Avenue du Président Georges Pompidou",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Cernay",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-20:00",
      phone: "+33 1 34 15 30 30",
      "ref:FR:FINESS": "950007716",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 530272886,
    x: 48.8506309,
    y: 2.3733225,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Quinze-Vingts",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "750020844",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 530272897,
    x: 48.8459797,
    y: 2.3731971,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Train Bleu",
      opening_hours: "Mo-Fr 08:00-22:00;Sa 09:00-21:00",
      "ref:FR:FINESS": "750021222",
      source: "survey;Celtipharm - 10/2014",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 530272918,
    x: 48.8465693,
    y: 2.3723121,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie du Départ",
      "ref:FR:FINESS": "750021651",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 531480572,
    x: 48.8447794,
    y: 2.3825189,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Viaduc",
      "ref:FR:FINESS": "750020893",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 534113628,
    x: 48.859647,
    y: 2.1480631,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Élysée Village",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780005781",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 534905511,
    x: 48.8460166,
    y: 2.3767039,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare de Lyon",
      "ref:FR:FINESS": "750021255",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 535320407,
    x: 48.8952933,
    y: 2.0821591,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "26",
      "addr:postcode": "78100",
      "addr:street": "Rue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Oiseaux",
      "ref:FR:FINESS": "780013140",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 536514454,
    x: 48.8274146,
    y: 2.3319952,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sarrette",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025066",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 537130405,
    x: 48.8275857,
    y: 2.2003872,
    tags: {
      amenity: "pharmacy",
      "contact:email": "pharmaciedesjardies@wanadoo.fr",
      "contact:phone": "+33 1 46 89 00 76",
      dispensing: "yes",
      entrance: "yes",
      name: "Pharmacie des Jardies",
      opening_hours:
        "Mo-Fr 08:30-12:30, 14:30-20:30; Sa 09:00-12:30, 14:30-19:00",
      "ref:FR:FINESS": "920008968",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-19",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 539377349,
    x: 49.0479736,
    y: 2.0957016,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950011031",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 542002846,
    x: 49.050389,
    y: 2.1003163,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Siou",
      "ref:FR:FINESS": "950011114",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 544792635,
    x: 49.0495666,
    y: 2.1027825,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lejour",
      "ref:FR:FINESS": "950011247",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 549998842,
    x: 48.9294948,
    y: 2.2683289,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marceau",
      "ref:FR:FINESS": "920013331",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 550179858,
    x: 49.0541976,
    y: 2.092888,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 57",
      "ref:FR:FINESS": "950011262",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 552052314,
    x: 49.058415,
    y: 2.0942773,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Charbonnier",
      "ref:FR:FINESS": "950011080",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 552954901,
    x: 48.9891234,
    y: 2.2592036,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 09:00-13:00,14:30-19:30; We,Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 34 15 10 64",
      "ref:FR:FINESS": "950007732",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 553329988,
    x: 48.7919413,
    y: 2.2860497,
    tags: {
      alt_name: "Pharmacie Phan",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920014115",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 555075563,
    x: 48.7373693,
    y: 2.3273174,
    tags: {
      "addr:city": "Wissous",
      "addr:housenumber": "2",
      "addr:postcode": "91320",
      "addr:street": "Chemin de la Vallée",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 30 21 91",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Wissous",
      "ref:FR:FINESS": "910014430",
      since: "1977-11-23",
    },
  },
  {
    type: "node",
    id: 557847646,
    x: 48.8471162,
    y: 2.4365305,
    tags: {
      alt_name: "Pharmacie Naccache",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Midi",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016140",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 557847649,
    x: 48.8478431,
    y: 2.4371598,
    tags: {
      alt_name: "Doussot pharmacie",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Vincennes",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015860",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 557847654,
    x: 48.847732,
    y: 2.4420494,
    tags: {
      alt_name: "Pharmacie Nissaraly Coeville",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bonne Nouvelle",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015993",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 557847657,
    x: 48.8481883,
    y: 2.4459932,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Defrance",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "940015910",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 558824391,
    x: 49.0775769,
    y: 1.9266415,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950013425",
      takeaway: "yes",
    },
  },
  {
    type: "node",
    id: 559000416,
    x: 49.0510763,
    y: 2.0971009,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lefort Paulin",
      "ref:FR:FINESS": "950011213",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2009;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 560158721,
    x: 48.8889696,
    y: 2.2199092,
    tags: {
      alt_name: "Pharmacie Hay",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Champs aux Melles",
      opening_hours: "Mo-Sa 09:00-19:45",
      "ref:FR:FINESS": "920017910",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 562967896,
    x: 49.0185644,
    y: 2.1000053,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Challe",
      "ref:FR:FINESS": "950007534",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 566152150,
    x: 48.8833224,
    y: 2.3133476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gatti",
      "ref:FR:FINESS": "750030801",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 567941037,
    x: 48.6963605,
    y: 2.154553,
    tags: {
      "addr:city": "Bures-sur-Yvette",
      "addr:housenumber": "2",
      "addr:postcode": "91440",
      "addr:street": "Rue de la Hacquinière",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 28 61 81",
      "contact:phone": "+33 1 64 46 62 93",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pénon Arnould",
      phone: "+33 1 85 77 03 11",
      "ref:FR:FINESS": "910006451",
      since: "1986-10-24",
    },
  },
  {
    type: "node",
    id: 567941044,
    x: 48.6972149,
    y: 2.1629661,
    tags: {
      "addr:city": "Bures-sur-Yvette",
      "addr:housenumber": "2",
      "addr:postcode": "91440",
      "addr:street": "Place de la Poste",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 07 48 43",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pierre Ben Fredj",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910006501",
      since: "1986-03-28",
    },
  },
  {
    type: "node",
    id: 567970605,
    x: 48.892816,
    y: 2.4122983,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Église",
      opening_hours: "Mo-Su 08:30-20:00",
      "opening_hours:covid19": "Mo-Su 08:30-20:00",
      "ref:FR:FINESS": "930012737",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 573739356,
    x: 48.943093,
    y: 2.4965602,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Vieux Pays",
      "ref:FR:FINESS": "930005137",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 574874556,
    x: 48.9028799,
    y: 2.4817821,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      "ref:FR:FINESS": "930006705",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 577383814,
    x: 48.8996344,
    y: 2.2458528,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 33 20 85",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Guillain",
      opening_hours:
        "Mo-Fr 08:30-12:45,14:00-19:30; Sa 09:00-12:30,14:00-19:00",
      "ref:FR:FINESS": "920013745",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 581704876,
    x: 48.888861,
    y: 2.1828072,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Desjardin",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00,15:00-19:30; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920019924",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 581704883,
    x: 48.8912676,
    y: 2.1925058,
    tags: {
      "addr:city": "Nanterre",
      "addr:housenumber": "67",
      "addr:postcode": "92000",
      "addr:street": "Rue Henri Barbusse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Petite Pharmacie de Nanterre",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      phone: "+33147217289",
      "ref:FR:FINESS": "920017795",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 581999840,
    x: 48.9984566,
    y: 2.0974258,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "780006961",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 583594654,
    x: 48.5730805,
    y: 2.3584113,
    tags: {
      "addr:city": "Vert-le-Grand",
      "addr:housenumber": "16",
      "addr:postcode": "91810",
      "addr:street": "Place de la Mairie",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 56 25 87",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Robert",
      "ref:FR:FINESS": "910006238",
      since: "2004-06-02",
    },
  },
  {
    type: "node",
    id: 586467255,
    x: 48.8521921,
    y: 2.4848099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sebbag",
      "ref:FR:FINESS": "940009004",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 595485039,
    x: 48.8357999,
    y: 2.3591086,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Laguintinie",
      "ref:FR:FINESS": "750022923",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 595640257,
    x: 48.8681611,
    y: 2.2256945,
    tags: {
      amenity: "pharmacy",
      "description:covid19":
        "Horaires non changés, pas de commande ni de livraison",
      dispensing: "yes",
      name: "Pharmacie du Pont de Suresnes",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 06 20 43",
      "ref:FR:FINESS": "920020955",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 596080629,
    x: 48.8889774,
    y: 2.3193555,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Batignolles",
      "ref:FR:FINESS": "750030967",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-12",
    },
  },
  {
    type: "node",
    id: 597266238,
    x: 48.8845426,
    y: 2.3213297,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      operator: "Haziza Brigitte",
      phone: "+33 1 45 22 52 78",
      "ref:FR:FINESS": "750029571",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597276232,
    x: 48.8928875,
    y: 2.3164329,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Népenthès",
      "ref:FR:FINESS": "750029951",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597292462,
    x: 48.9063406,
    y: 2.2836105,
    tags: {
      "addr:city": "Asnières-sur-Seine",
      "addr:housenumber": "2",
      "addr:postcode": "92600",
      "addr:street": "Rue Denis Papin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "920008463",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597297119,
    x: 48.9071166,
    y: 2.2859897,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Station",
      "ref:FR:FINESS": "920008661",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597698097,
    x: 48.8280997,
    y: 2.281412,
    tags: {
      "addr:city": "Issy-les-Moulineaux",
      "addr:housenumber": "44",
      "addr:postcode": "92130",
      "addr:street": "Rue Ernest Renan",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôpital",
      "opening_hours:covid19": "open",
      phone: "+33 1 40 93 44 64",
      "ref:FR:FINESS": "920015245",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597715246,
    x: 48.827293,
    y: 2.2796307,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie d'Issy",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 08:30-20:00",
      "opening_hours:covid19": "off",
      phone: "+33 1 40 93 44 64",
      "ref:FR:FINESS": "920015260",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597731013,
    x: 48.832047,
    y: 2.3041505,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Théodore",
      "ref:FR:FINESS": "750026783",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 597746140,
    x: 48.8346541,
    y: 2.3046336,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Brancion-Vouillé",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750027385",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 597749905,
    x: 48.8369162,
    y: 2.3060561,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Duquesne",
      "ref:FR:FINESS": "750026882",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 598451100,
    x: 49.1321301,
    y: 2.5129045,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "600004667",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 599582375,
    x: 48.9910516,
    y: 2.2591027,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Sa 09:15-12:30, 14:45-19:45",
      "opening_hours:covid19": "Mo-Sa 09:15-12:30,14:45-19:45",
      "ref:FR:FINESS": "950007682",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 599738046,
    x: 48.972968,
    y: 1.9818428,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014692",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 599956162,
    x: 48.8933844,
    y: 2.3277691,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "90",
      "addr:postcode": "75018",
      "addr:street": "Avenue de Saint-Ouen",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Renoul Georgel",
      "ref:FR:FINESS": "750034001",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-24",
    },
  },
  {
    type: "node",
    id: 600738924,
    x: 48.6972324,
    y: 2.1304914,
    tags: {
      "addr:city": "Gif-sur-Yvette",
      "addr:district": "Essonne",
      "addr:housenumber": "6",
      "addr:postcode": "91190",
      "addr:street": "Route de l'Abbaye",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 86 95 23",
      "contact:phone": "+33 1 69 07 75 68",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Berger",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910008887",
      start_date: "1992-03-11",
    },
  },
  {
    type: "node",
    id: 603506439,
    x: 48.8576477,
    y: 2.3541855,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Su 09:00-20:00",
      "opening_hours:covid19": "Mo-Su 09:00-20:00",
      "ref:FR:FINESS": "750010050",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 603506496,
    x: 48.8591132,
    y: 2.353881,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Village",
      opening_hours: "Mo-Sa 08:30-21:30; Su,PH 08:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010597",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 605018319,
    x: 48.8917065,
    y: 2.2012871,
    tags: {
      "addr:housenumber": "15",
      alt_name: "Pharmacie Benezra",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo-Fr 09:15-20:30; We,Sa 09:15-13:00,14:30-20:30",
      "opening_hours:covid19":
        "Mo,Tu,Th,Fr 09:15-20:30; We,Sa 09:15-13:00,14:30-20:30",
      phone: "+33147211742",
      "ref:FR:FINESS": "920017514",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "partial",
    },
  },
  {
    type: "node",
    id: 605019607,
    x: 48.9107608,
    y: 2.2214337,
    tags: {
      alt_name: "Pharmacie Hattal",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Petit Nanterre",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33147823630",
      "ref:FR:FINESS": "920017464",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 605047296,
    x: 48.8952159,
    y: 2.1965907,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "note:fr": "1 samedi sur 2 : 14:30-18:30",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 08:30-12:30,14:30-19:30; Sa 09:00-12:30",
      "opening_hours:covid19": "open",
      phone: "+33147211223",
      "ref:FR:FINESS": "920017894",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 605185947,
    x: 48.8800625,
    y: 2.2764554,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Piata",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 605199470,
    x: 48.883055,
    y: 2.2877856,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Baini",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 72 47 52",
      "ref:FR:FINESS": "750030371",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 605200013,
    x: 48.8853973,
    y: 2.2907825,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      "ref:FR:FINESS": "750031270",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 605501318,
    x: 48.8248961,
    y: 2.2955216,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 11 46",
      dispensing: "yes",
      name: "Grande Pharmacie Centrale du Plateau",
      "ref:FR:FINESS": "920021334",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 605686727,
    x: 48.8262497,
    y: 2.2780098,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale Mouton Unger",
      "opening_hours:covid19": "open",
      phone: "+33 1 40 93 44 74",
      "ref:FR:FINESS": "920015310",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 605687086,
    x: 48.8265051,
    y: 2.2787209,
    tags: {
      "addr:city": "Issy-les-Moulineaux",
      "addr:postcode": "92130",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "hassine.radjibaly@wanadoo.fr",
      name: "Pharmacie Corentin",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-19:30; Sa 09:00-12:30,14:00-18:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 40 93 02 42",
      "ref:FR:FINESS": "920015294",
      source: "Celtipharm - 10/2014",
      website: "http://pharmaciecorentin.fr/",
    },
  },
  {
    type: "node",
    id: 605706976,
    x: 48.8225949,
    y: 2.2765557,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Haut Moulin",
      "ref:FR:FINESS": "920015146",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 605844249,
    x: 48.8213151,
    y: 2.2855152,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 10 12",
      dispensing: "yes",
      name: "Guez",
      opening_hours: "Mo-Su 08:30-20:00",
      "ref:FR:FINESS": "920021300",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      website: "http://pharmacie-republique-guez-vanves.fr",
    },
  },
  {
    type: "node",
    id: 605946052,
    x: 48.8214042,
    y: 2.2734787,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dabi",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "920015161",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 608719988,
    x: 48.8178407,
    y: 2.2601802,
    tags: {
      "addr:city": "Issy-les-Moulineaux",
      "addr:housenumber": "54",
      "addr:postcode": "92130",
      "addr:street": "Rue d'Erevan",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.epinettes@gmail.com",
      name: "Pharmacie des Épinettes",
      opening_hours:
        "Mo-Fr 9:00-12:45,14:30-20:00;Sa 09:00-13:00,14:30-19:30; PH off",
      phone: "+33 1 46 38 75 19",
      "ref:FR:FINESS": "920015211",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 612560119,
    x: 48.932505,
    y: 2.4936571,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chiche",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930004817",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 614263816,
    x: 48.9694041,
    y: 2.3047011,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Thermes",
      "ref:FR:FINESS": "950007302",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 614319059,
    x: 48.8107978,
    y: 2.3078752,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "176",
      "contact:street": "Avenue Marx Dormoy",
      dispensing: "yes",
      name: "Pharmacie Marx Dormoy",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:30-12:30,14:30-19:30; Sa 09:30-13:00",
      "opening_hours:covid19":
        "Mo 14:30-19:30; Tu-Fr 09:30-12:30,14:30-19:30; Sa 09:30-13:00",
      phone: "+33 1 46 55 68 96",
      "ref:FR:FINESS": "920017225",
      "ref:FR:SIREN": "408419059",
      "ref:FR:SIRET": "40841905900014",
      source: "local Knowledge",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 614319060,
    x: 48.8135808,
    y: 2.3070756,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "131",
      "contact:street": "Avenue Jean Jaurès",
      dispensing: "yes",
      name: "Pharmacie du Square",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 42 53 04 88",
      "ref:FR:FINESS": "920017191",
      "ref:FR:SIREN": "398275883",
      "ref:FR:SIRET": "39827588300013",
      source: "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 614859970,
    x: 48.7703946,
    y: 2.3114497,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie CHAU VINH",
      "ref:FR:FINESS": "920008190",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 615299236,
    x: 48.8355331,
    y: 2.2320969,
    tags: {
      "addr:housenumber": "201",
      "addr:street": "Rue Galliéni",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Galliéni",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "920009727",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 619656858,
    x: 48.7793621,
    y: 2.3277269,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Truong",
      opening_hours:
        "Mo 15:00-19:30; Tu-Fr 09:00-12:30, 15:00-19:30; Sa 09:00-13:00,15:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009855",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 619967595,
    x: 48.8335964,
    y: 2.3657803,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nahmani",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022766",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 622478697,
    x: 48.819002,
    y: 2.2910809,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 18 41",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "920021185",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-06",
    },
  },
  {
    type: "node",
    id: 622662671,
    x: 48.9965796,
    y: 2.2551138,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmadeschenes95@gmail.com",
      healthcare: "pharmacy",
      name: "Pharmacie des Chênes",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-20:00; Sa 09:00-13:00, 15:00-19:30",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      phone: "+33 1 34 14 46 80",
      "ref:FR:FINESS": "950007773",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 624419674,
    x: 48.8379927,
    y: 2.4176004,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013147",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 624471734,
    x: 48.8351976,
    y: 2.3586346,
    tags: {
      alt_name: "Pharmacie Fuks",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "CareStore Salpétrière",
      "ref:FR:FINESS": "750022956",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 629569655,
    x: 48.8385579,
    y: 2.2512584,
    tags: {
      "addr:housenumber": "5",
      "addr:street": "Route de la Reine",
      alt_name: "Pharmacie Carron",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Stades",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-13:00",
      "ref:FR:FINESS": "920010394",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 631838817,
    x: 48.8510622,
    y: 2.5040724,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Arlette Scagliola-Jost",
      "ref:FR:FINESS": "940012495",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 632497870,
    x: 48.9436214,
    y: 2.4822777,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Balagny",
      "ref:FR:FINESS": "930004932",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 632680253,
    x: 48.84067,
    y: 2.4178437,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jeanne d'Arc",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013162",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 633156505,
    x: 48.8528327,
    y: 2.2754794,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie La Fontaine",
      opening_hours: "Mo 13:00-20:00, Tu-Fr 08:30-20:00, Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750027807",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 634659994,
    x: 48.8989078,
    y: 2.2093772,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Meziane",
      "ref:FR:FINESS": "920017431",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 637464383,
    x: 48.9034043,
    y: 2.3311715,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte de Saint-Ouen",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930015151",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 640684674,
    x: 48.8894281,
    y: 2.2024646,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carnelos",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:30-12:30,14:30-19:30; Sa 09:30-12:45,16:00-19:00",
      phone: "+33147213294",
      "ref:FR:FINESS": "920018090",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 642187251,
    x: 48.6879912,
    y: 2.384288,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:housenumber": "51",
      "addr:postcode": "91260",
      "addr:street": "Rue Monttessuy",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 21 15 67",
      "contact:phone": "+33 1 69 21 22 97",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Monttessuy",
      "ref:FR:FINESS": "910009455",
      since: "1991-07-10",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 648078123,
    x: 48.867548,
    y: 2.4092194,
    tags: {
      "addr:street": "Boulevard Mortier",
      alt_name: "Pharmacie Houdard",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Mortier",
      phone: "+33140317545",
      "ref:FR:FINESS": "750036352",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 652862427,
    x: 48.8039757,
    y: 1.964122,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Gâtines",
      "ref:FR:FINESS": "780012043",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 656416465,
    x: 48.8407096,
    y: 2.0783495,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Bailly",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780005047",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 656663677,
    x: 48.8375402,
    y: 2.4876251,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo 09:30-13:00,14:00-20:00; Tu-Th 09:00-13:00,14:00-20:00; Fr,Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "940011844",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 658054545,
    x: 48.7814184,
    y: 2.3159889,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Benhamou",
      "ref:FR:FINESS": "920011046",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 660187530,
    x: 48.8710933,
    y: 2.0971928,
    tags: {
      "addr:housenumber": "1",
      "addr:postcode": "78160",
      "addr:street": "Place de la Gare",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      operator: "Hauguel",
      "ref:FR:FINESS": "780010104",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 660663283,
    x: 48.8809801,
    y: 2.2850743,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "2",
      "addr:postcode": "75017",
      "addr:street": "Place du Général Kœnig",
      alt_name: "Pharmacie Wuhrlin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Sports",
      opening_hours: "Mo-Su 08:00-22:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 74 31 10",
      "ref:FR:FINESS": "750030355",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 661115975,
    x: 48.7786699,
    y: 2.0414309,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 130431276",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Beaujean",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "780010880",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 661155874,
    x: 48.9649453,
    y: 2.530472,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Baguet",
      "ref:FR:FINESS": "930016803",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 661733143,
    x: 48.8427327,
    y: 2.5028891,
    tags: {
      "addr:city": "Le Perreux-sur-Marne",
      "addr:housenumber": "72",
      "addr:postcode": "94170",
      "addr:street": "Avenue Ledru-Rollin",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Etienney",
      opening_hours: "Mo-Sa 08:00-20:00",
      phone: "+33 1 43 24 21 55",
      "ref:FR:FINESS": "940012560",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 661733147,
    x: 48.843211,
    y: 2.503185,
    tags: {
      "addr:city": "Le Perreux-sur-Marne",
      "addr:housenumber": "77",
      "addr:postcode": "94170",
      "addr:street": "Avenue Ledru-Rollin",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 43 24 21 72",
      "ref:FR:FINESS": "940012594",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 662618132,
    x: 48.5191453,
    y: 2.2957848,
    tags: {
      "addr:city": "Bouray-sur-Juine",
      "addr:housenumber": "59",
      "addr:postcode": "91850",
      "addr:street": "Rue Haute",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 27 10 71",
      "contact:phone": "+33 1 69 27 49 29",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Jacques Cabot",
      "ref:FR:FINESS": "910005834",
      since: "1989-08-25",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 663236746,
    x: 48.883307,
    y: 2.299164,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Courcelles - Demours",
      "ref:FR:FINESS": "750031221",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 664200524,
    x: 48.9945584,
    y: 2.1978314,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Sa 09:15-20:00",
      phone: "+33 1 34 50 87 01",
      "ref:FR:FINESS": "950010215",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 664695084,
    x: 48.8370966,
    y: 2.4955796,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rossignol",
      "ref:FR:FINESS": "940012610",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 665409019,
    x: 48.9902487,
    y: 2.1631849,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "950009282",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 665551065,
    x: 48.8416581,
    y: 2.5055762,
    tags: {
      "addr:city": "Le Perreux-sur-Marne",
      "addr:housenumber": "106",
      "addr:postcode": "94170",
      "addr:street": "Avenue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Aprium - Pharmacie du Marché",
      opening_hours: "Mo-Sa 08:30-20:00",
      phone: "+33 1 43 24 20 93",
      "ref:FR:FINESS": "940012511",
      source: "Celtipharm - 10/2014",
      website: "https://marche-perreux.aprium-pharmacie.fr/mapharmacie",
    },
  },
  {
    type: "node",
    id: 666459598,
    x: 48.8449639,
    y: 2.1869083,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché Saint-Louis",
      "ref:FR:FINESS": "920014313",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 667342365,
    x: 48.8735594,
    y: 2.3798822,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "pharmacie traditionnelle chinoise",
      "opening_hours:covid19": "off",
    },
  },
  {
    type: "node",
    id: 669075451,
    x: 48.8375698,
    y: 2.2972865,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Convention",
      "ref:FR:FINESS": "750027237",
      source: "knowledge;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 669904363,
    x: 48.7014999,
    y: 2.1044473,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Willemot",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "910018563",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 670068223,
    x: 48.5393605,
    y: 2.6401145,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dupuy",
      "ref:FR:FINESS": "770008324",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 670099009,
    x: 48.5433879,
    y: 2.6345875,
    tags: {
      "addr:housenumber": "760",
      "addr:street": "Avenue Maurice Dauvergne",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chy",
      "ref:FR:FINESS": "770008415",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 670930270,
    x: 48.8348078,
    y: 2.5074808,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours:
        "Mo 09:00-12:30,14:30-19:30; Tu-Fr 08:30-12:30,14:30-19:30; Sa 08:30-12:30,14:30-19:00",
      "ref:FR:FINESS": "940012644",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 670930448,
    x: 48.8341484,
    y: 2.5151959,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Mo 14:00-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940012677",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 670975095,
    x: 48.964375,
    y: 2.2556947,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Lasjunies",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "950012294",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 671784288,
    x: 48.9915908,
    y: 2.2917569,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fonsagrives",
      "ref:FR:FINESS": "950012864",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 674769683,
    x: 48.8811831,
    y: 2.3002006,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-04-27",
      dispensing: "yes",
      name: "Pharmacie Emrik",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750031833",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 674918038,
    x: 48.6329257,
    y: 2.5510213,
    tags: {
      "addr:city": "Lieusaint",
      "addr:country": "FR",
      "addr:housenumber": "1",
      "addr:postcode": "77127",
      "addr:street": "Place du Colombier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Colombier",
      "ref:FR:FINESS": "770007581",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "389243841",
      "ref:FR:SIRET": "38924384100027",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 678033087,
    x: 48.8600139,
    y: 2.3442832,
    tags: {
      "addr:city": "Paris 1er Arrondissement",
      "addr:housenumber": "12",
      "addr:postcode": "75001",
      "addr:street": "Rue du Pont Neuf",
      alt_name: "Pharmacie Fuger",
      amenity: "pharmacy",
      "description:covid19": "Une personne maximum dans la pharmacie",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmapontneuf@gmail.com",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont Neuf",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 10:00-18:00",
      phone: "+33 1 42 33 06 50",
      "ref:FR:FINESS": "750008724",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 678453760,
    x: 48.8844473,
    y: 2.296777,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Place du Maréchal Juin",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "Mo-Su 09:00-19:00",
      phone: "+33 1 47 63 34 39",
      "ref:FR:FINESS": "750030934",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 679068386,
    x: 48.8616909,
    y: 2.351843,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "54",
      "addr:postcode": "75003",
      "addr:street": "Rue Rambuteau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Beaubourg",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750009680",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 680651666,
    x: 48.8514096,
    y: 2.3002583,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Suffren",
      opening_hours: "Mo-Fr 08:45-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750027005",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 681294108,
    x: 48.8641153,
    y: 2.3464533,
    tags: {
      alt_name: "Pharmacie Yaker",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Eustache",
      "ref:FR:FINESS": "750008641",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 681459521,
    x: 48.8639239,
    y: 2.3401572,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Blaibel",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Banque",
      "ref:FR:FINESS": "750008526",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 681818442,
    x: 48.8664907,
    y: 2.3376599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Petits Champs",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-12:30",
      "ref:FR:FINESS": "750008674",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 681949203,
    x: 48.8671663,
    y: 2.334759,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Ben-Sallah",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bruno Fellous",
      "ref:FR:FINESS": "750008690",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 682267008,
    x: 48.8669846,
    y: 2.3362939,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Alain Fournier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Opéra-Victoire",
      "ref:FR:FINESS": "750009441",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 682347923,
    x: 48.8647572,
    y: 2.3326227,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Pyramides",
      "ref:FR:FINESS": "750008740",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 683283084,
    x: 48.865643,
    y: 2.3278956,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Champenier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Swann",
      "ref:FR:FINESS": "750008476",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 684322305,
    x: 49.0628845,
    y: 2.2991889,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Croix Bleue",
      "ref:FR:FINESS": "950005371",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 685287470,
    x: 48.8687712,
    y: 2.3355475,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Monsigny",
      opening_hours: "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750009045",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 685532211,
    x: 48.8721807,
    y: 2.3777353,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Au Gagne Petit",
      opening_hours: "Mo-Fr 08:30-21:00; Sa 09:00-21:00; Su 09:00-20:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 46 36 67 42",
      "ref:FR:FINESS": "750035701",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      website: "https://pharmacieaugagnepetit.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 691721746,
    x: 48.8644149,
    y: 2.3508382,
    tags: {
      alt_name: "Pharmacie Marciano",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mutualiste",
      opening_hours: "Mo-Fr 08:45-19:45, Sa 09:00-13:00",
      "opening_hours:covid19": "Mo-Fr 10:00-17:00",
      "ref:FR:FINESS": "750036972",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 694090000,
    x: 48.7924208,
    y: 2.5136105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Mo 14:30-20:00; Tu-Sa 09:00-12:30,14:30-20:00",
      "ref:FR:FINESS": "940013881",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 694353324,
    x: 49.1302348,
    y: 2.3707314,
    tags: {
      "addr:housenumber": "4",
      "addr:street": "Avenue de Royaumont",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharma Market",
      "ref:FR:FINESS": "950013375",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 694399122,
    x: 48.8700666,
    y: 2.3501189,
    tags: {
      "addr:housenumber": "19",
      "addr:street": "Boulevard de Bonne Nouvelle",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Globe",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750008914",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 699947385,
    x: 48.8591816,
    y: 2.3488558,
    tags: {
      alt_name: "Pharmacie Merly Monat",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Halles",
      opening_hours: "Mo-Su 08:30-21:00",
      "ref:FR:FINESS": "750010522",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 700405417,
    x: 48.8597261,
    y: 2.354074,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Temple",
      opening_hours: "Mo-Fr 08:45-21:00; Sa, Su 09:00-21:00",
      "ref:FR:FINESS": "750010613",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 701537704,
    x: 48.8600862,
    y: 2.3565927,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "1",
      "addr:postcode": "75004",
      "addr:street": "Rue Rambuteau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rambuteau",
      opening_hours: "Tu-Fr 09:00-20:00, Mo 10:00-20:00, Sa 10:00-19:00",
      "ref:FR:FINESS": "750010340",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 702296079,
    x: 48.8571292,
    y: 2.3541619,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 42744559",
      healthcare: "pharmacy",
      name: "Pharmacie des Archives",
      opening_hours: "Mo-Sa 08:30-20:30; Su 10:00-20:30",
      "opening_hours:covid19": "Mo-Su 09:00-21:00",
      phone: "+33 1 42784556",
      "ref:FR:FINESS": "750010035",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 702915499,
    x: 48.8573803,
    y: 2.3575668,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bénad",
      "ref:FR:FINESS": "750010720",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 702973628,
    x: 48.8397449,
    y: 2.3014421,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Casajuane Vitte",
      "ref:FR:FINESS": "750027187",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 702998963,
    x: 48.8811359,
    y: 2.096361,
    tags: {
      "addr:city": "Le Pecq",
      "addr:housenumber": "2",
      "addr:postcode": "78230",
      "addr:street": "Avenue des Vignes Benettes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Vignes Benettes",
      "ref:FR:FINESS": "780011797",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 703205799,
    x: 48.8257885,
    y: 2.3477809,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lor et Leuk",
      opening_hours: "Tu-Fr 08:30-20:00, Sa 09:00-19:30, Mo 09:00-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750023566",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 703876605,
    x: 48.8538727,
    y: 2.3672318,
    tags: {
      alt_name: "Pharmacie Zazoun",
      amenity: "pharmacy",
      "contact:phone": "+33142726743",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Tournelles",
      opening_hours: "Mo-Su 09:00-20:00",
      "ref:FR:FINESS": "750010670",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 703988503,
    x: 48.8540837,
    y: 2.3685677,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale de la Bastille",
      opening_hours: "Mo-Su 09:00-21:00",
      phone: "+33 1 42 72 38 31",
      "ref:FR:FINESS": "750010100",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 704808994,
    x: 48.9920064,
    y: 2.1680803,
    tags: {
      amenity: "pharmacy",
      description: "Homéopathie, herboristerie.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Château",
      opening_hours:
        "Mo-Fr 09:00-12:45,14:30-19:45; Sa 09:00-13:00,14:30-18:30",
      phone: "+33139971591",
      "ref:FR:FINESS": "950009316",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 705019158,
    x: 48.9905629,
    y: 2.1666853,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "position !",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:30,14:30-19:30",
      "ref:FR:FINESS": "950009217",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 705253315,
    x: 48.8542835,
    y: 2.3633155,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Paul",
      opening_hours: "Mo-Su 08:30-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010415",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 705627218,
    x: 48.8369764,
    y: 2.5207208,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Bry",
      opening_hours: "Mo-Sa 08:30-12:30,14:30-19:30",
      phone: "+33 9 53 41 29 68",
      "ref:FR:FINESS": "940006216",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 705627332,
    x: 48.8392295,
    y: 2.5230324,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Colombier",
      opening_hours:
        "Mo 14:00-19:30;Tu-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "940006257",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 706245867,
    x: 48.8651231,
    y: 2.3557678,
    tags: {
      amenity: "pharmacy",
      "description:covid19": "Amplitude réduite",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arts et Métiers",
      opening_hours: "Mo 12:00-20:00; Tu-Sa 09:00-20:00",
      "opening_hours:covid19": "same",
      phone: "+33148879835",
      "ref:FR:FINESS": "750009474",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 707316361,
    x: 48.8666938,
    y: 2.3609793,
    tags: {
      alt_name: "Pharmacie Métro-Temple",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Touati",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750009896",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 708980812,
    x: 48.8609056,
    y: 2.3546714,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Musées",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750009664",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 711950174,
    x: 48.7896718,
    y: 2.5747134,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "940012925",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 713468617,
    x: 48.8632446,
    y: 2.3614945,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Charret",
      "ref:FR:FINESS": "750009540",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 713493529,
    x: 48.8625296,
    y: 2.3635149,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Bretagne",
      opening_hours: "Mo-Sa 08:30-20:00",
      phone: "+33142784331",
      "ref:FR:FINESS": "750009524",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 713971866,
    x: 48.8612625,
    y: 2.3644482,
    tags: {
      alt_name: "Pharmacie CHAOUT",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie de Turenne",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750009961",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 721645570,
    x: 48.6313183,
    y: 2.2655072,
    tags: {
      "addr:city": "Linas",
      "addr:housenumber": "64",
      "addr:postcode": "91310",
      "addr:street": "Rue de la Division Leclerc",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 01 81 48",
      "contact:phone": "+33 1 69 01 01 01",
      dispensing: "yes",
      name: "Pharmacie Etienney",
      "ref:FR:FINESS": "910009620",
      since: "1991-08-08",
    },
  },
  {
    type: "node",
    id: 721645815,
    x: 48.6219368,
    y: 2.2689542,
    tags: {
      "addr:city": "Leuville-sur-Orge",
      "addr:country": "FR",
      "addr:housenumber": "3",
      "addr:postcode": "91310",
      "addr:street": "Rue du 8 Mai 1945",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 88 98 88",
      "contact:phone": "+33 1 60 84 22 56",
      dispensing: "yes",
      name: "Pharmacie de Leuville sur Orge",
      phone: "+33 1 60 84 22 56",
      "ref:FR:FINESS": "910009521",
      "ref:FR:NAF": "4773Z",
      since: "2006-04-10",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 725088658,
    x: 48.7921395,
    y: 2.5723903,
    tags: {
      "addr:city": "La Queue-en-Brie",
      "addr:country": "FR",
      "addr:housenumber": "2",
      "addr:postcode": "94510",
      "addr:street": "Avenue des Bordes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Bordes",
      "ref:FR:FINESS": "940012875",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 725089225,
    x: 48.7804942,
    y: 2.5737581,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Charmes",
      "ref:FR:FINESS": "940019136",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 725251929,
    x: 48.9910767,
    y: 2.1667689,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Valéry",
      "ref:FR:FINESS": "950009233",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 725893721,
    x: 48.8082784,
    y: 2.3878783,
    tags: {
      "addr:city": "Ivry-sur-Seine",
      "addr:housenumber": "40",
      "addr:postcode": "94200",
      "addr:street": "Rue Marat",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marat",
      "ref:FR:FINESS": "940010325",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 726888744,
    x: 48.8628288,
    y: 2.3793549,
    tags: {
      alt_name: "Pharmacie Legrand",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Saint-Maur",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750020075",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 729117597,
    x: 48.8805625,
    y: 2.3348575,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Théâtres",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750016115",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 731138346,
    x: 48.8796835,
    y: 2.3722606,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Sébag",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034985",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 734318719,
    x: 48.864011,
    y: 2.335707,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Bénouaiche",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Théâtre Français",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 10:00-19:30",
      "ref:FR:FINESS": "750008443",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 734859722,
    x: 48.8845456,
    y: 2.3332998,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Générale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032872",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 734862051,
    x: 48.885539,
    y: 2.3346124,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Butte Montmartre",
      "opening_hours:covid19": "Mo-Sa 10:30-19:00",
      "ref:FR:FINESS": "750032906",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 734862331,
    x: 48.886018,
    y: 2.335121,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Moulin",
    },
  },
  {
    type: "node",
    id: 734865241,
    x: 48.8861637,
    y: 2.3381011,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Bateau Lavoir",
      "ref:FR:FINESS": "750033854",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 734869011,
    x: 48.881922,
    y: 2.337556,
    tags: {
      alt_name: "Pharmacie Bailly",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Internationale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750016560",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 739079392,
    x: 48.8033475,
    y: 2.4850558,
    tags: {
      "addr:housenumber": "20",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Filippi",
      opening_hours: "Mo-Sa 09:00-19:45",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00, 14:00-19:00",
      "ref:FR:FINESS": "940013246",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-06",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 742229603,
    x: 48.6033071,
    y: 2.4686907,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:country": "FR",
      "addr:housenumber": "31",
      "addr:postcode": "91100",
      "addr:street": "Rue de Paris",
      alt_name: "SNC Pharmacie Somonnian Dossikian",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 96 97 36",
      "contact:phone": "+33 1 64 96 97 36",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de l'Église",
      "ref:FR:FINESS": "910007004",
      "ref:FR:NAF": "4773Z",
      since: "1986-03-27",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 744699261,
    x: 48.94717,
    y: 2.2564804,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Esplanade",
      "ref:FR:FINESS": "950004903",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 745206754,
    x: 48.7725739,
    y: 1.9510453,
    tags: {
      "addr:city": "Élancourt",
      "addr:housenumber": "7",
      "addr:postcode": "78990",
      "addr:street": "Rue de la Haie à Sorel",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des 4 Arbres",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 30 51 65 15",
      "ref:FR:FINESS": "780007613",
      source: "Celtipharm - 10/2014;local knowledge",
      website: "https://pharmacie-elancourt.fr/",
    },
  },
  {
    type: "node",
    id: 745207210,
    x: 48.7756117,
    y: 1.9459402,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "780007563",
    },
  },
  {
    type: "node",
    id: 745212345,
    x: 48.7705773,
    y: 1.9713676,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie des Templiers",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007464",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 759630230,
    x: 48.9086308,
    y: 2.1521238,
    tags: {
      "addr:housenumber": "46",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      ele: "49",
      name: "Pharmacie de la Plaine",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-20:00",
      "ref:FR:FINESS": "780010765",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 760473013,
    x: 48.8843053,
    y: 2.4769557,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de Rosny II",
      "ref:FR:FINESS": "930013800",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 761355693,
    x: 48.83988,
    y: 2.3609348,
    tags: {
      "addr:housenumber": "8",
      "addr:street": "Boulevard Saint-Marcel",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lam",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011983",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ;mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 765531776,
    x: 48.9068778,
    y: 2.150785,
    tags: {
      "addr:housenumber": "10",
      "addr:street": "Place Paul Demange",
      amenity: "pharmacy",
      "contact:email": "harmelpharmacie@orange.fr",
      "contact:phone": "+33130711374",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 08:30-20:00",
      "ref:FR:FINESS": "780017760",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 768065634,
    x: 48.7353361,
    y: 2.3238632,
    tags: {
      "addr:city": "Wissous",
      "addr:housenumber": "19",
      "addr:postcode": "91320",
      "addr:street": "Rue d'Antony",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 30 53 14",
      "contact:phone": "+33 1 69 20 86 58",
      dispensing: "yes",
      name: "Pharmacie Polge",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910014463",
      since: "1994-12-13",
    },
  },
  {
    type: "node",
    id: 768139329,
    x: 48.9197338,
    y: 1.9764275,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "ref:FR:FINESS": "780011631",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 770728175,
    x: 48.8679153,
    y: 2.44625,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 7 Chemins",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "930011036",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 773250388,
    x: 48.8647595,
    y: 2.3990532,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gambetta",
      "ref:FR:FINESS": "750036071",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 774899525,
    x: 48.802115,
    y: 2.4298726,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lellouch",
      "ref:FR:FINESS": "940011315",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 774899527,
    x: 48.804063,
    y: 2.4272933,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "86",
      "contact:street": "Avenue du Général de Gaulle",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie Servat",
      "ref:FR:FINESS": "940011331",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 774899536,
    x: 48.800928,
    y: 2.4317282,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011497",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 775834696,
    x: 48.9217915,
    y: 2.1873816,
    tags: {
      alt_name: "Pharmacie PHEULPIN",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 08:30-20:00",
      "ref:FR:FINESS": "780008447",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 775883888,
    x: 48.5853439,
    y: 2.3046771,
    tags: {
      amenity: "pharmacy",
      brand: "Univers Pharmacie",
      dispensing: "yes",
      ele: "83",
      name: "Univers Pharmacie",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 778108810,
    x: 48.8051016,
    y: 2.325706,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tran Van Thoan",
      "ref:FR:FINESS": "940005671",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 778236582,
    x: 48.8533506,
    y: 2.3334547,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Germain des Prés",
      "ref:FR:FINESS": "750012296",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 778379149,
    x: 48.8521594,
    y: 2.3307743,
    tags: {
      alt_name: "Pharmacie Sacareau",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Époque",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:00",
      "ref:FR:FINESS": "750012726",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 779345864,
    x: 48.9439074,
    y: 2.3253703,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920008729",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 784048487,
    x: 48.5247454,
    y: 2.384678,
    tags: {
      "addr:city": "Ballancourt-sur-Essonne",
      "addr:country": "FR",
      "addr:housenumber": "2",
      "addr:postcode": "91610",
      "addr:street": "Place de la Liberté",
      amenity: "pharmacy",
      "contact:fax": "+33 164935863",
      "contact:phone": "+33 164932048",
      datemaj: "2013-04-12",
      dispensing: "yes",
      name: "Pharmacie Larmanon Fabienne",
      "ref:FR:FINESS": "910005636",
      since: "1989-05-29",
      start_date: "1989-05-29",
    },
  },
  {
    type: "node",
    id: 785722420,
    x: 48.8487297,
    y: 2.3287519,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Normale de la Rive Gauche",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750012981",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 786868967,
    x: 48.8294421,
    y: 2.5640209,
    tags: {
      "addr:city": "Noisy-le-Grand",
      "addr:housenumber": "58",
      "addr:postcode": "93160",
      "addr:street": "Avenue Médéric",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Méderic",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 03 35 06",
      "ref:FR:FINESS": "930011986",
      source: "Celtipharm - 10/2014",
      website: "https://pharmaciemederic.fr",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 787704388,
    x: 48.846996,
    y: 2.3301669,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie D'Assas",
      "opening_hours:covid19": "open",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 787964372,
    x: 48.8439265,
    y: 2.3302977,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Vavin",
      "ref:FR:FINESS": "750013427",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 788508711,
    x: 48.649172,
    y: 2.4094219,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "38",
      "addr:postcode": "91130",
      "addr:street": "Avenue George Sand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 06 67 78",
      "contact:phone": "+33 1 69 06 67 78",
      dispensing: "yes",
      name: "Pharmacie Joëlle et Thierry Torti",
      opening_hours:
        "Mo 09:00-13:00,14:00-20:00; Tu-Sa 08:30-13:00,14:30-20:00",
      "ref:FR:FINESS": "910012061",
      since: "1989-01-25",
    },
  },
  {
    type: "node",
    id: 788761911,
    x: 48.839656,
    y: 2.3373647,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Observatoire",
      opening_hours:
        'Mo-Fr 08:00-20:00, Sa 09:00-20:00, PH 08:00-21:00; Su 08:00-21:00 "Dimanches de garde uniquement"',
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750011686",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 789986392,
    x: 48.8944168,
    y: 2.3812142,
    tags: {
      alt_name: "Pharmacie Ben-Hamou",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Flandre",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      phone: "+33 1 40 36 66 93",
      "ref:FR:FINESS": "750034654",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 791691407,
    x: 48.8438609,
    y: 2.3223528,
    tags: {
      alt_name: "Pharmacie Dahan",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de l'Arrivée",
      "ref:FR:FINESS": "750025207",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 791812527,
    x: 48.8485184,
    y: 2.3248051,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "57",
      "addr:postcode": "75006",
      "addr:street": "Rue du Cherche-Midi",
      alt_name: "Pharmacie Centrale du Cherche Midi",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.bravo@free.fr",
      healthcare: "pharmacy",
      name: "Pharmacie Bravo",
      opening_hours: "Mo-Fr 09:00-20:00; sa 09:00-19:30",
      phone: "+33 1 45 48 12 76",
      "ref:FR:FINESS": "750012445",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      website: "https://www.pharmaciebravo.com/",
    },
  },
  {
    type: "node",
    id: 791813753,
    x: 48.8489974,
    y: 2.3252251,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Autissier",
      "ref:FR:FINESS": "750012403",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 791813888,
    x: 48.8473682,
    y: 2.3262422,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Placide",
      "ref:FR:FINESS": "750013161",
      source: "survey  2016",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 791851108,
    x: 48.8460135,
    y: 2.3197443,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "122",
      "addr:postcode": "75006",
      "addr:street": "Rue du Cherche-Midi",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Canillac",
      opening_hours:
        "Mo 12:00-20:00; Tu-Fr 09:30-20:00; Sa 09:30-13:30,15:00-19:30",
      phone: "+33 1 47 34 88 88",
      "ref:FR:FINESS": "750012486",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 794784939,
    x: 49.0182356,
    y: 2.1119551,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Grillon",
      "ref:FR:FINESS": "950007500",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 794827567,
    x: 48.7021803,
    y: 2.1326956,
    tags: {
      "addr:city": "Gif-sur-Yvette",
      "addr:housenumber": "48",
      "addr:postcode": "91190",
      "addr:street": "Rue Henri Amodru",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 07 50 01",
      "contact:phone": "+33 1 69 07 50 53",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Delmas",
      opening_hours:
        "Mo 14:30-20:00, Tu-Fr 09:00-12:30,14:30-20:00, Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "Mo-Sa 09:30-12:30,14:30-19:00",
      "ref:FR:FINESS": "910008952",
      since: "1991-12-26",
    },
  },
  {
    type: "node",
    id: 797062898,
    x: 48.9261625,
    y: 2.0525152,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "4",
      "addr:postcode": "78300",
      "addr:street": "Rue Ernest Lavisse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dhont",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780012225",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 797456588,
    x: 48.8391455,
    y: 2.3957152,
    tags: {
      "addr:housenumber": "6",
      "addr:street": "Place Félix Éboué",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Daumesnil",
      opening_hours: "Su-Fr 08:00-21:00; Sa 9:00-20:00",
      "opening_hours:covid19": "Mo-Su 9:00-20:00",
      phone: "+33143431903",
      "ref:FR:FINESS": "750021487",
      source:
        "Celtipharm - 10/2014;cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
    },
  },
  {
    type: "node",
    id: 801199195,
    x: 48.8477564,
    y: 2.3193946,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Sèvres",
      opening_hours: "Mo-Sa 08:30-19:30",
      "ref:FR:FINESS": "750013377",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 812005422,
    x: 48.8283703,
    y: 2.1154684,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      ele: "152",
      name: "La Générale de Pharmacie",
    },
  },
  {
    type: "node",
    id: 813269040,
    x: 48.9238423,
    y: 2.2539028,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Haut Saint-Denis",
      "ref:FR:FINESS": "920013414",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 813926942,
    x: 48.8714188,
    y: 2.3150011,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Internationale",
      "ref:FR:FINESS": "750014847",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 814051566,
    x: 48.8729521,
    y: 2.3158478,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arts Élysées",
      "opening_hours:covid19": "Mo-Fr 09:00-18:00; Sa,Su 09:00-13:00",
      "ref:FR:FINESS": "750015315",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 814114775,
    x: 48.923869,
    y: 2.25635,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "LeaderSanté",
      "ref:FR:FINESS": "920013380",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 815764500,
    x: 48.8712562,
    y: 2.3235785,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Madeleine",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750014730",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 817222490,
    x: 48.8755539,
    y: 2.3252185,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Bailly",
      opening_hours: "Mo-Fr 08:00-18:00; Sa 10:00-18:00",
      phone: "+33 1 53 42 10 10",
      "ref:FR:FINESS": "750015513",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      website: "https://www.pharmaciebailly.com/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 817809644,
    x: 48.8769049,
    y: 2.3214132,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Rocher",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750015430",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 819101587,
    x: 48.8776805,
    y: 2.3267977,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie d'Amsterdam",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750014441",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 819175965,
    x: 48.8785701,
    y: 2.3268262,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "31",
      "addr:postcode": "75008",
      "addr:street": "Rue d'Amsterdam",
      alt_name: "Pharmacie Schies Tauzet",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Europe",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750014474",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      website: "https://www.pharmaciehomeo.com/",
    },
  },
  {
    type: "node",
    id: 819176114,
    x: 48.8794888,
    y: 2.3267113,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Liège",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00; Sa 09:00-13:00",
      "ref:FR:FINESS": "750015141",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 819866707,
    x: 48.8808916,
    y: 2.3244418,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Amsallem",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750014763",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 821320870,
    x: 48.923866,
    y: 2.2565875,
    tags: {
      alt_name: "Pharmacie CARRIER",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Village",
      "ref:FR:FINESS": "920013364",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 821879424,
    x: 48.8834171,
    y: 2.3280471,
    tags: {
      "addr:housenumber": "6",
      "addr:street": "Place de Clichy",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Selarl Pharmacie Européenne",
      opening_hours: "24/7",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750015877",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 821902039,
    x: 48.8829212,
    y: 2.3254797,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Acacias",
      opening_hours:
        "Mo,Tu,Th,Fr 08:00-20:00; We 08:00-13:30,15:30-20:00; Sa 09:00-13:30,15:30-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-19:30",
      "ref:FR:FINESS": "750014524",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 821902088,
    x: 48.8830214,
    y: 2.3238111,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fermon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750029506",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 822197633,
    x: 48.8799596,
    y: 2.3179042,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Moderne",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "750015463",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 822259798,
    x: 48.8781681,
    y: 2.3163408,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boiron",
      "opening_hours:covid19": "Mo-Fr 09:30-19:00; Su 10:00-19:00",
      "ref:FR:FINESS": "750015190",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 823784799,
    x: 48.8766669,
    y: 2.3130694,
    tags: {
      alt_name: "Pharmacie Kassis",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie La Bienfaisance",
      "ref:FR:FINESS": "750014581",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 823852244,
    x: 48.8752912,
    y: 2.3169194,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Haussmann - Laborde",
      "ref:FR:FINESS": "750015042",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 825380483,
    x: 48.7675512,
    y: 2.3336839,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Akwa",
      "ref:FR:FINESS": "940009905",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 825380497,
    x: 48.7732136,
    y: 2.338467,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Roseraie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007735",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 826357135,
    x: 48.8225152,
    y: 2.3277378,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "109",
      "contact:postcode": "75014",
      "contact:street": "Boulevard Jourdan",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jourdan",
      "ref:FR:FINESS": "750024416",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 827460283,
    x: 48.8477369,
    y: 2.3121791,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dorel",
      "ref:FR:FINESS": "750013740",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 827665902,
    x: 48.9197199,
    y: 2.2801221,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Drai",
      "ref:FR:FINESS": "920008315",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 827922876,
    x: 48.881085,
    y: 2.3088634,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Rotonde",
      opening_hours: "Mo-Fr 08:00-20:00",
      "ref:FR:FINESS": "750031155",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 828116873,
    x: 48.8750379,
    y: 2.3049347,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Triangle",
      operator: "Aprium",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 828184438,
    x: 48.9724933,
    y: 2.2557043,
    tags: {
      "addr:housenumber": "86",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boulbet",
      opening_hours:
        "Mo-Fr 08:30-12:30, 14:30-20:00; Sa 09:00-13:00, 15:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950012112",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 828200984,
    x: 48.970424,
    y: 2.2609267,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bounetta",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 39 81 20 43",
      "ref:FR:FINESS": "950012096",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 828353815,
    x: 48.9309529,
    y: 2.3540341,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-19:30",
      "ref:FR:FINESS": "930016498",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 829051642,
    x: 48.8715067,
    y: 2.3073286,
    tags: {
      alt_name: "Pharmacie Bénamran",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie La Boétie Champs-Élysées",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 10:00-20:00",
      "ref:FR:FINESS": "750015091",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 829526567,
    x: 48.8708475,
    y: 2.3057806,
    tags: {
      alt_name: "Pharmacie Téboul Le Cardiet",
      amenity: "pharmacy",
      "description:covid19": "Garde de minuit à 8h (appeler auparavant)",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Anglaise des Champs-Élysées",
      "opening_hours:covid19": "Mo-Su 09:00-20:30",
      phone: "+33 1 43 59 22 52",
      "ref:FR:FINESS": "750014631",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 830671206,
    x: 48.8674157,
    y: 2.3033941,
    tags: {
      alt_name: "Pharmacie Chouchana Kayes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Européenne",
      "ref:FR:FINESS": "750015125",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 833088619,
    x: 48.8681915,
    y: 2.298684,
    tags: {
      amenity: "pharmacy",
      "contact:facebook": "Pharmacie-Anglo-Americaine-1820597488226286",
      "contact:phone": "+33 9 67 22 07 37",
      dispensing: "yes",
      name: "Pharmacie Anglo-Américaine",
      "opening_hours:covid19": "Mo 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750028904",
      source: "survey; Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 833088724,
    x: 48.8658462,
    y: 2.2993986,
    tags: {
      alt_name: "Pharmacie Sabbagh",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marceau",
      "ref:FR:FINESS": "750028870",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 833344020,
    x: 48.8696182,
    y: 2.2979209,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Djololian",
      opening_hours: "Mo-Fr 08:30-19:45, Sa 09:00-13:00",
      "ref:FR:FINESS": "750028920",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 833522134,
    x: 48.8724883,
    y: 2.3022117,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hibert",
      "ref:FR:FINESS": "750015596",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 835772211,
    x: 48.7222754,
    y: 2.4987373,
    tags: {
      "addr:city": "Yerres",
      "addr:postcode": "91330",
      "addr:street": "Avenue de la Grange",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 48 29 61",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie des Arcades",
      "ref:FR:FINESS": "910014646",
      since: "2009-04-14",
    },
  },
  {
    type: "node",
    id: 837955137,
    x: 48.814998,
    y: 2.234549,
    tags: {
      "addr:housenumber": "3",
      "addr:postcode": "92190",
      "addr:street": "Place Stalingrad",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cottard",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr,PH 09:00-12:30,14:30-20:00; Sa 09:00-13:00,14:30-20:00; Su off",
      "ref:FR:FINESS": "920016847",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 838702682,
    x: 48.995033,
    y: 1.9101306,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Legrain",
      "ref:FR:FINESS": "780011391",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 838703955,
    x: 48.9927944,
    y: 1.9105597,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "780011292",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 839186509,
    x: 48.8465849,
    y: 2.4113407,
    tags: {
      alt_name: "Pharmacie Citypharma",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Porte de Vincennes",
      opening_hours: "24/7",
      "ref:FR:FINESS": "750022170",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 839279998,
    x: 48.9521995,
    y: 2.2783464,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie LAM",
      "ref:FR:FINESS": "950004606",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 839368911,
    x: 48.95134,
    y: 2.2440208,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "La Pharmacie du Triage",
      "ref:FR:FINESS": "950005132",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 844139902,
    x: 48.7583,
    y: 2.306249,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      ele: "97",
      name: "Pharmacie Antony Briand",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920007796",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 864653475,
    x: 48.8233194,
    y: 2.3476282,
    tags: {
      alt_name: "Pharmacie Goulant",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bobillot Montsouris",
      opening_hours:
        "Mo-Sa 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:00-19:30; PH closed",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022535",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 864663708,
    x: 48.8406008,
    y: 2.2277694,
    tags: {
      alt_name: "Pharmacie JUNQUA",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Rond-Point",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920010600",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 869615087,
    x: 48.7020686,
    y: 2.3209677,
    tags: {
      "addr:city": "Chilly-Mazarin",
      "addr:postcode": "91380",
      "addr:street": "Place de la Libération",
      alt_name: "SELARL Pharmacie Centrale",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 34 02 88",
      "contact:phone": "+33 1 69 09 25 20",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "910006683",
      since: "2007-05-03",
    },
  },
  {
    type: "node",
    id: 869615107,
    x: 48.7056297,
    y: 2.3153821,
    tags: {
      "addr:city": "Chilly-Mazarin",
      "addr:housenumber": "36",
      "addr:postcode": "91380",
      "addr:street": "Avenue Mazarin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 09 79 63",
      "contact:phone": "+33 1 69 09 79 67",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Leloup",
      "ref:FR:FINESS": "910006733",
      since: "1967-02-15",
    },
  },
  {
    type: "node",
    id: 870473510,
    x: 48.8762458,
    y: 2.4177548,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vercruysse",
      "ref:FR:FINESS": "930009154",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 871715214,
    x: 48.7041356,
    y: 2.3175736,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Chilly-Mazarin",
      "contact:district": "Essonne",
      "contact:fax": "+33 1 60 49 27 59",
      "contact:housenumber": "20",
      "contact:phone": "+33 1 69 09 13 89",
      "contact:postcode": "91380",
      "contact:street": "Avenue Mazarin",
      dispensing: "yes",
      ele: "128.40",
      name: "Pharmacie Monrocq Reytier",
      "ref:FR:FINESS": "910006717",
      start_date: "1991-05-14",
    },
  },
  {
    type: "node",
    id: 874577021,
    x: 48.8437797,
    y: 2.1865902,
    tags: {
      "addr:city": "Garches",
      "addr:housenumber": "9",
      "addr:street": "Avenue du Maréchal Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de L'Hôtel de Ville",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-20:00;Sa 09:00-13:00,14:00-19:30;PH closed",
      "ref:FR:FINESS": "920014412",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 875696736,
    x: 48.8330505,
    y: 2.2538887,
    tags: {
      "addr:housenumber": "62",
      "addr:street": "Avenue Pierre Grenier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Point du Jour",
      opening_hours: "Mo 12:00-19:30; Tu-Fr 09:00-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "920010121",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 877958983,
    x: 48.8835841,
    y: 2.3602483,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Balmagie Grojsman",
      opening_hours: "Mo 12:00-19:45; Tu-Fr 09:00-19:45; Sa 09:00-19:30",
      "opening_hours:covid19":
        "Mo 12:00-19:45; Tu-Fr 09:00-19:45; Sa 09:00-19:30",
      "ref:FR:FINESS": "750018046",
      source: "Celtipharm - 10/2014",
      "survey:date": "2018-12-22",
    },
  },
  {
    type: "node",
    id: 883484145,
    x: 48.880262,
    y: 2.3643405,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Doumerc",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "same",
      phone: "+33 1 46 07 34 71",
      "ref:FR:FINESS": "750017691",
      source: "survey 11/2017",
    },
  },
  {
    type: "node",
    id: 887163339,
    x: 48.8825662,
    y: 2.2174552,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Bourhis",
      phone: "+33142040997",
      "ref:FR:FINESS": "920018165",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 887165786,
    x: 48.8856366,
    y: 2.217581,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ismaldjee",
      opening_hours:
        "Mo 14:00-19:30; Tu-Fr 09:00-13:30,15:00-19:30; Sa 09:30-12:30,15:00-17:30",
      phone: "+33142041748",
      "ref:FR:FINESS": "920017712",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 887166006,
    x: 48.8810286,
    y: 2.2207674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Alfandary",
      opening_hours: "Mo-Sa 09:00-12:30, Tu-Sa 14:00-20:00",
      "ref:FR:FINESS": "920017563",
      source: "Celtipharm - 10/2014",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 892698757,
    x: 48.4331545,
    y: 2.753372,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cauquil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770013027",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 892853440,
    x: 48.8338784,
    y: 2.2435192,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 08:00-21:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009974",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 892853449,
    x: 48.8319994,
    y: 2.2377044,
    tags: {
      "addr:housenumber": "60-64",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Parmacie Billancourt",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "920010931",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 896464993,
    x: 48.9853312,
    y: 2.2369193,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre Commercial Cadet de Vaux",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-20:00; Sa 09:00-13:00, 14:30-19:00",
      phone: "+33 1 34 15 59 59",
      "ref:FR:FINESS": "950007971",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 901762682,
    x: 48.8937723,
    y: 2.2563669,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "37",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-12:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013992",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 901762725,
    x: 48.8397284,
    y: 2.2469869,
    tags: {
      alt_name: "Pharmacie Weiss",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Victor Hugo",
      opening_hours: "Mo-Fr 08:30-19:45; Sa 09:00-13:00, 14:00-19:30",
      phone: "+33 1 46 05 28 28",
      "ref:FR:FINESS": "920010865",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 906036071,
    x: 48.9952811,
    y: 2.2206439,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Marais",
      "ref:FR:FINESS": "950008201",
      source: "survey",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 909529788,
    x: 48.8196355,
    y: 2.365317,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte de Choisy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023350",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 911887919,
    x: 48.9978126,
    y: 2.2773276,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Grand Cèdre",
      opening_hours:
        "Tu-Fr 09:00-12:30, 14:30-19:30; Mo 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      phone: "+33 1 34 16 17 84",
      "ref:FR:FINESS": "950007070",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 911888240,
    x: 48.9915271,
    y: 2.276647,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Europe",
      opening_hours:
        "We-Th, Sa 09:00-12:30, 14:30-19:30; Tu, Fr 08:45-13:00, 14:30-19:30; Mo 14:30-19:30",
      "ref:FR:FINESS": "950007005",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 915432099,
    x: 48.9850548,
    y: 2.2727836,
    tags: {
      "addr:housenumber": "67",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 2000",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-20:00; Sa 09:00-12:30, 14:30-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30, 14:30-19:30",
      phone: "+33 1 39 59 45 14",
      "ref:FR:FINESS": "950007021",
      source: "survey 2020",
    },
  },
  {
    type: "node",
    id: 916544980,
    x: 48.935787,
    y: 2.4878389,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tour Eiffel",
      opening_hours: "Mo-Fr 08:30-12:30,14:30-19:30, Sa 09:30-16:30",
      phone: "+33148666552",
      "ref:FR:FINESS": "930005186",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 916889384,
    x: 48.8919768,
    y: 2.4061085,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Poste",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-19:00",
      "ref:FR:FINESS": "930012687",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 918087198,
    x: 48.8488819,
    y: 2.3707431,
    tags: {
      alt_name: "Pharmacie Blanc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nouvelle de Lyon",
      opening_hours: "Mo-Fr 08:30-19:30, Sa 09:00-13:30,15:00-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750021701",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 918317415,
    x: 48.8806948,
    y: 2.3618078,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bensoussan",
      "ref:FR:FINESS": "750017931",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 919110930,
    x: 48.927772,
    y: 2.2536773,
    tags: {
      "addr:city": "Colombes",
      "addr:housenumber": "55",
      "addr:postcode": "92700",
      "addr:street": "Boulevard de Valmy",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Valmy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013497",
      source: "survey;Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 919869896,
    x: 48.9330673,
    y: 2.4936856,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie SAAL",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "930004833",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 922525996,
    x: 48.8344874,
    y: 2.2428848,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "L'Amicale",
      opening_hours: "Mo-Fr 08:00-21:00; Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "920009941",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 925740062,
    x: 48.8654723,
    y: 2.2956788,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Galliera",
      "ref:FR:FINESS": "750029035",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 926046019,
    x: 48.9975869,
    y: 2.108489,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Savignat",
      "ref:FR:FINESS": "780007191",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 926540866,
    x: 48.8699534,
    y: 2.2961465,
    tags: {
      alt_name: "Pharmacie Dahan",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Galilée",
      "ref:FR:FINESS": "750028557",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 926569989,
    x: 48.8675617,
    y: 2.2975753,
    tags: {
      alt_name: "Pharmacie Goldes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Pierre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750028573",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 926662788,
    x: 48.8720178,
    y: 2.2948076,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Étoile-Iéna",
      opening_hours: "Mo-Fr 09:00-19:30",
      phone: "+33 1 45 00 60 66",
      "ref:FR:FINESS": "750028755",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 926845338,
    x: 48.9620215,
    y: 2.279351,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950011510",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 928066689,
    x: 48.8407413,
    y: 2.3515357,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Guenon des Mesnards",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011447",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 928560007,
    x: 48.8683863,
    y: 2.2914212,
    tags: {
      alt_name: "Pharmacie Depery",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Boissière",
      "ref:FR:FINESS": "750028656",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 929429310,
    x: 48.5842085,
    y: 2.4511071,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centre Commercial Villabé A6",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 86 07 85",
      ref: "1",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 929441372,
    x: 48.8648186,
    y: 2.2917006,
    tags: {
      alt_name: "Pharmacie Lubeck",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cornillon",
      "ref:FR:FINESS": "750028821",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 929442068,
    x: 48.8651067,
    y: 2.2883942,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Trocadéro",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:30-20:00",
      "ref:FR:FINESS": "750028722",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 929536204,
    x: 48.8639118,
    y: 2.2882305,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Kléber",
      opening_hours: "Mo-Fr 08:30-21:00; Sa-Su 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-18:30",
      "ref:FR:FINESS": "750028706",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 930541474,
    x: 48.762079,
    y: 2.3081395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hamarsy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920007812",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 932347069,
    x: 48.867148,
    y: 2.2878128,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lengronne",
      opening_hours: "Mo-Fr 08:30-20:00",
      "ref:FR:FINESS": "750028805",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 932350774,
    x: 48.8675763,
    y: 2.2902468,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dubly",
      "ref:FR:FINESS": "750028672",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 933570011,
    x: 48.8832777,
    y: 2.2042531,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sebag",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920017977",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 933859473,
    x: 48.9265977,
    y: 2.4695776,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Toledano",
      "ref:FR:FINESS": "930006036",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 933859561,
    x: 48.924456,
    y: 2.4631714,
    tags: {
      alt_name: "Pharmacie GONCALVES",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Angelus",
      "ref:FR:FINESS": "930006051",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 933859588,
    x: 48.9180172,
    y: 2.4478232,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ohayon",
      "ref:FR:FINESS": "930007802",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 933859708,
    x: 48.9147965,
    y: 2.4411899,
    tags: {
      alt_name: "Pharmacie Defort",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "930006333",
      source: "Celtipharm - 10/2014;survey 02/2018",
    },
  },
  {
    type: "node",
    id: 934471189,
    x: 48.527811,
    y: 2.3723276,
    tags: {
      "addr:city": "Ballancourt-sur-Essonne",
      "addr:country": "FR",
      "addr:housenumber": "61",
      "addr:postcode": "91610",
      "addr:street": "Rue Pierre Curie",
      amenity: "pharmacy",
      "contact:fax": "164932725",
      "contact:phone": "+33 1 64 93 20 14",
      description: "Allopathie - Homéopathie - Orthopédie - Diététique",
      dispensing: "yes",
      name: "Pharmacie Rolland",
      opening_hours:
        "Mo-Fr 08:30-12:30, Mo-Sa 14:30-19:30, Sa 08:30-12:30,14:30-19:00",
      "ref:FR:FINESS": "910005651",
      since: "1988-12-27",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013",
      start_date: "1988-12-27",
    },
  },
  {
    type: "node",
    id: 936923316,
    x: 48.8729379,
    y: 2.2926546,
    tags: {
      alt_name: "Pharmacie de l'Arc de Triomphe",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cavanna",
      opening_hours: "Tu-Sa 09:00-19:30; Mo 12:00-19:30",
      "ref:FR:FINESS": "750029282",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 937176868,
    x: 48.8696653,
    y: 2.2860871,
    tags: {
      alt_name: "Pharmacie de la Place Victor Hugo",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Place Victor Hugo",
      opening_hours: "Mo-Su 08:30-21:00",
      "ref:FR:FINESS": "750029373",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 937451417,
    x: 48.7793321,
    y: 2.3150819,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-03-08",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Martin-Lavigne",
      opening_hours: "Mo-Fr 09:00-19:45; Sa 08:30-19:30",
      "ref:FR:FINESS": "920011095",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 937614718,
    x: 48.8367202,
    y: 2.2416071,
    tags: {
      alt_name: "Pharmacie MAAREK",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009925",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 937873471,
    x: 48.8743633,
    y: 2.291973,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Étoile",
      "ref:FR:FINESS": "750041964",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 938011912,
    x: 48.8752949,
    y: 2.2863783,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Duret",
      "ref:FR:FINESS": "750028516",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 938079721,
    x: 48.8753128,
    y: 2.2841014,
    tags: {
      alt_name: "Pharmacie Koplewicz Journo",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Malakoff",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750028854",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942553728,
    x: 48.8680015,
    y: 2.2810489,
    tags: {
      "addr:housenumber": "2",
      "addr:street": "Place Jean Monnet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Basire",
      "name:ko": "바질약국",
      "name:ko-Latn": "바질약국",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750029134",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942586986,
    x: 48.8703665,
    y: 2.2827253,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cohen",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 47 27 54 77",
      "ref:FR:FINESS": "750028466",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942676696,
    x: 48.8655162,
    y: 2.282918,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lafayette Place de Mexico",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750028987",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942677251,
    x: 48.865253,
    y: 2.2829502,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "5",
      "addr:postcode": "75016",
      "addr:street": "Place de Mexico",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Moreau",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750028953",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942732743,
    x: 48.867603,
    y: 2.2800442,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lamartine",
      opening_hours: "Tu-Sa 08:30-20:00; Mo 11:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      phone: "+33 1 47 27 87 85",
      "ref:FR:FINESS": "750029332",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 942932544,
    x: 48.8681867,
    y: 2.2756876,
    tags: {
      alt_name: "Pharmacie Zana",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Faisanderie",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-13:00, 14:00-19:30",
      "ref:FR:FINESS": "750028532",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 944576115,
    x: 48.7767077,
    y: 2.3144599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours:
        "Tu-Fr 10:00-12:30, 14:30-19:00; Mo 15:00-19:00; Sa 09:30-12:30",
      "ref:FR:FINESS": "920011111",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 944621047,
    x: 48.8918833,
    y: 2.2530932,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Victor Hugo",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013695",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 945401998,
    x: 48.7799913,
    y: 2.315382,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Seyfried",
      "ref:FR:FINESS": "920011061",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 945428854,
    x: 48.7037647,
    y: 2.3761385,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "74",
      "addr:postcode": "91200",
      "addr:street": "Rue des Plantes",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 43 81",
      "contact:phone": "+33 1 69 38 08 80",
      "description:covid19": "Livraison possible à proximité.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Plantes",
      opening_hours:
        "Mo 13:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-18:00",
      "opening_hours:covid19": "Mo-Su,PH 07:00-21:30",
      "ref:FR:FINESS": "910005453",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "43796015600010",
      since: "1969-08-19",
      website: "https://pharmaciegerme.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 946245600,
    x: 48.8666021,
    y: 2.2776192,
    tags: {
      alt_name: "Pharmacie Klasser",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Victor Hugo",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750029357",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 946417321,
    x: 48.8862024,
    y: 2.2618426,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Roule",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "920018611",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 946599858,
    x: 48.9081699,
    y: 2.4684997,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie YEM",
      "ref:FR:FINESS": "930006234",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 947019816,
    x: 48.8804989,
    y: 2.2749961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pinta",
      "ref:FR:FINESS": "920018264",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 948015838,
    x: 48.9042887,
    y: 2.2557816,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Ouest",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013596",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 948568371,
    x: 48.8637003,
    y: 2.2771979,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750029084",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 952809968,
    x: 48.8607344,
    y: 2.2802509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Tour",
      "ref:FR:FINESS": "750029233",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 952916150,
    x: 48.7914131,
    y: 2.6039248,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "82",
      "addr:postcode": "77340",
      "addr:street": "Avenue Charles Rouxel",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Bouquet",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:30-20:00; Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 43 80 26",
      "ref:FR:FINESS": "770010411",
      source: "Celtipharm - 10/2014",
      website:
        "https://www.mypharmactiv.fr/pharmacie/pharmacie-dubouquet-77340",
    },
  },
  {
    type: "node",
    id: 955113820,
    x: 48.8294471,
    y: 2.233255,
    tags: {
      "addr:housenumber": "158",
      "addr:street": "Allée du Forum",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont de Sèvres",
      opening_hours: "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "920009693",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 955173802,
    x: 48.8629455,
    y: 2.2765257,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Briot",
      opening_hours: "Mo-Sa 08:00-20:30; Su 08:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 04 73 46",
      "ref:FR:FINESS": "750029050",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 955190464,
    x: 48.8637245,
    y: 2.2725469,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Seguin",
      "ref:FR:FINESS": "750029266",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 956308959,
    x: 48.8702368,
    y: 2.2270916,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaciedumanegesuresnes@gmail.com",
      fax: "+33 147727264",
      name: "Pharmacie du Manège",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 08:30-19:30",
      phone: "+33 1 45 06 76 49",
      "ref:FR:FINESS": "920021102",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 956308995,
    x: 48.8704875,
    y: 2.2259119,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "equipe@pharmacie-suresnes.fr",
      fax: "+33 145064913",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      phone: "+33 1 45 06 05 90",
      "ref:FR:FINESS": "920021136",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 956309037,
    x: 48.8692075,
    y: 2.2278569,
    tags: {
      alt_name: "Pharmavance Suresnes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché Suresnes",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "Mo-Sa 08:30-19:30",
      phone: "+33 1 45 06 10 33",
      "ref:FR:FINESS": "920020856",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 956410017,
    x: 48.6782411,
    y: 2.1745737,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      brand: "Pharm&Price",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharm & Price",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910014976",
    },
  },
  {
    type: "node",
    id: 957291635,
    x: 48.8589355,
    y: 2.2769651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Doumer-Passy",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750029001",
      source: "survey 2012-10;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 957457841,
    x: 48.9163836,
    y: 2.4444114,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Micoulaud",
      "ref:FR:FINESS": "930007786",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 960476173,
    x: 48.7752597,
    y: 2.3132722,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Chambord",
      opening_hours:
        "Tu-Fr 09:00-12:45, 14:30-20:00; Sa 09:15-12:45, 15:15-19:15; Mo 14:30-20:00",
      "ref:FR:FINESS": "920020484",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 962375779,
    x: 48.8601021,
    y: 2.2854852,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Chaillot",
      "ref:FR:FINESS": "750029217",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 962379495,
    x: 48.8583425,
    y: 2.2847292,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Voillot",
      opening_hours: "Mo-Su 08:00-20:30",
      "ref:FR:FINESS": "750029456",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 965414745,
    x: 48.5277944,
    y: 2.0218935,
    tags: {
      "addr:city": "Dourdan",
      "addr:housenumber": "46",
      "addr:postcode": "91410",
      "addr:street": "Rue Raymond Laubier",
      alt_name: "Pharmacie Bachelart Dhennin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 59 62 43",
      "contact:phone": "+33 1 64 59 71 71",
      dispensing: "yes",
      name: "Action Pharma",
      "ref:FR:FINESS": "910017243",
      since: "1998-09-12",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 967782164,
    x: 48.8204512,
    y: 2.2850104,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 72 21",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920021250",
      source: "Celtipharm - 10/2014",
      website: "http://pharmacieduparc.fr",
    },
  },
  {
    type: "node",
    id: 967789416,
    x: 48.821871,
    y: 2.284659,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 10 88",
      dispensing: "yes",
      name: "Pharmacie Nguyen Dac",
      opening_hours:
        "Tu-Sa 09:00-12:30,14:30-19:30, Mo 14:30-18:30, Sa 09:00-12:30",
      "ref:FR:FINESS": "920021383",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 968924215,
    x: 48.8415926,
    y: 2.3143795,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande pharmacie centrale Pasteur",
      opening_hours: "Mo-Fr 07:45-20:30, Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      operator: "Valérie Murciano",
      phone: "+33 1 43 20 78 19",
      "ref:FR:FINESS": "750026866",
      "ref:FR:SIRET": "38979144300010",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 971476051,
    x: 48.875702,
    y: 2.3437474,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cadet - Lafayette",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "750016263",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 971476137,
    x: 48.873873,
    y: 2.3449763,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "12",
      "addr:postcode": "75009",
      "addr:street": "Rue de la Boule Rouge",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Folies Bergère",
      "ref:FR:FINESS": "750015711",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 971476192,
    x: 48.8761473,
    y: 2.3456662,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mignon",
      opening_hours: "Mo-Fr 09:00-19:30, Sa 10:00-13:00",
      "ref:FR:FINESS": "750016842",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 975092246,
    x: 48.8572978,
    y: 2.2727541,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mozart",
      "ref:FR:FINESS": "750027872",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 975246205,
    x: 48.8552591,
    y: 2.2709985,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lafayette du Ranelagh",
      opening_hours: "Mo-Sa 08:00-20:30",
      "ref:FR:FINESS": "750028201",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      website: "http://pharmacieranelagh.fr",
    },
  },
  {
    type: "node",
    id: 975267517,
    x: 48.8559782,
    y: 2.2750423,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie L'Huillier",
      "ref:FR:FINESS": "750028136",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 975371057,
    x: 48.8577204,
    y: 2.2774067,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie du Marché de Passy",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché de Passy",
      opening_hours: "Mo-Sa 08:30-20:00",
      phone: "+33 1 42 88 01 76",
      "ref:FR:FINESS": "750028086",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 975458257,
    x: 48.8563119,
    y: 2.2777017,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place Chopin",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:30-20:00",
      "ref:FR:FINESS": "750027732",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 975760587,
    x: 48.8485449,
    y: 2.4348638,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Olivier",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016041",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 975957739,
    x: 48.8577255,
    y: 2.2811747,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Passy Chernoviz",
      "ref:FR:FINESS": "750027682",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 976978227,
    x: 48.8353887,
    y: 2.2497642,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dauriac",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 20 46 77",
      "ref:FR:FINESS": "920009610",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 977194943,
    x: 48.8437349,
    y: 2.3546913,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cuvier",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00; PH off",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011256",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciecuvier.fr",
    },
  },
  {
    type: "node",
    id: 979800368,
    x: 48.7512758,
    y: 2.3452736,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lavacquery Chantal",
      opening_hours:
        "Mo-Fr 09:00-13:30,15:00-20:00, Sa 09:00-13:00,15:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33146861900",
      "ref:FR:FINESS": "940012974",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 980768719,
    x: 48.6651033,
    y: 2.8409832,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Darras",
      "ref:FR:FINESS": "770004844",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 981331363,
    x: 48.7591562,
    y: 2.0502931,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Cadaert",
      "ref:FR:FINESS": "780016341",
    },
  },
  {
    type: "node",
    id: 986085930,
    x: 48.8319197,
    y: 2.2446034,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Jaurès",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "920010881",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 986085939,
    x: 48.8306186,
    y: 2.2443347,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Dôme",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-19:15",
      "opening_hours:covid19": "Mo-Fr 08:30-19:30; Sa 09:00-19:15",
      "ref:FR:FINESS": "920009560",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 986921179,
    x: 48.8360799,
    y: 2.3099828,
    tags: {
      alt_name: "Pharmacie Falguière",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Solal Bismuth",
      opening_hours: "Mo-Fr 8:30-20:00; Sa 9:00-19:30",
      "opening_hours:covid19": "Mo-Fr 8:30-20:00; Sa 9:00-19:30",
      phone: "+33145324757",
      "ref:FR:FINESS": "750026007",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 987112197,
    x: 48.880424,
    y: 2.2730292,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Sablons",
      "ref:FR:FINESS": "920018330",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 987112303,
    x: 48.8817916,
    y: 2.2709236,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bilgraer",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:30-19:30",
      "ref:FR:FINESS": "920018314",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 988268469,
    x: 48.8534396,
    y: 2.2649713,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Du Docteur Blanche",
      opening_hours:
        "Mo-Fr 08:30-13:00, 14:00-20:00; Sa 09:00-13:00, 14:00-20:00",
      phone: "+33 1 46 47 82 90",
      "ref:FR:FINESS": "750027716",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 988277621,
    x: 48.882503,
    y: 2.2686944,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Courcy",
      "ref:FR:FINESS": "920018363",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 988277727,
    x: 48.882921,
    y: 2.2651607,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Bouilhac",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-14:00",
      "ref:FR:FINESS": "920018413",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 989039401,
    x: 48.8514366,
    y: 2.2678072,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dambreville",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      phone: "+33 1 42 88 53 33",
      "ref:FR:FINESS": "750027922",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 989239062,
    x: 48.8490325,
    y: 2.2657341,
    tags: {
      alt_name: "Pharmacie Decouvral",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie d'Auteuil",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750028185",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 990033858,
    x: 48.8425947,
    y: 2.2438255,
    tags: {
      alt_name: "Pharmacie Duffau",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00, 15:00-19:00",
      "ref:FR:FINESS": "920009677",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 990506257,
    x: 48.9186272,
    y: 2.2527735,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Drighes",
      "ref:FR:FINESS": "920013075",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 992616586,
    x: 48.9711909,
    y: 2.4007862,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie E Qotni",
      "ref:FR:FINESS": "950008334",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 992951518,
    x: 48.8229405,
    y: 2.324857,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours: "24/7",
      "opening_hours:covid19": "24/7",
      phone: "+33 1 45 42 27 75",
      "ref:FR:FINESS": "750025082",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 993834099,
    x: 48.8484927,
    y: 2.2658715,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Teyber",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Quartier d'Auteuil",
      "ref:FR:FINESS": "750027617",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 993843280,
    x: 48.8483073,
    y: 2.2644663,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Mellian Maarek",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale d'Auteuil",
      "ref:FR:FINESS": "750027856",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 993843757,
    x: 48.8481034,
    y: 2.262677,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte d'Auteuil",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750027583",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 995308305,
    x: 48.9313691,
    y: 2.2597469,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Stade",
      "ref:FR:FINESS": "920013125",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 995502864,
    x: 48.8256786,
    y: 2.2478924,
    tags: {
      alt_name: "Pharmacie Mettoudi",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont de Billancourt",
      "ref:FR:FINESS": "920010915",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 996536511,
    x: 48.8229379,
    y: 2.5505008,
    tags: {
      "addr:city": "Villiers-sur-Marne",
      "addr:housenumber": "51",
      "addr:postcode": "94350",
      "addr:street": "Avenue André Rouy",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaciedeleurope.villierssurmarne@pharmaciengiphar.fr",
      name: "Pharmacie de l'Europe",
      opening_hours: "Mo 14:30-19:00; Tu-Sa 09:00-12:30,14:00-19:30; PH off",
      phone: "+33 9 83 31 47 22",
      "ref:FR:FINESS": "940015795",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      website:
        "https://pharmacies.pharmaciengiphar.com/ile-de-france/val-de-marne/villiers-sur-marne/pharmacie-de-leurope-261",
    },
  },
  {
    type: "node",
    id: 997119474,
    x: 48.8279448,
    y: 2.2468539,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jean Jaurès",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "920009990",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 999443732,
    x: 48.7569837,
    y: 2.3153025,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Peupleraie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009517",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1003045002,
    x: 48.8269224,
    y: 2.3285268,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "750024572",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1003096691,
    x: 48.8478181,
    y: 2.2657099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Village d'Auteuil",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
    },
  },
  {
    type: "node",
    id: 1003097454,
    x: 48.847685,
    y: 2.2652772,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Croix Bleue",
      "ref:FR:FINESS": "750027567",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1003745172,
    x: 48.818669,
    y: 2.120573,
    tags: {
      alt_name: "Pharmacie Miscault Hubert",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Antoine",
      "ref:FR:FINESS": "780006581",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1005449278,
    x: 49.0795556,
    y: 2.5021622,
    tags: {
      "addr:city": "Marly-la-Ville",
      "addr:housenumber": "2 Ter",
      "addr:postcode": "95670",
      "addr:street": "Rue Roger Salengro",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Marly",
      opening_hours:
        "Tu-Fr 09:00-12:30, 14:30-19:30; Mo 14:30-19:30; Sa 09:00-12:30, 14:30-18:30",
      "ref:FR:FINESS": "950009837",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1007278883,
    x: 48.8236913,
    y: 2.5426685,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940015811",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1011079310,
    x: 48.8259255,
    y: 2.5608669,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bois de Gaumont",
      "ref:FR:FINESS": "940015647",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1012296744,
    x: 48.8398865,
    y: 2.2363409,
    tags: {
      "addr:housenumber": "107",
      "addr:postcode": "92100",
      "addr:street": "Route de la Reine",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mersier",
      opening_hours: "Mo-Fr 08:00-21:30; Sa 08:30-20:30",
      "ref:FR:FINESS": "920010527",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1012595884,
    x: 48.8274251,
    y: 2.5413686,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Tilleuls",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015712",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1013351518,
    x: 48.8403836,
    y: 2.2340739,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Reine",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 04 00 62",
      "ref:FR:FINESS": "920010543",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1014690251,
    x: 48.8608937,
    y: 2.3534909,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre Pompidou",
      "ref:FR:FINESS": "750010084",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1016454078,
    x: 48.8430519,
    y: 2.2650095,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Jouvenet",
      "ref:FR:FINESS": "750027666",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1018176350,
    x: 48.842807,
    y: 2.2381734,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Château",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:30",
      phone: "+33 1 46 05 06 87",
      "ref:FR:FINESS": "920009875",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1020227352,
    x: 48.8714691,
    y: 2.3750538,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Faubourg du Temple",
      opening_hours: "Mo-Su 09:00-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750017410",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1022129386,
    x: 48.6732341,
    y: 2.0689536,
    tags: {
      "addr:city": "Les Molières",
      "addr:housenumber": "11",
      "addr:postcode": "91470",
      "addr:street": "Place de la Mairie",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 12 48 44",
      "contact:phone": "+33 1 60 12 28 60",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Emmanuelle Chaugny",
      "ref:FR:FINESS": "910010677",
      since: "1995-12-18",
    },
  },
  {
    type: "node",
    id: 1022484003,
    x: 48.8400795,
    y: 2.2560712,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc des Princes",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-13:30, 15:00-19:30",
      "ref:FR:FINESS": "750028003",
      source: "survey 2019",
    },
  },
  {
    type: "node",
    id: 1025469137,
    x: 48.8387308,
    y: 2.2609773,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750028417",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1039548048,
    x: 48.822952,
    y: 2.249911,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Île Saint-Germain",
      opening_hours:
        "Mo 10:00-20:00; Tu-Fr 08:30-13:30, 14:30-20:00; Sa 09:00-13:00, 14:30-20:00",
      phone: "+33 1 46 42 28 05",
      "ref:FR:FINESS": "920015377",
      source: "survey;Celtipharm - 10/2014",
      website: "http://www.pharm-isg.com",
    },
  },
  {
    type: "node",
    id: 1041097910,
    x: 48.8399501,
    y: 2.2395932,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Eisenbeth",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "920009891",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1049759444,
    x: 48.8269809,
    y: 2.3245691,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Alesia - Jean Moulin",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "750024382",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-03-16",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1049759500,
    x: 48.8320415,
    y: 2.3248498,
    tags: {
      alt_name: "Pharmacie Aoulay",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Europe",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024523",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1053842726,
    x: 48.829782,
    y: 2.347087,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-24",
      dispensing: "yes",
      name: "Pharmacie Blanqui",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022386",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1054675534,
    x: 48.8029222,
    y: 2.4900476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Foch",
      "ref:FR:FINESS": "940013477",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1071272352,
    x: 48.8032669,
    y: 2.3713192,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Acacias",
      "ref:FR:FINESS": "940014764",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1077728002,
    x: 48.9668434,
    y: 2.3171101,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Barre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950006783",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1079716274,
    x: 48.8470028,
    y: 2.3415709,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Soufflot",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750012247",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 1079719392,
    x: 48.9224131,
    y: 2.5439234,
    tags: {
      "addr:housenumber": "14",
      "addr:street": "Place de la Libération",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "930009451",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1079719562,
    x: 48.9347589,
    y: 2.5687515,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mataga",
      "ref:FR:FINESS": "930016316",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1079806055,
    x: 48.6132783,
    y: 2.3764797,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
      opening_hours:
        "Mo-Fr 08:30-13:00,14:30-19:30; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "910005784",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1080956401,
    x: 48.8375805,
    y: 2.3194622,
    tags: {
      "addr:housenumber": "24",
      "addr:street": "Place de Catalogne",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khun",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023970",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1082897931,
    x: 48.9923024,
    y: 2.2844639,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Bois Jacques",
      opening_hours: "Mo-Fr 09:00-12:30, 14:00-20:00; Sa-Su 09:00-19:30",
      phone: "+33 1 39 59 42 49",
      "ref:FR:FINESS": "950007104",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1084247660,
    x: 48.7921446,
    y: 1.9725234,
    tags: {
      "addr:housenumber": "14",
      "addr:postcode": "78990",
      "addr:street": "Place de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Clef Saint-Pierre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007597",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1085585151,
    x: 48.828994,
    y: 2.2510648,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Soleil",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 46 20 20 70",
      "ref:FR:FINESS": "920010071",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1089280984,
    x: 48.931258,
    y: 2.4950402,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "930005020",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1091536309,
    x: 48.5649077,
    y: 2.6125165,
    tags: {
      "addr:city": "Vert-Saint-Denis",
      "addr:housenumber": "20",
      "addr:postcode": "77240",
      "addr:street": "Rue Pasteur",
      alt_name: "Pharmacie GUILLON",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      email: "nguillon@wanadoo.fr",
      healthcare: "pharmacy",
      name: "Pharmacie Pasteur",
      opening_hours:
        "Mo 10:00-12:30,14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-18:30",
      operator: "Nadine Guillon",
      phone: "+33 1 64 41 99 24",
      "ref:FR:FINESS": "770012664",
      source: "Celtipharm - 10/2014",
      "survey:date": "2018-12-22",
    },
  },
  {
    type: "node",
    id: 1092982594,
    x: 48.5648584,
    y: 2.4380569,
    tags: {
      "addr:city": "Mennecy",
      "addr:housenumber": "47",
      "addr:postcode": "91540",
      "addr:street": "Boulevard Charles de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 23 40 94",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Avenir",
      operator: "pharmacie",
      phone: "+33 1 69 23 40 93",
      "ref:FR:FINESS": "910017532",
      "ref:FR:NAF": "4773Z",
      source: "Celtipharm - 10/2014",
      start_date: "2009-08-10",
      "type:FR:FINESS": "620",
      website: "https://pharmaciedelavenirmennecy.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 1096258145,
    x: 48.8349894,
    y: 2.2549359,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Stade de Coubertin",
      opening_hours:
        "Mo 12:45-20:00; Tu-Th 09:00-20:00; Fr 09:00-19:30; Sa 09:00-15:00",
      "ref:FR:FINESS": "920010261",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1105164216,
    x: 48.691056,
    y: 2.3753135,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:country": "FR",
      "addr:district": "Essonne",
      "addr:housenumber": "13",
      "addr:postcode": "91260",
      "addr:street": "Place du Maréchal Leclerc",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 21 40 63",
      "contact:phone": "+33 1 69 21 40 63",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "910009422",
      start_date: "1989-01-27",
    },
  },
  {
    type: "node",
    id: 1105435942,
    x: 48.8932771,
    y: 2.2905105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Abitbol",
      "ref:FR:FINESS": "920015674",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1106057842,
    x: 48.8132936,
    y: 2.5113222,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Aprium Pharmacie",
      "ref:FR:FINESS": "940006984",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1107336082,
    x: 48.877227,
    y: 2.2853859,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "68",
      "addr:postcode": "75017",
      "addr:street": "Avenue de la Grande Armée",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie de la Porte Maillot",
      opening_hours: "24/7",
      "ref:FR:FINESS": "750030405",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1107823415,
    x: 48.8824225,
    y: 2.381271,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750034316",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1108731807,
    x: 48.8760513,
    y: 2.2866166,
    tags: {
      alt_name: "Pharmacie Boujenah",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boujenah",
      "ref:FR:FINESS": "750028607",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1109677510,
    x: 48.8511726,
    y: 2.345929,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Quartier Latin",
      "ref:FR:FINESS": "750011843",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1109781769,
    x: 48.8512339,
    y: 2.3482344,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Notre-Dame",
      "ref:FR:FINESS": "750011173",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1110914708,
    x: 48.8498704,
    y: 2.3434646,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Sorbonne",
      "ref:FR:FINESS": "750012155",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1113738977,
    x: 48.8422628,
    y: 2.3136097,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Necker Pasteur",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026833",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1113739019,
    x: 48.8418058,
    y: 2.31301,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pasteur",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026817",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1114763124,
    x: 48.8849782,
    y: 2.3792829,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75019",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Buttes Chaumont",
      opening_hours: "Mo-Fr 09:00-20:15; Sa 10:00-20:15",
      "ref:FR:FINESS": "750034837",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1118965094,
    x: 48.8503118,
    y: 2.8202248,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Golf",
      "ref:FR:FINESS": "770014470",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1119108143,
    x: 48.8831719,
    y: 2.2375878,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'hôtel de ville",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-13:00,15:00-19:30",
      "ref:FR:FINESS": "920019346",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1119984202,
    x: 48.8286573,
    y: 2.2730915,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Héliport",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025405",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1119984224,
    x: 48.8352624,
    y: 2.2859287,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
    },
  },
  {
    type: "node",
    id: 1125137854,
    x: 48.6600835,
    y: 2.5754482,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tchou Kien",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770005445",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1128854283,
    x: 48.8712109,
    y: 2.3430984,
    tags: {
      alt_name: "Pharmacie Aucher",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Grands Boulevards",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 13:00-20:00",
      "ref:FR:FINESS": "750009144",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1129371353,
    x: 48.9844352,
    y: 2.227626,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Moser",
      "ref:FR:FINESS": "950008086",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1132540237,
    x: 48.8878847,
    y: 2.3259177,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 87 69 36",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Fourche",
      opening_hours: "Mo-Fr 08:00-01:00; Sa 09:00-01:00",
      "ref:FR:FINESS": "750033953",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1133711894,
    x: 48.9840539,
    y: 2.2269203,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "950008102",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1134170014,
    x: 48.6754396,
    y: 2.3741132,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "27",
      "addr:postcode": "91170",
      "addr:street": "Rue Danielle Casanova",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 24 46 73",
      "contact:phone": "+33 1 69 24 29 10",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Roque",
      "ref:FR:FINESS": "910014190",
      since: "2005-04-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2011",
    },
  },
  {
    type: "node",
    id: 1137436572,
    x: 48.8831719,
    y: 2.2375879,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-13:00,15:00-19:30",
      "ref:FR:FINESS": "920019346",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1137858671,
    x: 48.8810643,
    y: 2.2379994,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920019296",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1141486776,
    x: 48.8415876,
    y: 2.3435962,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bruyère Cam-Tu",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010985",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1141486808,
    x: 48.8438494,
    y: 2.3420774,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gay-Lussac",
      "ref:FR:FINESS": "750010951",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1141489400,
    x: 48.8460312,
    y: 2.3413867,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Facultés",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010936",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1143043422,
    x: 48.8950474,
    y: 2.251827,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Charras",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "920013547",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1143774104,
    x: 48.8496043,
    y: 2.3739078,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ledru-Rollin",
      "ref:FR:FINESS": "750021602",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1144156434,
    x: 48.8462789,
    y: 2.3405322,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Michel",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750012106",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 1144416547,
    x: 48.838935,
    y: 2.3403744,
    tags: {
      alt_name: "Pharmacie Cochin - Port-Royal",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cochin-Port Royal",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011637",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      "survey:date": "2020-04-21",
    },
  },
  {
    type: "node",
    id: 1145667778,
    x: 48.8425907,
    y: 2.3519394,
    tags: {
      "addr:housenumber": "74",
      "addr:postcode": "75005",
      "addr:street": "Rue Monge",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Monge",
      "name:ko": "몽쥬약국",
      opening_hours: "Mo-Sa 08:00-21:00; Su 08:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 31 39 44",
      "ref:FR:FINESS": "750011314",
      shop: "chemist",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      website: "https://pharmacie-monge.fr/",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1152866665,
    x: 48.8198975,
    y: 1.9874652,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "780006714",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1157983655,
    x: 48.8375628,
    y: 2.3535312,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Marcel",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750012064",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      "survey:date": "2018-08-11",
    },
  },
  {
    type: "node",
    id: 1157983784,
    x: 48.8384808,
    y: 2.3564239,
    tags: {
      "access:covid19": "yes",
      alt_name: "Pharmacie Notelet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Notelet",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750012015",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 1158018407,
    x: 48.8411226,
    y: 2.3622939,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Jardin des Plantes",
      "ref:FR:FINESS": "750011041",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1162321543,
    x: 48.927997,
    y: 2.223365,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Akbaraly",
      "ref:FR:FINESS": "950005835",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1174439695,
    x: 48.8146501,
    y: 2.4593293,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Leroux",
      "ref:FR:FINESS": "940013980",
      source: "survey;Celtipharm - 10/2014",
      "source:name": "www.ordre.pharmacien.fr",
    },
  },
  {
    type: "node",
    id: 1179777336,
    x: 48.8589842,
    y: 2.3037923,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Parisienne",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750014177",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1179777347,
    x: 48.8571424,
    y: 2.30461,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bosquet Grenelle",
      "ref:FR:FINESS": "750013641",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1179818788,
    x: 48.8562829,
    y: 2.301939,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Bourdonnais",
      "ref:FR:FINESS": "750013880",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      wikidata: "Q22924679",
    },
  },
  {
    type: "node",
    id: 1179818789,
    x: 48.8597553,
    y: 2.3011269,
    tags: {
      alt_name: "Pharmacie Ramajo",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Brisebarre",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      phone: "+33 1 47 05 41 25",
      "ref:FR:FINESS": "750014060",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      wikidata: "Q22924681",
    },
  },
  {
    type: "node",
    id: 1180890482,
    x: 48.8200297,
    y: 2.2578998,
    tags: {
      "addr:housenumber": "99",
      "addr:postcode": "92130",
      "addr:street": "Avenue de Verdun",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Arches",
      "opening_hours:covid19": "open",
      phone: "+33 1 40 95 02 52",
      "ref:FR:FINESS": "920015492",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1183024876,
    x: 49.033524,
    y: 2.126423,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Boutang",
      "ref:FR:FINESS": "950011965",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1183555599,
    x: 48.9382757,
    y: 2.4949532,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de L'Herminier",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930004916",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1185035321,
    x: 48.9466177,
    y: 2.5000546,
    tags: {
      alt_name: "Pharmacie CLASTRE DARGENCOURT",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Robespierre",
      "ref:FR:FINESS": "930005202",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1185035381,
    x: 48.943359,
    y: 2.5070534,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "930005236",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1191785955,
    x: 48.8534739,
    y: 2.2128601,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Beausoleil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920020351",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1191788516,
    x: 48.8669352,
    y: 2.2167715,
    tags: {
      alt_name: "Pharmacie MEGAIDES",
      amenity: "pharmacy",
      "description:covid19": "commande en ligne sur le site internet",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmarciedesvignes@wellandwell.fr",
      healthcare: "pharmacy",
      name: "Pharmacie des Vignes",
      "opening_hours:covid19": "open",
      phone: "+33 1 47 72 70 31",
      "ref:FR:FINESS": "920021086",
      source: "Survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1192692990,
    x: 48.7647297,
    y: 2.4875334,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Danan",
      "ref:FR:FINESS": "940006117",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1193494082,
    x: 49.036448,
    y: 2.211936,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Exupéry",
      "ref:FR:FINESS": "950005603",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1199074018,
    x: 48.7980358,
    y: 2.4851099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Théâtre",
      "ref:FR:FINESS": "940013345",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1204247054,
    x: 48.562963,
    y: 2.6052496,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Albaret",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770004281",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1210676086,
    x: 48.7052875,
    y: 2.0709566,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-11-15",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Saint-Rémy",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "780013546",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1211031753,
    x: 48.9093266,
    y: 2.2864139,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie d'Asnières",
      operator: "Pharmavance",
      "ref:FR:FINESS": "920008513",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1211073191,
    x: 48.9027259,
    y: 2.2753245,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 33 52 28",
      dispensing: "yes",
      name: "Pharmacie Sajus",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "920013927",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1211862780,
    x: 48.8447931,
    y: 2.2347733,
    tags: {
      alt_name: "Pharmacie Lao",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie",
      opening_hours: "Mo-Sa 09:00-19:30",
      phone: "+33 1 46 05 02 78",
      "ref:FR:FINESS": "920009826",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1212257158,
    x: 48.856516,
    y: 2.3269654,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bellaiche",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:30-19:30",
      "ref:FR:FINESS": "750013526",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1224795722,
    x: 48.8491906,
    y: 2.3784376,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmaligre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750020703",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "takeaway:covid19": "no",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1224811503,
    x: 48.7984843,
    y: 2.4647084,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Face à l'Interco",
      opening_hours: "Mo-Sa 08:00-21:00",
      "ref:FR:FINESS": "940018310",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1224811527,
    x: 48.7980141,
    y: 2.4560676,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Renoult",
      "ref:FR:FINESS": "940008816",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1224811530,
    x: 48.7981584,
    y: 2.477793,
    tags: {
      "addr:city": "Saint-Maur-des-Fossés",
      "addr:housenumber": "44",
      "addr:postcode": "94100",
      "addr:street": "Boulevard de Créteil",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mitre",
      opening_hours: "Mo 14:00-20:00; Tu-Fr 09:00-12:30,14:30-20:00",
      "ref:FR:FINESS": "940013295",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1225041724,
    x: 48.8137112,
    y: 1.8998248,
    tags: {
      "access:covid19": "yes",
      alt_name: "Pharmacie Joseph",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780011441",
      source: "survey;Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 1225049002,
    x: 48.8314297,
    y: 1.9422893,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "780011995",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1225461864,
    x: 48.8658467,
    y: 2.3525632,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Canonne Réaumur",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750009763",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1231110048,
    x: 48.906242,
    y: 2.268632,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sebag",
      "ref:FR:FINESS": "920008760",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1233866989,
    x: 48.7016512,
    y: 2.4863697,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "9",
      "addr:postcode": "91330",
      "addr:street": "Place Gambetta",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 51 97",
      "contact:phone": "+33 1 69 48 89 18",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Place Gambetta",
      opening_hours: "Mo-Su 09:00-20:00",
      "ref:FR:FINESS": "910014612",
      since: "1995-07-26",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1234207306,
    x: 48.8824281,
    y: 2.3336567,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fontaine",
      "ref:FR:FINESS": "750017014",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1234212852,
    x: 48.8792406,
    y: 2.334651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pecker",
      "ref:FR:FINESS": "750016313",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1234664783,
    x: 48.9698663,
    y: 2.2626688,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950012062",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1235238252,
    x: 48.8769401,
    y: 2.3393848,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Martyrs",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 08:30-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750016347",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1236523496,
    x: 48.927694,
    y: 2.4910513,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "930005053",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1239624304,
    x: 48.806911,
    y: 2.5088376,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013840",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1240428261,
    x: 48.8368695,
    y: 2.2790628,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Balard",
      "ref:FR:FINESS": "750025223",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1243135615,
    x: 48.8379895,
    y: 2.3992701,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Breton",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021156",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1243135743,
    x: 48.836896,
    y: 2.4022462,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Pharmacie du Métro Michel Bizot",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021172",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1243135761,
    x: 48.841628,
    y: 2.3866804,
    tags: {
      alt_name: "Pharmacie Touboul",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "750021115",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-12-06",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1243135890,
    x: 48.8349908,
    y: 2.4065836,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte Dorée",
      "ref:FR:FINESS": "750021370",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1244411727,
    x: 48.8474822,
    y: 2.3019418,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Place Cambronne",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025322",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1244411739,
    x: 48.8447637,
    y: 2.3102862,
    tags: {
      "addr:housenumber": "5",
      "addr:street": "Rue Lecourbe",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sitruk",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026353",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1247302779,
    x: 48.7977822,
    y: 2.4975146,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Richard",
      "ref:FR:FINESS": "940013360",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1249130824,
    x: 48.907824,
    y: 2.149283,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bonnin",
      opening_hours: "Mo-Fr 08:30-13:00,14:00-19:30; Sa 09:00-19:00",
      "ref:FR:FINESS": "780010781",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1249989678,
    x: 48.9066674,
    y: 2.1171819,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Deslandre",
      "ref:FR:FINESS": "780010740",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1252384106,
    x: 48.8392544,
    y: 2.3893624,
    tags: {
      "addr:housenumber": "1",
      "addr:postcode": "75012",
      "addr:street": "Boulevard de Reuilly",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dichamp",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021966",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1253957802,
    x: 48.8463869,
    y: 2.2380202,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Roland Garros",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:30-13:30, 14:00-19:30",
      "ref:FR:FINESS": "920009776",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1255346261,
    x: 48.8459622,
    y: 2.237423,
    tags: {
      alt_name: "Pharmacie Rouland",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Notre-Dame",
      opening_hours: "Mo-Sa 09:00-19:45",
      "opening_hours:covid19": "open",
      phone: "+33 1 55 60 08 46",
      "ref:FR:FINESS": "920009792",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1260668944,
    x: 48.8485448,
    y: 2.3086944,
    tags: {
      alt_name: "Pharmacie Chétrit",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Saxe",
      opening_hours: "Mo 11:00-20:00; Tu-Fr 09:00-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "750014011",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1262108157,
    x: 48.8176116,
    y: 2.4616227,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Canadiens",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940014012",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1264875573,
    x: 48.8503908,
    y: 2.3115133,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mesnard",
      "ref:FR:FINESS": "750013724",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1266553216,
    x: 48.8569128,
    y: 2.3780314,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Israël",
      opening_hours: "Mo-Th 08:30-21:00; Fr 08:30-16:00; Su 08:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33143797854",
      "ref:FR:FINESS": "750019960",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1272037736,
    x: 48.8824738,
    y: 2.3166175,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "750030223",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1272931927,
    x: 48.8795545,
    y: 2.4150569,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale des Lilas",
      opening_hours: "Mo-Fr 08:30-20:00;Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00;Sa 09:00-20:00",
      "ref:FR:FINESS": "930009204",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1272931934,
    x: 48.8805634,
    y: 2.4182863,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie des Lilas",
      opening_hours: "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "930009220",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1274142436,
    x: 48.801939,
    y: 2.4589447,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "disused:amenity": "pharmacy",
      name: "Pharmacie Laferrière",
      phone: "+33142075593",
      "ref:FR:FINESS": "940008634",
      "ref:FR:SIRET": "79314904800019",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1278098858,
    x: 48.6741571,
    y: 2.5342959,
    tags: {
      "addr:city": "Quincy-sous-Sénart",
      "addr:country": "FR",
      "addr:housenumber": "1 bis",
      "addr:postcode": "91480",
      "addr:street": "Rue de Brunoy",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 00 40 94",
      "contact:phone": "+33 1 69 00 88 51",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011915",
      "ref:FR:NAF": "4773Z",
      since: "1993-11-24",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1278249397,
    x: 48.6511293,
    y: 2.4037198,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "60",
      "addr:postcode": "91130",
      "addr:street": "Route de Grigny",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 06 35 07",
      "contact:phone": "+33 1 69 06 35 07",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie N'Gbe",
      "ref:FR:FINESS": "910012095",
      since: "1989-02-08",
    },
  },
  {
    type: "node",
    id: 1279413101,
    x: 48.7645543,
    y: 2.4881375,
    tags: {
      amenity: "pharmacy",
      dispensing: "no",
      name: "Parapharmacie",
      shop: "chemist",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1280606400,
    x: 48.877421,
    y: 2.370499,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Métro",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30; PH off",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750018475",
    },
  },
  {
    type: "node",
    id: 1280690210,
    x: 48.8774044,
    y: 2.3694714,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Colonel Fabien",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017774",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1280732224,
    x: 48.9582775,
    y: 2.2572715,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950005157",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 1281880407,
    x: 48.8839135,
    y: 2.3676289,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Oberic",
      "ref:FR:FINESS": "750017097",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1282038004,
    x: 48.8815178,
    y: 2.364961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 217",
      "ref:FR:FINESS": "750017964",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1285336051,
    x: 48.944577,
    y: 2.2508657,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Almeida",
      "ref:FR:FINESS": "950004622",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1286171259,
    x: 48.636056,
    y: 2.798856,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770007144",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 1287625423,
    x: 48.8864274,
    y: 2.1803436,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Centre Colmar",
      "opening_hours:covid19": "Mo-Fr 09:00-19:45; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "920019791",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1288269864,
    x: 48.8698134,
    y: 2.3700786,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Postic Lagrandeur",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017360",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1289573694,
    x: 48.9461548,
    y: 2.2493343,
    tags: {
      alt_name: "Pharmacie Languillat Leroux",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Avenue",
      "ref:FR:FINESS": "950005173",
      source: "survey;Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 1290404656,
    x: 48.770505,
    y: 2.025427,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hurabielle Chevalier",
      opening_hours:
        "Mo-Fr 09:00-12:30,15:00-19:00; Sa 09:00-12:30,15:00-19:30",
      "ref:FR:FINESS": "780011060",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1290450353,
    x: 48.8757327,
    y: 2.3977989,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "750034472",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1290622976,
    x: 48.76276,
    y: 2.033998,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mare Caillon",
      phone: "+33130641946",
      "ref:FR:FINESS": "780011094",
      "ref:FR:SIRET": "81446832800012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1292055727,
    x: 48.8702549,
    y: 2.3098514,
    tags: {
      "addr:housenumber": "49 bis",
      "addr:postcode": "75008",
      "addr:street": "Avenue Franklin Delano Roosevelt",
      alt_name: "Pharmacie Lipszyc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Rond-Point des Champs-Élysées",
      "opening_hours:covid19": "Mo-Fr 10:00-19:00",
      "ref:FR:FINESS": "750014912",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1295312843,
    x: 48.8820895,
    y: 2.319335,
    tags: {
      "addr:housenumber": "78",
      "addr:postcode": "75017",
      "addr:street": "Boulevard des Batignolles",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Chaptal",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00, 15:00-19:00",
      phone: "+33 1 43 87 28 58",
      "ref:FR:FINESS": "750029522",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciechaptal.fr",
    },
  },
  {
    type: "node",
    id: 1302120688,
    x: 48.8854729,
    y: 2.2594752,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont",
      opening_hours: "Mo-Fr 08:15-19:45, Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920018496",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1304095502,
    x: 48.8618971,
    y: 2.123157,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "780008991",
    },
  },
  {
    type: "node",
    id: 1306563389,
    x: 48.8251368,
    y: 2.365621,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      layer: "2",
      level: "2",
      name: "Pharmacie du Javelot",
      opening_hours: "Mo-Sa 00:09-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750023186",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1312783434,
    x: 48.8458039,
    y: 2.6154205,
    tags: {
      "addr:city": "Noisiel",
      "addr:housenumber": "35",
      "addr:postcode": "77186",
      "addr:street": "Cours des Roches",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Roches",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      phone: "+33 1 60 06 75 10",
      "ref:FR:FINESS": "770009991",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1312808401,
    x: 48.8432658,
    y: 2.6159469,
    tags: {
      "addr:city": "Noisiel",
      "addr:housenumber": "85",
      "addr:postcode": "77186",
      "addr:street": "Cours des Roches",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00,14:00-19:00",
      phone: "+33 1 60 17 57 00",
      "ref:FR:FINESS": "770010015",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmacie-orthopedie-noisiel.fr/",
    },
  },
  {
    type: "node",
    id: 1313398506,
    x: 48.8093477,
    y: 2.296567,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Berthelot",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 42 53 79 47",
      "ref:FR:FINESS": "920011640",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1313892910,
    x: 48.6397923,
    y: 2.3493083,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "2",
      "addr:postcode": "91700",
      "addr:street": "Avenue Charlie Chaplin",
      alt_name: "Pharmacie Dessailly",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 23 22",
      "contact:phone": "+33 1 60 15 49 21",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Canal",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910012442",
      since: "2006-10-02",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1314234588,
    x: 48.6486049,
    y: 2.4067723,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "1",
      "addr:postcode": "91130",
      "addr:street": "Place du Moulin à Vent",
      alt_name: "Pharmacie M. Coniglio",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 06 86 89",
      "contact:phone": "+33 1 69 06 39 02",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "910012145",
      since: "1992-09-23",
    },
  },
  {
    type: "node",
    id: 1316102367,
    x: 48.8642184,
    y: 2.5752781,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 05 36 49",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont de Gournay",
      opening_hours: "Mo 15:00-19:30; Tu-Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "930009006",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1323583340,
    x: 48.8575297,
    y: 2.3795048,
    tags: {
      alt_name: "Pharmacie Nahum",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale du XIe",
      opening_hours: "Mo-Su 08:00-21:00",
      "ref:FR:FINESS": "750019226",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
      "source:opening_hours": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1332634701,
    x: 48.9069911,
    y: 2.3641791,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Phamacie du Métro Front Populaire",
      opening_hours: "Mo-Sa 09:00-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "930014154",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm 11/2015",
    },
  },
  {
    type: "node",
    id: 1335249139,
    x: 48.9909405,
    y: 2.2886477,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Mont d'Eaubonne",
      "ref:FR:FINESS": "950007120",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1350350266,
    x: 48.843673,
    y: 2.4861282,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Aprium Pharmacie du Boulevard",
      "ref:FR:FINESS": "940012040",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1351141182,
    x: 48.5815469,
    y: 1.93736,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780012860",
    },
  },
  {
    type: "node",
    id: 1351141193,
    x: 48.5713607,
    y: 1.9386099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "780012894",
    },
  },
  {
    type: "node",
    id: 1351854315,
    x: 48.8473444,
    y: 2.3778677,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Visot",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750020877",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1352879250,
    x: 48.8441746,
    y: 2.2935563,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "89",
      "addr:postcode": "75015",
      "addr:street": "Rue du Commerce",
      alt_name: "Pharmacie Aouizerat",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie du Commerce",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      operator: "iPharm",
      "ref:FR:FINESS": "750025553",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1353929084,
    x: 48.7895443,
    y: 2.2231415,
    tags: {
      "access:covid19": "yes",
      "addr:housename": "Pharmacie du Moulin",
      "addr:housenumber": "17",
      "addr:postcode": "92360",
      "addr:street": "Avenue du Maréchal Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Moulin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016946",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1354010120,
    x: 48.8057138,
    y: 2.4562715,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Planètes",
      "ref:FR:FINESS": "940011612",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1361695546,
    x: 48.581032,
    y: 2.5825037,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie J.-L. Cilla",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-18:30",
      "ref:FR:FINESS": "770016558",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1362787026,
    x: 48.8229667,
    y: 2.3585907,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hammer",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023137",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1364881845,
    x: 48.902555,
    y: 2.2685008,
    tags: {
      "addr:housenumber": "101",
      "addr:postcode": "92400",
      "addr:street": "Rue Armand Silvestre",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Adenis",
      opening_hours: "Mo-Sa 08:30-20:30",
      phone: "+33143331141",
      "ref:FR:FINESS": "920013513",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1366493221,
    x: 48.8256661,
    y: 2.2938573,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "32",
      "contact:phone": "+33 1 46 42 10 30",
      "contact:street": "Rue Auguste Comte",
      dispensing: "yes",
      name: "Pharmacie du Plateau",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "920021151",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1367070737,
    x: 48.8210094,
    y: 2.2923285,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 38 94",
      dispensing: "yes",
      name: "Burbot Laurent",
      "ref:FR:FINESS": "920021201",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1367095391,
    x: 48.8196172,
    y: 2.2881014,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 34 78",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "920021235",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1367095392,
    x: 48.8202306,
    y: 2.2901635,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 42 03 81",
      dispensing: "yes",
      name: "Pharmacie Trincal",
      "ref:FR:FINESS": "920021284",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1379439286,
    x: 48.879672,
    y: 2.2913772,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Caillault",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031171",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1398081956,
    x: 48.8764045,
    y: 2.1806704,
    tags: {
      "addr:housenumber": "14",
      "addr:postcode": "92500",
      "addr:street": "Place de l'église",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Église",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "920019742",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1398085546,
    x: 48.8771037,
    y: 2.1813588,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmatie du Centre",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "920019981",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 1398085549,
    x: 48.8772621,
    y: 2.1814607,
    tags: {
      alt_name: "Pharmacie Manuel Château",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hôtel de Ville",
      "ref:FR:FINESS": "920019965",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1398094379,
    x: 48.8751756,
    y: 2.1811289,
    tags: {
      alt_name: "Pharmacie NGAU LY",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Château",
      opening_hours:
        "Tu-Sa 09:00-13:00, Tu-Fr 14:30-20:00, Sa 14:30-19:00, Mo 14:30-20:00",
      "opening_hours:covid19":
        "Mo 14:30-19:00; Tu-Fr 09:00-13:00,14:00-19:00; Sa 09:00-13:00,14:00-18:30",
      "ref:FR:FINESS": "920019577",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1398094381,
    x: 48.878305,
    y: 2.1783032,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Sa 08:30-19:00",
      "opening_hours:covid19": "Mo-Sa 08:30-13:00,14:00-20:00",
      "ref:FR:FINESS": "920020047",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1402586039,
    x: 48.8798271,
    y: 2.1809041,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920019940",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1404741144,
    x: 48.8641869,
    y: 2.2058127,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Paix",
      "opening_hours:covid19": "Mo-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00",
      phone: "+33 1 45 06 18 32",
      "ref:FR:FINESS": "920021037",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1409649753,
    x: 48.9100586,
    y: 2.2741967,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jehl",
      "ref:FR:FINESS": "920008893",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1411953914,
    x: 48.8054627,
    y: 2.1421708,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "3",
      "addr:postcode": "78000",
      "addr:street": "Rue de Montreuil",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Montreuil",
      phone: "+33 1 39 50 20 44",
      "ref:FR:FINESS": "780015277",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1411963973,
    x: 48.8047366,
    y: 2.1460872,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "1",
      "addr:postcode": "78000",
      "addr:street": "Boulevard de Lesseps",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pasmant",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-20:00",
      "ref:FR:FINESS": "780015160",
      source: "Celtipharm - 10/2014",
      website:
        "https://www.mypharmactiv.fr/pharmacie/pharmacie-saint-symphorien",
    },
  },
  {
    type: "node",
    id: 1417050157,
    x: 48.9713127,
    y: 2.2576355,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 39 81 34 16",
      "ref:FR:FINESS": "950012195",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1417929149,
    x: 48.8274933,
    y: 2.3708429,
    tags: {
      alt_name: "Pharmacie Lelong",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmactiv",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "750023517",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1420474141,
    x: 48.844933,
    y: 2.3838312,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "2",
      "contact:street": "Rue Érard",
      dispensing: "yes",
      name: "Pharmacie de la Place",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "750021420",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1423458432,
    x: 48.8803829,
    y: 2.2235395,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Chênes",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:30-20:00; Sa 09:00-12:30,15:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920020906",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1423880032,
    x: 48.9035419,
    y: 2.320669,
    tags: {
      "addr:housenumber": "177",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Victor Hugo",
      opening_hours: "Mo-Sa 09:30-19:30",
      "ref:FR:FINESS": "930015284",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1426931880,
    x: 48.7239904,
    y: 2.09142,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gerst",
      "ref:FR:FINESS": "780009163",
    },
  },
  {
    type: "node",
    id: 1427364029,
    x: 48.8472188,
    y: 2.3959207,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "20",
      "contact:postcode": "75012",
      "contact:street": "Place de la Nation",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fabre d'Églantine",
      "name:de": "Pharmacie Fabre d'Eglantine",
      opening_hours: "Mo-Fr 08:30-21:00, Sa 08:30-20:00, Su,PH 08:30-20:00",
      "ref:FR:FINESS": "750021800",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1427983619,
    x: 48.8101817,
    y: 2.1340387,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "53",
      "addr:postcode": "78000",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      atm: "no",
      "check_date:opening_hours": "2021-08-09",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      internet_access: "no",
      name: "Pharmacie de la Gare Rive Droite",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-19:30",
      phone: "+33 1 39 50 08 48",
      "ref:FR:FINESS": "780015210",
      source: "Celtipharm - 10/2014",
      "survey:date": "2019-03-09",
      website: "http://pharminfo.fr",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1438162464,
    x: 48.8128083,
    y: 2.4728518,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Abbaye",
      phone: "+33148838086",
      "ref:FR:FINESS": "940013261",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1445604122,
    x: 48.7217391,
    y: 2.4694637,
    tags: {
      "addr:city": "Crosne",
      "addr:housenumber": "8",
      "addr:postcode": "91560",
      "addr:street": "Avenue du Président Salvador Allende",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 74 20",
      "contact:phone": "+33 1 69 48 74 18",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Plaine Haute",
      "ref:FR:FINESS": "910007350",
      since: "1991-01-25",
    },
  },
  {
    type: "node",
    id: 1450939219,
    x: 48.8255688,
    y: 2.4051308,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de Charenton",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007255",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1451356246,
    x: 49.0309336,
    y: 2.3623657,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "950007823",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1453550765,
    x: 48.8385021,
    y: 2.2887611,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Baruc",
      opening_hours: "Mo-Fr 08:45-19:45; Sa 09:00-13:00,14:00-19:00",
      "ref:FR:FINESS": "750026551",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1454697262,
    x: 48.7926172,
    y: 2.4629152,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bonnardel",
      opening_hours: "Mo-Sa 08:45-19:30",
      "ref:FR:FINESS": "940008451",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1455456189,
    x: 48.8444103,
    y: 2.2196044,
    tags: {
      "addr:city": "Saint-Cloud",
      "addr:housenumber": "8",
      "addr:postcode": "92210",
      "addr:street": "Rue de la Libération",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33146020387",
      "ref:FR:FINESS": "920020302",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1456695078,
    x: 48.8670689,
    y: 2.2806034,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Homéopathique Centrale",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750029100",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1461876705,
    x: 48.8462203,
    y: 2.4675405,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Taverna Delset",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008907",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1463853552,
    x: 48.8684973,
    y: 2.2828687,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 111",
      "opening_hours:covid19": "Mo-Fr 09:30-17:00; Sa 09:30-14:00",
      "ref:FR:FINESS": "750029316",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1465849700,
    x: 48.9036632,
    y: 2.4774494,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "37",
      "addr:postcode": "93140",
      "addr:street": "Rue Jules Guesde",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Drahy",
      "ref:FR:FINESS": "930006622",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "384546156",
      "ref:FR:SIRET": "38454615600017",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011;Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1473027350,
    x: 48.8323851,
    y: 2.23894,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Métro",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009743",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1477656332,
    x: 48.8640464,
    y: 2.369279,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Bataclan",
      "name:en": "Pharmacy Bataclan",
      opening_hours: "Mo-Fr 8:30-20:00; Sa 9:00-19:00",
      "ref:FR:FINESS": "750020315",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1477656333,
    x: 48.8635104,
    y: 2.3697345,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Wizman",
      "name:en": "Pharmacy Wizman",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      phone: "+33147005161",
      "ref:FR:FINESS": "750019374",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1479044844,
    x: 48.994696,
    y: 2.3687489,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "950011411",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1481532229,
    x: 48.7968871,
    y: 2.4864184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie d'Adamville",
      "ref:FR:FINESS": "940013527",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1481532297,
    x: 48.7873414,
    y: 2.4950936,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Alma",
      "ref:FR:FINESS": "940013691",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1481532314,
    x: 48.7890435,
    y: 2.5019814,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bismuth",
      "ref:FR:FINESS": "940013824",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1481532347,
    x: 48.7944479,
    y: 2.4855149,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fleury",
      "ref:FR:FINESS": "940013493",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1483017730,
    x: 48.8768567,
    y: 2.3359301,
    tags: {
      alt_name: "Pharmacie Herpin Marina",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sebag-Meimoun",
      phone: "+33 1 48 74 13 38",
      "ref:FR:FINESS": "750016776",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1488772107,
    x: 48.8769179,
    y: 2.3560295,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie du Septentrionet de l'Est",
      "ref:FR:FINESS": "750017576",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1489806922,
    x: 48.8346526,
    y: 2.4726919,
    tags: {
      "addr:street": "Avenue de Joinville",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Baltard",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 08:00-12:30, 14:00-20:00; Sa 08:00-19:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "940011943",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1492185598,
    x: 48.5837643,
    y: 2.8052881,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benoist",
      "ref:FR:FINESS": "770004380",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1502267700,
    x: 48.8508439,
    y: 2.2930393,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-08-16",
      dispensing: "yes",
      name: "Pharmacie Grenelle T Eiffel",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:30",
      "ref:FR:FINESS": "750026205",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1503909812,
    x: 48.8439514,
    y: 2.4312009,
    tags: {
      "addr:city": "Vincennes",
      "addr:housenumber": "2",
      "addr:postcode": "94300",
      "addr:street": "Rue du Maréchal Maunoury",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Vignerons",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "940016280",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1504098208,
    x: 48.8706039,
    y: 2.3262245,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie LeClerc",
      "ref:FR:FINESS": "750016941",
      source: "survey;Celtipharm - 10/2014",
      start_date: "1881",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1504098235,
    x: 48.8577488,
    y: 2.3222584,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bove",
      "ref:FR:FINESS": "750013963",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1506812925,
    x: 48.8954022,
    y: 2.2278059,
    tags: {
      "addr:city": "Nanterre",
      "addr:housenumber": "52",
      "addr:postcode": "92000",
      "addr:street": "Boulevard des Bouvets",
      alt_name: "Pharmacie Bertrand",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Parapharmacie Les Terrasses de l'Arche",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-13:00",
      "opening_hours:covid19": "open",
      phone: "+33147215643",
      "ref:FR:FINESS": "920017811",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1509993125,
    x: 48.7705682,
    y: 2.5197475,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "940014293",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1514111312,
    x: 48.8953711,
    y: 2.2495237,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 08:00-21:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "920014040",
      source: "Celtipharm - 10/2014",
      "source:amenity": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1523019273,
    x: 48.8262242,
    y: 2.2192354,
    tags: {
      "addr:city": "Sèvres",
      "addr:housenumber": "29",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours:
        "Tu-Fr 09:00-20:00, Mo 12:00-20:00, Sa 09:30-13:00,14:30-19:00",
      "ref:FR:FINESS": "920020682",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1529214524,
    x: 48.6296532,
    y: 2.4236039,
    tags: {
      "addr:city": "Courcouronnes",
      "addr:housenumber": "58",
      "addr:postcode": "91080",
      "addr:street": "Rue Jean Renoir",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 91 12 49",
      "contact:phone": "+33 1 69 91 11 68",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie NGuyen",
      "ref:FR:FINESS": "910007251",
      since: "1992-01-03",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
    },
  },
  {
    type: "node",
    id: 1531650187,
    x: 48.7242292,
    y: 2.2604003,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "43",
      "addr:postcode": "91300",
      "addr:street": "Avenue Carnot",
      alt_name: "Pharmacie Chau",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 32 19 83",
      "contact:phone": "+33 1 69 20 12 77",
      "contact:webcam": "https://leadersante.fr",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 2 Gares",
      opening_hours: "Mo-Fr 08:00-20:00;Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      owner: "François Hazem",
      "ref:FR:FINESS": "910010263",
      start_date: "1994-09-19",
    },
  },
  {
    type: "node",
    id: 1537124196,
    x: 48.7680984,
    y: 1.9492708,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "4",
      "addr:street": "Place Mendes France",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Eric Meunier",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007431",
      source: "Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 1538621323,
    x: 48.7639743,
    y: 1.9474032,
    tags: {
      "addr:city": "Maurepas",
      "addr:housename": "pharmacie des Pyramides",
      "addr:housenumber": "21",
      "addr:postcode": "78310",
      "addr:street": "rue de Brie",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Christine Bullet",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-14:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "780010344",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1544866142,
    x: 48.8260577,
    y: 2.4069482,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Gravelle",
      "ref:FR:FINESS": "940007420",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1544866162,
    x: 48.8533437,
    y: 2.4110062,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte de Montreuil",
      opening_hours: "24/7",
      "ref:FR:FINESS": "750036485",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1551565607,
    x: 48.948797,
    y: 2.493944,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Moulin de la Ville",
      opening_hours: "Mo-Fr 09:30-13:00,14:30-20:00, Sa 09:30-13:00",
      "ref:FR:FINESS": "930005251",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1552445744,
    x: 48.8024373,
    y: 2.4546496,
    tags: {
      "addr:housenumber": "8",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Bouleaux",
      "ref:FR:FINESS": "940008220",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 1552446434,
    x: 48.8068461,
    y: 2.4734389,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Reveilleau",
      "ref:FR:FINESS": "940013626",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1552446461,
    x: 48.8071633,
    y: 2.4722875,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-SA 14:30-20:00; Tu-Sa 09:00-12:30",
      "ref:FR:FINESS": "940013394",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1559465848,
    x: 48.808039,
    y: 2.2359782,
    tags: {
      "addr:housenumber": "32",
      "addr:postcode": "92190",
      "addr:street": "Rue de la République",
      alt_name: "Pharmacie Seret",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Quatre Saisons",
      phone: "+33 1 45341053",
      "ref:FR:FINESS": "920016763",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1559465896,
    x: 48.8069377,
    y: 2.2356781,
    tags: {
      "addr:housenumber": "38",
      "addr:postcode": "92190",
      "addr:street": "Rue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fontenaist",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "920016797",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1559465931,
    x: 48.8078123,
    y: 2.2361517,
    tags: {
      "addr:housenumber": "39",
      "addr:postcode": "92190",
      "addr:street": "Rue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharma Conseil",
      phone: "+33 1 55640101",
      "ref:FR:FINESS": "920016813",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1568009910,
    x: 48.8310799,
    y: 2.2791376,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Carrousel",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-19:30",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00, Sa 09:30-19:30",
      phone: "+33 1 46 48 77 22",
      "ref:FR:FINESS": "920015427",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1569044777,
    x: 48.7140781,
    y: 2.2592389,
    tags: {
      alt_name: "SELARL Pharmacie de l'Yvette",
      amenity: "pharmacy",
      "contact:city": "Palaiseau",
      "contact:housenumber": "75",
      "contact:phone": "+33 1 69 30 08 56",
      "contact:street": "Avenue de Stalingrad",
      "contact:website": "http://www.pharmaciedelyvette.com",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie de l'Yvette",
      "ref:FR:FINESS": "910011725",
      since: "1990-11-14",
    },
  },
  {
    type: "node",
    id: 1571866457,
    x: 48.607405,
    y: 2.304938,
    tags: {
      "addr:housenumber": "10",
      alt_name: "Pharmacie Déchanet",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale de Brétigny",
      phone: "+33 1 69 88 80 42",
      "ref:FR:FINESS": "910006071",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1572522527,
    x: 48.63057,
    y: 2.317945,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "2",
      "addr:postcode": "91240",
      "addr:street": "Rue Boieldieu",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "910012780",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 1572538187,
    x: 48.636305,
    y: 2.320699,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "28",
      "addr:postcode": "91240",
      "addr:state": "Essonne",
      "addr:street": "Rue Berlioz",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 16 98 23",
      "contact:phone": "+33 1 60 16 15 20",
      dispensing: "yes",
      name: "Pharmacie Berlioz",
      "ref:FR:FINESS": "910012764",
      since: "1992-01-22",
    },
  },
  {
    type: "node",
    id: 1574981511,
    x: 48.8943508,
    y: 2.320594,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-04-11",
      dispensing: "yes",
      name: "Pharmacie de la Jonquière",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "750031320",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-08-03",
    },
  },
  {
    type: "node",
    id: 1574981608,
    x: 48.8938617,
    y: 2.3220769,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Castagnet",
      opening_hours: "Mo-Fr 08:45-20:00; Sa 09:00-19:30",
      operator: "Castagnet Henri Bruno",
      phone: "+33 1 46 27 08 86",
      "ref:FR:FINESS": "750030637",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1579266678,
    x: 48.8083258,
    y: 2.3324943,
    tags: {
      "addr:housenumber": "38",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Laplace",
      "name:fr": "Pharmacie Laplace",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940005770",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1579618686,
    x: 48.8244668,
    y: 2.3237073,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "25",
      "contact:postcode": "75014",
      "contact:street": "Rue Friant",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Patschkowski",
      "ref:FR:FINESS": "750024234",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1579642968,
    x: 48.8277248,
    y: 2.3271996,
    tags: {
      "addr:housenumber": "79",
      "addr:postcode": "75014",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Alésia",
      opening_hours: "Mo-Sa 08:30-20:30",
      phone: "+33 1 43 27 03 39",
      "ref:FR:FINESS": "750024333",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1579679666,
    x: 48.8234361,
    y: 2.3236145,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Bernard Zerbib",
      "ref:FR:FINESS": "750023954",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
    },
  },
  {
    type: "node",
    id: 1585524527,
    x: 48.8314837,
    y: 2.3264507,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750023855",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1589527199,
    x: 48.8429335,
    y: 2.3895933,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 45 47 34",
      dispensing: "yes",
      name: "Pharmacie Montgallet",
      opening_hours: "Mo-Th 09:00-20:00; Fr-Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021750",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1589659219,
    x: 48.8194519,
    y: 2.4646849,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:30-20:00; Sa 09:00-12:30,14:30-20:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 48 83 20 50",
      "ref:FR:FINESS": "940010713",
      source: "Celtipharm - 10/2014",
      website: "https://pharmaciedelagare94.pharminfo.fr",
    },
  },
  {
    type: "node",
    id: 1594726277,
    x: 48.8489464,
    y: 2.3974432,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Nation",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "same",
      operator: "Citypharma",
      "ref:FR:FINESS": "750019341",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1594976799,
    x: 48.821618,
    y: 2.3421128,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "70",
      "contact:postcode": "75014",
      "contact:street": "Rue de l'Amiral Mouchez",
      dispensing: "yes",
      name: "Pharmacie de la Cité",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:00",
      "ref:FR:FINESS": "750023806",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1596016234,
    x: 49.0355006,
    y: 2.0616479,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Village",
      opening_hours:
        "Mo-Fr 09:00-12:30,15:00-19:00; Sa 09:00-12:30,15:00-18:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950006205",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1596035462,
    x: 48.9862626,
    y: 1.9689778,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Lavergne",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014676",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1596036392,
    x: 48.9821196,
    y: 1.9703288,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Graviers",
      "ref:FR:FINESS": "780014643",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1596738674,
    x: 48.8469268,
    y: 2.4080604,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Cours de Vincennes",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-13:30,14:30-19:30",
      "ref:FR:FINESS": "750022220",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1598188547,
    x: 48.6879355,
    y: 2.3580356,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "104",
      "addr:postcode": "91600",
      "addr:street": "Boulevard Aristide Briand",
      alt_name: "SELARL Pharmacie du Montoir",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 96 31 28",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Montoir",
      opening_hours: "Mo-Sa 09:00-12:30,14:00-20:00",
      "ref:FR:FINESS": "910013143",
      since: "2008-04-14",
    },
  },
  {
    type: "node",
    id: 1602728175,
    x: 48.8757608,
    y: 2.4266674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930005707",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1609143020,
    x: 48.9714101,
    y: 2.3081608,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "950007401",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1614244828,
    x: 48.8177218,
    y: 2.2495339,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Magguy Hancock",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 42 29 47",
      "ref:FR:FINESS": "920015526",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1618238724,
    x: 49.0082734,
    y: 2.2846075,
    tags: {
      "addr:housenumber": "51",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Morin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950010264",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 1623888904,
    x: 48.6280686,
    y: 2.4189861,
    tags: {
      "addr:city": "Courcouronnes",
      "addr:country": "FR",
      "addr:housenumber": "16",
      "addr:postcode": "91080",
      "addr:street": "Place des Copains d'Abord",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 91 15 43",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Loghman Adham",
      "ref:FR:FINESS": "910007186",
      "ref:FR:NAF": "4773Z",
      since: "1988-09-30",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1633692729,
    x: 48.8840597,
    y: 2.7100654,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Carpentier Dutheil",
      "ref:FR:FINESS": "770013191",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1634887877,
    x: 48.8958517,
    y: 2.7141623,
    tags: {
      "addr:city": "Thorigny-sur-Marne",
      "addr:postcode": "77400",
      "addr:street": "Allée des Rousselets",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dutheil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770011997",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1635124554,
    x: 48.7980767,
    y: 2.3283226,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-19:45; Sa 09:00-13:00,15:00-19:00; Su off",
      phone: "+33 1 46 64 05 90",
      "ref:FR:FINESS": "940006356",
      "ref:FR:SIRET": "48026649300018",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1641991880,
    x: 48.8179494,
    y: 2.4663107,
    tags: {
      "addr:city": "Joinville-le-Pont",
      "addr:postcode": "94340",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940010747",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1642963174,
    x: 48.7958924,
    y: 2.5076358,
    tags: {
      amenity: "pharmacy",
      day_off: "sunday",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "940013741",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1643357947,
    x: 48.7932721,
    y: 2.5147905,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bac",
      "ref:FR:FINESS": "940013774",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1643357956,
    x: 48.7927292,
    y: 2.5168463,
    tags: {
      amenity: "pharmacy",
      brand: "PharmaVie",
      dispensing: "yes",
      name: "Pharmavie",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 09:00-12:30,14:00-20:00; Sa 09:00-12:30,14:00-19:30",
      "ref:FR:FINESS": "940013790",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1643541926,
    x: 49.1033735,
    y: 2.5854208,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Trois Forêts",
      "ref:FR:FINESS": "600005755",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1646777843,
    x: 48.8240046,
    y: 2.4972176,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie du Tremblay",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940006885",
      source: "survey 2012-02",
    },
  },
  {
    type: "node",
    id: 1654622673,
    x: 48.8511376,
    y: 2.2748692,
    tags: {
      "addr:street": "Avenue Théophile Gautier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Deves",
      opening_hours: "Mo 14:00-20:00; Tu-Sa 08:00-20:00",
      "ref:FR:FINESS": "750028250",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1654623624,
    x: 48.8618383,
    y: 2.3830584,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Labi",
      "ref:FR:FINESS": "750018764",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1657945784,
    x: 48.8909793,
    y: 2.3478652,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "18",
      "addr:postcode": "75018",
      "addr:street": "Rue Simart",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      email: "phiesoussan@wanadoo.fr",
      healthcare: "pharmacy",
      name: "Pharmacie Soussan-Aizenman",
      opening_hours: "Mo 12:00-20:00; Tu-Sa 09:00-20:00",
      phone: "+33 1 42 54 07 68",
      "ref:FR:FINESS": "750034050",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1659552439,
    x: 48.7427471,
    y: 2.2971517,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Neveu",
      "ref:FR:FINESS": "920008075",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1660794201,
    x: 48.8126231,
    y: 2.3338151,
    tags: {
      "access:covid19": "yes",
      "addr:postcode": "94250",
      "addr:street": "Le chaperon vert",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Chaperon Vert",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940005705",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1661386022,
    x: 48.8088103,
    y: 2.1546447,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Jussieu",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014890",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1662213745,
    x: 48.9376104,
    y: 2.3580478,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Baali",
      opening_hours: "Mo-Sa 08:00-20:00; Su 09:00-19:30",
      "ref:FR:FINESS": "930014121",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1664971571,
    x: 49.070067,
    y: 2.3217664,
    tags: {
      "addr:street": "Rue Clottins",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Allaire",
      "ref:FR:FINESS": "950010595",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1665584045,
    x: 48.9857969,
    y: 2.177446,
    tags: {
      "addr:city": "La Frette-sur-Seine",
      "addr:housenumber": "2",
      "addr:postcode": "95530",
      "addr:street": "Rue du Petit Pont",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Petit Pont",
      phone: "+33 1 34 50 15 30",
      "ref:FR:FINESS": "950008284",
      source:
        "http://www.ville-la-frette95.fr/article.php3?id_article=74 ; Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1665596500,
    x: 48.9803708,
    y: 2.1825768,
    tags: {
      "addr:city": "La Frette-sur-Seine",
      "addr:housenumber": "125 ter",
      "addr:postcode": "95530",
      "addr:street": "Boulevard de Pontoise",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Frette",
      phone: "+33 1 34 50 88 44",
      "ref:FR:FINESS": "950008300",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmazone.fr/Pharmacie_Frette/",
    },
  },
  {
    type: "node",
    id: 1667931019,
    x: 48.9660465,
    y: 2.5427675,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Mousseaux",
      "ref:FR:FINESS": "930016605",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1668449923,
    x: 48.893575,
    y: 2.342801,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché du Poteau",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032286",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1668686134,
    x: 48.9195822,
    y: 2.3427,
    tags: {
      "addr:housenumber": "14",
      "addr:postcode": "93200",
      "addr:street": "Place des Pianos",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pleyel",
      "ref:FR:FINESS": "930014600",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1668706402,
    x: 48.894808,
    y: 2.343062,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mathieu",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032922",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1669014042,
    x: 48.894533,
    y: 2.341318,
    tags: {
      "access:covid19": "yes",
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Zederman Fantin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750033722",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 1670113227,
    x: 48.8704589,
    y: 2.3594512,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Château d'Eau",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:30-19:30",
      phone: "+33 1 42 08 22 72",
      "ref:FR:FINESS": "750017196",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1670160274,
    x: 48.8690317,
    y: 2.3634064,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Idoine",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017113",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1672702779,
    x: 48.8315222,
    y: 2.3662626,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jeanne d'Arc",
      "ref:FR:FINESS": "750023202",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1673525694,
    x: 48.9120621,
    y: 2.3345274,
    tags: {
      "addr:housenumber": "5",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930014923",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1673554171,
    x: 48.9049223,
    y: 2.3316376,
    tags: {
      "addr:housenumber": "85",
      "addr:postcode": "93400",
      "addr:street": "Av. Gabriel-Péri",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930015136",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1673554173,
    x: 48.9107884,
    y: 2.333241,
    tags: {
      "addr:housenumber": "16",
      "addr:postcode": "93400",
      "addr:street": "Av. Gabriel-Péri",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie NET",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 09:00-20:00; Sa 09:00-13:00,15:30-19:30",
      "ref:FR:FINESS": "930015052",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1673554174,
    x: 48.9083997,
    y: 2.3324275,
    tags: {
      "addr:housenumber": "50 bis",
      "addr:postcode": "93400",
      "addr:street": "Av. Gabriel-Péri",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "930015086",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674536509,
    x: 48.904458,
    y: 2.3284786,
    tags: {
      "addr:housenumber": "2",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fermon",
      "ref:FR:FINESS": "930015037",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674536511,
    x: 48.905744,
    y: 2.3439094,
    tags: {
      "addr:housenumber": "102",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Michelet",
      "ref:FR:FINESS": "930015185",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674544505,
    x: 48.9047176,
    y: 2.3328744,
    tags: {
      "addr:housenumber": "24",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ottino",
      "ref:FR:FINESS": "930014956",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674544507,
    x: 48.9105984,
    y: 2.3377417,
    tags: {
      "addr:housenumber": "2",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Monet",
      "ref:FR:FINESS": "930014972",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674544508,
    x: 48.904927,
    y: 2.3379146,
    tags: {
      "addr:housenumber": "45",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mamane",
      "opening_hours:covid19": "open",
      operator: "leader santé",
      "ref:FR:FINESS": "930015235",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1674973037,
    x: 48.7722311,
    y: 2.254287,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bois",
      "ref:FR:FINESS": "920011160",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1675719450,
    x: 48.8037561,
    y: 2.4437964,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "name:signed": "no",
      "opening_hours:covid19": "open",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1675825673,
    x: 48.8338308,
    y: 2.2284725,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Khiat et Icart",
      opening_hours:
        "Mo 10:00-19:30; Tu-Fr 9:00-19:30; Sa 9:00-13:00,15:00-19:30",
      "ref:FR:FINESS": "920010691",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1675825754,
    x: 48.8384864,
    y: 2.228419,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Arc en Ciel",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00, 14:30-19:30",
      "ref:FR:FINESS": "920010667",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1676314017,
    x: 48.7875446,
    y: 2.2345088,
    tags: {
      "addr:housenumber": "2",
      "addr:postcode": "92360",
      "addr:street": "Allée Marcel Simon",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      fax: "+33 1 46 31 54 17",
      healthcare: "pharmacy",
      name: "Pharmacie de Trivaux",
      old_name: "Pharmacie des Trivaux",
      operator: "SELARL PHARMACIE DE TRIVAUX",
      phone: "+33 1 46 31 60 76",
      "ref:FR:FINESS": "920016912",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "800598492",
      "ref:FR:SIRET": "80059849200018",
      "ref:vatin": "FR22800598492",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmaciedetrivaux.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1676314018,
    x: 48.7883551,
    y: 2.2304067,
    tags: {
      "addr:housename": "Pharmacie LEMPERIERE",
      "addr:housenumber": "7 ter",
      "addr:postcode": "92360",
      "addr:street": "Avenue du Général de Gaulle",
      alt_name: "Pharmacie LEMPERIERE",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      old_name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920016896",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1676314020,
    x: 48.7840772,
    y: 2.2318324,
    tags: {
      "addr:housenumber": "7",
      "addr:street": "Rue de la Synagogue",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Clinique",
      "ref:FR:FINESS": "920017027",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1678955968,
    x: 48.8136092,
    y: 2.3886148,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "940010150",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1679063444,
    x: 48.673633,
    y: 2.326638,
    tags: {
      "addr:city": "Épinay-sur-Orge",
      "addr:housenumber": "28",
      "addr:postcode": "91360",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 49 26 60",
      "contact:phone": "+33 1 69 09 22 69",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910008002",
      since: "2005-12-01",
    },
  },
  {
    type: "node",
    id: 1679462225,
    x: 48.89134,
    y: 2.351486,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Labrely",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750033334",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1679508516,
    x: 48.895662,
    y: 2.345742,
    tags: {
      alt_name: "La Grande Pharmacie Paris-Ornano",
      amenity: "pharmacy",
      brand: "Univers Pharmacie",
      dispensing: "yes",
      name: "Univers Pharmacie",
      "ref:FR:FINESS": "750033532",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1679673515,
    x: 48.7439843,
    y: 1.9485149,
    tags: {
      "addr:housenumber": "79 Bis",
      "addr:postcode": "78320",
      "addr:street": "rue Emile Fontanier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Delecroix Wursthorn Régine",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780010542",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1686316678,
    x: 48.6697316,
    y: 2.3307633,
    tags: {
      "addr:city": "Épinay-sur-Orge",
      "addr:housenumber": "34",
      "addr:postcode": "91360",
      "addr:street": "Rue de la Gare",
      alt_name: "Pharmacie Besse",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 54 06 51",
      "contact:phone": "+33 1 69 09 20 28",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910007954",
      since: "1992-04-21",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012",
    },
  },
  {
    type: "node",
    id: 1688117199,
    x: 48.9059476,
    y: 2.2556269,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Augier",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "920014545",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1695110196,
    x: 48.8166678,
    y: 2.3212215,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "72",
      "contact:street": "Avenue Henri Ginoux",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-20:00, Sa 09:00-13:00,14:00-19:30",
      phone: "+33 1 42 53 02 41",
      "ref:FR:FINESS": "920017092",
      "ref:FR:SIRET": "43281326900011",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1696238875,
    x: 48.6559433,
    y: 2.4093036,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1698195180,
    x: 48.8645029,
    y: 2.1806583,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Iéna",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00,15:00-19:30",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920019775",
    },
  },
  {
    type: "node",
    id: 1700024525,
    x: 49.0096417,
    y: 2.1969873,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950005405",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1704122898,
    x: 48.9170774,
    y: 2.2652415,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "920009446",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1704661062,
    x: 48.8105076,
    y: 2.5171697,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940007222",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 1707030325,
    x: 48.8977669,
    y: 2.3251169,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bessières",
      "ref:FR:FINESS": "750029704",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1709746432,
    x: 48.7788232,
    y: 2.4629751,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kennedy",
      "ref:FR:FINESS": "940008436",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1709992490,
    x: 48.7781988,
    y: 2.4706222,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Abbaye",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008204",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1711636585,
    x: 48.8796464,
    y: 2.101202,
    tags: {
      "addr:city": "Marly-le-Roi",
      "addr:place": "Centre Commercial des Grandes Terres",
      "addr:postcode": "78160",
      "addr:street": "Avenue de l'Amiral Lemonnier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Grandes Terres",
      "opening_hours:covid19": "open",
      operator: "Monlibert-Pujol",
      "ref:FR:FINESS": "780010047",
    },
  },
  {
    type: "node",
    id: 1722769966,
    x: 48.731137,
    y: 2.1726638,
    tags: {
      "addr:city": "Saclay",
      "addr:housenumber": "10",
      "addr:postcode": "91400",
      "addr:street": "Rue Charles Thomassin",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 85 34 12",
      dispensing: "yes",
      name: "Pharmacie de Saclay",
      "ref:FR:FINESS": "910012244",
      since: "1993-11-26",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2018",
    },
  },
  {
    type: "node",
    id: 1728775085,
    x: 48.9820825,
    y: 2.2324023,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "La Pharmacie du Parc",
      opening_hours: "Mo-Sa 09:00-13:00, Mo-Fr 14:00-20:00, Sa 15:00-19:00",
      "ref:FR:FINESS": "950008003",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1728936593,
    x: 48.897284,
    y: 2.247776,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      entrance: "yes",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013562",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1730369095,
    x: 48.6738459,
    y: 2.3532654,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "6",
      "addr:postcode": "91600",
      "addr:street": "Rue des Rossays",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 05 28 81",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Brigitte Faure Antony",
      "ref:FR:FINESS": "910013325",
      since: "1987-09-30",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
    },
  },
  {
    type: "node",
    id: 1731592077,
    x: 48.5326472,
    y: 2.6759112,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rosello Fardoit",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770012490",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1731667424,
    x: 48.3883672,
    y: 2.8066611,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Quai de Seine",
      "ref:FR:FINESS": "770011393",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1732756840,
    x: 48.7705053,
    y: 2.3404345,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dechamps",
      "ref:FR:FINESS": "940007701",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1732756898,
    x: 48.758997,
    y: 2.329236,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Paix",
      "ref:FR:FINESS": "940009467",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1738551287,
    x: 48.7416949,
    y: 2.302896,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      "ref:FR:FINESS": "920007960",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1741258100,
    x: 48.8851813,
    y: 2.336968,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cayla",
      "opening_hours:covid19": "Mo-Fr 08:30-19:30; Sa 09:00-19:30",
      "ref:FR:FINESS": "750031916",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1742656326,
    x: 48.8876033,
    y: 2.0986433,
    tags: {
      "addr:housenumber": "9",
      "addr:postcode": "78230",
      "addr:street": "Allée de Normandie",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Paquereau",
      "ref:FR:FINESS": "780011714",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1744161799,
    x: 48.7979916,
    y: 2.1268026,
    tags: {
      amenity: "pharmacy",
      dispensing: "no;yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Tournelles",
      opening_hours:
        "Mo 09:00-13:00,14:00-20:00; Tu-Fr 08:30-13:00,14:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "780015640",
      shop: "chemist",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1747116508,
    x: 48.9952481,
    y: 2.1911972,
    tags: {
      "addr:city": "Montigny-lès-Cormeilles",
      "addr:housenumber": "147",
      "addr:postcode": "95370",
      "addr:street": "Boulevard Victor Bordier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Patte d'Oie",
      opening_hours: "Mo-Sa 09:00-20:00; Fr 13:30-08:00",
      "ref:FR:FINESS": "950010231",
      "ref:FR:SIREN": "79270443900019",
      "ref:FR:SIRET": "792704439",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1747533424,
    x: 48.7168128,
    y: 2.5060351,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "39",
      "addr:postcode": "91330",
      "addr:street": "Rue Paul Doumer",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 83 86 04",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Jean Letierce",
      "ref:FR:FINESS": "910014661",
      since: "1989-06-30",
    },
  },
  {
    type: "node",
    id: 1749523849,
    x: 48.8202057,
    y: 2.4774091,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont de Joinville",
      "ref:FR:FINESS": "940010630",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1749844239,
    x: 48.7411104,
    y: 2.2822119,
    tags: {
      "addr:city": "Antony",
      "addr:housenumber": "210",
      "addr:postcode": "92160",
      "addr:street": "Rue Adolphe Pajeaud",
      alt_name: "Pharmacie Brousse Jean-Marie Franç.",
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 66 40 80",
      dispensing: "yes",
      is_in: "Hauts-de-Seine",
      name: "Pharmacie Val de Bièvre",
      "ref:FR:FINESS": "920007747",
      source: "Celtipharm - 10/2014",
      start_date: "1991-06-13",
    },
  },
  {
    type: "node",
    id: 1749844465,
    x: 48.8962074,
    y: 2.1054776,
    tags: {
      "addr:city": "Le Pecq",
      "addr:country": "FR",
      "addr:housenumber": "14",
      "addr:postcode": "78230",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "780011763",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1751079635,
    x: 48.8281082,
    y: 2.234132,
    tags: {
      "addr:housenumber": "5",
      "addr:street": "Passage Pierre Bézier",
      alt_name: "Pharmacie du Pont de Sèvres",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "ParaSens",
      website: "http://www.parasens.info/",
    },
  },
  {
    type: "node",
    id: 1754934036,
    x: 48.9152603,
    y: 2.2711651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009412",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1756731482,
    x: 48.8406048,
    y: 2.2999249,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jacqueminet",
      opening_hours:
        "Mo 09:00-13:00, 14:00-20:00; Tu-Fr 08:30-13:00, 14:00-20:00; Sa 09:00-13:00, 14:00-19:30",
      "ref:FR:FINESS": "750025173",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1756731568,
    x: 48.8452107,
    y: 2.2974384,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
      "opening_hours:covid19": "open",
      phone: "+33 1 48 28 31 14",
      "ref:FR:FINESS": "750025736",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1763253416,
    x: 48.8732114,
    y: 2.0941341,
    tags: {
      "addr:city": "Marly-le-Roi",
      "addr:housenumber": "8",
      "addr:postcode": "78160",
      "addr:street": "Avenue de Saint-Germain",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Desplat",
      "ref:FR:FINESS": "780010161",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1765475963,
    x: 48.8147538,
    y: 2.4204647,
    tags: {
      "addr:housenumber": "26",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "940011281",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1767755404,
    x: 48.8203401,
    y: 2.2517303,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 42 23 89",
      "ref:FR:FINESS": "920015393",
      source: "Celtipharm - 10/2014",
      website: "http://www.issy3moulins.com/PHARMACIE.php",
    },
  },
  {
    type: "node",
    id: 1767958776,
    x: 48.9212611,
    y: 2.2718422,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sergent",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009396",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1768195096,
    x: 48.5636015,
    y: 2.2948823,
    tags: {
      "addr:city": "Marolles-en-Hurepoix",
      "addr:housenumber": "36",
      "addr:postcode": "91630",
      "addr:street": "Avenue du Général de Gaulle",
      alt_name: "Pharmacie Éric Audet",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 56 23 29",
      "contact:phone": "+33 1 64 91 80 70",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "910010081",
      since: "1995-01-09",
    },
  },
  {
    type: "node",
    id: 1773041547,
    x: 48.9271841,
    y: 1.9375851,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Morainvilliers",
      opening_hours: "Mo-Fr 09:00-12:00,15:00-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "780011144",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1777475702,
    x: 48.8798491,
    y: 2.1115829,
    tags: {
      "addr:city": "Le Port-Marly",
      "addr:housenumber": "12",
      "addr:postcode": "78560",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Barets",
      "ref:FR:FINESS": "780012530",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1780787318,
    x: 48.9250951,
    y: 2.1884746,
    tags: {
      "addr:housenumber": "12",
      "addr:street": "Rue Gabriel Péri",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "780008512",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1780793504,
    x: 48.923902,
    y: 2.1878375,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gendron",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      "ref:FR:FINESS": "780008413",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1781570221,
    x: 48.911975,
    y: 2.2963701,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benisty",
      "ref:FR:FINESS": "920008810",
      source: "photo survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1781570235,
    x: 48.9122764,
    y: 2.2955268,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Santé et Réussite",
      "ref:FR:FINESS": "920008877",
      source: "photo survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1781570325,
    x: 48.9146562,
    y: 2.2897041,
    tags: {
      "addr:housenumber": "51",
      "addr:postcode": "92600",
      "addr:street": "Rue de la Comète",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 93 21 92",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Collange",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "920008430",
      source: "photo survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1784622238,
    x: 48.893576,
    y: 2.3234384,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 42",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:30",
      phone: "+33 1 46 27 06 03",
      "ref:FR:FINESS": "750030603",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-19",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1784866253,
    x: 48.7835146,
    y: 2.1910531,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Maire",
      "ref:FR:FINESS": "780014361",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1785144744,
    x: 49.115957,
    y: 2.281288,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "ref:FR:FINESS": "950011296",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1785271528,
    x: 48.8352673,
    y: 2.4018017,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Paris",
      "addr:postcode": "75012",
      "addr:street": "Avenue du Général Michel Bizot",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 58",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750021503",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1785423843,
    x: 48.8231032,
    y: 2.3543307,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-19",
      dispensing: "yes",
      name: "Pharmacie du Village des Peupliers",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022733",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1785908328,
    x: 48.872721,
    y: 2.309853,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Roule",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00; Sa 09:30-20:00",
      "ref:FR:FINESS": "750014946",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1786150832,
    x: 48.914548,
    y: 2.2575383,
    tags: {
      "addr:housenumber": "32",
      "addr:postcode": "92700",
      "addr:street": "rue des Vallées",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale des Vallées",
      opening_hours:
        "Mo-Fr 14:30-19:30, Tu-Fr 08:30-12:30, Sa 09:00-13:00,15:00-19:00",
      "opening_hours:covid19":
        "Mo 14:30-19:30; Tu-Fr 08:30-12:30,14:30-19:30; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920013463",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmaciecentraledesvallees.com/",
    },
  },
  {
    type: "node",
    id: 1787088754,
    x: 48.8827159,
    y: 2.3147856,
    tags: {
      "addr:housenumber": "35",
      "addr:postcode": "75017",
      "addr:street": "Rue de Levis",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacieterrasse@orange.fr",
      healthcare: "pharmacy",
      name: "Pharmacie de la Terrasse",
      opening_hours: "Mo-Sa 08:30-20:00",
      phone: "+33 1 47 63 24 12",
      "ref:FR:FINESS": "750030835",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacie-de-la-terrasse-paris17.mesoigner.fr",
    },
  },
  {
    type: "node",
    id: 1787090215,
    x: 48.881753,
    y: 2.315289,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Villiers",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750031700",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1787533319,
    x: 48.8013752,
    y: 2.3796792,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nabet",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016942",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1788412908,
    x: 48.857733,
    y: 2.4712005,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Talamoni",
      "ref:FR:FINESS": "940009285",
      source: "local_knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1790141030,
    x: 48.7997734,
    y: 2.6081485,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:postcode": "77340",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre-Ville",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 43 95 90",
      "ref:FR:FINESS": "770010577",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1791412479,
    x: 48.8865819,
    y: 2.3505079,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Myrha",
      opening_hours: "Mo-Sa 09:00-20:00, Su 10:00-19:30",
      "ref:FR:FINESS": "750033185",
      source: "local knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1792382080,
    x: 48.9234474,
    y: 2.3695355,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Grand Canal",
      "ref:FR:FINESS": "930004486",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1792384829,
    x: 48.9285481,
    y: 2.3627473,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "930014204",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1792981331,
    x: 49.0451491,
    y: 2.3209055,
    tags: {
      "addr:city": "Bouffémont",
      "addr:housenumber": "7",
      "addr:postcode": "95570",
      "addr:street": "Allée de la Gare",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "950005934",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1792982200,
    x: 49.0446978,
    y: 2.3188653,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Pavots",
      "ref:FR:FINESS": "950005967",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1793370412,
    x: 48.8986071,
    y: 2.2870793,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Baudin",
      "ref:FR:FINESS": "920015690",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1793656408,
    x: 48.7982812,
    y: 2.1347979,
    tags: {
      "addr:housenumber": "43",
      "addr:postcode": "78000",
      "addr:street": "Rue des États Généraux",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Chantiers",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-13:00,15:00-19:00",
      "ref:FR:FINESS": "780015012",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1795159244,
    x: 48.8259441,
    y: 2.1976757,
    tags: {
      "addr:housenumber": "48",
      "addr:postcode": "92410",
      "addr:street": "Rue de Sèvres",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 09 53 62",
      dispensing: "yes",
      name: "Pharmacie du Parc de Lesser",
      "opening_hours:covid19": "Mo 14:30-20:00; Tu-Sa 09:00-12:00,14:30-20:00",
      "ref:FR:FINESS": "920021573",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1795161180,
    x: 48.9151327,
    y: 2.2941271,
    tags: {
      "addr:city": "Asnières-sur-Seine",
      "addr:housenumber": "62",
      "addr:postcode": "92600",
      "addr:street": "Boulevard Voltaire",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Djaffardjee",
      "ref:FR:FINESS": "920008844",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1795166925,
    x: 48.8999881,
    y: 2.2386402,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mona Lisa",
      opening_hours: "Mo-Fr 09:00-20:30, Sa 09:00-19:00",
      "ref:FR:FINESS": "920020518",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1795167319,
    x: 48.8967852,
    y: 2.2364454,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Léonard de Vinci",
      opening_hours: "Mo-Fr 08:30-21:00; Sa 9:00-20:00",
      "opening_hours:covid19": "open",
      operator: "Paris Pharma",
      "ref:FR:FINESS": "920025020",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1797965662,
    x: 48.8112481,
    y: 2.3145403,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "contact:housenumber": "20",
      "contact:street": "Rue Molière",
      dispensing: "yes",
      fax: "+33 1 46 56 60 59",
      name: "Pharmacie Tuffier",
      opening_hours: "Mo-Sa 08:45-20:00",
      phone: "+33 1 46 56 60 59",
      "ref:FR:FINESS": "920017365",
      "ref:FR:SIREN": "342228327",
      "ref:FR:SIRET": "34222832700033",
      source: "local Knowledge",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1800265356,
    x: 49.0518837,
    y: 2.205156,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Frépillon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950008250",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1801602832,
    x: 48.8120455,
    y: 2.1434358,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Clagny",
      "ref:FR:FINESS": "780015541",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1802274067,
    x: 48.9011113,
    y: 2.3037133,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "920012697",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1802274069,
    x: 48.9020109,
    y: 2.3024719,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Soldat Laboureur",
      opening_hours: "09:00-20:00",
      phone: "+33 1 47 37 23 22",
      "ref:FR:FINESS": "920012747",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-10-08",
      website: "https://www.pharmavie.fr",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1803870599,
    x: 48.8862038,
    y: 2.3178895,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030785",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1805589819,
    x: 48.8024195,
    y: 2.4716643,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Knur",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19":
        "Mo 14:00-20:00;Tu-Fr 08:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "940013592",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1806210211,
    x: 48.7644915,
    y: 2.4094084,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "940007966",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1806438539,
    x: 49.0721984,
    y: 2.6697895,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bitton Darmon",
      "ref:FR:FINESS": "770010064",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1806920185,
    x: 49.0732698,
    y: 2.6748566,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Dusseigneur",
      "ref:FR:FINESS": "770015816",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1807954154,
    x: 48.8320199,
    y: 2.1167851,
    tags: {
      alt_name: "Pharmacie Gautier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      phone: "+33 1 39 54 92 00",
      "ref:FR:FINESS": "780006490",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1812754635,
    x: 48.7793133,
    y: 2.33519,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "Mo 14:00-19:00; Tu-Sa 09:00-12:00, 14:00-19:00",
      "ref:FR:FINESS": "940009756",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1817302196,
    x: 48.8295135,
    y: 1.9610449,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Grand Plaisir",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780011946",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 1819902889,
    x: 48.9768667,
    y: 2.0149111,
    tags: {
      "addr:city": "Triel-sur-Seine",
      "addr:housenumber": "9-11",
      "addr:postcode": "78510",
      "addr:street": "Rue des Chatelaines",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie J.L. Moret",
      opening_hours:
        "Mo-Fr 08:30-12:30,15:00-20:00; Sa 09:00-12:30,15:00-19:30; Jul-Aug Mo-Fr 09:00-12:30,15:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30,15:00-19:00",
      phone: "+33 1 39 74 77 04",
      "ref:FR:FINESS": "780014213",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1821304262,
    x: 48.8705289,
    y: 2.3425306,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Panoramas",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 14:00-19:00",
      "ref:FR:FINESS": "750009110",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1821733177,
    x: 48.8762097,
    y: 2.3328316,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Badiou",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Trinité",
      opening_hours: "Mo-Th 08:30-20:00, Fr 08:30-19:30, Sa 10:00-14:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750015794",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1822082691,
    x: 48.8701054,
    y: 2.3514869,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bonne Nouvelle",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 09:00-20:00",
      "ref:FR:FINESS": "750017147",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1822876544,
    x: 48.8094653,
    y: 2.3611223,
    tags: {
      "addr:postcode": "94270",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôpital",
      opening_hours: "Mo-Sa 08:30-21:00",
      "ref:FR:FINESS": "940010861",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1824509210,
    x: 48.8742456,
    y: 2.3328589,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "69",
      "addr:postcode": "75009",
      "addr:street": "Rue de Provence",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Carré Opéra",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 09:30-20:30",
      "opening_hours:covid19": "Mo-Fr 08:00-20:30; Sa 09:30-20:30",
      "ref:FR:FINESS": "750015810",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1824978327,
    x: 48.7459255,
    y: 2.3478606,
    tags: {
      "addr:city": "Rungis",
      "addr:country": "FR",
      "addr:housename": "Pharmacie Lernould",
      "addr:housenumber": "13",
      "addr:postcode": "94150",
      "addr:street": "Rue de l'Abreuvoir",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Lernould",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-19:30; Sa 09:00-12:30,14:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 86 29 03",
      "ref:FR:FINESS": "940012941",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1826101232,
    x: 48.8352223,
    y: 2.3476258,
    tags: {
      alt_name: "Pharmacie Thiébaud",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Thiebaud Serafin",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022352",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1826139457,
    x: 48.7032141,
    y: 2.2437053,
    tags: {
      "addr:city": "Villebon-sur-Yvette",
      "addr:housenumber": "4",
      "addr:postcode": "91140",
      "addr:street": "Rue Henri Dunant",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 14 19 92",
      "contact:phone": "+33 1 60 10 22 08",
      "description:covid19": "Les clients rentrent 3 par 3",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Weil",
      "opening_hours:covid19":
        "Tu-Sa 09:00-12:30,15:00-19:00; Mo 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910014026",
      since: "1988-03-25",
    },
  },
  {
    type: "node",
    id: 1826178050,
    x: 48.7657383,
    y: 2.2605558,
    tags: {
      amenity: "pharmacy",
      atm: "yes",
      dispensing: "yes",
      name: "Pharmacie de la Division Leclerc",
      "ref:FR:FINESS": "920011376",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1827261393,
    x: 48.835369,
    y: 2.3465771,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Coscas Bouzemane",
      "ref:FR:FINESS": "750022337",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1827896305,
    x: 48.888845,
    y: 2.1696597,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "only",
      "description:covid19":
        "Ne livre que ses clients connus et répertoriés comme dépendants",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie Lieu",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "Mo-Fr 08:00-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "920025046",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 1827896316,
    x: 48.8889191,
    y: 2.1676164,
    tags: {
      amenity: "pharmacy",
      "description:covid19":
        "Ne livre que ses clients connus dans le secteur et répertoriés comme dépendants.",
      dispensing: "yes",
      name: "Pharmacie de l'Europe",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00; Sa 09:30-13:30",
      phone: "+33 1 41 42 31 99",
      "ref:FR:FINESS": "920020096",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1827911006,
    x: 48.7644178,
    y: 2.4083147,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tsaramaro",
      "ref:FR:FINESS": "940007982",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1828408055,
    x: 48.9184414,
    y: 2.2832286,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Zibi",
      "ref:FR:FINESS": "920008695",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1830505874,
    x: 48.8601225,
    y: 2.4474909,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dang Vu Thi",
      "ref:FR:FINESS": "930009956",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1832603994,
    x: 48.9203581,
    y: 2.4104123,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "930007505",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1834656352,
    x: 48.8338833,
    y: 2.2904242,
    tags: {
      "addr:housenumber": "395",
      "addr:postcode": "75015",
      "addr:street": "Rue de Vaugirard",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie De La Porte De Versailles",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750027302",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1834715463,
    x: 48.6453105,
    y: 2.0761719,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Limours",
      "addr:country": "FR",
      "addr:housenumber": "4",
      "addr:postcode": "91470",
      "addr:street": "Rue du Couvent",
      alt_name: "Pharmacie Chérubin",
      amenity: "pharmacy",
      "contact:fax": "+33 9 71 70 13 41",
      "contact:phone": "+33 1 64 91 02 19",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 91 02 19",
      "ref:FR:FINESS": "910009570",
      "ref:FR:NAF": "4773Z",
      since: "1995-09-21",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1834715608,
    x: 48.6459571,
    y: 2.0768215,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Limours",
      "addr:country": "FR",
      "addr:housenumber": "16",
      "addr:postcode": "91470",
      "addr:street": "Place du Général de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "164912979",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Limours",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 91 00 71",
      "ref:FR:FINESS": "910009554",
      "ref:FR:NAF": "4773Z",
      since: "1976-09-27",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1838490846,
    x: 48.8244871,
    y: 2.129473,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Glatigny",
      "ref:FR:FINESS": "780006474",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1843095149,
    x: 48.8269305,
    y: 2.1893599,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 50 43 10",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19":
        "Mo 14:30-20:00; Tu-Fr 09:00-13h30,14:30-20:00; Sa 09:00-13:30,14:30-19:30",
      "ref:FR:FINESS": "920021557",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1843639325,
    x: 48.864427,
    y: 2.3423345,
    tags: {
      alt_name: "Pharmacie du Coq-Héron",
      amenity: "pharmacy",
      brand: "Pharm&Price",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharm&Price",
      "ref:FR:FINESS": "750008492",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1843649679,
    x: 49.1679859,
    y: 2.3593948,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Château",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "600004857",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1847437847,
    x: 48.8936912,
    y: 2.2579372,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sainte-Marie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013943",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1848545990,
    x: 48.842554,
    y: 2.3977804,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021354",
      source: "Celtipharm - 10/2014",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1848545992,
    x: 48.8451294,
    y: 2.4027346,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Picpus",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022105",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1853317937,
    x: 48.8270535,
    y: 2.5430275,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015746",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1853327993,
    x: 48.8323955,
    y: 2.5516802,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kaddouze",
      "ref:FR:FINESS": "940017221",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1853347985,
    x: 48.831576,
    y: 2.5397099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Zeitoun",
      "ref:FR:FINESS": "940019185",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1855787215,
    x: 48.8883089,
    y: 2.0910599,
    tags: {
      "addr:housenumber": "25",
      "addr:postcode": "78100",
      "addr:street": "Rue Schnapper",
      amenity: "pharmacy",
      "contact:phone": "+33 1 34 51 33 92",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Tanneries Royales",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "780013363",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1855983896,
    x: 48.8951194,
    y: 2.0706566,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "7",
      "addr:postcode": "78100",
      "addr:street": "Place du Préfet Claude Érignac",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie de Bel Air",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780013090",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1858881743,
    x: 48.8661723,
    y: 2.0952301,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Grande Rue",
      "ref:FR:FINESS": "780010146",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1861994111,
    x: 48.6913175,
    y: 2.6101406,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "770004042",
      source: "survey+bing",
    },
  },
  {
    type: "node",
    id: 1861994214,
    x: 48.6909321,
    y: 2.6095071,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770004075",
      source: "survey+bing",
    },
  },
  {
    type: "node",
    id: 1861994324,
    x: 48.703563,
    y: 2.6010713,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770013050",
      source: "survey+bing",
    },
  },
  {
    type: "node",
    id: 1861994396,
    x: 48.7247408,
    y: 2.6575028,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Decaup",
      phone: "+33 1 64 05 35 87",
      "ref:FR:FINESS": "770005296",
      source: "survey+bing;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1862155115,
    x: 48.8621366,
    y: 2.194881,
    tags: {
      "addr:city": "Rueil-Malmaison",
      "addr:housenumber": "58",
      "addr:postcode": "92500",
      "addr:street": "Avenue de Fouilleuse",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "920019825",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1863066324,
    x: 48.7387868,
    y: 2.6064025,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "770007490",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1863067525,
    x: 48.7386607,
    y: 2.5651645,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie TSIA REJANE",
      "ref:FR:FINESS": "940011711",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1863067770,
    x: 48.7441676,
    y: 2.6153002,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vieux Village",
      opening_hours:
        "Mo-Fr 09:00-12:30, 15:00-19:30; Sa 09:00-12:30, 15:00-19:00",
      "ref:FR:FINESS": "770007524",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1863068052,
    x: 48.747654,
    y: 2.6153994,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marty",
      "ref:FR:FINESS": "770007540",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1863068143,
    x: 48.748804,
    y: 2.5130697,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940005986",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 1863068477,
    x: 48.7617231,
    y: 2.5265302,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940014061",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 1863990106,
    x: 48.4160629,
    y: 1.8793922,
    tags: {
      "addr:housenumber": "12",
      "addr:postcode": "28700",
      "addr:street": "Place Farcot",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie de Sainville",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "280004417",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1864625091,
    x: 48.89517,
    y: 2.0940063,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "56",
      "addr:postcode": "78100",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      "ref:FR:FINESS": "780013231",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1864625092,
    x: 48.8960705,
    y: 2.0919912,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "6",
      "addr:postcode": "78100",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Birckel",
      "ref:FR:FINESS": "780013215",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1865315763,
    x: 48.8072231,
    y: 2.354802,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Fort de Bicêtre",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-19:00",
      "ref:FR:FINESS": "940010812",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1866351129,
    x: 48.7139936,
    y: 2.4624211,
    tags: {
      "addr:city": "Crosne",
      "addr:housenumber": "28",
      "addr:postcode": "91560",
      "addr:street": "Avenue Jean Jaurès",
      alt_name: "Pharmacie Dauve",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 65 11",
      "contact:phone": "+33 1 69 48 67 38",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de l'Église",
      "ref:FR:FINESS": "910007301",
      since: "2006-04-01",
    },
  },
  {
    type: "node",
    id: 1866464517,
    x: 48.7876366,
    y: 2.3908266,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Théâtre",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "940016991",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1866465221,
    x: 48.7853356,
    y: 2.3885295,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Herbin",
      "ref:FR:FINESS": "940016710",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1866513227,
    x: 48.7950487,
    y: 2.3345273,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "4",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Talbot",
      opening_hours:
        "Mo 10:30-13:00,14:30-20:30; Tu-Fr 09:00-12:30,14:30-20:30; Sa 09:00-13:00,15:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 64 09 36",
      "ref:FR:FINESS": "940006455",
      "ref:FR:SIRET": "38173335100013",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1867003381,
    x: 48.8300971,
    y: 2.3262304,
    tags: {
      "addr:city": "Paris",
      "addr:country": "FR",
      "addr:housenumber": "193",
      "addr:postcode": "75014",
      "addr:street": "Avenue du Maine",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Homéopathique Maine Vally",
      "ref:FR:FINESS": "750024556",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1867076277,
    x: 48.6771858,
    y: 2.2939377,
    tags: {
      "addr:city": "Ballainvilliers",
      "addr:postcode": "91160",
      "addr:street": "Rue du Rouillon",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 34 90 97",
      "contact:phone": "+33 1 64 48 12 44",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Nguyen Tl Huong",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:30-19:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "910005586",
      since: "1987-04-01",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1867361381,
    x: 48.9423632,
    y: 2.3832069,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Promenade",
      "ref:FR:FINESS": "930014535",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1867505985,
    x: 48.8448765,
    y: 2.1319843,
    tags: {
      "addr:housenumber": "4",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bourgeois-Le Verre",
      opening_hours: "Mo-Fr 08:30-13:00, Mo-Fr 15:00-19:30; Sa 09:00-18:00",
      "ref:FR:FINESS": "780005724",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1868055167,
    x: 48.6700145,
    y: 2.6963077,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Orcibal",
      "ref:FR:FINESS": "770005676",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 1870150425,
    x: 48.852775,
    y: 2.5125074,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du RER",
      "ref:FR:FINESS": "930011317",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1870150429,
    x: 48.8492791,
    y: 2.4955547,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Rond-Point",
      "ref:FR:FINESS": "940012446",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1870150500,
    x: 48.8499724,
    y: 2.4988312,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pérard",
      "ref:FR:FINESS": "940012461",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1870150599,
    x: 48.8790245,
    y: 2.5335334,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie UZAN",
      "ref:FR:FINESS": "930008735",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1871175929,
    x: 48.7530405,
    y: 2.5060884,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": " 940005887",
    },
  },
  {
    type: "node",
    id: 1873990015,
    x: 48.707685,
    y: 2.4878932,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "60",
      "addr:postcode": "91330",
      "addr:street": "Rue Gabriel Péri",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 49 24 99",
      "contact:phone": "+33 1 69 48 35 62",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Dony Laclaire Laurence",
      "ref:FR:FINESS": "910014596",
      since: "1994-09-22",
    },
  },
  {
    type: "node",
    id: 1876650295,
    x: 48.4979785,
    y: 1.9619337,
    tags: {
      "addr:city": "Corbreuse",
      "addr:country": "FR",
      "addr:housenumber": "7",
      "addr:postcode": "91410",
      "addr:street": "Place Étienne de Garlande",
      alt_name: "Pharmacie Jouffroy",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 59 52 28",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Garlande",
      "ref:FR:FINESS": "910007103",
      "ref:FR:NAF": "4773Z",
      since: "1983-07-29",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1877552517,
    x: 48.8876375,
    y: 2.3200004,
    tags: {
      "addr:housenumber": "79",
      "addr:street": "Rue Legendre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Legendre",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750031056",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1882370362,
    x: 48.7434499,
    y: 2.4070793,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saffar",
      "ref:FR:FINESS": "940012131",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1882978692,
    x: 48.7131544,
    y: 2.362487,
    tags: {
      "addr:city": "Paray-Vieille-Poste",
      "addr:country": "FR",
      "addr:housenumber": "9",
      "addr:postcode": "91550",
      "addr:street": "Place Henri Barbusse",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 96 64",
      "contact:phone": "+33 1 69 38 33 04",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Vieille Poste",
      "opening_hours:covid19": "open",
      phone: "+33 1 69 38 33 04",
      "ref:FR:FINESS": "910011774",
      since: "2006-01-09",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012",
    },
  },
  {
    type: "node",
    id: 1884072735,
    x: 48.8286085,
    y: 2.3529303,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-25",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Lavaux",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750022501",
      source: "knowledge;Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 1888477410,
    x: 48.9152448,
    y: 2.403183,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boukaia",
      opening_hours: "Mo,We-Fr 09:30-20:30, Tu 08:30-20:00, Sa 08:30-20:00",
      "ref:FR:FINESS": "930004205",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1889706518,
    x: 48.9017256,
    y: 2.2513837,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Solaret",
      opening_hours:
        "Mo-Fr 08:15-13:00,14:30-19:45, Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920013646",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1894574668,
    x: 48.8879596,
    y: 2.2990758,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmaice Maarek",
      "ref:FR:FINESS": "750029654",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1896105404,
    x: 48.8940422,
    y: 2.332537,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Éden",
      opening_hours: "Mo-Sa 07:30-21:00; Su 09:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034233",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1896170390,
    x: 48.897735,
    y: 2.3437738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Herboriste de Paris",
      "ref:FR:FINESS": "750033235",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1896323400,
    x: 48.8894588,
    y: 2.3334837,
    tags: {
      "access:covid19": "yes",
      "addr:postcode": "75018",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Triqueneaux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032500",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1896372260,
    x: 48.8951731,
    y: 2.3373305,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rubinstein",
      "ref:FR:FINESS": "750032617",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1896379899,
    x: 48.8934462,
    y: 2.3386363,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 130 Ordener",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750033433",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1898757104,
    x: 48.8976903,
    y: 2.08865,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "55",
      "addr:postcode": "78100",
      "addr:street": "Rue de Pologne",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Arcades",
      "ref:FR:FINESS": "780013314",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1898757280,
    x: 48.8963475,
    y: 2.0914373,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "9",
      "addr:postcode": "78100",
      "addr:street": "Rue du Vieux Marché",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Compagne",
      "ref:FR:FINESS": "780013397",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1898757436,
    x: 48.8958777,
    y: 2.0912621,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:housenumber": "14",
      "addr:postcode": "78100",
      "addr:street": "rue André Bonnenfant",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bonnenfant",
      opening_hours: "Mo-Sa 09:30-12:30+; Mo-Fr 14:00-20:00; Sa 14:00-19:00",
      "ref:FR:FINESS": "780013074",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1898757916,
    x: 48.8984514,
    y: 2.0890049,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "11",
      "addr:postcode": "78100",
      "addr:street": "Rue de Poissy",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Europe",
      "ref:FR:FINESS": "780013264",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1899126584,
    x: 48.8903453,
    y: 2.4530046,
    tags: {
      "addr:postcode": "93130",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "930012273",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1899126624,
    x: 48.8945422,
    y: 2.4582835,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ferrari",
      "ref:FR:FINESS": "930012323",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1899126654,
    x: 48.8879609,
    y: 2.4620652,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie El Ghadouani",
      "ref:FR:FINESS": "930012216",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1899126657,
    x: 48.8885293,
    y: 2.4558431,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rambour",
      "ref:FR:FINESS": "930012182",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1902278749,
    x: 48.8976521,
    y: 2.0931547,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "64",
      "addr:postcode": "78100",
      "addr:street": "rue au Pain",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Leclercq",
      "ref:FR:FINESS": "780013181",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1903776300,
    x: 48.7047065,
    y: 2.4606971,
    tags: {
      "addr:city": "Montgeron",
      "addr:housenumber": "95",
      "addr:postcode": "91230",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 52 39 44",
      "contact:phone": "+33 1 69 03 32 99",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Pierre Louge",
      "ref:FR:FINESS": "910010826",
      since: "1995-12-18",
    },
  },
  {
    type: "node",
    id: 1904279144,
    x: 48.8968971,
    y: 2.0924532,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:housenumber": "35",
      "addr:postcode": "78100",
      "addr:street": "Rue au Pain",
      amenity: "pharmacy",
      "contact:phone": "+33 1 34 51 01 61",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Marceau",
      opening_hours:
        "Mo 14:00-19:30; Tu-Fr 09:00-13:00,14:00-19:30; Sa 09:30-13:00,14:00-19:30",
      "ref:FR:FINESS": "780013413",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1905624924,
    x: 48.9652222,
    y: 1.8632302,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007811",
    },
  },
  {
    type: "node",
    id: 1909178785,
    x: 48.8980076,
    y: 2.3378558,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte de Montmartre",
      "ref:FR:FINESS": "750033250",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1909181117,
    x: 48.8980009,
    y: 2.3369345,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "144",
      "addr:postcode": "75018",
      "addr:street": "Boulevard Ney",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Boulevard Ney",
      "ref:FR:FINESS": "750033706",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1909183614,
    x: 48.8979639,
    y: 2.3349302,
    tags: {
      alt_name: "Pharmacie Maarek",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bichat",
      "ref:FR:FINESS": "750033284",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1909741683,
    x: 48.9071114,
    y: 2.3324335,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rivier",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "930015102",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1909836388,
    x: 48.8953467,
    y: 2.32804,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Souied et Cie",
      opening_hours: "Mo-Sa 08:30-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031452",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1909838091,
    x: 48.8962874,
    y: 2.3283324,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "133",
      "addr:postcode": "75017",
      "addr:street": "Avenue de Saint-Ouen",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie de la Porte de Saint-Ouen",
      opening_hours: "Mo-Sa 8:30-20:30",
      operator: "Pharmavance",
      phone: "+33 1 46 27 51 87",
      "ref:FR:FINESS": "750031486",
      "survey:date": "2018-05-22",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1909853359,
    x: 48.8997059,
    y: 2.0798021,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "108",
      "addr:postcode": "78100",
      "addr:street": "Rue Léon Desoyer",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kanoui Lebhar",
      "ref:FR:FINESS": "780013165",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1911406628,
    x: 48.8163976,
    y: 2.3113604,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "6",
      "contact:place": "Place Jean Jaurès",
      "contact:postcode": "92120",
      dispensing: "yes",
      fax: "+33 1 47 35 93 55",
      level: "0",
      name: "Pharmacie Dupain",
      opening_hours: "Mo-Fr 08:30-19:30, Sa 09:00-12:30",
      "opening_hours:covid19": "Mo-Fr 08:30-19:30; Sa 09:00-12:15",
      phone: "+33 1 47 35 29 93",
      "ref:FR:FINESS": "920017175",
      "ref:FR:SIREN": "389242934",
      "ref:FR:SIRET": "38924293400021",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1911407563,
    x: 48.8160855,
    y: 2.3085881,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "111",
      "contact:street": "Avenue Verdier",
      dispensing: "yes",
      name: "Pharmacie Renard",
      opening_hours:
        "Tu-Fr 09:00-19:30; Mo 14:00-19:30; Sa 09:00-13:00,15:00-19:30",
      "opening_hours:covid19":
        "Mo 14:00-19:00; Tu-Fr 09:00-13:00,14:30-19:00; Sa 09:00-15:00",
      phone: "+33 1 42 53 21 93",
      "ref:FR:FINESS": "920017381",
      "ref:FR:SIREN": "821840949",
      "ref:FR:SIRET": "82184094900019",
      source: "survey",
      "type:FR:FINESS": "620",
      website: "https://pharmacie-renard-montrouge.fr/",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1917444719,
    x: 48.8723646,
    y: 2.1925895,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Peltier",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:00-19:00; Sa 09:00-13:00,15:00-18:30",
      "ref:FR:FINESS": "920020013",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1917523410,
    x: 48.8217964,
    y: 2.358317,
    tags: {
      alt_name: "Pharmacie Abijoux",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Maison Blanche",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023103",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 1920175264,
    x: 48.9815851,
    y: 2.2435975,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Baud",
      opening_hours: "Tu-Sa 09:00-12:30, 15:00-19:30; Mo 15:00-19:30",
      phone: "+33 1 34 15 47 75",
      "ref:FR:FINESS": "950007617",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1920861894,
    x: 48.9928371,
    y: 1.9038272,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ama Koffi",
      "ref:FR:FINESS": "780011276",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1924884949,
    x: 48.754556,
    y: 1.944507,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014791",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1925482806,
    x: 48.8971047,
    y: 2.0772145,
    tags: {
      "addr:city": "Saint-Germain-en-Laye",
      "addr:country": "FR",
      "addr:housenumber": "8",
      "addr:postcode": "78100",
      "addr:street": "Rue Saint-Léger",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Puget",
      "ref:FR:FINESS": "780013330",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1930004929,
    x: 48.7796521,
    y: 2.0356066,
    tags: {
      "addr:housenumber": "6",
      "addr:street": "Place André Malraux",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Quevreux",
      opening_hours: "Mo-Fr 09:30-12:30,15:00-19:30, Sa 10:00-13:00",
      "ref:FR:FINESS": "780011011",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1931492283,
    x: 48.8769136,
    y: 2.2495603,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Longchamp",
      "ref:FR:FINESS": "920018595",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1933481672,
    x: 48.9118074,
    y: 2.3585046,
    tags: {
      "addr:city": "Saint-Denis",
      "addr:housenumber": "170",
      "addr:postcode": "93210",
      "addr:street": "Avenue du Président Wilson",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khay Ibbat",
      "ref:FR:FINESS": "930014857",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1933649994,
    x: 48.7863835,
    y: 2.2932376,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lombart",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-20:00",
      "ref:FR:FINESS": "920014230",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1933742640,
    x: 48.8660569,
    y: 2.5341499,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Houari",
      "ref:FR:FINESS": "930011507",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1933742653,
    x: 48.8642669,
    y: 2.5258578,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie des Jonquilles",
      "ref:FR:FINESS": "930011572",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1936029104,
    x: 48.931492,
    y: 2.2750967,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Mourinoux",
      "ref:FR:FINESS": "920008711",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1936548492,
    x: 48.7769472,
    y: 2.3231204,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Bièvre",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-20:00, Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920010964",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1936686487,
    x: 48.91754,
    y: 2.3515606,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Etoiles",
      "ref:FR:FINESS": "930014485",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 1938060944,
    x: 48.9262443,
    y: 2.186875,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Schweitzer",
      "ref:FR:FINESS": "780008546",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1938608364,
    x: 48.8423893,
    y: 2.2813187,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mirabeau",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "750028433",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1939356502,
    x: 48.883467,
    y: 2.5286448,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jaurès",
      "ref:FR:FINESS": "930019328",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 1939415440,
    x: 48.7168368,
    y: 1.8922881,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "780007696",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1939432305,
    x: 48.7203274,
    y: 1.8920871,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780007712",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1946024696,
    x: 48.8204797,
    y: 2.2284345,
    tags: {
      "addr:city": "Meudon",
      "addr:housenumber": "22",
      "addr:postcode": "92190",
      "addr:street": "Rue Marcel Allégot",
      alt_name: "Pharmacie SCHMEDER",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bellevue",
      opening_hours:
        "Mo-Sa,PH 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 30 10 98",
      "ref:FR:FINESS": "920016714",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 1950640656,
    x: 48.8554898,
    y: 2.4013519,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Habib",
      "ref:FR:FINESS": "750036733",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1950660982,
    x: 48.8567743,
    y: 2.4001839,
    tags: {
      alt_name: "Pharmacie Raulot",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale de Charonne",
      "ref:FR:FINESS": "750036766",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1950785349,
    x: 48.8573471,
    y: 2.4084118,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Wolff",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750036782",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1956879660,
    x: 48.7835121,
    y: 2.055499,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Saules",
      "ref:FR:FINESS": "780008181",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1958510179,
    x: 48.8145011,
    y: 2.2148818,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Bruyères",
      "ref:FR:FINESS": "920020617",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1959465562,
    x: 48.9904486,
    y: 2.4180957,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dahan",
      "ref:FR:FINESS": "950005207",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1966291219,
    x: 48.780695,
    y: 2.042659,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Sud Canal",
      "ref:FR:FINESS": "780010864",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1972906071,
    x: 48.9264143,
    y: 1.9902817,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marcou",
      "ref:FR:FINESS": "780011615",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1975260639,
    x: 48.9782829,
    y: 1.9119927,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du centre commercial Espace",
      opening_hours: "Mo-Sa 09:00-20:15",
      "ref:FR:FINESS": "780011326",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1981638626,
    x: 48.8045977,
    y: 2.1315782,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "22BIS",
      "addr:postcode": "78000",
      "addr:street": "Avenue de Saint-Cloud",
      amenity: "pharmacy",
      atm: "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      internet_access: "no",
      name: "Pharmacie Benyamin",
      "ref:FR:FINESS": "780015665",
      source: "survey 2015;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 1981640779,
    x: 48.805377,
    y: 2.1308594,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "47",
      "addr:postcode": "78000",
      "addr:street": "Rue Carnot",
      amenity: "pharmacy",
      atm: "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      internet_access: "no",
      name: "Pharmacie Kuoch",
      opening_hours: "Mo 10:30-19:30; Tu-Sa 09:00-19:30",
      "opening_hours:covid19": "Mo 10:30-19:30; Tu-Sa 09:00-19:30",
      "ref:FR:FINESS": "780014874",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1982876787,
    x: 48.8233923,
    y: 2.208411,
    tags: {
      "addr:housenumber": "91",
      "addr:postcode": "92310",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Auger",
      "ref:FR:FINESS": "920020716",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1982884289,
    x: 48.8222153,
    y: 2.2064757,
    tags: {
      "addr:housenumber": "126",
      "addr:postcode": "92310",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Siboni",
      opening_hours:
        "Tu-Fr 08:45-20:00; Mo 09:00-20:00; Sa 08:45-13:00,14:30-19:30",
      "ref:FR:FINESS": "920020732",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1982886614,
    x: 48.8206542,
    y: 2.2017993,
    tags: {
      "addr:housenumber": "149",
      "addr:postcode": "92310",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Abecassis",
      "ref:FR:FINESS": "920020757",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1983083066,
    x: 48.8256634,
    y: 2.207307,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Coteau",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920020633",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1985723023,
    x: 48.9525764,
    y: 2.2247413,
    tags: {
      amenity: "pharmacy",
      dispensing: "no",
      healthcare: "pharmacy",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1985727626,
    x: 48.9533719,
    y: 2.2284794,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950004507",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 1986853115,
    x: 48.7851411,
    y: 2.0447826,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 10:00-20:00",
      "ref:FR:FINESS": "780010963",
      source: "Celtipharm - 10/2014",
      "survey:date": "2019-04-29",
    },
  },
  {
    type: "node",
    id: 1989596617,
    x: 48.9357328,
    y: 2.2382548,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont Neuf",
      "ref:FR:FINESS": "950004937",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 1989596622,
    x: 48.9467268,
    y: 2.2325551,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tordjman",
      "ref:FR:FINESS": "950004804",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1995029575,
    x: 48.848434,
    y: 2.4373744,
    tags: {
      alt_name: "Pharmacie S. Sebag",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-02-27",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015894",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 1999044398,
    x: 48.5538915,
    y: 2.4307117,
    tags: {
      "addr:city": "Mennecy",
      "addr:country": "FR",
      "addr:full": "Centre Commercial de la Verville 91540 Mennecy",
      "addr:place": "Centre Commercial de la Verville",
      "addr:postcode": "91540",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 99 73 70",
      "contact:phone": "+33 1 64 99 81 85",
      dispensing: "yes",
      name: "Pharmacie de la Verville",
      opening_hours: "Mo-Sa 09:00-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00; 14:00-19:00",
      operator: "pharmacie",
      "payment:coins": "yes",
      "payment:mastercard": "yes",
      "payment:visa": "yes",
      phone: "+33 1 64 99 81 85",
      "ref:FR:FINESS": "910010495",
      "ref:FR:NAF": "4773Z",
      since: "1995-07-04",
      source: "extrapolation",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 1999996985,
    x: 48.7857901,
    y: 2.1791085,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dupriet-Delane",
      "ref:FR:FINESS": "780014544",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2000109916,
    x: 48.7832217,
    y: 2.0523885,
    tags: {
      "addr:housenumber": "21",
      "addr:street": "Rue de la Mare de Troux",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cantegrit",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780008280",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2000634470,
    x: 48.9567387,
    y: 2.2850954,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lagarenne",
      opening_hours: "Mo-We,Fr,Sa 08:00-20:00",
      "ref:FR:FINESS": "950004572",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2000813095,
    x: 48.8881063,
    y: 2.3740635,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Taieb",
      "ref:FR:FINESS": "750034605",
      source: "wikimedia;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2001041693,
    x: 48.9528949,
    y: 2.2258911,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Loemba",
      "ref:FR:FINESS": "950004416",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2003145887,
    x: 48.8607124,
    y: 2.3497206,
    tags: {
      "addr:city": "Paris",
      "addr:street": "Boulevard de Sébastopol",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Univers Pharmacie",
      note: "Anciennement : Pharmacie Première",
      opening_hours: "Mo-Su 08:00-24:00",
      phone: "+33 1 48 87 62 30",
      "ref:FR:FINESS": "750010563",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2003145936,
    x: 48.8627388,
    y: 2.3544858,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 54 Beaubourg",
      opening_hours: "Mo-Sa 08:30-20:30; Su 09:00-13:00; 14:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750009581",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2004007074,
    x: 48.8055994,
    y: 2.1277577,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "6",
      "addr:postcode": "78000",
      "addr:street": "Place Hoche",
      amenity: "pharmacy",
      atm: "no",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmaciedelaplacehoche@hotmail.com",
      healthcare: "pharmacy",
      internet_access: "no",
      name: "Pharmacie de la Place Hoche",
      "opening_hours:covid19": "Mo-Sa 08:30-18:00",
      operator: "Dr Carole TOVOLI-CAPITAINE",
      phone: "+33 1 39 50 01 73",
      "ref:FR:FINESS": "780015111",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2007769274,
    x: 48.8644061,
    y: 2.3713501,
    tags: {
      alt_name: "Pharmacie Amar",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Folie Méricourt",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750019390",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2012711966,
    x: 48.8356592,
    y: 2.3240019,
    tags: {
      amenity: "pharmacy",
      atm: "no",
      dispensing: "yes",
      drive_through: "no",
      internet_access: "no",
      name: "Pharmacie Charlemagne",
      "ref:FR:FINESS": "750024473",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2012779215,
    x: 48.8357428,
    y: 2.3922686,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bremon-Lemet",
      "ref:FR:FINESS": "750021024",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2012786714,
    x: 48.7907393,
    y: 2.2887891,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Telle",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920014081",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2013424501,
    x: 48.8212361,
    y: 2.4195948,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Plateau",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007321",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2014865316,
    x: 48.8553351,
    y: 2.1328638,
    tags: {
      alt_name: "Pharmacie DRIAT",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Sa 09:00-12:30; Mo-Fr 15:00-20:00; Sa 15:00-19:00",
      "ref:FR:FINESS": "780005344",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2015858079,
    x: 48.7759952,
    y: 2.1297469,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre Buc",
      "ref:FR:FINESS": "780005476",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2015858271,
    x: 48.8017472,
    y: 2.061415,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Abbaye",
      "ref:FR:FINESS": "780012977",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2021727484,
    x: 48.8178715,
    y: 2.3190587,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "46",
      "contact:street": "Avenue de la République",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      opening_hours: "Sa 09:00-13:00,14:00-19:15, Mo-Fr 09:00-20:00",
      phone: "+33 1 42 53 00 46",
      "ref:FR:FINESS": "920017290",
      "ref:FR:SIREN": "851552083",
      "ref:FR:SIRET": "85155208300012",
      source: "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2027851838,
    x: 48.8489126,
    y: 2.4232463,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Clemenceau",
      opening_hours: "Mo-Fr 08:45-19:30",
      "ref:FR:FINESS": "940016090",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2028362335,
    x: 48.9627491,
    y: 2.3371267,
    tags: {
      alt_name: "Pharmacie BLANC",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Les Sablons",
      "ref:FR:FINESS": "950010314",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2028447568,
    x: 48.9751503,
    y: 2.3438886,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gentil",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "950010330",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2032104699,
    x: 48.8052967,
    y: 2.1841522,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Pointe",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920011814",
    },
  },
  {
    type: "node",
    id: 2032350181,
    x: 48.8113221,
    y: 2.1889559,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carnot",
      opening_hours: "Mo-Fr 09:30-12:30,14:30-19:30, Sa 09:00-13:00",
      "ref:FR:FINESS": "920011681",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2033085769,
    x: 48.9758863,
    y: 2.3259133,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chiche",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950006833",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2035233148,
    x: 48.9746292,
    y: 2.3463011,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Floquet",
      "ref:FR:FINESS": "950010363",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2036633243,
    x: 48.8685798,
    y: 2.3017069,
    tags: {
      alt_name: "Pharmacie Mansard",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Evans",
      "ref:FR:FINESS": "750015380",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2045901623,
    x: 48.8022262,
    y: 2.3859738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Fort",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016843",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2046139095,
    x: 48.7911747,
    y: 2.3881299,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Macval",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016744",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2049104486,
    x: 48.8525035,
    y: 2.3849901,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Paul Bert",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750019697",
      shop: "chemist",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 2015-12-18",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2051796091,
    x: 48.9486372,
    y: 2.2454936,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Centre Podologique",
    },
  },
  {
    type: "node",
    id: 2051833484,
    x: 48.9463863,
    y: 2.2333646,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hôpital",
      "ref:FR:FINESS": "950004770",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2051879509,
    x: 48.9755901,
    y: 2.3369878,
    tags: {
      alt_name: "Pharmacie KEUKY",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "950006700",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2054780813,
    x: 48.7820685,
    y: 2.3950358,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Khoy",
      "ref:FR:FINESS": "940016496",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2054833708,
    x: 48.7952624,
    y: 2.3839302,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940016892",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2061995162,
    x: 48.8661911,
    y: 2.138334,
    tags: {
      alt_name: "Pharmacie MOREL",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours:
        "Tu-Fr 09:00-12:30,14:30-20:00; Mo 10:00-12:30,14:30-20:00",
      "ref:FR:FINESS": "780005377",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2062424326,
    x: 48.8048421,
    y: 2.4148249,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Charles",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "940005572",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2066749988,
    x: 48.9011073,
    y: 2.315776,
    tags: {
      alt_name: "Pharmacie KHENISSI",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Myosotis",
      opening_hours: "Mo-Sa 09:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012374",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2072670504,
    x: 48.6864126,
    y: 2.3785436,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:housenumber": "30",
      "addr:postcode": "91260",
      "addr:street": "Rue Pasteur",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 21 78 48",
      "contact:phone": "+33 1 69 21 44 05",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Pasteur",
      "ref:FR:FINESS": "910009471",
      since: "1984-09-13",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012",
    },
  },
  {
    type: "node",
    id: 2074923955,
    x: 48.8810334,
    y: 2.3731628,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Robert",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035016",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2074923969,
    x: 48.8819,
    y: 2.3720199,
    tags: {
      alt_name: "Pharmacie Roizen",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Secrétan",
      opening_hours: "Mo-Sa 07:30-21:00; Su 08:00-21:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750035172",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2078338910,
    x: 48.8000668,
    y: 2.1863543,
    tags: {
      "addr:city": "Chaville",
      "addr:housenumber": "29",
      "addr:postcode": "92370",
      "addr:street": "Rue de Jouy",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmaciebrazeaudalexis@pharmodel.com",
      healthcare: "pharmacy",
      name: "Pharmacie Brazeau d'Alexis",
      opening_hours:
        "Mo 09:30-12:30,14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      operator: "Pharmodel",
      phone: "+33 1 47 50 42 87",
      "ref:FR:FINESS": "920011715",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmodel.com/174/pharmacie-brazeau-d-alexis/",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2082621042,
    x: 48.7431335,
    y: 2.3962454,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bredillet",
      "ref:FR:FINESS": "940012214",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2084076542,
    x: 48.8153143,
    y: 2.3609359,
    tags: {
      "addr:city": "Le Kremlin-Bicêtre",
      "addr:housenumber": "11",
      "addr:postcode": "94270",
      "addr:street": "Avenue de Fontainebleau",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Paris",
      "opening_hours:covid19": "open",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "940010895",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2086423562,
    x: 48.8780529,
    y: 2.2876728,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Place Saint-Ferdinand",
      opening_hours: "Mo-Fr 08:45-20:00; Sa 09:00-13:45, 14:30-19:45",
      "ref:FR:FINESS": "750030272",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2096571803,
    x: 48.8288832,
    y: 2.3693573,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023335",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2103216541,
    x: 48.8726054,
    y: 2.3430164,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Téboul Gazers",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00,15:00-20:00",
      "ref:FR:FINESS": "750016040",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2104401338,
    x: 48.8479464,
    y: 2.4345472,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "Mo-Sa 08:00-20:00",
      "ref:FR:FINESS": "940016165",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2105997905,
    x: 48.9748921,
    y: 2.2500883,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "2",
      "contact:street": "Boulevard Gambetta",
      dispensing: "yes",
      name: "Pharmacie Gambetta",
      opening_hours:
        "Mo-Fr 09:00-12:30, 15:00-20:00; Sa 09:00-12:30, 15:00-18:30",
      "ref:FR:FINESS": "950012112",
      "ref:FR:SIRET": "43435064100016",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2108065415,
    x: 48.5250867,
    y: 2.6949037,
    tags: {
      "addr:housenumber": "457",
      "addr:postcode": "77000",
      "addr:street": "Rue des Trois Rodes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Damien",
      "ref:FR:FINESS": "770012524",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2108133052,
    x: 48.8123552,
    y: 2.5258523,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Abbou",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940006752",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2112049866,
    x: 48.8368139,
    y: 2.7085657,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "770005098",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2115237756,
    x: 48.8386449,
    y: 2.7099195,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du RER",
      "ref:FR:FINESS": "770004190",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2115237845,
    x: 48.8372731,
    y: 2.711802,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Esplanade",
      "ref:FR:FINESS": "770003689",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2118409702,
    x: 48.8119401,
    y: 2.1922574,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Créneaux",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:00,15:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920011731",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2121575610,
    x: 48.8223571,
    y: 2.126999,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Redingote",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 08:30-19:30",
      phone: "+33 1 39 54 19 01",
      "ref:FR:FINESS": "780006631",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2124497636,
    x: 48.9402068,
    y: 2.5199349,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 2124630290,
    x: 48.8848919,
    y: 2.3073085,
    tags: {
      "addr:housenumber": "151",
      "addr:postcode": "75017",
      "addr:street": "Boulevard Malesherbes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Carnot",
      phone: "+33 1 42 27 50 40",
      "ref:FR:FINESS": "750030900",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2125350895,
    x: 48.8190905,
    y: 2.3031091,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Anne Provent",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016326",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2126331189,
    x: 48.849234,
    y: 2.2914045,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "19",
      "addr:postcode": "75015",
      "addr:street": "Rue de Lourmel",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Océane Pitoun",
      phone: "+33 1 45788618",
      "ref:FR:FINESS": "750026700",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2133067128,
    x: 48.61304,
    y: 2.4600704,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "100",
      "addr:postcode": "91100",
      "addr:street": "Boulevard Jean Jaurès",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 88 24 80",
      "contact:phone": "+33 1 60 88 15 78",
      dispensing: "yes",
      name: "Pharmacie Vallmajo",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00, 14:30-19:30",
      "ref:FR:FINESS": "910006923",
      since: "1988-10-25",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013",
    },
  },
  {
    type: "node",
    id: 2134807897,
    x: 48.894447,
    y: 2.266476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Île de la Jatte",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00",
      "ref:FR:FINESS": "920018512",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2134992127,
    x: 48.8516708,
    y: 1.8785185,
    tags: {
      "addr:city": "Beynes",
      "addr:postcode": "78650",
      "addr:street": "Centre commercial de la Petite Mauldre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bedel",
      "ref:FR:FINESS": "780005062",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2136225267,
    x: 48.8237068,
    y: 2.2101161,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Romain",
      "ref:FR:FINESS": "920020666",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2137619614,
    x: 48.7904232,
    y: 2.3925808,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Abisror",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 08:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016314",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 2137916620,
    x: 48.8231046,
    y: 2.2119033,
    tags: {
      "addr:housenumber": "5",
      "addr:postcode": "92310",
      "addr:street": "Rue Pierre Midrin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "920020773",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2141535974,
    x: 48.9605849,
    y: 2.3071681,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Filémon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930008552",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2142861092,
    x: 48.7903142,
    y: 2.4026621,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016561",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2145341436,
    x: 48.846049,
    y: 2.3883759,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "750022055",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2146845931,
    x: 48.8930762,
    y: 2.1128338,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie POU",
      "ref:FR:FINESS": "780011813",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2149201612,
    x: 48.7798693,
    y: 2.3160029,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen Phung",
      "ref:FR:FINESS": "920010980",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2153795648,
    x: 48.7676249,
    y: 2.2796231,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Georges",
      "ref:FR:FINESS": "920011327",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2161550373,
    x: 48.8806189,
    y: 2.2384661,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "920019445",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2163808747,
    x: 48.6922883,
    y: 2.3639481,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "293",
      "addr:postcode": "91600",
      "addr:street": "Boulevard Aristide Briand",
      alt_name: "SELURL Pharmacie Jouve",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 96 09 87",
      "contact:phone": "+33 1 69 96 31 66",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Jouve",
      opening_hours: "Mo-Sa 09:00-12:30, Mo-Fr 14:30-19:30, Sa 14:30-19:00",
      "ref:FR:FINESS": "910013176",
      since: "2009-12-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2166857672,
    x: 48.8120995,
    y: 2.3913637,
    tags: {
      "addr:city": "Ivry-sur-Seine",
      "addr:housenumber": "14",
      "addr:postcode": "94200",
      "addr:street": "Rue Saint-Just",
      amenity: "pharmacy",
      "contact:email": "martine.sultan@wanadoo.fr",
      "contact:phone": "+33146723382",
      dispensing: "yes",
      name: "Pharmacie Sultan Well & Well",
      note: "14 rue Saint-Just",
      "ref:FR:FINESS": "940010531",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2167563487,
    x: 48.5665848,
    y: 2.4343676,
    tags: {
      "addr:city": "Mennecy",
      "addr:country": "FR",
      "addr:housenumber": "5",
      "addr:postcode": "91540",
      "addr:street": "Rue de la Croix Boissée",
      alt_name: "SELARL PHARM DE LA MAIRIE",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 57 08 56",
      "contact:phone": "+33 1 64 57 00 36",
      dispensing: "yes",
      drive_through: "no",
      is_in: "Essonne",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Sa 8:00-13:00,14:00-20:00; Su off",
      operator: "pharmacie",
      "ref:FR:FINESS": "910010545",
      "ref:FR:NAF": "4773Z",
      since: "2007-10-01",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website: "https://pharmaciedelamairie-mennecy.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 2177177804,
    x: 48.8370211,
    y: 2.484687,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "940011794",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2177690473,
    x: 48.8206435,
    y: 2.3504445,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "63",
      "contact:street": "Boulevard Kellermann",
      dispensing: "yes",
      name: "Pharmacie Kellermann",
      opening_hours: "Mo-Fr 08:30-20:30; Su 08:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 88 23 24",
      "ref:FR:FINESS": "750023236",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2178785502,
    x: 48.882702,
    y: 2.2402479,
    tags: {
      "addr:housenumber": "112",
      "addr:postcode": "92800",
      "addr:street": "Rue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "920019262",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2179777513,
    x: 48.8483465,
    y: 2.3710764,
    tags: {
      alt_name: "Pharmacie Gabin",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Conseils Santé",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021685",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2180078835,
    x: 48.8500545,
    y: 2.392669,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      entrance: "yes",
      name: "Pharmacie Voltaire Nation",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 73 51 73",
      "ref:FR:FINESS": "750020562",
      shop: "chemist",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2181192441,
    x: 48.8380401,
    y: 2.1550658,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Étoile",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920021433",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2184624219,
    x: 48.8216224,
    y: 2.3127568,
    tags: {
      "addr:city": "Malakoff",
      "addr:housenumber": "20",
      "addr:postcode": "92240",
      "addr:street": "Avenue Pierre Brossolette",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Porte de Chatillon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016391",
      source: "survey",
      "survey:date": "2020-08-01",
    },
  },
  {
    type: "node",
    id: 2187139857,
    x: 48.8476249,
    y: 2.7717116,
    tags: {
      access: "no",
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Mo-Fr 08:30-17:00; Sa 08:30-12:00",
      phone: "+33 1 61 10 71 86",
    },
  },
  {
    type: "node",
    id: 2189661874,
    x: 48.8976513,
    y: 2.1115114,
    tags: {
      "addr:city": "Le Pecq",
      "addr:country": "FR",
      "addr:housenumber": "5",
      "addr:postcode": "78230",
      "addr:street": "Avenue de la Paix",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nougarede",
      "ref:FR:FINESS": "780011730",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2191525298,
    x: 48.8982629,
    y: 2.114743,
    tags: {
      "addr:city": "Le Vésinet",
      "addr:country": "FR",
      "addr:housenumber": "90",
      "addr:postcode": "78110",
      "addr:street": "Route de Montesson",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Rond-Point",
      "ref:FR:FINESS": "780015863",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2191607495,
    x: 48.8131168,
    y: 2.3610438,
    tags: {
      "addr:city": "Le Kremlin-Bicêtre",
      "addr:housenumber": "46",
      "addr:postcode": "94270",
      "addr:street": "Avenue de Fontainebleau",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Casaurang",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "940010945",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      website: "https://pharmaciecasaurang.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 2191795356,
    x: 48.8187866,
    y: 2.3610099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte d'Italie",
      "ref:FR:FINESS": "750022816",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2193389537,
    x: 48.8258685,
    y: 2.3448678,
    tags: {
      "addr:housenumber": "229",
      "addr:street": "Rue de Tolbiac",
      alt_name: "Pharmacie Cenréaux",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-16",
      dispensing: "yes",
      name: "Pharmacie de la Butte aux Cailles",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      phone: "+33145897456",
      "ref:FR:FINESS": "750023582",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2194776625,
    x: 48.8277141,
    y: 2.3297596,
    tags: {
      "addr:housenumber": "65",
      "addr:postcode": "75014",
      "addr:street": "Rue d'Alésia",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Chouvin",
      "ref:FR:FINESS": "750023657",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2020-03-15",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2195652745,
    x: 48.8937022,
    y: 2.1353738,
    tags: {
      "addr:city": "Le Vésinet",
      "addr:country": "FR",
      "addr:housenumber": "34",
      "addr:postcode": "78110",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Minel et Malmejan",
      "ref:FR:FINESS": "780015780",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2195980877,
    x: 48.8919005,
    y: 2.1345356,
    tags: {
      "addr:city": "Le Vésinet",
      "addr:country": "FR",
      "addr:housenumber": "18",
      "addr:postcode": "78110",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "780015830",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2195980882,
    x: 48.8915909,
    y: 2.1341497,
    tags: {
      "addr:city": "Le Vésinet",
      "addr:country": "FR",
      "addr:housenumber": "11",
      "addr:postcode": "78110",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Plasson",
      "ref:FR:FINESS": "780015814",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2196302907,
    x: 48.9035617,
    y: 2.3923213,
    tags: {
      "addr:housenumber": "74",
      "addr:postcode": "93500",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Des 4 Chemins",
      phone: "+33 1 48 45 7371",
      "ref:FR:FINESS": "930012570",
      source: "Local Survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2197663261,
    x: 48.8926646,
    y: 2.1319066,
    tags: {
      "addr:city": "Le Vésinet",
      "addr:country": "FR",
      "addr:housenumber": "1",
      "addr:postcode": "78110",
      "addr:street": "Place du Marché",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "780015764",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2204791658,
    x: 48.8683712,
    y: 2.401694,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Haim",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750035982",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2206746845,
    x: 48.8716275,
    y: 2.4045199,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Fargeau",
      opening_hours: "Mo-Fr 08:00-21:00; Sa,Su 09:00-20:00",
      "ref:FR:FINESS": "750036006",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2206863554,
    x: 48.8732754,
    y: 2.3993407,
    tags: {
      "addr:housenumber": "28",
      "addr:postcode": "75020",
      "addr:street": "Rue du Télégraphe",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaposte@perso.alliafis.net",
      name: "Pharmacie de la Poste",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 08:30-19:30",
      phone: "+33 1 46 36 73 03",
      "ref:FR:FINESS": "750036873",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2207102220,
    x: 48.8654901,
    y: 2.3978337,
    tags: {
      alt_name: "Pharmacie Sajet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Gâtines",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 08:30-20:00; Su 09:00-20:00",
      "ref:FR:FINESS": "750036600",
      source: "Celtipharm - 10/2014;survey",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2216206678,
    x: 48.8344622,
    y: 2.3673602,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "La Grande Pharmacie du Chevaleret",
      "ref:FR:FINESS": "750022584",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2216206679,
    x: 48.8322086,
    y: 2.3703008,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 2216220588,
    x: 48.8271728,
    y: 2.3685245,
    tags: {
      amenity: "pharmacy",
      "defibrillator:location": "inside",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Sa 08:30-21:00; PH,Su 08:00-21:00",
      "opening_hours:covid19": "Mo-Su 09:00-19:00",
      operator: "Paris pharma",
      phone: "+33 1 44 24 10 00",
      "ref:FR:FINESS": "750023533",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2217454642,
    x: 48.8230552,
    y: 2.3621472,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Adele",
      "ref:FR:FINESS": "750022600",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2217679704,
    x: 48.8256469,
    y: 2.3607545,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ly Ky",
      opening_hours: "Mo-Sa 09:30-13:00,14:30-20:00",
      "ref:FR:FINESS": "750023152",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2220107474,
    x: 48.8242464,
    y: 2.3195866,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jean Moulin",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:30-14:00,15:00-19:30",
      "ref:FR:FINESS": "750024754",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2234105654,
    x: 48.8679582,
    y: 2.3516149,
    tags: {
      "addr:housenumber": "247",
      "addr:postcode": "75002",
      "addr:street": "Rue Saint-Denis",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Sentier",
      "ref:FR:FINESS": "750009326",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2239279067,
    x: 48.8357921,
    y: 2.3009742,
    tags: {
      "access:covid19": "yes",
      "addr:postcode": "75015",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Convention",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:30",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30; Sa 09:00-20:30",
      operator: "Haddad Jaoudi Chantal",
      phone: "+33 1 48 28 96 98",
      "ref:FR:FINESS": "750025702",
      source: "Celtipharm - 10/2014",
      website: "http://www.parispharma.com/convention/",
    },
  },
  {
    type: "node",
    id: 2252705874,
    x: 48.8258334,
    y: 2.3128357,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Paris",
      "contact:housenumber": "64",
      "contact:postcode": "75014",
      "contact:street": "Boulevard Brune",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte Didot",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750023921",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2252802568,
    x: 48.8265022,
    y: 2.3098169,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "38",
      "contact:postcode": "75014",
      "contact:street": "Boulevard Brune",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "750023905",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2252840383,
    x: 48.8274102,
    y: 2.3057506,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "8",
      "contact:postcode": "75014",
      "contact:street": "Boulevard Brune",
      dispensing: "yes",
      name: "Pharmacie de la Porte de Vanves",
      "ref:FR:FINESS": "750023871",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2256109229,
    x: 48.8337566,
    y: 2.2863285,
    tags: {
      "addr:housenumber": "47",
      "addr:postcode": "75015",
      "addr:street": "Boulevard Victor",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Svoboda",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "750027336",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2256156633,
    x: 48.8310482,
    y: 2.29268,
    tags: {
      "addr:housenumber": "59",
      "addr:postcode": "75015",
      "addr:street": "Boulevard Lefebvre",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gaumerais",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      "ref:FR:FINESS": "750026635",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2256214719,
    x: 48.8913687,
    y: 2.3615676,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "42",
      "contact:street": "Rue de Torcy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034167",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2260028031,
    x: 48.896772,
    y: 2.1446005,
    tags: {
      "addr:housenumber": "117",
      "addr:postcode": "78400",
      "addr:street": "Rue des Landes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Landes",
      "ref:FR:FINESS": "780006227",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2265964338,
    x: 48.843338,
    y: 2.2593388,
    tags: {
      "addr:housenumber": "77",
      "addr:street": "Boulevard Exelmans",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Exelmans",
      opening_hours: "Mo-Fr 08:00-21:30; Sa 08:30-21:00",
      "ref:FR:FINESS": "750027773",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacieexelmans.com/",
    },
  },
  {
    type: "node",
    id: 2265978701,
    x: 48.8408126,
    y: 2.2622287,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Michel-Ange",
      "ref:FR:FINESS": "750027633",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2265998707,
    x: 48.8396253,
    y: 2.2622106,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Côme",
      opening_hours: "Tu-Fr 08:30-20:00; Mo 14:00-20:00; Sa 09:00-19:00",
      phone: "+33 1 42 88 48 18",
      "ref:FR:FINESS": "750028383",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2267565746,
    x: 48.846049,
    y: 2.278046,
    tags: {
      "addr:city": "Paris",
      "addr:country": "FR",
      "addr:housenumber": "4",
      "addr:postcode": "75015",
      "addr:street": "Avenue Émile Zola",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rive Gauche",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025850",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmacie-rivegauche.com",
    },
  },
  {
    type: "node",
    id: 2267594423,
    x: 48.845643,
    y: 2.2772011,
    tags: {
      "addr:city": "Paris",
      "addr:country": "FR",
      "addr:housenumber": "7",
      "addr:postcode": "75015",
      "addr:street": "Rond-Point du Pont Mirabeau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mirabeau",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750026916",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmacie-mirabeau-massard.fr",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2267754489,
    x: 48.8421144,
    y: 2.2778529,
    tags: {
      "addr:city": "Paris",
      "addr:country": "FR",
      "addr:housenumber": "47",
      "addr:postcode": "75015",
      "addr:street": "Rue Balard",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Cévennes",
      "ref:FR:FINESS": "750025256",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2267954870,
    x: 48.8835676,
    y: 2.1313807,
    tags: {
      "addr:housenumber": "64bis",
      "addr:postcode": "78110",
      "addr:street": "Avenue de la Princesse",
      alt_name: "Pharmacie BRIEY",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Princesse",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015913",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2268459881,
    x: 48.8898494,
    y: 2.3629189,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pajol",
      operator: "Fabien Legoupil",
      "ref:FR:FINESS": "750033870",
      source: "GPS",
    },
  },
  {
    type: "node",
    id: 2270305928,
    x: 48.9588137,
    y: 2.5426648,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc de la Noue",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930016787",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2271177396,
    x: 48.7562864,
    y: 2.3994395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Wu Yao Kwang",
      "ref:FR:FINESS": "940014426",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2274508472,
    x: 48.559638,
    y: 2.5962064,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
    },
  },
  {
    type: "node",
    id: 2275949485,
    x: 48.8842341,
    y: 2.0717421,
    tags: {
      "addr:city": "Mareil-Marly",
      "addr:housenumber": "17",
      "addr:postcode": "78750",
      "addr:street": "Rue du 4 Septembre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Mareil-Marly",
      "ref:FR:FINESS": "780009940",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 2279649022,
    x: 48.6264197,
    y: 2.4306854,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "21",
      "addr:postcode": "91000",
      "addr:street": "Cours Blaise Pascal",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 97 19 18",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910008317",
      since: "1987-02-13",
    },
  },
  {
    type: "node",
    id: 2285154156,
    x: 48.8907659,
    y: 2.3596503,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale de la Chapelle",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032203",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2286779160,
    x: 48.8279309,
    y: 2.3495913,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tran Huu Nghia",
      "ref:FR:FINESS": "750022667",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2288842610,
    x: 48.7643261,
    y: 1.9340416,
    tags: {
      alt_name: "Pharmacie de Bretagne",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Bessières",
      "ref:FR:FINESS": "780010310",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2289753897,
    x: 48.7638027,
    y: 2.6724156,
    tags: {
      "addr:city": "Ozoir-la-Ferrière",
      "addr:housenumber": "9",
      "addr:postcode": "77330",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 02 80 98",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie de La Nouvelle Source",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-20:00; Sa 09:00-13:00, 14:30-19:30; Su off",
      "ref:FR:FINESS": "770010130",
      shop: "chemist",
    },
  },
  {
    type: "node",
    id: 2291929433,
    x: 48.7608069,
    y: 2.6787083,
    tags: {
      "addr:city": "Ozoir-la-Ferrière",
      "addr:country": "FR",
      "addr:housenumber": "3",
      "addr:postcode": "77330",
      "addr:street": "Rue Danton",
      amenity: "pharmacy",
      "contact:email": "pharmacie@chambrin.com",
      "contact:phone": "+33 1 60 02 62 14",
      dispensing: "yes",
      name: "Pharmacie Chambrin",
      opening_hours: "Mo 14:00-19:45; Tu-Sa 09:00-12:30,14:00-19:45; Su off",
      "ref:FR:FINESS": "770010114",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
      website: "www.chambrin.com",
    },
  },
  {
    type: "node",
    id: 2294302494,
    x: 48.8435254,
    y: 2.5776671,
    tags: {
      "addr:city": "Noisy-le-Grand",
      "addr:housenumber": "26",
      "addr:postcode": "93160",
      "addr:street": "Allée du Bataillon Hildevert",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Champy",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-13:00, Sa 15:00-19:00",
      phone: "+33 1 43 05 51 16",
      "ref:FR:FINESS": "930011770",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2295269831,
    x: 48.8147778,
    y: 2.3479413,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Courtois Senneville",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009616",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2295517553,
    x: 48.8759746,
    y: 2.2315776,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "My Pharma",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920019361",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2295932456,
    x: 48.6981507,
    y: 2.3722019,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:housenumber": "68",
      "addr:postcode": "91260",
      "addr:street": "Avenue de la Cour de France",
      alt_name: "Pharmacie Houssack Bonnaud",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 45 19 55",
      "contact:phone": "+33 1 69 21 43 40",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie de la Pyramide",
      "ref:FR:FINESS": "910009356",
      since: "2006-04-18",
    },
  },
  {
    type: "node",
    id: 2296270737,
    x: 48.6905779,
    y: 2.3815547,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:housenumber": "39",
      "addr:postcode": "91260",
      "addr:street": "Rue de Gaulois",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 21 04 59",
      "contact:phone": "+33 1 69 21 43 46",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "910009372",
      since: "1985-08-30",
    },
  },
  {
    type: "node",
    id: 2296272341,
    x: 48.6880276,
    y: 2.3773332,
    tags: {
      "addr:city": "Juvisy-sur-Orge",
      "addr:housenumber": "16",
      "addr:postcode": "91260",
      "addr:street": "Grande Rue",
      alt_name: "Pharmacie Laveix",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 21 10 11",
      "contact:phone": "+33 1 69 21 41 53",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910009406",
      since: "1988-10-25",
    },
  },
  {
    type: "node",
    id: 2296868977,
    x: 48.7883176,
    y: 2.2706633,
    tags: {
      "addr:city": "Le Plessis-Robinson",
      "addr:housenumber": "84",
      "addr:postcode": "92350",
      "addr:street": "Rue Bernard Iské",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boust",
      "ref:FR:FINESS": "920018876",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2299202154,
    x: 48.8549264,
    y: 2.7757593,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Forum Santé Val d'Europe",
      opening_hours: "Mo-Sa 09:00-21:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "770011815",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2300055419,
    x: 48.9285166,
    y: 2.2930133,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boubia",
      "ref:FR:FINESS": "920014743",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2300079963,
    x: 48.9243325,
    y: 2.2947663,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920014842",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2301882952,
    x: 48.8263842,
    y: 2.3568786,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-07-23",
      dispensing: "yes",
      name: "Pharmacie",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750023053",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2301930722,
    x: 48.7580536,
    y: 2.6994709,
    tags: {
      "addr:city": "Ozoir-la-Ferrière",
      "addr:housenumber": "84",
      "addr:postcode": "77330",
      "addr:street": "Rue François de Tessan",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 40 02 27",
      dispensing: "yes",
      name: "Pharmacie Belle-Croix",
      opening_hours: "Mo-Sa 09:00-12:30, 14:30-19:30; Su off",
      "ref:FR:FINESS": "770010080",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2302438555,
    x: 48.8729928,
    y: 2.3891191,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "71",
      "addr:postcode": "75020",
      "addr:street": "Rue de la Mare",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036121",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2305278730,
    x: 48.9232984,
    y: 2.2083586,
    tags: {
      "addr:postcode": "95870",
      "addr:street": "Place du Grand Cerf",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Quantin",
      "ref:FR:FINESS": "950005801",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2306504650,
    x: 48.8899144,
    y: 2.3604238,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Marx Dormoy",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 08:30-20:00",
      "ref:FR:FINESS": "750033623",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2311546297,
    x: 48.7326807,
    y: 2.2210024,
    tags: {
      "addr:city": "Igny",
      "addr:housenumber": "10",
      "addr:postcode": "91430",
      "addr:street": "Place de Stalingrad",
      alt_name: "SARL Pharmacie de la Ferme",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 41 85 35",
      "contact:phone": "+33 1 69 41 09 92",
      dispensing: "yes",
      name: "Pharmacie de la Ferme",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009273",
      since: "2002-02-18",
    },
  },
  {
    type: "node",
    id: 2316963570,
    x: 48.7841892,
    y: 2.4005477,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Colivet",
      "ref:FR:FINESS": "940016413",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2317269071,
    x: 48.7849119,
    y: 2.3921483,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016975",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2318837696,
    x: 48.8044316,
    y: 2.1572956,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "21",
      "addr:postcode": "78000",
      "addr:street": "Esplanade Grand Siècle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Grand Siècle",
      "ref:FR:FINESS": "780014965",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2322905386,
    x: 48.814625,
    y: 2.32043,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "104",
      "contact:street": "Avenue Henri Ginoux",
      dispensing: "yes",
      name: "Pharmacie C. Prévost",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 57 89 29",
      "ref:FR:FINESS": "920017126",
      "ref:FR:SIREN": "421484940",
      "ref:FR:SIRET": "42148494000018",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2322922644,
    x: 48.7903927,
    y: 2.6564619,
    tags: {
      "addr:city": "Roissy-en-Brie",
      "addr:housenumber": "2",
      "addr:postcode": "77680",
      "addr:street": "Première Avenue",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 28 22 74",
      "ref:FR:FINESS": "770011047",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2322922683,
    x: 48.7949266,
    y: 2.6442352,
    tags: {
      "addr:city": "Roissy-en-Brie",
      "addr:housenumber": "21",
      "addr:postcode": "77680",
      "addr:street": "Boulevard de la Malibran",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie La Malibran",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 29 95 08",
      "ref:FR:FINESS": "770010965",
      "ref:FR:SIRET": "51269062900013",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2328361470,
    x: 48.7884475,
    y: 2.3661455,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie N Guyen",
      opening_hours: "Mo-Sa 09:00-13:00, Mo-Fr 14:00-19:30; Sa 14:30-18:30",
      "opening_hours:covid19": "Mo-Sa 09:00-18:30",
      "ref:FR:FINESS": "940015076",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2335048673,
    x: 48.8740176,
    y: 2.3311821,
    tags: {
      alt_name: "Pharmacie de l'Opéra Mogador",
      amenity: "pharmacy",
      "description:covid19":
        "s'adresser à la pharmacie du 54 rue de la Chaussée d'Antin",
      dispensing: "yes",
      name: "Pharmacie des Galeries",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:30-20:00",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "750016511",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2337922406,
    x: 48.8837087,
    y: 2.2459285,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Brami",
      "ref:FR:FINESS": "920019312",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2343418564,
    x: 48.786649,
    y: 2.3183885,
    tags: {
      "addr:city": "Bourg-la-Reine",
      "addr:housenumber": "15",
      "addr:postcode": "92340",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Faïencerie",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920011012",
      website: "http://4sq.com/2q7r8me",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2345350869,
    x: 48.9970421,
    y: 2.0623287,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Montigny Debote",
      opening_hours: "Mo-Sa 09:00-12:30, Mo-Fr 14:30-20:00; Sa 14:30-19:30",
      "ref:FR:FINESS": "780010260",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2347265560,
    x: 48.8393203,
    y: 2.3908004,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "11",
      "addr:postcode": "75012",
      "addr:street": "Boulevard de Reuilly",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Seroussi",
      opening_hours: "Sa 09:00-20:00; Mo-Fr 08:30-20:00",
      "ref:FR:FINESS": "750021982",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2348297723,
    x: 48.821649,
    y: 2.4199888,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Deux Communes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013931",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2348493356,
    x: 48.804135,
    y: 2.3643756,
    tags: {
      "addr:housenumber": "71",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Soleil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940014962",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013",
    },
  },
  {
    type: "node",
    id: 2349347148,
    x: 48.8873089,
    y: 2.35955,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dupré",
      "ref:FR:FINESS": "750033086",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2350539157,
    x: 48.8846074,
    y: 2.3647555,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Steinberg",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharma de Paname",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032187",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 2352630125,
    x: 48.8085088,
    y: 2.1253674,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "5",
      "addr:postcode": "78000",
      "addr:street": "Rue de la Paroisse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ambrosi",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015343",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2353459861,
    x: 48.7557684,
    y: 2.0540167,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Jasserand-Saville",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780016291",
    },
  },
  {
    type: "node",
    id: 2363380994,
    x: 48.7552628,
    y: 2.4127675,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'École",
      "ref:FR:FINESS": "940007883",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2365610537,
    x: 48.8133473,
    y: 2.3162607,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "128",
      "contact:street": "Avenue de la République",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 42 53 01 08",
      "ref:FR:FINESS": "920017340",
      "ref:FR:SIREN": "823634332",
      "ref:FR:SIRET": "82363433200014",
      source: "local Knowledge",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2365887858,
    x: 48.8159772,
    y: 2.3514586,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "940009582",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2370254478,
    x: 48.8069133,
    y: 2.3755555,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940010515",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      "source:amenity": "survey",
    },
  },
  {
    type: "node",
    id: 2370317912,
    x: 48.7263932,
    y: 2.2567665,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "36",
      "addr:postcode": "91300",
      "addr:street": "Avenue Raymond Aron",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 41 14 03",
      "contact:phone": "+33 1 69 20 39 43",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Le-Trân",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19":
        "Mo-Fr 08:00-12:30,14:30-19:30;Sa 08:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910010396",
      since: "1988-03-25",
      "source:loc": "survey",
    },
  },
  {
    type: "node",
    id: 2373033378,
    x: 48.8801837,
    y: 2.4205403,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: 'Pharmacie "Les Lilas"',
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930009287",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2373428078,
    x: 48.8388765,
    y: 2.6293602,
    tags: {
      "addr:city": "Lognes",
      "addr:housenumber": "1",
      "addr:postcode": "77185",
      "addr:street": "Rond-Point Andreï Sakharov",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Paix",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 06 21 66",
      "ref:FR:FINESS": "770017036",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2373512614,
    x: 48.8795946,
    y: 2.4163042,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Métro",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930009121",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2374255002,
    x: 48.8785849,
    y: 2.4122788,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Familles",
      opening_hours: "Sa 09:00-20:00; Mo-Fr 08:00-20:00",
      "ref:FR:FINESS": "930009170",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2380614923,
    x: 48.7245881,
    y: 2.0842105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre-Ville",
      "ref:FR:FINESS": "780009114",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2380660326,
    x: 48.7912374,
    y: 2.4346804,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
    },
  },
  {
    type: "node",
    id: 2380680965,
    x: 48.6189972,
    y: 2.4340455,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "2",
      "addr:postcode": "91000",
      "addr:street": "Place des Aunettes",
      alt_name: "SELARL Pharmacie des Aunettes",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 78 09 10",
      "contact:phone": "+33 1 60 78 08 07",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie des Aunettes",
      "ref:FR:FINESS": "910008572",
      "ref:FR:NAF": "4773Z",
      since: "2006-12-04",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2381401398,
    x: 49.0702666,
    y: 2.171415,
    tags: {
      "addr:housenumber": "33",
      "addr:street": "Rue du Pois",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vernier",
      "ref:FR:FINESS": "950005355",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2381520432,
    x: 48.8111035,
    y: 2.379877,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 55",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "940010226",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2381794153,
    x: 49.0179134,
    y: 2.2454053,
    tags: {
      "addr:housenumber": "26",
      "addr:postcode": "95320",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "Tu-Sa 09:00-12:30, Mo-Fr 14:30-19:45, Sa 14:30-19:00",
      phone: "+33 1 39 60 00 28",
      "ref:FR:FINESS": "950011726",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2383448694,
    x: 49.0262596,
    y: 2.2247938,
    tags: {
      "addr:city": "Taverny",
      "addr:postcode": "95150",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "950013094",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2386243868,
    x: 48.8754662,
    y: 2.3900096,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lafayette du Village",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034456",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2386245830,
    x: 48.8142377,
    y: 2.3767508,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Clément",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "940010192",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2386905211,
    x: 49.058811,
    y: 2.1891406,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "950010025",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2387186007,
    x: 48.8747858,
    y: 2.3875414,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours: "Mo-Sa 08:30-20:00; PH off",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750034407",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2387824662,
    x: 48.7831884,
    y: 2.2434355,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Clamart",
      "contact:housenumber": "1",
      "contact:phone": "+33 1 46 30 03 19",
      "contact:postcode": "92140",
      "contact:street": "Place Cesaire",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie De La Plaine",
      opening_hours:
        "Mo 09:00-12:30, 14:30-19:00; Tu-Sa 09:00-12:30, 14:30-19:30",
      "opening_hours:covid19":
        "Mo 09:00-12:30, 14:30-19:00; Tu-Sa 09:00-12:30, 14:30-19:30",
      "ref:FR:FINESS": "920012192",
    },
  },
  {
    type: "node",
    id: 2387891453,
    x: 49.0867301,
    y: 2.1968109,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hélias",
      "ref:FR:FINESS": "950006023",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2388411309,
    x: 48.8535577,
    y: 2.3656033,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Battino-Borgel",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 42 72 04 75",
      "ref:FR:FINESS": "750010365",
      source: "survey 2013;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2388934090,
    x: 49.0759392,
    y: 2.202677,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Villemont",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950009936",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2389310275,
    x: 48.8751346,
    y: 2.3889962,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 137",
      opening_hours: "Mo-Sa 08:30-13:30,15:00-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750034423",
      source: "survey 2013",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
      website: "https://www.pharmacienomberlottin.fr/",
    },
  },
  {
    type: "node",
    id: 2390075255,
    x: 49.0715883,
    y: 2.196264,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950009902",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2390653161,
    x: 48.8738767,
    y: 2.3893362,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pyrénées-Jourdain",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "Mo-Fr 09:30-13:00,14:00-19:00",
      "ref:FR:FINESS": "750036667",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2393777502,
    x: 48.8735729,
    y: 2.3845184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "PharmAvance",
      opening_hours: "Mo-Sa 08:30-20:30; PH off",
      "opening_hours:covid19": "same",
      phone: "+33 1 46 36 69 04",
      "ref:FR:FINESS": "750035750",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-07-22",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2394027666,
    x: 48.889397,
    y: 2.3596573,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tran",
      "ref:FR:FINESS": "750033102",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2395186203,
    x: 48.8867304,
    y: 2.3598075,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Zalay",
      opening_hours: "Mo 15:00-19:30; Tu-Fr 10:00-20:30; Sa 10:00-20:00",
      phone: "+331 46 07 25 70",
      "ref:FR:FINESS": "750033052",
      source: "Celtipharm - 10/2014;streetlevel imagery",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2397270217,
    x: 48.8743127,
    y: 2.3863159,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lafayette La Bellevilloise",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00; PH off",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "payment:american_express": "yes",
      "ref:FR:FINESS": "750035784",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2397270220,
    x: 48.8738082,
    y: 2.385616,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du métro Pyrénées",
      "ref:FR:FINESS": "750036717",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2401135791,
    x: 48.8786416,
    y: 2.7049132,
    tags: {
      alt_name: "Pharmacie BEAL COUHARDE",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Chemin de Fer",
      "ref:FR:FINESS": "770007326",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2401331915,
    x: 48.8473546,
    y: 2.1961927,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Truong Tan",
      "ref:FR:FINESS": "920014461",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2401391745,
    x: 48.8694068,
    y: 2.0725072,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Verlinden",
      "opening_hours:covid19": "open",
      phone: "+33 1 39 58 97 38",
      "ref:FR:FINESS": "780007746",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2402411816,
    x: 48.8730284,
    y: 2.3811633,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sok",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035735",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-07-31",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2402604324,
    x: 49.0499426,
    y: 2.0094516,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Nautilus",
      opening_hours: "Mo-Fr 14:30-19:30, Sa 14:30-19:00, Mo-Sa 09:00-12:30",
      "opening_hours:covid19": "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-19:00",
      "ref:FR:FINESS": "950004218",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2402604973,
    x: 49.0456325,
    y: 2.0038162,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Louvière",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950006650",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2402701481,
    x: 48.8862246,
    y: 2.1712746,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Pharmacie de la Gare RER",
      "opening_hours:covid19": "Mo-Fr 08:00-19:00; Sa 09:00-18:00",
      "ref:FR:FINESS": "920019544",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2402805076,
    x: 48.8893866,
    y: 2.2476309,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "33",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Manhattan",
      opening_hours: "Mo-Fr 08:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33147670707",
      "ref:FR:FINESS": "920013810",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2405146643,
    x: 48.8374905,
    y: 2.2394614,
    tags: {
      alt_name: "Pharmacie OZIEL",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Passages de l'Hôtel de Ville",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "920010436",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2406086258,
    x: 48.8070873,
    y: 2.4193521,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie LAY",
      opening_hours: "Mo-Sa 09:30-13:30,14:30-20:00",
      "ref:FR:FINESS": "940005333",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2406134741,
    x: 48.8837145,
    y: 2.2927125,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Haziza",
      "ref:FR:FINESS": "750030652",
      source: "survey:2013-08-03;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2411949231,
    x: 48.8765184,
    y: 2.4035726,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Moret",
      "ref:FR:FINESS": "750034506",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-08-09",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2413633736,
    x: 48.8837964,
    y: 2.3210243,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande pharmacie des Dames",
      opening_hours: "Mo-Sa 08:00-20:00",
      "ref:FR:FINESS": "750030173",
      source: "survey:2015-04-03/Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2414442532,
    x: 48.8468832,
    y: 2.2143662,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "SELAS Pharmacie des Hauts de Saint-Cloud",
      opening_hours: "Mo-Fr 08:30-20:30;Sa 09:00-20:00;PH closed",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920020336",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2414814092,
    x: 48.8765453,
    y: 2.3938412,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place des Fêtes",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:30,14:30-20:00",
      "opening_hours:covid19": "open",
      phone: "+33142398767",
      "ref:FR:FINESS": "750034373",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "survey:date": "2013-08-09",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2417853128,
    x: 48.8441609,
    y: 2.4177686,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013030",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2419684592,
    x: 48.8604285,
    y: 2.3434854,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Samaritain",
      "ref:FR:FINESS": "750008781",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2422095189,
    x: 48.8159667,
    y: 2.5386201,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Sciences",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007016",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2425124528,
    x: 48.8608281,
    y: 2.3675773,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "2",
      "addr:postcode": "75011",
      "addr:street": "Boulevard des Filles du Calvaire",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Les Filles du Calvaire",
      opening_hours:
        "Mo 12:00-20:00; Tu-Fr 09:00-20:00; Sa 09:00-13:00,14:30-19:00",
      phone: "+33147008144",
      "ref:FR:FINESS": "750018996",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2425342114,
    x: 48.8642004,
    y: 2.36579,
    tags: {
      alt_name: "Pharmacie CAUSSIGNAC",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Temple",
      opening_hours: "Mo-Fr 12:00-20:00",
      "ref:FR:FINESS": "750009813",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2425350425,
    x: 48.8594971,
    y: 2.367318,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Beaumarchais",
      "name:it": "Beaumarchais",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750009490",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2425858309,
    x: 48.8569139,
    y: 2.3685048,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Belda",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750018525",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2428529938,
    x: 48.8455873,
    y: 2.436434,
    tags: {
      alt_name: "Pharmacie Chelli",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Château",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015845",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2433860898,
    x: 48.8543674,
    y: 2.3701218,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Opéra-Bastille",
      opening_hours: "24/7",
      phone: "+33 1 47 00 49 44",
      "ref:FR:FINESS": "750020653",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2435592308,
    x: 48.8163873,
    y: 2.3950099,
    tags: {
      "addr:city": "Ivry-sur-Seine",
      "addr:housenumber": "37",
      "addr:postcode": "94200",
      "addr:street": "Rue Lénine",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Sa 09:00-13:00; Mo-Fr 14:00-20:00; Sa 14:00-19:00",
      phone: "+33 1 46 72 01 99",
      "ref:FR:FINESS": "940010275",
      source: "survey 2013-08-29;Celtipharm - 10/2014",
      website: "https://pharmaciedelagare.pharminfo.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2441134664,
    x: 48.7429646,
    y: 1.9632022,
    tags: {
      "addr:housename": "Pharmacie de L'Église",
      "addr:housenumber": "10",
      "addr:postcode": "78320",
      "addr:street": "rue Raymond Berrurier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de L'Église",
      "ref:FR:FINESS": "780010575",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2448380869,
    x: 48.8496954,
    y: 2.434442,
    tags: {
      alt_name: "Viadys",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Charrier",
      opening_hours:
        "Mo-Fr 09:00-13:00, 14:30-20:00; Sa 09:00-13:00, 14:30-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 28 07 77",
      "ref:FR:FINESS": "940016181",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2449485237,
    x: 48.8464239,
    y: 2.2857557,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie-Pharmarket Beaugrenelle",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750025934",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2450281042,
    x: 48.8784459,
    y: 2.1433521,
    tags: {
      "addr:city": "Croissy-sur-Seine",
      "addr:housenumber": "34",
      "addr:postcode": "78290",
      "addr:street": "Boulevard Fernand Hostachy",
      alt_name: "Pharmacie PERROTIN",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Rond Point",
      opening_hours: "Mo-Sa 09:00-13:00, 14:30-20:00",
      phone: "+33 1 39 76 25 32",
      "ref:FR:FINESS": "780007290",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2450471470,
    x: 48.8779007,
    y: 2.1445601,
    tags: {
      "addr:city": "Croissy-sur-Seine",
      "addr:housenumber": "19",
      "addr:postcode": "78290",
      "addr:street": "Boulevard Fernand Hostachy",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mornas",
      opening_hours: "Mo-Sa 9:00-13:00, 14:30-20:00",
      phone: "+33 1 39 76 21 69",
      "ref:FR:FINESS": "780007274",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2468540399,
    x: 48.8891655,
    y: 2.3229773,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Clichy-Legendre",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750029886",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 2471187829,
    x: 48.8782291,
    y: 2.3451211,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pharmavance",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      operator: "S Vieillard",
      "ref:FR:FINESS": "750016693",
      source: "survey 2013;Celtipharm - 10/2014",
      "survey:date": "2018-05-05",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2473600749,
    x: 48.8856031,
    y: 2.3259033,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Dames",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030132",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2021-07-31",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2473697756,
    x: 48.8900229,
    y: 2.3208798,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Minel",
      "ref:FR:FINESS": "750029902",
      source: "survey:2013-09-27;Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 2474882137,
    x: 48.8569223,
    y: 2.3642921,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "37",
      "addr:postcode": "75003",
      "addr:street": "Rue de Turenne",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Meyer Pointsot",
      opening_hours:
        "Mo 11:00-13:00,14:00-20:00; Tu-Sa 09:15-13:00,14:30-20:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 48 87 97 60",
      "ref:FR:FINESS": "750009912",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2474888080,
    x: 48.8797698,
    y: 2.3290246,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Casino de Paris",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:30-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      phone: "+33 1 48 74 65 16",
      "ref:FR:FINESS": "750015893",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2477686433,
    x: 48.8565579,
    y: 2.3711226,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Richard Lenoir",
      "ref:FR:FINESS": "750020596",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2478005427,
    x: 48.7828828,
    y: 2.2766053,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Legrand",
      phone: "+33143500465",
      "ref:FR:FINESS": "920018926",
      "ref:FR:SIRET": "42997672300016",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2478201221,
    x: 48.8649842,
    y: 2.3451707,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75002",
      "addr:street": "Rue Montmartre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "750009060",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2478974033,
    x: 48.856592,
    y: 2.3951782,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Alexandre Dumas",
      opening_hours: "Mo-Su 08:30-20:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750035552",
      source: "survey:2013-10-01;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2478974034,
    x: 48.8589893,
    y: 2.4016192,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Garcia",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00, Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "750035602",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2479459643,
    x: 48.7997234,
    y: 2.1246875,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "33",
      "addr:postcode": "78000",
      "addr:street": "Rue de Satory",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-01-11",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Martin",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "780015681",
      source: "survey 2013;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2479783705,
    x: 48.8077001,
    y: 2.1283948,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "33",
      "addr:postcode": "78000",
      "addr:street": "Rue de la Paroisse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Paroisse",
      "ref:FR:FINESS": "780015426",
      source: "survey 2013;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2485092707,
    x: 48.8853291,
    y: 2.2959914,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Satellite",
      opening_hours: "Mo-Fr 08:45-20:15",
      "ref:FR:FINESS": "750031767",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2013-10-04",
    },
  },
  {
    type: "node",
    id: 2485105680,
    x: 48.8145966,
    y: 2.303159,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Joliot Curie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016441",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2486263643,
    x: 48.8803605,
    y: 2.3986503,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "750034282",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-10-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2497946431,
    x: 48.8758681,
    y: 2.3298856,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Lazare",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 12:00-19:00",
      "ref:FR:FINESS": "750016784",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2498267590,
    x: 48.830412,
    y: 2.3341178,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "15",
      "addr:postcode": "75014",
      "addr:street": "Avenue René Coty",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Coty-Issoire",
      opening_hours: "Mo-Fr 8:30-20:00; Sa 9:00-19:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "750025033",
      shop: "chemist",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014;Celtipharm - 10/2014",
      website: "http://www.pharmacie-coty.com/pharmacie-paris.php",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2499489152,
    x: 48.6978733,
    y: 2.4147399,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "23",
      "addr:postcode": "91270",
      "addr:street": "Avenue Henri Barbusse",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 15 52",
      "contact:phone": "+33 1 69 03 15 52",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Marin",
      "ref:FR:FINESS": "910013697",
      since: "1995-01-09",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013",
    },
  },
  {
    type: "node",
    id: 2500448682,
    x: 48.8373325,
    y: 2.3031026,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Farjon",
      "ref:FR:FINESS": "750026320",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2500448707,
    x: 48.8386302,
    y: 2.3085873,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Catala Chapelier",
      "ref:FR:FINESS": "750025835",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2500908061,
    x: 48.8344319,
    y: 2.2954625,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cohena",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750027450",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2501584895,
    x: 48.8284972,
    y: 2.3009044,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Aprium Pharmacie Porte de Brancion",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 48 28 32 55",
      "ref:FR:FINESS": "750026650",
      source: "survey 2019",
    },
  },
  {
    type: "node",
    id: 2507346116,
    x: 48.7023591,
    y: 2.3872878,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:district": "Essonne",
      "addr:housenumber": "111",
      "addr:postcode": "91200",
      "addr:street": "Avenue du 18 Avril 1944",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 48 40 94",
      "contact:phone": "+33 1 69 38 82 28",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bensegnor",
      "ref:FR:FINESS": "910005255",
      start_date: "1962-07-02",
    },
  },
  {
    type: "node",
    id: 2507395193,
    x: 48.7085112,
    y: 2.3879609,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "16",
      "addr:postcode": "91200",
      "addr:street": "Rue Valentin Conrart",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 46 17",
      "contact:phone": "+33 1 69 38 44 26",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie de la Mairie",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910005537",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "75050289000015",
      since: "1990-09-20",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadas. Mise à jour : 2013",
    },
  },
  {
    type: "node",
    id: 2507717146,
    x: 48.7028539,
    y: 2.428494,
    tags: {
      "addr:housenumber": "153",
      "addr:postcode": "91270",
      "addr:street": "Avenue Henri Barbusse",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
    },
  },
  {
    type: "node",
    id: 2509481307,
    x: 48.868867,
    y: 2.3900956,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Attuil - Giffard",
      "ref:FR:FINESS": "750036303",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-10-26",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2513746662,
    x: 48.8850316,
    y: 2.3109009,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Tocqueville",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750031635",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2514493575,
    x: 48.876056,
    y: 2.3436865,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benmoha Pariente",
      "ref:FR:FINESS": "750015745",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2524701154,
    x: 48.8176936,
    y: 2.3084636,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "9",
      "contact:street": "Avenue de la Marne",
      dispensing: "yes",
      fax: "+33 1 47 35 57 19",
      name: "Pharmacie Goncourt",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-18:00",
      phone: "+33 1 46 55 82 89",
      "ref:FR:FINESS": "920017241",
      "ref:FR:SIRET": "75224428500017",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2526317228,
    x: 48.8953218,
    y: 2.3625779,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bana",
      "ref:FR:FINESS": "750034183",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2527783444,
    x: 48.8444566,
    y: 2.3093042,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75015",
      alt_name: "Pharmacie aucoin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lecourbe",
      opening_hours:
        "Mo 10:00-13:30, 15:00-19:30; Tu-Th 09:00-13:30, 15:00-19:30; Fr 09:30-13:30, 15:00-19:30; Sa 10:00-13:30, 15:00-19:00",
      "ref:FR:FINESS": "750025983",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2531257404,
    x: 48.8185287,
    y: 2.3225912,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "52",
      "contact:street": "Avenue Henri Ginoux",
      dispensing: "yes",
      name: "Pharmacie de Paris",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-13:30,14:30-20:00",
      phone: "+33 1 42 53 01 26",
      "ref:FR:FINESS": "920017043",
      "ref:FR:SIREN": "487609869",
      "ref:FR:SIRET": "48760986900010",
      source: "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2531553523,
    x: 48.8260647,
    y: 2.3576772,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "City Pharma",
      "name:zh": "城市药妆店",
      opening_hours: "08:00-21:00",
      "ref:FR:FINESS": "750023020",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre ; mise à jour : 2009;Celtipharm - 10/2014",
      "source:opening_hours": "survey",
    },
  },
  {
    type: "node",
    id: 2531825478,
    x: 48.7409972,
    y: 2.4035497,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "940012263",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2532558779,
    x: 48.8122399,
    y: 2.3579504,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "940019706",
      "source:amenity": "survey",
      "source:name": "Celtipharm - 10/2014",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 2533389938,
    x: 48.4296462,
    y: 2.1478374,
    tags: {
      "addr:housenumber": "1",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Martin",
      "ref:FR:FINESS": "910008184",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013",
    },
  },
  {
    type: "node",
    id: 2533777265,
    x: 48.7074491,
    y: 2.0410636,
    tags: {
      "addr:city": "Chevreuse",
      "addr:housenumber": "47",
      "addr:postcode": "78460",
      "addr:street": "Rue de la Division Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Château",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780006664",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2540381966,
    x: 48.7466509,
    y: 2.267728,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 20 20 70",
      "delivery:covid19": "yes",
      "description:covid19":
        "Livraisons à domicile et PharmaDrive : commande par téléphone ou mail",
      dispensing: "yes",
      email: "pharmaciecentrale91370@gmail.com",
      name: "Pharmacie Centrale",
      "opening_hours:covid19":
        "Mo,Tu,Th,Fr 09:00-12:00,14:30-19:30; We,Sa 09:00-13:00,14:30-19:30",
      phone: "+33 1 69 20 20 70",
      "ref:FR:FINESS": "910013481",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 2544806251,
    x: 48.8501938,
    y: 2.2852966,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Michon",
      "ref:FR:FINESS": "750027021",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2546512684,
    x: 48.8023602,
    y: 2.3643632,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pradel",
      "ref:FR:FINESS": "940014996",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2550906663,
    x: 48.804277,
    y: 2.1324062,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "10",
      "addr:postcode": "78000",
      "addr:street": "Rue Georges Clemenceau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Georges Clemenceau",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "780015095",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2564727305,
    x: 48.8871638,
    y: 2.3511635,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché Dejean",
      "ref:FR:FINESS": "750034100",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2565671474,
    x: 48.8315787,
    y: 2.3542019,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Place d'Italie",
      "ref:FR:FINESS": "750023434",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2571718056,
    x: 48.5306759,
    y: 2.3877838,
    tags: {
      "addr:city": "Ballancourt-sur-Essonne",
      "addr:housenumber": "1",
      "addr:postcode": "91610",
      "addr:street": "Rue des Bernaches",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 93 20 63",
      datemaj: "2013-03-14",
      dispensing: "yes",
      name: "Pharmacie Patrick Vidal",
      "ref:FR:FINESS": "910005602",
      since: "1996-05-31",
      start_date: "1996-05-31",
    },
  },
  {
    type: "node",
    id: 2571792737,
    x: 48.8904909,
    y: 2.1527327,
    tags: {
      "addr:housenumber": "79",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'École",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780006292",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2571792738,
    x: 48.8892499,
    y: 2.1573536,
    tags: {
      "addr:housenumber": "9",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Maudry",
      opening_hours: "Mo-Sa 09:00-12:30, 14:30-1:30",
      "ref:FR:FINESS": "780006276",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2572877376,
    x: 48.8831921,
    y: 2.3953289,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte Brunet",
      "ref:FR:FINESS": "750035073",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "survey:date": "2013-12-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2574706535,
    x: 48.6863264,
    y: 2.4089199,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "1",
      "addr:postcode": "91210",
      "addr:street": "Place de la République",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 83 09 80",
      "contact:phone": "+33 1 69 09 72 03",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910007673",
      since: "1999-05-20",
    },
  },
  {
    type: "node",
    id: 2578683004,
    x: 48.8857992,
    y: 2.1551013,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Célerier Denis",
      opening_hours: "Mo-Fr 08:00-13:00, 14:00-20:00; Sa 08:00-19:30",
      phone: "+33 1 39 52 10 67",
      "ref:FR:FINESS": "780006326",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2579425145,
    x: 48.8971571,
    y: 2.2077904,
    tags: {
      "access:covid19": "yes",
      alt_name: "Pharmacie d'Aubigny",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Meziane",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920017480",
      source: "survey 2014;Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 2579664139,
    x: 48.889725,
    y: 2.1597402,
    tags: {
      alt_name: "Pharmacie de l'Église",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      opening_hours:
        "Mo 14:45-20:00; Tu-Fr 09:00-12:30, 14:45-20:00; Sa 09:00-12:30, 14:45-19:30",
      phone: "+33 1 39 52 43 71",
      "ref:FR:FINESS": "780006342",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2580128450,
    x: 48.9355054,
    y: 2.4567544,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fatealy",
      "ref:FR:FINESS": "930007752",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2580132749,
    x: 48.9343839,
    y: 2.4528678,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie LAO",
      "ref:FR:FINESS": "930008032",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2581294934,
    x: 48.9307653,
    y: 2.4568336,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Progrès",
      "ref:FR:FINESS": "930008016",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2584777627,
    x: 49.112292,
    y: 2.2171488,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Plantes",
      "ref:FR:FINESS": "950009423",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2585285623,
    x: 48.9366696,
    y: 2.458235,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "930005822",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2585317559,
    x: 48.9393159,
    y: 2.4619901,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Barbusse",
      "ref:FR:FINESS": "930005855",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2587928200,
    x: 48.771726,
    y: 2.5094116,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la gare",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-17:00",
      "ref:FR:FINESS": "940014210",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2590042899,
    x: 48.8991891,
    y: 2.2668911,
    tags: {
      "addr:city": "Courbevoie",
      "addr:housenumber": "124",
      "addr:postcode": "92400",
      "addr:street": "Boulevard Saint-Denis",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benaim Levy",
      "ref:FR:FINESS": "920013893",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2590047046,
    x: 48.9041743,
    y: 2.2695584,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pasteur",
      opening_hours: "Mo-Sa 09:00-13:00,14:00-20:00",
      "ref:FR:FINESS": "920013794",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2590051108,
    x: 48.9048954,
    y: 2.2691427,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare de Bécon",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-12:00,14:30-18:30",
      "ref:FR:FINESS": "920013844",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2595407369,
    x: 48.8744569,
    y: 2.6406114,
    tags: {
      "addr:city": "Vaires-sur-Marne",
      "addr:postcode": "77360",
      "addr:street": "Rue de la Gare",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 13 64",
      "ref:FR:FINESS": "770012292",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2597595677,
    x: 48.8260107,
    y: 2.3418204,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-16",
      dispensing: "yes",
      name: "Pharmacie Pelissier",
      opening_hours: "Mo-Sa 08:30-20:00",
      phone: "+33145891005",
      "ref:FR:FINESS": "750022287",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2599975603,
    x: 48.8119254,
    y: 2.3233672,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "31",
      "contact:street": "Rue Carves",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du Fort",
      opening_hours: "Mo 09:00-12:30, Tu-Sa 09:00-12:30,14:00-19:30",
      phone: "+33 1 46 56 91 71",
      "ref:FR:FINESS": "920017142",
      "ref:FR:SIREN": "398983379",
      "ref:FR:SIRET": "39898337900015",
      source: "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2604332375,
    x: 48.8169815,
    y: 1.9456291,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Phamacie Pasteur",
      opening_hours:
        "Mo-Fr 08:30-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "780012092",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2604332671,
    x: 48.8269108,
    y: 1.9479842,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Juillet",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "780011896",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2609894805,
    x: 48.8292031,
    y: 2.3439087,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-06-19",
      "contact:phone": "+33 1 45804605",
      dispensing: "yes",
      name: "Pharmacie du Théâtre",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750022857",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2610673856,
    x: 48.8907746,
    y: 2.3312473,
    tags: {
      "addr:postcode": "75018",
      alt_name: "Pharmacie Lamarck",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharma Réference",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032757",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2611399062,
    x: 48.4035084,
    y: 2.6985895,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "19",
      "addr:postcode": "77300",
      "addr:street": "Rue Denecourt",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Brabis",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 22 20 73",
      "ref:FR:FINESS": "770006831",
      source: "Celtipharm - 10/2014",
      "survey:date": "2019-12-26",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2611722780,
    x: 48.4150947,
    y: 2.7185997,
    tags: {
      "addr:housenumber": "31",
      "addr:street": "Avenue Franklin Roosevelt",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Avenue",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770003614",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2611722783,
    x: 48.4163629,
    y: 2.7251877,
    tags: {
      "addr:street": "Rue de la Gare",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "770003713",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2612718468,
    x: 48.7846519,
    y: 2.3625779,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Benattasse",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "940014897",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 2618535752,
    x: 48.6220136,
    y: 2.4406725,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "23",
      "addr:postcode": "91000",
      "addr:street": "Place de la Commune",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 64 57",
      "contact:phone": "+33 1 60 78 26 26",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie des Épinettes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910008366",
      since: "1988-12-27",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 2623496214,
    x: 48.8343028,
    y: 2.3449782,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-12-06",
      dispensing: "yes",
      name: "Pharmacie Nordmann Glacière",
      opening_hours:
        "Mo 14:00-19:45, Tu-Fr 09:00-19:45, Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022832",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2635358644,
    x: 48.8987788,
    y: 2.343828,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ntsourankoua",
      "ref:FR:FINESS": "750033672",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2635786715,
    x: 48.8391923,
    y: 2.573002,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mme Akoun",
      opening_hours: "Mo 15:00-19:30,Tu-Sa 09:00-12:30; Tu-Sa 15:00-19:30, Su",
      "ref:FR:FINESS": "930011804",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2636037444,
    x: 48.7231027,
    y: 2.263942,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 9 67 10 91 64",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Atlantis",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-19:00",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00;Sa 08:00-19:00",
      "ref:FR:FINESS": "910010214",
    },
  },
  {
    type: "node",
    id: 2637404912,
    x: 48.8129032,
    y: 2.2724763,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Debelmas",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920011913",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2639430161,
    x: 48.8189992,
    y: 2.374526,
    tags: {
      "addr:housenumber": "53",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Moulin",
      "ref:FR:FINESS": "940010382",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 2639787856,
    x: 48.915957,
    y: 2.6268814,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Pin",
      "ref:FR:FINESS": "770010296",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2642678121,
    x: 48.8191173,
    y: 2.300013,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Malakoff",
      opening_hours:
        "Mo 13:00-19:45; Tu-Fr 08:30-19:45; Sa 09:00-12:30,14:30-19:45",
      "ref:FR:FINESS": "920016243",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2656051543,
    x: 48.8704346,
    y: 2.3237502,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Mieux Vivre",
    },
  },
  {
    type: "node",
    id: 2661585742,
    x: 48.9353852,
    y: 2.4618496,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "930005871",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2661638748,
    x: 48.9372045,
    y: 2.4637865,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie les Camélias",
      "ref:FR:FINESS": "930006101",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2663432509,
    x: 48.9406498,
    y: 2.450126,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Richard Oudot",
      "ref:FR:FINESS": "930005756",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2667321470,
    x: 48.926928,
    y: 2.4506593,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Taheraly",
      "ref:FR:FINESS": "930007653",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2671067683,
    x: 48.8299806,
    y: 2.3563975,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du 13ème",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 82 86 60",
      "ref:FR:FINESS": "750022972",
      shop: "chemist",
      source: "local Knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2673251679,
    x: 48.8519426,
    y: 2.2917149,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie de Grenelle",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 08:30-20:00",
      phone: "+33 1 45 79 53 19",
      "ref:FR:FINESS": "750026171",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2676932691,
    x: 48.9939658,
    y: 2.2351044,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30",
      "ref:FR:FINESS": "950008052",
      source: "survey:2014;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2676932692,
    x: 48.993443,
    y: 2.2341096,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Portes Brandebourg",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,15:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950008235",
      source: "survey:2014;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2677117433,
    x: 48.9211245,
    y: 2.4544445,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale de Drancy",
      "ref:FR:FINESS": "930008172",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2678728621,
    x: 48.830662,
    y: 2.3114368,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Paix",
      "ref:FR:FINESS": "750024952",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-05",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2678731867,
    x: 48.8319648,
    y: 2.3140431,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bodin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023756",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2678904256,
    x: 48.7935687,
    y: 2.3205599,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Bagneux",
      "contact:housenumber": "212",
      "contact:postcode": "92220",
      "contact:street": "Avenue Aristide Briand",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont Royal",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 9:00-13:00, 14:30-20:00; Sa 9:00-13:00, 14:30-19:30",
      "opening_hours:covid19":
        "Mo 14:00-20:00; Tu-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920008927",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 2682028786,
    x: 48.8797063,
    y: 2.5865191,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "19",
      "addr:postcode": "77500",
      "addr:street": "Rue Gustave Nast",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      email: "pharmaciebuatois@gmail.com",
      healthcare: "pharmacy",
      name: "Pharmacie Nast",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 87 78",
      "ref:FR:FINESS": "770005064",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      website: "https://pharmacienast-chelles.com/",
    },
  },
  {
    type: "node",
    id: 2682091628,
    x: 48.9184736,
    y: 2.4652769,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bois de Groslay",
      "ref:FR:FINESS": "930007703",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2682279528,
    x: 48.9152827,
    y: 2.4603024,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vinh Jean Jaurès",
      "ref:FR:FINESS": "930007901",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2682494234,
    x: 48.823628,
    y: 2.3761871,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Traditionelle Massena",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "750023251",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2687089945,
    x: 48.8046362,
    y: 2.2893466,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie de La Poste",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "920011541",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2687106685,
    x: 48.80568,
    y: 2.2917331,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Paris",
      opening_hours: "Mo-Fr 09:00-21:30; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920011574",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2688060558,
    x: 48.8436699,
    y: 2.3724975,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Paris",
      "contact:housenumber": "193",
      "contact:postcode": "75012",
      "contact:street": "Rue de Bercy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gamma",
      phone: "+33 1 43 40 89 52",
      "ref:FR:FINESS": "750020745",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2688271405,
    x: 48.7951014,
    y: 2.1385921,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "5",
      "addr:postcode": "78000",
      "addr:street": "Rue de la Porte de Buc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte de Buc",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015525",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2688494134,
    x: 48.9514967,
    y: 2.6012816,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fabre",
      "ref:FR:FINESS": "770012912",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2696470064,
    x: 48.8248876,
    y: 2.3256746,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Para&Pharmacie du grand Paris",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024721",
      source: "survey",
      "source:name": "Celtipharm - 10/2014",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      "survey:date": "2020-03-15",
    },
  },
  {
    type: "node",
    id: 2696617306,
    x: 48.9196563,
    y: 2.4507534,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des 4 Routes",
      "ref:FR:FINESS": "930007836",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2698555294,
    x: 48.76789,
    y: 2.0167441,
    tags: {
      "addr:housenumber": "2",
      "addr:street": "Rue Jean Goujon",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Manet",
      "ref:FR:FINESS": "780011045",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2700628651,
    x: 48.8451109,
    y: 2.6239278,
    tags: {
      "addr:city": "Noisiel",
      "addr:housenumber": "6",
      "addr:postcode": "77186",
      "addr:street": "Passage Louis Logre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Ferme du Buisson",
      opening_hours: "Mo-Fr 09:00-20:00; Su 09:30-13:00",
      "opening_hours:covid19": "Mo-Fr 09:30-19:00; Su 09:30-13:00",
      phone: "+33 1 60 05 42 83",
      "ref:FR:FINESS": "770009975",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2707103490,
    x: 48.8551245,
    y: 2.3604541,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Paris",
      "addr:housenumber": "119",
      "addr:postcode": "75004",
      "addr:street": "Rue Saint-Antoine",
      alt_name: "Pharmacie Furano",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marais",
      "opening_hours:covid19": "open",
      phone: "+33 1 42 72 20 44",
      "ref:FR:FINESS": "750010431",
      source: "Celtipharm - 10/2014",
      website: "https://pharmaciedumarais.pharminfo.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2712897779,
    x: 48.8641399,
    y: 2.3507425,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nahon",
      "ref:FR:FINESS": "750009391",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2712897784,
    x: 48.8651441,
    y: 2.3467946,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "49",
      "addr:postcode": "75002",
      "addr:street": "Rue Montorgueil",
      amenity: "pharmacy",
      "contact:phone": "+33 1 42 33 25 41",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Poirot",
      opening_hours: "Mo-Fr 08:30-21:00; Sa 09:00-21:00; Su 08:00-21:00",
      "ref:FR:FINESS": "750009177",
      source: "Celtipharm - 10/2014 + survey 01/2019",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2713790944,
    x: 48.8900719,
    y: 2.3033682,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte d'Asnières",
      "ref:FR:FINESS": "750029621",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2714943129,
    x: 48.8016169,
    y: 2.2866924,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Sa 8:30-20:30",
      "ref:FR:FINESS": "920011475",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-16",
    },
  },
  {
    type: "node",
    id: 2717238421,
    x: 48.5342043,
    y: 2.6568649,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Progrès",
      opening_hours: "Mo 09:30-19:30; Tu-Sa 08:45-19:30",
      "ref:FR:FINESS": "770008712",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2725582614,
    x: 48.8205703,
    y: 2.41475,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "940007271",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2727544895,
    x: 48.6473384,
    y: 2.3273114,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "173",
      "addr:postcode": "91700",
      "addr:street": "Avenue Gabriel Péri",
      alt_name: "Pharmacie Proust Belin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 33 69",
      "contact:phone": "+33 1 60 16 63 55",
      dispensing: "yes",
      name: "Pharmacie Belin",
      "ref:FR:FINESS": "910012426",
      since: "1983-12-20",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2727545273,
    x: 48.649237,
    y: 2.320338,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "79",
      "addr:postcode": "91700",
      "addr:street": "Avenue Gabriel Péri",
      alt_name: "SARL Pharmacie du Parc",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 51 11 84",
      "contact:phone": "+33 1 60 16 00 20",
      dispensing: "yes",
      name: "Pharmacie Durand",
      "ref:FR:FINESS": "910012392",
      since: "2007-04-16",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2727935107,
    x: 48.8888376,
    y: 2.3731738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Riquet",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035156",
      source: "Obs. pers.;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2731683429,
    x: 48.7998966,
    y: 2.018382,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rhanimi",
      opening_hours: "Mo-Sa 09:30-12:30,14:30-19:00",
      "ref:FR:FINESS": "780005146",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2732160528,
    x: 48.880023,
    y: 2.29516,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Niel",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 47 54 00 39",
      "ref:FR:FINESS": "750031007",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2733545351,
    x: 48.8775261,
    y: 2.2983719,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Wagram",
      opening_hours: "Mo-Sa 09:00-21:00",
      "opening_hours:covid19": "Mo-Sa 09:00-21:00",
      "ref:FR:FINESS": "750014896",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2733596705,
    x: 48.8783435,
    y: 2.2947415,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des 3 Avenues",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 46 22 28 12",
      "ref:FR:FINESS": "750031551",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2736209529,
    x: 48.8059681,
    y: 2.2863295,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Aérospatiale",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920011624",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2750304082,
    x: 48.8342764,
    y: 2.3539253,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Gobelins",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022907",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2752320893,
    x: 48.9626593,
    y: 2.4010321,
    tags: {
      "addr:housenumber": "3",
      "addr:street": "Rue Louison Bobet",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Soleil",
      "ref:FR:FINESS": "950008656",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2756370633,
    x: 48.84109,
    y: 2.3740378,
    tags: {
      alt_name: "Pharmacie Lannois",
      amenity: "pharmacy",
      "contact:email": "pharmaciedebercy@yahoo.fr",
      "contact:housenumber": "30",
      "contact:phone": "+33 143431948",
      "contact:street": "Quai de la Râpée",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Bercy",
      opening_hours: "Mo-Fr 08:00-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "750022204",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2756541391,
    x: 48.8872531,
    y: 2.2945914,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sainte-Odile",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030082",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2756554994,
    x: 48.887202,
    y: 2.295409,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Conservatoire",
      "ref:FR:FINESS": "750030116",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2756680411,
    x: 48.7816892,
    y: 2.5217382,
    tags: {
      "addr:city": "Ormesson-sur-Marne",
      "addr:housenumber": "9",
      "addr:postcode": "94490",
      "addr:street": "Rue Édouard Branly",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Châtelets",
      "ref:FR:FINESS": "940012347",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2758210199,
    x: 48.7869858,
    y: 2.529852,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Népenthès",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-19:30",
    },
  },
  {
    type: "node",
    id: 2758846117,
    x: 48.88616,
    y: 2.291785,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Champerret",
      "ref:FR:FINESS": "750031536",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2770625325,
    x: 48.8364526,
    y: 2.3524225,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "OR'EL des Gobelins",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022873",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2771135103,
    x: 48.9039296,
    y: 2.3173113,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bahsoun",
      "ref:FR:FINESS": "920012390",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2775842977,
    x: 48.8528806,
    y: 2.3888431,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Voltaire-Dumas",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "750020513",
      shop: "chemist",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2777277944,
    x: 48.8814164,
    y: 2.2946947,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Leclerc",
      "ref:FR:FINESS": "750031205",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2781166517,
    x: 48.944973,
    y: 2.5663102,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gare du Vert Galant",
      "ref:FR:FINESS": "930016704",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2792744296,
    x: 48.8443153,
    y: 2.5834684,
    tags: {
      "addr:city": "Champs-sur-Marne",
      "addr:housenumber": "7",
      "addr:postcode": "77420",
      "addr:street": "Avenue André-Marie Ampère",
      alt_name: "Pharmacie MURCIANO",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Descartes",
      opening_hours: "Mo-Fr 08:00-20:15; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 68 96 22",
      "ref:FR:FINESS": "770004596",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website: "https://pharmacie-champs-sur-marne.com/",
    },
  },
  {
    type: "node",
    id: 2792838473,
    x: 48.8466885,
    y: 2.5847405,
    tags: {
      "addr:city": "Champs-sur-Marne",
      "addr:housenumber": "16",
      "addr:postcode": "77420",
      "addr:street": "Place du Bois de Grâce",
      alt_name: "Pharmacie SAADA",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Bois de Grâce",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 68 10 12",
      "ref:FR:FINESS": "770004430",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2793393735,
    x: 48.879074,
    y: 2.291173,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031684",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2798249873,
    x: 48.6529601,
    y: 2.3157014,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "3",
      "addr:postcode": "91700",
      "addr:state": "Essonne",
      "addr:street": "Avenue Paul Vaillant-Couturier",
      amenity: "pharmacy",
      "contact:fax": "+33 160169695",
      "contact:phone": "+33 160161489",
      datemaj: "2013-08-02",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "910012475",
      since: "1991-12-26",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
      start_date: "1991-12-26",
    },
  },
  {
    type: "node",
    id: 2799009855,
    x: 48.8348443,
    y: 2.2897292,
    tags: {
      amenity: "pharmacy",
      dispensing: "no",
      name: "Orthopharm",
      opening_hours: "Mo-Sa 09:00-20:00",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2799009856,
    x: 48.8766739,
    y: 2.3412606,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Goldfarb",
      "ref:FR:FINESS": "750016412",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2799010415,
    x: 48.8739699,
    y: 2.3425841,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Choplin Montmartre",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:30-13:30,15:30-19:00",
      "ref:FR:FINESS": "750016065",
      source: "survey 2017;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2799010423,
    x: 48.8763917,
    y: 2.3411816,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lili",
      "ref:FR:FINESS": "750016446",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2805560239,
    x: 48.799949,
    y: 2.2897845,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920011525",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-16",
    },
  },
  {
    type: "node",
    id: 2810582165,
    x: 48.8409327,
    y: 2.3040247,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Danan",
      "ref:FR:FINESS": "750025355",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2820541878,
    x: 48.8194513,
    y: 2.3099767,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "description:covid19": "Fonctionne par la fenêtre de garde.",
      dispensing: "yes",
      name: "Barsac-Le Guen",
      "opening_hours:covid19": "Mo-Fr 10:00-18:30",
      "ref:FR:FINESS": "920016425",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2820561365,
    x: 48.9004862,
    y: 2.1480004,
    tags: {
      "addr:housenumber": "204",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Diffre",
      "ref:FR:FINESS": "780006177",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2821127201,
    x: 48.885224,
    y: 2.293731,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Héliopolis",
      "ref:FR:FINESS": "750031783",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2823189701,
    x: 48.880474,
    y: 2.287142,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Ternes",
      "ref:FR:FINESS": "750031585",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2824492997,
    x: 48.7834549,
    y: 2.2471974,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Janet",
      "ref:FR:FINESS": "920011897",
    },
  },
  {
    type: "node",
    id: 2832734360,
    x: 48.6607639,
    y: 2.2680602,
    tags: {
      "addr:city": "La Ville-du-Bois",
      "addr:housenumber": "1",
      "addr:postcode": "91620",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 01 06 47",
      "contact:phone": "+33 1 69 01 06 47",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Liliane Pichereau",
      "ref:FR:FINESS": "910014075",
      since: "1979-03-21",
    },
  },
  {
    type: "node",
    id: 2835819733,
    x: 48.885272,
    y: 2.2339919,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lorilleux",
      "ref:FR:FINESS": "920019213",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2836131103,
    x: 48.8882916,
    y: 2.3066969,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square de Tocqueville",
      "ref:FR:FINESS": "750031650",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2841692419,
    x: 48.8744347,
    y: 2.5584028,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie VO",
      "ref:FR:FINESS": "930008750",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2843829818,
    x: 48.8486571,
    y: 2.4328554,
    tags: {
      "addr:city": "Vincennes",
      "addr:housenumber": "196",
      "addr:postcode": "94300",
      "addr:street": "Rue de Fontenay",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Soleil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016074",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2854422802,
    x: 48.641851,
    y: 2.3338508,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "91",
      "addr:postcode": "91700",
      "addr:state": "Essonne",
      "addr:street": "Route de Corbeil",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 40 19",
      "contact:phone": "+33 1 60 15 00 50",
      dispensing: "yes",
      name: "Pharmacie Brigitte Fromentin",
      "ref:FR:FINESS": "910012343",
      since: "1987-12-30",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2855032001,
    x: 48.8488007,
    y: 2.3403871,
    tags: {
      alt_name: "Pharmacie Rosilio",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Sénat",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750012833",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2855614790,
    x: 48.8497894,
    y: 2.4562925,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khiat",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "940009202",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2855879093,
    x: 48.8113535,
    y: 2.3854459,
    tags: {
      "addr:housenumber": "1-3",
      "addr:street": "Promenée Venise Gosnat",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du centre commercial Jeanne Hachette",
      "ref:FR:FINESS": "940010564",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2860580144,
    x: 48.6439357,
    y: 2.3150706,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "105",
      "addr:postcode": "91700",
      "addr:street": "Avenue du Président Salvador Allende",
      alt_name: "Pharmacie Dang",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 15 28 73",
      "contact:phone": "+33 1 60 15 03 03",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Cottage",
      "ref:FR:FINESS": "910012541",
      since: "1992-03-30",
    },
  },
  {
    type: "node",
    id: 2861442269,
    x: 48.6371706,
    y: 2.3318206,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "9",
      "addr:postcode": "91700",
      "addr:street": "Avenue du Régiment Normandie-Niémen",
      alt_name: "SELARL Pharmacie du Donjon",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 46 87",
      "contact:phone": "+33 1 60 15 11 84",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Donjon",
      "ref:FR:FINESS": "910012491",
      since: "2008-04-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2868570276,
    x: 48.7130373,
    y: 2.4890757,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Concy",
      "ref:FR:FINESS": "910014497",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2869300320,
    x: 48.8794349,
    y: 2.3875791,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Botzaris des Buttes-Chaumont",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750018491",
      source: "survey",
      "source:name": "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2869934198,
    x: 48.8485851,
    y: 2.4261651,
    tags: {
      "addr:street": "Rue des Laitières",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Laitières",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-13:30, 15:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016124",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2874340113,
    x: 48.8020037,
    y: 2.170702,
    tags: {
      "addr:city": "Viroflay",
      "addr:postcode": "78220",
      "addr:street": "Rue Rieussec",
      amenity: "pharmacy",
      "contact:housenumber": "36",
      dispensing: "yes",
      name: "Pharmacie Saint-Vigor",
      opening_hours: "Tu-Sa 09:00-12:30,14:30-19:30, Mo 14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780016275",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2881748202,
    x: 48.910967,
    y: 2.2722978,
    tags: {
      "addr:city": "Asnières-sur-Seine",
      "addr:housenumber": "10",
      "addr:street": "Rue des Bourguignons",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Paumard",
      "ref:FR:FINESS": "920008331",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2882581944,
    x: 48.8746967,
    y: 2.3796965,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rébeval",
      opening_hours: "Mo-Sa 09:00-20:00; PH off",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750035123",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2883464158,
    x: 48.8423005,
    y: 2.5841643,
    tags: {
      "addr:city": "Champs-sur-Marne",
      "addr:housenumber": "17",
      "addr:postcode": "77420",
      "addr:street": "Avenue André-Marie Ampère",
      alt_name: "Pharmacie Copernic",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Copernic",
      opening_hours: "Mo-Fr 07:45-20:30; Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      phone: "+33 1 64 61 88 26",
      "ref:FR:FINESS": "770003358",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website:
        "https://www.mypharmactiv.fr/pharmacie/pharmacie-copernic-champssurmarne",
    },
  },
  {
    type: "node",
    id: 2892502021,
    x: 48.7001877,
    y: 2.2268785,
    tags: {
      "addr:city": "Villebon-sur-Yvette",
      "addr:housenumber": "5",
      "addr:postcode": "91140",
      "addr:street": "Place Gérard-Nevers",
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 10 69 14",
      "description:covid19":
        "Les clients rentrent 2 par 2.Sens de circulation à respecter à l’intérieur",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:30-18:30; Sa 09:00-12:30,14:30-18:00",
      "ref:FR:FINESS": "910013960",
      since: "2008-10-01",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2893993900,
    x: 48.8587751,
    y: 2.4075335,
    tags: {
      alt_name: "Pharmacie GROUSSIN ROUILLER",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square",
      "ref:FR:FINESS": "750035834",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2897483751,
    x: 48.817973,
    y: 2.2674637,
    tags: {
      "addr:city": "Issy-les-Moulineaux",
      "addr:housenumber": "63",
      "addr:postcode": "92130",
      "addr:street": "Esplanade du Belvédère",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Fort d'Issy",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 42 25 68",
      "ref:FR:FINESS": "920015476",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      website: "http://www.pharmacie-du-fort.com/",
    },
  },
  {
    type: "node",
    id: 2898999149,
    x: 48.9121821,
    y: 2.4524198,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Family Pharma",
      "ref:FR:FINESS": "930007851",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2903101412,
    x: 48.9044777,
    y: 2.3040787,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Landy",
      "ref:FR:FINESS": "920012580",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-10-24",
    },
  },
  {
    type: "node",
    id: 2904060999,
    x: 48.8042234,
    y: 2.1710532,
    tags: {
      "addr:city": "Viroflay",
      "addr:housenumber": "116",
      "addr:postcode": "78220",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "0130244624",
      "ref:FR:FINESS": "780016143",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2904737339,
    x: 48.8041602,
    y: 2.1741268,
    tags: {
      "addr:city": "Viroflay",
      "addr:housenumber": "84",
      "addr:postcode": "78220",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      "contact:city": "Viroflay",
      "contact:housenumber": "84",
      "contact:phone": "+33 1 30 24 47 27",
      "contact:postcode": "78220",
      "contact:street": "Avenue du General Leclerc",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-13:00, 14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 30 24 47 27",
      "ref:FR:FINESS": "780016127",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2907989673,
    x: 48.8646358,
    y: 2.408131,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Espace santé",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035651",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2908306665,
    x: 48.875452,
    y: 2.3822672,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035222",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2909206606,
    x: 48.8613804,
    y: 2.3778956,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Optique du Monde",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33147004679",
      "ref:FR:FINESS": "750019580",
      source: "survey;Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2911696117,
    x: 48.8593223,
    y: 2.3826385,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie St. Maur - Desmoulins",
      opening_hours: "Mo-Fr 8:30-20:00; Sa 9:00-19:30",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      phone: "+33143484303",
      "ref:FR:FINESS": "750019531",
      source: "local Knowledge;Celtipharm - 10/2014",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2913188423,
    x: 48.8851105,
    y: 2.3800766,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Deux Rues",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034852",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2913188424,
    x: 48.8858942,
    y: 2.3827093,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 112",
      "ref:FR:FINESS": "750034886",
    },
  },
  {
    type: "node",
    id: 2917352883,
    x: 48.8406121,
    y: 2.3051658,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vaugirard",
      opening_hours:
        "Tu-Fr 09:00-19:45, Mo 10:30-19:45, Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "750027153",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2917666502,
    x: 48.8860404,
    y: 2.3819393,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Sitbon",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jaurès-Crimée",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035354",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2920701033,
    x: 48.8597592,
    y: 2.3242049,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie d'Orsay",
      "opening_hours:signed": "no",
      "ref:FR:FINESS": "750013591",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2922595146,
    x: 48.883322,
    y: 2.3877237,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Manin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034936",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2924827704,
    x: 48.8860701,
    y: 2.3911943,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035057",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2924827720,
    x: 48.8870513,
    y: 2.3871615,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bethoux Pouget",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034902",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2924827721,
    x: 48.8880598,
    y: 2.3919611,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 144847098",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      opening_hours: "Mo-Su",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034571",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      website: "https://pharmacie-paris-lavillette.fr",
    },
  },
  {
    type: "node",
    id: 2925648935,
    x: 48.847152,
    y: 2.3050056,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Faval",
      phone: "+33 1 43 06 04 94",
      "ref:FR:FINESS": "750027484",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2929994647,
    x: 48.8647995,
    y: 2.3736939,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "58",
      "addr:postcode": "75011",
      "addr:street": "Rue Oberkampf",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 47 00 41 82",
      healthcare: "pharmacy",
      name: "Pharmacy (Phamarcie du 58 / iPharm)",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "opening_hours:covid19": "open with the same schedules",
      operator: "Chan Kyle",
      phone: "+33 1 47 00 65 02",
      "ref:FR:FINESS": "750019424",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 2930675715,
    x: 48.9743103,
    y: 2.3711632,
    tags: {
      "addr:city": "Sarcelles",
      "addr:housenumber": "5",
      "addr:postcode": "95200",
      "addr:street": "Place Camille Saint-Saëns",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Saëns",
      "ref:FR:FINESS": "950012393",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 2930983533,
    x: 48.9219946,
    y: 2.4474059,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bouquin Naouri",
      "ref:FR:FINESS": "930007885",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2931863147,
    x: 48.8851636,
    y: 2.392619,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Paris Pharma",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2932841002,
    x: 48.6202308,
    y: 2.4918716,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
    },
  },
  {
    type: "node",
    id: 2934420301,
    x: 48.883655,
    y: 2.304729,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "71",
      "addr:postcode": "75017",
      "addr:street": "Avenue de Villiers",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Stioui",
      opening_hours: "Mo-Sa 08:00-20:00",
      phone: "+33 1 47 63 73 94",
      "ref:FR:FINESS": "750031882",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2934433001,
    x: 48.8822367,
    y: 2.3040102,
    tags: {
      "addr:housenumber": "53",
      "addr:postcode": "75017",
      "addr:street": "Rue de Prony",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Prony",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      phone: "+33 1 47 63 30 16",
      "ref:FR:FINESS": "750031353",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2935457135,
    x: 48.6519675,
    y: 2.4133176,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "32",
      "addr:postcode": "91130",
      "addr:street": "Avenue de la Libération",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 25 89 34",
      "contact:phone": "+33 1 69 06 23 11",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie El Bori",
      "ref:FR:FINESS": "910012111",
      since: "1975-09-26",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2935916401,
    x: 48.881756,
    y: 2.296039,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cassam",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031023",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2936716925,
    x: 48.7446978,
    y: 2.2679985,
    tags: {
      "addr:city": "Verrières-le-Buisson",
      "addr:housenumber": "46",
      "addr:postcode": "91370",
      "addr:street": "Boulevard du Maréchal Foch",
      alt_name: "SNC Pharmacie Renou et Delouis",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 20 52 07",
      "contact:phone": "+33 1 69 20 12 33",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "910013564",
      since: "2006-05-01",
    },
  },
  {
    type: "node",
    id: 2936716928,
    x: 48.7325641,
    y: 2.257007,
    tags: {
      alt_name: "Pharmacie du Lycée",
      amenity: "pharmacy",
      "contact:city": "Massy",
      "contact:housenumber": "72",
      "contact:phone": "+33 1 69 20 29 21",
      "contact:postcode": "91300",
      "contact:street": "Rue de Versailles",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hacin",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 08:00-12:30,15:00-19:30; Sa 08:00-13:00,15:00-19:00",
      "opening_hours:covid19":
        "Mo 14:30-18:30; Tu-Fr 09:00-12:30,15:00-19:30; Sa 09:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "910010461",
      since: "1999-05-21",
    },
  },
  {
    type: "node",
    id: 2936734119,
    x: 48.8478801,
    y: 2.4390901,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016025",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2939309601,
    x: 48.849326,
    y: 2.353462,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Deux Rives",
      opening_hours: "Mo-Sa 08:30-20:00, Su,PH 09:00-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750011751",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2939322833,
    x: 48.9270864,
    y: 2.4381935,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Poncet",
      "ref:FR:FINESS": "930007687",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2939354701,
    x: 48.846246,
    y: 2.351593,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arènes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011330",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2947844614,
    x: 48.8676501,
    y: 2.3535643,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Buénavida",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:30-12:30",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00, Sa 09:30-12:30",
      "ref:FR:FINESS": "750009797",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-06-29",
    },
  },
  {
    type: "node",
    id: 2952348084,
    x: 48.6742546,
    y: 2.3774233,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "1",
      "addr:postcode": "91170",
      "addr:street": "Place René Coty",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 05 28 30",
      "contact:phone": "+33 1 69 05 28 30",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Centrale Falempin",
      "ref:FR:FINESS": "910014414",
      since: "1984-02-23",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2952356539,
    x: 48.661391,
    y: 2.3640491,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "69",
      "addr:postcode": "91170",
      "addr:street": "Avenue du Commandant Barré",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 24 07 04",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Paule Jordi",
      "ref:FR:FINESS": "910014174",
      since: "1990-01-02",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2952357282,
    x: 48.6526698,
    y: 2.3626133,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "88",
      "addr:postcode": "91170",
      "addr:street": "Avenue de la Forêt",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 24 60 47",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Moyon Leibig",
      "ref:FR:FINESS": "910014224",
      since: "1979-02-26",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 2952583206,
    x: 48.8037471,
    y: 2.3835084,
    tags: {
      "addr:housenumber": "1",
      "addr:street": "Rue Amédée Huon",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "940010036",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 2959090736,
    x: 48.8490614,
    y: 2.3923936,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dior",
      opening_hours: "Mo-Fr 10:30-14:00,15:30-20:00, Sa 10:30-14:30",
      "ref:FR:FINESS": "750018962",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2959753634,
    x: 48.9063476,
    y: 2.176171,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lambin",
      "ref:FR:FINESS": "780005625",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2970575181,
    x: 48.8797362,
    y: 2.4115488,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Babylone",
      "ref:FR:FINESS": "930013271",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2971978320,
    x: 48.8452318,
    y: 2.4177396,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013014",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2976098022,
    x: 48.8407127,
    y: 2.6560491,
    tags: {
      "addr:city": "Torcy",
      "addr:housenumber": "1",
      "addr:postcode": "77200",
      "addr:street": "Promenade du 7e Art",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khun Bay 1",
      phone: "+33 1 60 05 86 36",
      "ref:FR:FINESS": "770012045",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website: "https://pharmaciekhun.pharmabest.com/",
    },
  },
  {
    type: "node",
    id: 2976098029,
    x: 48.8401918,
    y: 2.6532599,
    tags: {
      "addr:city": "Torcy",
      "addr:housenumber": "26",
      "addr:postcode": "77200",
      "addr:street": "Promenade du Belvédère",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khun RER",
      phone: "+33 1 60 17 80 60",
      "ref:FR:FINESS": "770012011",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website: "https://pharmacie-rer-torcy.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 2977988860,
    x: 48.8731446,
    y: 2.3278704,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lahmi",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750015646",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2981304072,
    x: 48.9250768,
    y: 2.4361052,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Multon",
      "ref:FR:FINESS": "930008057",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2984948761,
    x: 48.8659079,
    y: 2.2238705,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Fr 08:30-19:30, Sa 09:00-14:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 45 06 10 46",
      "ref:FR:FINESS": "920020971",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2984999402,
    x: 48.8359778,
    y: 2.3842126,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du PARC",
      "ref:FR:FINESS": "750040735",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2985615240,
    x: 48.8197099,
    y: 2.3058648,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Montlouis",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016524",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 2986637543,
    x: 48.8146306,
    y: 2.3598073,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "14",
      dispensing: "yes",
      name: "Pharmacie du Kremlin",
      "ref:FR:FINESS": "940010994",
      source: "survey",
      "source:name": "Celtipharm - 10/2014",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2986694839,
    x: 48.842146,
    y: 2.3285198,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Sciences et des Arts",
      "ref:FR:FINESS": "750024622",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2988531194,
    x: 48.869678,
    y: 2.3945412,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Well & Well",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036634",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2989392241,
    x: 48.8875386,
    y: 2.3414422,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Tertre",
      opening_hours: "Mo-Sa 14:00-19:00, Tu-Sa 09:30-12:30",
      "ref:FR:FINESS": "750032435",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2989866590,
    x: 48.8278987,
    y: 2.3720944,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie centrale Seine",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00, Su closed",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750023467",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2991274883,
    x: 48.8424925,
    y: 2.4178019,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013063",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2991274884,
    x: 48.8469224,
    y: 2.4171317,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "name:signed": "no",
      "ref:FR:FINESS": "940012990",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2991274885,
    x: 48.8474846,
    y: 2.4178245,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "940013196",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 2992573651,
    x: 48.8161845,
    y: 2.3418945,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Andriamanjato",
      opening_hours:
        "Mo-Fr 14:00-19:30, Tu-Fr 08:30-12:30, Sa 09:00-12:30,14:30-18:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009673",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 2992773923,
    x: 48.853708,
    y: 2.2890997,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Bir-Hakeim",
      opening_hours: "Mo-Sa 08:15-22:00",
      "ref:FR:FINESS": "750026155",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 2995727345,
    x: 48.9206523,
    y: 2.4401179,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Morin",
      "ref:FR:FINESS": "930008073",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 2996508706,
    x: 48.8574572,
    y: 2.381041,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie",
      "ref:FR:FINESS": "750020406",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3004918729,
    x: 48.9215017,
    y: 2.445927,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Berthelot",
      "ref:FR:FINESS": "930007984",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3005534346,
    x: 48.8666193,
    y: 2.7074501,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chanthavongthan",
      "ref:FR:FINESS": "770007292",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3015094417,
    x: 48.8370016,
    y: 2.4181712,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Rocher",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013113",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3017685298,
    x: 48.539065,
    y: 2.6627657,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Jean",
      "opening_hours:covid19": "Mo-Sa 08:30-19:30",
      "ref:FR:FINESS": "770008761",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3018621825,
    x: 48.8746172,
    y: 2.3732994,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Maxi Pharma Sambre et Meuse",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018327",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3026845596,
    x: 48.8757,
    y: 2.2967445,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "10",
      "addr:postcode": "75008",
      "addr:street": "Avenue de Wagram",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Arc de Triomphe",
      phone: "+33 1 42 27 16 97",
      "ref:FR:FINESS": "750015562",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3030070143,
    x: 48.84804,
    y: 2.351166,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Loireau",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010860",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3032568255,
    x: 48.8515626,
    y: 2.3275283,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Alloun",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Lutetia",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750013294",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3033182555,
    x: 48.8019643,
    y: 2.2744025,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marcillac",
      "ref:FR:FINESS": "920011590",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3037670738,
    x: 48.8568752,
    y: 2.2212836,
    tags: {
      "addr:city": "Saint Cloud",
      "addr:housenumber": "14",
      "addr:postcode": "92210",
      "addr:street": "Avenue de Longchamp",
      alt_name: "Pharmacie DREVILLE",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Coteaux",
      "opening_hours:covid19": "open",
      phone: "+33 1 47 71 26 26",
      "ref:FR:FINESS": "920020252",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3039196278,
    x: 49.0366341,
    y: 2.6935279,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Saint-Mard",
      "ref:FR:FINESS": "770011427",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 12/2015",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3040923163,
    x: 48.866607,
    y: 2.3816586,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rossi",
      opening_hours:
        "Mo-Fr 10:00-13:15,14:15-19:45; Sa 10:00-13:15,14:15-19:00",
      "ref:FR:FINESS": "750019465",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3041803047,
    x: 48.8487,
    y: 2.348668,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 5",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010902",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3042311480,
    x: 48.8223815,
    y: 2.4142476,
    tags: {
      "addr:city": "Charenton-le-Pont",
      "addr:housenumber": "2",
      "addr:postcode": "94220",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mondot",
      phone: "+33 1 43 68 03 97",
      "ref:FR:FINESS": "940007354",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3050987378,
    x: 48.8802753,
    y: 2.3518849,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie de Maubeuge",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 10:00-20:00",
      "ref:FR:FINESS": "750018210",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3057528227,
    x: 48.9279236,
    y: 2.4374414,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Khoury",
      "ref:FR:FINESS": "930007935",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3058191942,
    x: 48.8836827,
    y: 2.1715553,
    tags: {
      "addr:city": "Rueil-Malmaison",
      "addr:housenumber": "28",
      "addr:postcode": "92500",
      "addr:street": "Rue des Frères Lumière",
      alt_name: "Pharmacie des Martinets",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie D'Haussy - Guillet",
      "opening_hours:covid19": "Mo-Su 09:00-12:30,15:00-19:30",
      "ref:FR:FINESS": "920019841",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 3063264034,
    x: 48.840301,
    y: 2.3953091,
    tags: {
      alt_name: "Pharmacie Benhamou",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Verte de Reuilly",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-13:00,15:00-19:00; Sa 08:30-13:00",
      "ref:FR:FINESS": "750022071",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3067862413,
    x: 49.0258718,
    y: 2.3265431,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950006957",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3069999571,
    x: 48.6546421,
    y: 2.3505584,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:postcode": "91390",
      "addr:street": "Place Marcel Sembat",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 04 06 13",
      "contact:phone": "+33 1 69 04 72 93",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "910011162",
      since: "2008-09-01",
    },
  },
  {
    type: "node",
    id: 3070011463,
    x: 48.6457637,
    y: 2.3540182,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "9",
      "addr:postcode": "91390",
      "addr:street": "Place Aimé et Marie Geoffroy",
      alt_name: "Pharmacie Buisson Charitat",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 17 77",
      "contact:phone": "+33 1 60 15 15 73",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Gribelette",
      "ref:FR:FINESS": "910011063",
      since: "2007-03-12",
    },
  },
  {
    type: "node",
    id: 3070348017,
    x: 48.7605552,
    y: 1.9440046,
    tags: {
      "addr:city": "Maurepas",
      "addr:housenumber": "21",
      "addr:postcode": "78310",
      "addr:street": "Avenue du Forez",
      alt_name: "Pharmacie ARNAWOUT",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du Forez",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "780010476",
      source: "Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 3070361876,
    x: 48.7618317,
    y: 1.9444766,
    tags: {
      "access:covid19": "yes",
      "addr:street": "Place du Sancerrois",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Monier",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780010393",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3071376163,
    x: 49.0310214,
    y: 2.3346169,
    tags: {
      "addr:city": "Domont",
      "addr:housenumber": "25",
      "addr:postcode": "95330",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "950006932",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3071811180,
    x: 48.5962967,
    y: 2.4925097,
    tags: {
      "addr:city": "Saintry-sur-Seine",
      "addr:housenumber": "92",
      "addr:postcode": "91250",
      "addr:street": "Grande Rue Charles de Gaulle",
      alt_name: "Pharmacie Villerey",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 75 52 79",
      "contact:phone": "+33 1 60 75 41 33",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "910012962",
      since: "1977-05-10",
    },
  },
  {
    type: "node",
    id: 3074009930,
    x: 48.6528829,
    y: 2.3571469,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "1",
      "addr:postcode": "91390",
      "addr:street": "Rue Jean Dussart",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 37 00",
      "contact:phone": "+33 1 69 04 52 62",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "910011097",
      since: "2008-06-30",
    },
  },
  {
    type: "node",
    id: 3075933401,
    x: 48.8453589,
    y: 2.4044766,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Rendez-vous",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750021933",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3086619242,
    x: 48.641218,
    y: 2.34259,
    tags: {
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "23",
      "addr:postcode": "91700",
      "addr:street": "Rue Roger Vailland",
      alt_name: "SELARL Pharmacie des Fées",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 46 19 49",
      "contact:phone": "+33 1 60 15 14 46",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie des Fées",
      "ref:FR:FINESS": "910012525",
      since: "2006-05-01",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3088910410,
    x: 48.8745382,
    y: 2.3080937,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rouche",
      "ref:FR:FINESS": "750014565",
      source: "Survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3089040571,
    x: 48.863165,
    y: 2.2116456,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Jardins",
      "ref:FR:FINESS": "920020922",
      source: "Survey;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3089482461,
    x: 48.8957062,
    y: 2.29652,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Victor Hugo",
      "ref:FR:FINESS": "920016177",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3094913781,
    x: 48.887904,
    y: 2.2229875,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Fontenelles",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "920017944",
      source: "survey 2014;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3098860754,
    x: 48.8116634,
    y: 2.2948411,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Helary",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,15:30-19:00",
      "ref:FR:FINESS": "920016474",
      source: "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3099695425,
    x: 48.819292,
    y: 2.4116386,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 76 62 32",
      dispensing: "yes",
      name: "Pharmacie Nguyen Quach",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "940007487",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3100412537,
    x: 48.9296421,
    y: 2.4326561,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rouquet",
      "ref:FR:FINESS": "930007950",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3109790930,
    x: 48.878495,
    y: 2.289737,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Ferdinand",
      "ref:FR:FINESS": "750031437",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3111552947,
    x: 48.8825369,
    y: 2.3507603,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Paris-Magenta",
      "ref:FR:FINESS": "750018194",
      source: "survey;Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 3114931214,
    x: 48.6932016,
    y: 2.272559,
    tags: {
      "addr:city": "Saulx-les-Chartreux",
      "addr:housenumber": "3",
      "addr:postcode": "91160",
      "addr:street": "Rue de l'Arpajonnais",
      alt_name: "Pharmacie M. Frimon",
      amenity: "pharmacy",
      "contact:phone": "+331 60 49 20 20",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Moulin",
      "opening_hours:covid19":
        "Mo-Fr 10:00-12:30,15:00-19:30; Sa 10:00-12:30,15:00-19:00",
      "ref:FR:FINESS": "910015254",
      since: "1997-12-31",
    },
  },
  {
    type: "node",
    id: 3116545384,
    x: 48.8673865,
    y: 2.3967482,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie CHY HAC",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036584",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3116696688,
    x: 48.8581768,
    y: 2.3827711,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours:
        "Mo-Fr 08:30-13:00, 14:00-20:00; Sa 08:30-13:00, 14:00-19:30",
      "ref:FR:FINESS": "750019994",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3118100741,
    x: 48.7188795,
    y: 2.1022475,
    tags: {
      "addr:city": "Magny-les-Hameaux",
      "addr:housenumber": "1",
      "addr:postcode": "78114",
      "addr:street": "Place du 19 Mars 1962",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Priker",
      "ref:FR:FINESS": "780009130",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3121795371,
    x: 48.7164238,
    y: 2.0852474,
    tags: {
      "addr:city": "Saint-Rémy-lès-Chevreuse",
      "addr:postcode": "78470",
      "addr:street": "Avenue des Buissons",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Arnault",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "780018297",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3123789380,
    x: 48.8228456,
    y: 2.5427825,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940015811",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3124835626,
    x: 48.8283324,
    y: 2.3559634,
    tags: {
      amenity: "pharmacy",
      "description:covid19": "Entrée par la rue Vandrezanne",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Italie 2",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750023004",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3124835627,
    x: 48.8310135,
    y: 2.3574868,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Nextypharm Vincent Auriol",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023616",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3126157583,
    x: 48.9080811,
    y: 2.2472272,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "920014677",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126163933,
    x: 48.9066877,
    y: 2.2480451,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 08:00-20:30, Sa 08:00-20:00",
      "ref:FR:FINESS": "920014610",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126170020,
    x: 48.9110509,
    y: 2.2542421,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Aigle",
      opening_hours: "Tu-Sa 09:00-12:30, 14:30-19:45; Mo 14:30-19:45",
      "ref:FR:FINESS": "920014495",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126172592,
    x: 48.9097821,
    y: 2.2410424,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pecqueur",
      opening_hours: "Mo-Sa 09:00-13:00, Mo-Fr 14:00-20:00, Sa 14:00-19:00",
      "ref:FR:FINESS": "920014511",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126177399,
    x: 48.9040065,
    y: 2.2495488,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Reggiani",
      "ref:FR:FINESS": "920014693",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126184130,
    x: 48.906996,
    y: 2.240578,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Charlebourg",
      opening_hours: "Mo-Fr 08:15-20:00; Sa 09:30-12:30,14:00-20:00",
      "ref:FR:FINESS": "920014727",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3126194785,
    x: 48.9132761,
    y: 2.2583035,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 42 42 09 50",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de La Gare des Vallees",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:30-20:00; Sa 09:00-12:30,15:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009511",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3128052746,
    x: 48.6341855,
    y: 2.3299684,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "30",
      "addr:postcode": "91240",
      "addr:street": "Avenue de Brétigny",
      alt_name: "Pharmacie Le",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 16 55 35",
      "contact:phone": "+33 1 60 15 06 78",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Bellevue",
      "ref:FR:FINESS": "910012814",
      since: "1988-08-08",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3128052747,
    x: 48.6323272,
    y: 2.336481,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Sainte-Geneviève-des-Bois",
      "addr:housenumber": "205 bis",
      "addr:postcode": "91700",
      "addr:state": "Essonne",
      "addr:street": "Route de Corbeil",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 15 79 76",
      "contact:phone": "+33 1 60 15 10 51",
      dispensing: "yes",
      name: "Pharmacie Pinchaux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910012376",
      since: "1996-12-06",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3131956715,
    x: 48.6514884,
    y: 2.3367862,
    tags: {
      "addr:city": "Villemoisson-sur-Orge",
      "addr:housenumber": "136",
      "addr:postcode": "91360",
      "addr:street": "Route de Corbeil",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 04 59 86",
      "contact:phone": "+33 1 69 04 63 03",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Vieux Chêne",
      "ref:FR:FINESS": "910014091",
      since: "1992-04-29",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 3133707472,
    x: 48.8772545,
    y: 2.3954035,
    tags: {
      "addr:housenumber": "15",
      "addr:postcode": "75019",
      "addr:street": "Rue Henri Ribière",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lafayette Daloy",
      opening_hours: "Mo-Fr 08:30-21:00; Sa,Su 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33153386000",
      "ref:FR:FINESS": "750034704",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3136900666,
    x: 48.6950722,
    y: 2.5211261,
    tags: {
      "addr:city": "Épinay-sous-Sénart",
      "addr:housenumber": "12",
      "addr:postcode": "91860",
      "addr:street": "Rue du Levant",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 46 63 81",
      "contact:phone": "+33 1 60 46 66 45",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Centre Commercial Relais Ouest",
      opening_hours:
        "Mo-Fr 09:00-12:30,15:00-19:30; Sa 09:00-13:00,15:00-19:30",
      "ref:FR:FINESS": "910007855",
      since: "1970-10-30",
    },
  },
  {
    type: "node",
    id: 3137057401,
    x: 48.8744982,
    y: 2.3927178,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "750035966",
      source: "survey",
      "source:ref:FR:FINESS": "finess.sante.gouv.fr",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3138558740,
    x: 48.8559893,
    y: 2.3328832,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Boubert",
      opening_hours: "Mo-Fr 09:00-19:45; Sa 09:00-18:00",
      "opening_hours:covid19": "open",
      phone: "+33142602737",
      "ref:FR:FINESS": "750012783",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3138571443,
    x: 48.8535947,
    y: 2.3367962,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Petite Pharmacie",
      "ref:FR:FINESS": "750013245",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3138571444,
    x: 48.8529909,
    y: 2.3348298,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Faldini",
      "ref:FR:FINESS": "750012643",
    },
  },
  {
    type: "node",
    id: 3138571445,
    x: 48.854802,
    y: 2.3362572,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Zgorski Annie",
      "ref:FR:FINESS": "750012742",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3138571446,
    x: 48.8544717,
    y: 2.3370259,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Seine",
      "ref:FR:FINESS": "750013260",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3138571447,
    x: 48.8555653,
    y: 2.3366336,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Institut",
      "ref:FR:FINESS": "750013211",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3138573268,
    x: 48.8581073,
    y: 2.328377,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bac-Verneuil",
      "opening_hours:covid19": "Mo-Sa 09:30-19:30",
      "ref:FR:FINESS": "750013476",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3142574320,
    x: 48.8463873,
    y: 2.4205294,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Vincennes Saint-Mandé",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      "ref:FR:FINESS": "940016264",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3143641957,
    x: 48.7932056,
    y: 2.1484462,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "68",
      "addr:postcode": "78000",
      "addr:street": "Rue Albert Sarraut",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Floch",
      phone: "+33 1 39 50 66 43",
      "ref:FR:FINESS": "780014841",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3145306493,
    x: 48.8581195,
    y: 2.3231884,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Solférino",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750014243",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3149342933,
    x: 48.8459273,
    y: 2.4218157,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Tourelle",
      "ref:FR:FINESS": "940013212",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3149378661,
    x: 48.8114032,
    y: 2.5105679,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940006570",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3149396534,
    x: 48.8137998,
    y: 2.5115379,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bourliaud",
      "ref:FR:FINESS": "940006604",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3149517318,
    x: 48.8705363,
    y: 2.3358361,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Choiseul",
      opening_hours: "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750008963",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3149582057,
    x: 48.8726106,
    y: 2.3907847,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "750036683",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3152335843,
    x: 48.8801401,
    y: 2.5444166,
    tags: {
      "addr:housenumber": "126",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Tong",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 30 49 02",
      "ref:FR:FINESS": "930008917",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacietong.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 3152358767,
    x: 48.8775663,
    y: 2.5529829,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie HAIK",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930008982",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3156121847,
    x: 48.7683697,
    y: 2.2509143,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Chaigneau",
      "ref:FR:FINESS": "920011277",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3157535577,
    x: 48.9624299,
    y: 1.8977879,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Bouafle",
      "ref:FR:FINESS": "780005310",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3160049520,
    x: 48.8769048,
    y: 2.4071896,
    tags: {
      "addr:housenumber": "168",
      "addr:street": "boulevard mortier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la porte des Lilas",
      opening_hours: "Mo-Su 08:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036386",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3161197295,
    x: 48.8384649,
    y: 2.6314907,
    tags: {
      "addr:city": "Lognes",
      "addr:housenumber": "1",
      "addr:postcode": "77185",
      "addr:street": "Esplanade des Droits de l'Homme",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours: "Mo-Sa 09:00-20:00; Tu 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 11 92 88",
      "ref:FR:FINESS": "770007680",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
      website: "https://pharmaciedelhoteldeville.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 3161888815,
    x: 48.7552831,
    y: 2.3712312,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      note: "quelque part dans le centre commercial mais pas sur la bretelle d'autoroute ...",
      "ref:FR:FINESS": "940020381",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3164679172,
    x: 48.8567808,
    y: 2.3972893,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "30",
      "addr:postcode": "75020",
      "addr:street": "Rue de Bagnolet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Monte-Cristo",
      opening_hours: "Mo-Sa 09:00-18:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035586",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3167460031,
    x: 48.8471624,
    y: 2.3107392,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Breteuil",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 47 34 14 85",
      "ref:FR:FINESS": "750025306",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3168734719,
    x: 48.8698537,
    y: 2.402572,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036055",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3168760149,
    x: 48.7836921,
    y: 2.1701484,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "SNC Pharmacie Mozart",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014577",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3169075464,
    x: 48.7771288,
    y: 2.2559403,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "920018942",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3173395874,
    x: 48.607414,
    y: 2.4683479,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "24",
      "addr:postcode": "91100",
      "addr:street": "Boulevard Georges Michel",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 96 95 40",
      "contact:phone": "+33 1 64 96 81 17",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Corbeil Centre",
      "ref:FR:FINESS": "910006782",
      since: "2007-06-01",
    },
  },
  {
    type: "node",
    id: 3174514195,
    x: 48.8821064,
    y: 2.2407956,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Guyonnaud",
      "ref:FR:FINESS": "920019247",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3175603387,
    x: 48.8744888,
    y: 2.4155695,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930005483",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3175606039,
    x: 48.8890648,
    y: 2.2315383,
    tags: {
      "addr:city": "Puteaux",
      "addr:housenumber": "1",
      "addr:postcode": "92800",
      "addr:street": "Rue Nélaton",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaciedesbouvets@hotmail.com",
      name: "Pharmacie des Bouvets",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      phone: "+33 1 47 75 02 42",
      "ref:FR:FINESS": "920019395",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3176697773,
    x: 48.8475959,
    y: 2.3184518,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Paris",
      "addr:housenumber": "76",
      "addr:postcode": "75007",
      "addr:street": "Rue de Sèvres",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacy Lafragette",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750014276",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3181230118,
    x: 48.8558279,
    y: 2.3750061,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie centrale Roquette",
      opening_hours: "Mo-Sa 08:00-21:00",
      "ref:FR:FINESS": "750019911",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3183544988,
    x: 48.8677009,
    y: 2.3819329,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      noname: "yes",
      opening_hours: "Mo-Fr 09:00-20:00; Su 09:00-13:00,15:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018541",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3183544989,
    x: 48.8691626,
    y: 2.3800109,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Couronnes",
      "opening_hours:covid19": "Mo-Sa 08:30-19:30",
      "ref:FR:FINESS": "750018574",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3188270361,
    x: 48.6980684,
    y: 2.1849684,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Orsay",
      "contact:district": "Essonne",
      "contact:fax": "+33 1 69 86 17 82",
      "contact:housenumber": "17",
      "contact:phone": "+33 1 69 28 40 20",
      "contact:postcode": "91400",
      "contact:street": "Boulevard Dubreuil",
      "delivery:covid19": "yes",
      "description:covid19": "Livraison : contacter pour confirmer",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Corinne Roulot",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-19:30; Sa 09:00-12:30,14:30-19:15",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:00-19:30; Sa 09:00-12:30,14:30-19:15",
      "ref:FR:FINESS": "910011360",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
      start_date: "1996-01-29",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3188469318,
    x: 49.160283,
    y: 1.9348403,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Chars",
      "ref:FR:FINESS": "950006452",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3190569345,
    x: 48.8596611,
    y: 2.3868012,
    tags: {
      amenity: "pharmacy",
      "contact:email": "pharmaciedelafolieregnault@gmail.com",
      "contact:phone": "+33143790529",
      delivery: "yes",
      dispensing: "yes",
      name: "Pharmacie de la Folie-Regnault",
      open: "yes",
      opening_hours: "08:30-20:00",
      "opening_hours:covid19": "09:00-19:00",
      "ref:FR:FINESS": "750020687",
      source: "survey;Celtipharm - 10/2014",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3190569350,
    x: 48.8587152,
    y: 2.3848271,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie TRAN",
      opening_hours: "closed",
      "ref:FR:FINESS": "750020034",
      source: "survey;Celtipharm - 10/2014",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3193694647,
    x: 48.8515574,
    y: 2.4003677,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "750035420",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des femmes - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3194271166,
    x: 48.8761171,
    y: 2.201606,
    tags: {
      "addr:city": "Rueil-Malmaison",
      "addr:housenumber": "1",
      "addr:postcode": "92500",
      "addr:street": "Rue Roger Jourdain",
      amenity: "pharmacy",
      "contact:phone": "+33 1 41 42 11 11",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Neau",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920016268",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 3201442793,
    x: 48.8377383,
    y: 2.3460608,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Villemont-Martinez",
      "ref:FR:FINESS": "750011603",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201446070,
    x: 48.8404823,
    y: 2.3461454,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Peret",
      "ref:FR:FINESS": "750010795",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201446463,
    x: 48.8428957,
    y: 2.3414897,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Jacques",
      "ref:FR:FINESS": "750011934",
      "ref:FR:SIRET": "47989815700017",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3201446464,
    x: 48.8425203,
    y: 2.3384674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Luxembourg",
      "ref:FR:FINESS": "750012122",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201446465,
    x: 48.8406994,
    y: 2.3405872,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Val de Grace",
      opening_hours:
        "Tu-Fr 09:00-19:30, Sa 09:00-13:00,14:30-19:00, Mo 14:00-19:30",
      "ref:FR:FINESS": "750011967",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3201450772,
    x: 48.8406428,
    y: 2.3343655,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 26 51 07",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Notre Dame des Champs",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750012890",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201450773,
    x: 48.8391692,
    y: 2.3393518,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Maternité",
      "ref:FR:FINESS": "750011652",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-04-20",
    },
  },
  {
    type: "node",
    id: 3201450774,
    x: 48.8414031,
    y: 2.3294036,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Dome",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024804",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201451724,
    x: 48.8440602,
    y: 2.3292343,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Juillet",
      "ref:FR:FINESS": "750012965",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201451925,
    x: 48.846865,
    y: 2.3299291,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dreyfus-Lepère",
      "ref:FR:FINESS": "750012601",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201451926,
    x: 48.8480547,
    y: 2.3306456,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Palais",
      "ref:FR:FINESS": "750013393",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201459100,
    x: 48.8488277,
    y: 2.2880032,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Théâtre",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750027054",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201483092,
    x: 48.8583183,
    y: 2.2741241,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ponsard",
      "ref:FR:FINESS": "750027955",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201483093,
    x: 48.858012,
    y: 2.2739092,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Muette",
      opening_hours: "Mo-Fr 08:15-20:15; Sa 09:00-20:00",
      "ref:FR:FINESS": "750027971",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3201484862,
    x: 48.8553404,
    y: 2.2697854,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bailly",
      "ref:FR:FINESS": "750027906",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201491801,
    x: 48.8579878,
    y: 2.2806416,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale de Passy",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:30",
      "ref:FR:FINESS": "750029423",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3201498301,
    x: 48.8584677,
    y: 2.283688,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Franklin",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:30-19:30",
      phone: "+33 1 42 88 22 32",
      "ref:FR:FINESS": "750028102",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3201498302,
    x: 48.865513,
    y: 2.2866451,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Spinhirny",
      "ref:FR:FINESS": "750029167",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201498303,
    x: 48.8668133,
    y: 2.2839901,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Belles Feuilles",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 47 27 14 72",
      "ref:FR:FINESS": "750029183",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3201500164,
    x: 48.8707344,
    y: 2.2916096,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Dome",
      "ref:FR:FINESS": "750028771",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3204283855,
    x: 48.7979159,
    y: 2.3993509,
    tags: {
      "addr:housenumber": "2",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Brijawi",
      phone: "+33 1 46 80 77 89",
      "ref:FR:FINESS": "940016546",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3205822587,
    x: 48.8625852,
    y: 2.3538494,
    tags: {
      amenity: "pharmacy",
      name: "Inter Para",
    },
  },
  {
    type: "node",
    id: 3207212041,
    x: 49.0410944,
    y: 2.1136127,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "950011940",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3210442437,
    x: 48.863653,
    y: 2.3667474,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+331 47 00 24 71",
      dispensing: "yes",
      name: "Pharmacie du Cirque d'Hiver",
      opening_hours: "Mo 15:00-20:00; Tu-Fr 10:00-20:00; Sa 10:00-19:00",
      "ref:FR:FINESS": "750020224",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3210710710,
    x: 48.8866695,
    y: 2.2488194,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bellini",
      "ref:FR:FINESS": "920019163",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3211066236,
    x: 48.8218986,
    y: 2.293414,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Bleuzen",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920021367",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3211439483,
    x: 48.8492511,
    y: 2.3890811,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      noname: "yes",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-19:00; Sa 10:00-13:00,14:00-19:00",
      "ref:FR:FINESS": "750021453",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3212808920,
    x: 48.9257499,
    y: 2.4303213,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dutremblay",
      "ref:FR:FINESS": "930008156",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3214511005,
    x: 48.8737268,
    y: 2.3586272,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie Ostrom",
      "ref:FR:FINESS": "750018145",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 3217139448,
    x: 48.4330477,
    y: 2.1550438,
    tags: {
      "addr:city": "Étampes",
      "addr:housenumber": "89",
      "addr:postcode": "91150",
      "addr:street": "Rue Saint-Jacques",
      alt_name: "Pharmacie Jacques et Mouline",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 94 62 72",
      "contact:phone": "+33 1 64 94 64 45",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Elixir",
      "ref:FR:FINESS": "910008150",
      since: "1943-12-27",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2010",
    },
  },
  {
    type: "node",
    id: 3217139449,
    x: 48.4314051,
    y: 2.1506728,
    tags: {
      "addr:city": "Étampes",
      "addr:housenumber": "3 ter",
      "addr:postcode": "91150",
      "addr:street": "Rue Neuve Saint-Gilles",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 94 75 92",
      "contact:phone": "+33 1 60 80 18 22",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Paytra",
      "ref:FR:FINESS": "910008036",
      since: "1988-01-28",
    },
  },
  {
    type: "node",
    id: 3217709866,
    x: 48.8682556,
    y: 2.4180358,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930005582",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3217857416,
    x: 48.4352987,
    y: 2.1460536,
    tags: {
      "addr:city": "Étampes",
      "addr:postcode": "91150",
      "addr:street": "Avenue Geoffroy Saint-Hilaire",
      alt_name: "Pharmacie Couturier",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 94 13 33",
      "contact:phone": "+33164941333",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Guinette",
      "ref:FR:FINESS": "910008051",
      since: "1984-09-03",
    },
  },
  {
    type: "node",
    id: 3220764312,
    x: 48.8834039,
    y: 2.2596466,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-James",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "920018694",
      source: "survey;Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3221961350,
    x: 48.8847961,
    y: 2.2616182,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Manne",
      "ref:FR:FINESS": "920018447",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221967851,
    x: 48.8835563,
    y: 2.2630448,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Yaïche",
      "ref:FR:FINESS": "920018462",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221984261,
    x: 48.8846741,
    y: 2.2671128,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Groussier",
      "ref:FR:FINESS": "920018215",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221984262,
    x: 48.8848894,
    y: 2.2682746,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Haggiac",
      "ref:FR:FINESS": "920018827",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221986333,
    x: 48.8852288,
    y: 2.266425,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Buffard",
      opening_hours:
        "Tu-Fr 08:30-20:00, Mo 09:00-20:00, Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "920018777",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221986334,
    x: 48.8836699,
    y: 2.2779864,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Michelis",
      "ref:FR:FINESS": "920018660",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3221986883,
    x: 48.8907545,
    y: 2.2742805,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Chézy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920018561",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3223319266,
    x: 48.8444532,
    y: 2.4107855,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021057",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3223959763,
    x: 48.8443464,
    y: 2.406966,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharm O'naturel",
      "ref:FR:FINESS": "750022154",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3227023852,
    x: 48.8712692,
    y: 2.3763039,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La confiance",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750019762",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3230176162,
    x: 48.8549878,
    y: 2.3244237,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bac Grenelle",
      "ref:FR:FINESS": "750013815",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3230176163,
    x: 48.8555367,
    y: 2.3250127,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bac St. Germain",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 10:00-19:00",
      "ref:FR:FINESS": "750013526",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3231019961,
    x: 48.7284825,
    y: 2.2851087,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "12",
      "addr:postcode": "91300",
      "addr:street": "Avenue Nationale",
      alt_name: "Selarl Pharmacie Huynh Dang",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 47 07 89",
      "contact:phone": "+33 1 60 11 34 74",
      dispensing: "yes",
      name: "Pharmacie Huynh Dang",
      opening_hours:
        "Mo 14:00-19:00 ;Tu-Fr 09:00-13:00,14:30-19:30;Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19":
        "Mo 14:00-19:00;Tu-Fr 09:00-12:30,14:30-19:00;Sa 09:00-12:30",
      "ref:FR:FINESS": "910010446",
      since: "1977-10-20",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3231581752,
    x: 48.825049,
    y: 2.2593663,
    tags: {
      "addr:city": "Issy-les-Moulineaux",
      "addr:country": "FR",
      "addr:housenumber": "10",
      "addr:postcode": "92130",
      "addr:street": "Cours de l'Ancienne Boulangerie",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharma-bordsdeseine@hotmail.fr",
      fax: "+33 146385560",
      name: "Pharmacie des Bords de Seine",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 10:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33146385556",
      "ref:FR:FINESS": "920009099",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3231694352,
    x: 48.8861427,
    y: 2.3706463,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de Stalingrad",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035321",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3234569477,
    x: 49.0527623,
    y: 2.0171869,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bontemps",
      "opening_hours:covid19": "Mo-Fr 08:45-19:45; Sa 09:00-18:30",
      "ref:FR:FINESS": "950006270",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3234698291,
    x: 49.0621836,
    y: 2.0511749,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vauvarois",
      "ref:FR:FINESS": "950010694",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235199768,
    x: 48.93089,
    y: 2.0397669,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "34",
      "addr:postcode": "78300",
      "addr:street": "Rue du Général-de-Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Mathieu",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30, Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "780012241",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235226655,
    x: 48.9289285,
    y: 2.0437577,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "99",
      "addr:postcode": "78300",
      "addr:street": "Rue du Général-de-Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de Poissy",
      phone: "+33 1 39 65 03 68",
      "ref:FR:FINESS": "780012290",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235235869,
    x: 48.9300118,
    y: 2.0415771,
    tags: {
      "addr:city": "Poissy",
      "addr:postcode": "78300",
      "addr:street": "Rue du Général-de-Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "780012274",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235245570,
    x: 48.9325356,
    y: 2.0411268,
    tags: {
      "addr:city": "Poissy",
      "addr:postcode": "78300",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "780012324",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235255370,
    x: 48.9291884,
    y: 2.0492074,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "43",
      "addr:postcode": "78300",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Le Clos d'Arcy",
      phone: "+33139650594",
      "ref:FR:FINESS": "780012365",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235268969,
    x: 48.9341973,
    y: 2.0522111,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "55",
      "addr:postcode": "78300",
      "addr:street": "Boulevard Robespierre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Duclos Grenet",
      "ref:FR:FINESS": "780012431",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235282983,
    x: 48.9210008,
    y: 2.0330841,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "50",
      "addr:postcode": "78300",
      "addr:street": "Avenue de la Maladrerie",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Juillard",
      "ref:FR:FINESS": "780012340",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3235294520,
    x: 48.9217493,
    y: 2.0287645,
    tags: {
      "addr:city": "Poissy",
      "addr:housenumber": "26",
      "addr:postcode": "78300",
      "addr:street": "Place Racine",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Racine",
      operator: "Pascal Geffray",
      "ref:FR:FINESS": "780012415",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3238915606,
    x: 48.9466148,
    y: 2.5700737,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Verheyde Tran",
      "ref:FR:FINESS": "930016035",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3238927868,
    x: 48.9457367,
    y: 2.5645284,
    tags: {
      alt_name: "Pharmacie DARGENT",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vert-Galant",
      "ref:FR:FINESS": "930016670",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3238952556,
    x: 48.9548202,
    y: 2.5373082,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Petits Ponts",
      "ref:FR:FINESS": "930016837",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3238953593,
    x: 48.951846,
    y: 2.5510938,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fontaine Mallet",
      "ref:FR:FINESS": "930016654",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3239107579,
    x: 48.9575928,
    y: 2.5478628,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ghizlan",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930016720",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3241377461,
    x: 49.037345,
    y: 2.1081602,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Baldi",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950011825",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3243218811,
    x: 48.8825882,
    y: 2.4087683,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tchamba",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930013321",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3243694061,
    x: 48.9656317,
    y: 2.0675611,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 39 11 19 26",
      "contact:website": "https://www.pharmacie-haziza-gozlan.fr/",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Gozlan Haziza",
      opening_hours: "Mo-Sa 09:00-19:30",
      operator: "Well&Well",
      "ref:FR:FINESS": "780004677",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3245169452,
    x: 48.7838512,
    y: 1.9566,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Village",
      note: "1 chemin des vignes",
      "ref:FR:FINESS": "780007530",
      source: "Celtipharm - 10/2014",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3245223371,
    x: 48.7682335,
    y: 1.9432195,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Berget",
      "ref:FR:FINESS": "780010427",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3245223372,
    x: 48.767534,
    y: 1.952891,
    tags: {
      "addr:city": "Élancourt",
      "addr:housenumber": "6",
      "addr:postcode": "78990",
      "addr:street": "Rue Danton",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des Petits Prés",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "780007415",
      source: "Celtipharm - 10/2014;local knowledge",
    },
  },
  {
    type: "node",
    id: 3245223373,
    x: 48.7718992,
    y: 1.942563,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie la Villeparc",
      "ref:FR:FINESS": "780010294",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3246196691,
    x: 49.0659753,
    y: 2.089218,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Briel",
      "ref:FR:FINESS": "950010660",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3246198797,
    x: 49.0600611,
    y: 2.0617884,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Guiot",
      "ref:FR:FINESS": "950010645",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3246258820,
    x: 48.7960411,
    y: 2.3021354,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dampierre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009164",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3247052676,
    x: 48.8435945,
    y: 2.2827053,
    tags: {
      "addr:housenumber": "55 bis",
      "addr:postcode": "75015",
      "addr:street": "Rue de la Convention",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Convention Saint-Charles",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025587",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3248268171,
    x: 48.9428489,
    y: 1.9954844,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Fontaine",
      "ref:FR:FINESS": "780015962",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3248387278,
    x: 48.9507321,
    y: 2.0686546,
    tags: {
      "addr:city": "Achères",
      "addr:housenumber": "11",
      "addr:postcode": "78260",
      "addr:street": "Place du 14 Juillet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pillonnet",
      opening_hours:
        "Tu-Fr 09:00-12:30,14:30-19:15; Sa 09:30-12:30; Mo 10:00-12:30,14:30-19:15",
      operator: "Jérome Pillonnet",
      phone: "+33965295153",
      "ref:FR:FINESS": "780004743",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3248389901,
    x: 48.9639538,
    y: 2.0731293,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Zerrouk",
      "ref:FR:FINESS": "780004644",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3248434163,
    x: 48.9588637,
    y: 2.0632495,
    tags: {
      "addr:city": "Achères",
      "addr:country": "FR",
      "addr:housenumber": "59",
      "addr:postcode": "78260",
      "addr:street": "Avenue Lénine",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Moutet",
      opening_hours: "Mo-Fr 09:00-12:00, 14:30-19:15; Sa 09:00-12:00",
      "ref:FR:FINESS": "780004693",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3250238616,
    x: 48.7009265,
    y: 2.481545,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "61",
      "addr:postcode": "91330",
      "addr:street": "Avenue Pierre Brossolette",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 20 13",
      "contact:phone": "+33 1 69 48 90 19",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Catherine Gassier",
      "ref:FR:FINESS": "910014695",
      since: "1995-03-27",
    },
  },
  {
    type: "node",
    id: 3253931998,
    x: 48.8862241,
    y: 2.3946584,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place Cochet",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035107",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3257358047,
    x: 48.8231401,
    y: 1.9539048,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Trang",
      "ref:FR:FINESS": "780011961",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257361288,
    x: 48.8240689,
    y: 1.964493,
    tags: {
      "addr:city": "Plaisir",
      "addr:housenumber": "85",
      "addr:postcode": "78370",
      "addr:street": "Place Madeleine Brès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Aqueduc",
      "ref:FR:FINESS": "780012126",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257434581,
    x: 48.8407139,
    y: 2.1234686,
    tags: {
      "addr:city": "La Celle-Saint-Cloud",
      "addr:housenumber": "12",
      "addr:postcode": "78170",
      "addr:street": "Place du Comte de Bendern",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Saint-Françoise",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      phone: "+33 1 39 69 95 66",
      "ref:FR:FINESS": "780005740",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257435397,
    x: 48.8171249,
    y: 2.1270509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Blanc",
      "ref:FR:FINESS": "780006441",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257435398,
    x: 48.8278317,
    y: 2.1258543,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Charbit",
      "ref:FR:FINESS": "780006540",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257435399,
    x: 48.8182408,
    y: 2.1313255,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Julien",
      "ref:FR:FINESS": "780006615",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257435400,
    x: 48.8197707,
    y: 2.137801,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Matar",
      "ref:FR:FINESS": "780006565",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257435401,
    x: 48.8249772,
    y: 2.125999,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Piscine",
      "ref:FR:FINESS": "780006524",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257440742,
    x: 48.8392463,
    y: 2.1860071,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Baradat",
      opening_hours: "Mo-Sa 09:00-13:00,14:00-20:00;PH closed",
      "ref:FR:FINESS": "920014396",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3257440743,
    x: 48.8566143,
    y: 2.1935141,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Cardonnet",
      "opening_hours:covid19": "Mo-Fr 09:00-13:00,14:30-19:45; Sa 09:00-13:00",
      "ref:FR:FINESS": "920019593",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 3257440744,
    x: 48.8422999,
    y: 2.2075514,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fraenkel",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "920020211",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257440745,
    x: 48.8437138,
    y: 2.1916926,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Miet Truong",
      "ref:FR:FINESS": "920014347",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257442117,
    x: 48.863041,
    y: 2.2025549,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Théatre",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00",
      phone: "+33 1 45 06 27 38",
      "ref:FR:FINESS": "920020807",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3257444789,
    x: 48.8625609,
    y: 2.2108352,
    tags: {
      amenity: "pharmacy",
      "description:covid19": "commande des ordonnances par email",
      dispensing: "yes",
      email: "pharmacie.prioux@gmail.com",
      name: "Pharmacie Prioux",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      phone: "+33 1 45 06 18 29",
      "ref:FR:FINESS": "920021003",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3264121275,
    x: 48.9386142,
    y: 2.0560417,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Clermont",
      "ref:FR:FINESS": "780012464",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3264173072,
    x: 48.7667507,
    y: 2.0787015,
    tags: {
      "addr:housenumber": "40",
      "addr:street": "Rue Georges Haussmann",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Villaroy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780008314",
      source:
        "extraction vectorielle v1 cadastre-dgi-fr source : Direction Générale des Impôts - Cadas. Mise à jour : 2010;Celtipharm - 10/2014",
      "survey:date": "2017-07-17",
      wall: "no",
    },
  },
  {
    type: "node",
    id: 3265140289,
    x: 48.9116751,
    y: 2.2232822,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôpital",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      operator: "N. Nichabouri",
      phone: "+33147823863",
      "ref:FR:FINESS": "920018140",
      source: "survey 2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3265925634,
    x: 48.8519421,
    y: 2.3567772,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale - le Saint-Louis",
      "opening_hours:covid19": "Mo-Sa 10:00-18:00",
      "ref:FR:FINESS": "750010175",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmaciedelilesaintlouis.fr",
    },
  },
  {
    type: "node",
    id: 3265925635,
    x: 48.8498808,
    y: 2.3501204,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Koch",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750011801",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3265933188,
    x: 48.8440473,
    y: 2.352559,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Drumlewicz",
      "ref:FR:FINESS": "750011413",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266779942,
    x: 48.8524286,
    y: 2.342047,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Delpech",
      opening_hours: "Mo-Fr 10:00-19:30; Sa 10:00-13:30",
      "ref:FR:FINESS": "750012536",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266781115,
    x: 48.8457261,
    y: 2.3428618,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Panthéon",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 43 54 21 11",
      "ref:FR:FINESS": "750011884",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3266788629,
    x: 48.8550528,
    y: 2.3260617,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bac Raspail",
      "ref:FR:FINESS": "750014094",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266788630,
    x: 48.8539071,
    y: 2.3387894,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Coppola",
      "ref:FR:FINESS": "750012585",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3266788631,
    x: 48.8515303,
    y: 2.336934,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Devos",
      "ref:FR:FINESS": "750013195",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266788632,
    x: 48.8482112,
    y: 2.3204309,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sèvres-Vaneau",
      "ref:FR:FINESS": "750013344",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3266788633,
    x: 48.8518375,
    y: 2.3258083,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Babylone",
      "ref:FR:FINESS": "750013443",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266791498,
    x: 48.8450837,
    y: 2.3195999,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Necker",
      "ref:FR:FINESS": "750026767",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266819785,
    x: 48.8517918,
    y: 2.3188642,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Andarelli",
      "ref:FR:FINESS": "750014375",
      source: "Celtipharm - 10/2014;survey",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3266819786,
    x: 48.8576298,
    y: 2.317667,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bourgogne",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00",
      "ref:FR:FINESS": "750013690",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266819787,
    x: 48.8528934,
    y: 2.3192935,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dron",
      "ref:FR:FINESS": "750014342",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3266829349,
    x: 48.8595893,
    y: 2.3064959,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Aboulker",
      "ref:FR:FINESS": "750014144",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266829350,
    x: 48.857814,
    y: 2.3074922,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cotinat",
      "ref:FR:FINESS": "750013831",
      source: "Celtipharm - 10/2014",
      wikidata: "Q22924680",
    },
  },
  {
    type: "node",
    id: 3266829351,
    x: 48.8571081,
    y: 2.3064008,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Faugeras",
      "ref:FR:FINESS": "750013765",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266829352,
    x: 48.8597581,
    y: 2.3089643,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "75",
      "addr:postcode": "75007",
      "addr:street": "Rue Saint-Dominique",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Comète",
      "ref:FR:FINESS": "750014110",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833091,
    x: 48.8583642,
    y: 2.3018327,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75007",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Péron",
      "ref:FR:FINESS": "750014193",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833092,
    x: 48.8594152,
    y: 2.2980801,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Tour Eiffel",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750014045",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833093,
    x: 48.8579304,
    y: 2.3006577,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Champs de Mars",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:30-20:00",
      "ref:FR:FINESS": "750014227",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833151,
    x: 48.8524763,
    y: 2.3434193,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bader",
      "ref:FR:FINESS": "750013146",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833976,
    x: 48.8542822,
    y: 2.3067078,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'École Militaire",
      "ref:FR:FINESS": "750014425",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266833977,
    x: 48.8561384,
    y: 2.307876,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Septième",
      "ref:FR:FINESS": "750013914",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266840398,
    x: 48.8485548,
    y: 2.3029374,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bénichou",
      "ref:FR:FINESS": "750026734",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266841578,
    x: 48.8465112,
    y: 2.3088641,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Joussain",
      opening_hours: "Mo-Fr 08:30-20:45; Sa 08:30-20:30",
      "ref:FR:FINESS": "750027070",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266842317,
    x: 48.8468677,
    y: 2.3002919,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cambronne - Croix Nivert",
      opening_hours: "Mo-Sa 08:00-20:00",
      "ref:FR:FINESS": "750027435",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266842318,
    x: 48.8456788,
    y: 2.2999547,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Villa Croix Nivert",
      opening_hours: "Mo-Fr 08:45-19:30; Sa 08:45-13:30, 14:00-19:00",
      "ref:FR:FINESS": "750025785",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3266843622,
    x: 48.8497643,
    y: 2.2993321,
    tags: {
      "addr:street": "Avenue de la Motte-Picquet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fréva",
      "ref:FR:FINESS": "750026304",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3266843736,
    x: 48.8528662,
    y: 2.2984786,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Avenue",
      opening_hours: "Mo-Sa 08:00-20:30",
      "ref:FR:FINESS": "750014326",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3266854696,
    x: 48.8503953,
    y: 2.2946035,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 77 Picquet-Grenelle",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750026221",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267462167,
    x: 48.8790537,
    y: 2.3017209,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Courcelles",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-14:00,15:00-20:00",
      "ref:FR:FINESS": "750029985",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 3267547015,
    x: 48.8415387,
    y: 2.2886727,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Blondin",
      "ref:FR:FINESS": "750026056",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267547016,
    x: 48.8407621,
    y: 2.2874322,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Boucicaut",
      "ref:FR:FINESS": "750026072",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267547017,
    x: 48.8464029,
    y: 2.2952154,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "24/7",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 79 75 01",
      "ref:FR:FINESS": "750025470",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3267547018,
    x: 48.8403852,
    y: 2.290018,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Convention Croix Nivert",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-18:30",
      "ref:FR:FINESS": "750025637",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267547019,
    x: 48.8467692,
    y: 2.2928941,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Darmon",
      opening_hours: "Mo-Fr 08:00-19:00, Sa 09:00-19:00",
      "ref:FR:FINESS": "750025884",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267547020,
    x: 48.8420447,
    y: 2.2905797,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Félix Faure",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750026023",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267547021,
    x: 48.8418761,
    y: 2.2864688,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-14",
      dispensing: "yes",
      name: "Nouveau Boucicaut",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "750025603",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267572730,
    x: 48.8444593,
    y: 2.2838616,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Grande Pharmacie du XVème",
      "opening_hours:covid19": "open",
      operator: "Myer Laurent",
      "ref:FR:FINESS": "750026957",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267572731,
    x: 48.8443378,
    y: 2.2833554,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Charles",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026973",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604876,
    x: 48.8342538,
    y: 2.3285289,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Daguerre",
      opening_hours: "Mo-Sa 08:30-20:30, Su,PH 08:30-21:00",
      "ref:FR:FINESS": "750024036",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604877,
    x: 48.8426808,
    y: 2.3120065,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Buffon",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:30-13:00",
      "ref:FR:FINESS": "750027104",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604878,
    x: 48.8309695,
    y: 2.3298918,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'avenue",
      "ref:FR:FINESS": "750024317",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-03-13",
    },
  },
  {
    type: "node",
    id: 3267604879,
    x: 48.8360215,
    y: 2.2930618,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Desnouettes",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750025801",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604881,
    x: 48.8378457,
    y: 2.2947209,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Corre",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-13:30,14:30-20:00",
      "ref:FR:FINESS": "750025652",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604883,
    x: 48.8438998,
    y: 2.3065923,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "60",
      "addr:postcode": "75015",
      "addr:street": "Rue Lecourbe",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lecrubier",
      "ref:FR:FINESS": "750026387",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604885,
    x: 48.8390544,
    y: 2.3225698,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Parmast",
      "ref:FR:FINESS": "750024457",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3267604886,
    x: 48.8295474,
    y: 2.3300309,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Coin de la Rue",
      "ref:FR:FINESS": "750024606",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-03-14",
    },
  },
  {
    type: "node",
    id: 3267604887,
    x: 48.8414668,
    y: 2.3075456,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ryziger",
      opening_hours: "Mo-Fr 08:30-19:45, Sa 09:00-19:00",
      "ref:FR:FINESS": "750027120",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604888,
    x: 48.8409279,
    y: 2.296672,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Lambert",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750026486",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604889,
    x: 48.8362604,
    y: 2.2945954,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Schafler",
      opening_hours: "Tu-Sa 09:00-19:45, Mo 14:00-19:45",
      "ref:FR:FINESS": "750027286",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604890,
    x: 48.8384853,
    y: 2.2991128,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vaugirard",
      opening_hours: "Mo-Sa 09:00-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750027203",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604891,
    x: 48.835464,
    y: 2.3030539,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vouillé Convention",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750027351",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604893,
    x: 48.8324279,
    y: 2.3302969,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "750024200",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-03-18",
    },
  },
  {
    type: "node",
    id: 3267604894,
    x: 48.8365001,
    y: 2.2995243,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Su 09:00-19:30; Mo-Fr 08:30-20:30; Sa 08:30-20:00",
      phone: "+33148286792",
      "ref:FR:FINESS": "750025686",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267604895,
    x: 48.8447698,
    y: 2.289889,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square Violet",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750025967",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267614676,
    x: 48.8361011,
    y: 2.3194903,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Well & Well",
      opening_hours: "Mo 10:00-19:45; Tu-Sa 09:00-19:45",
      "ref:FR:FINESS": "750024366",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267614678,
    x: 48.8309683,
    y: 2.3238141,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Plantes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023822",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 3267614679,
    x: 48.8334909,
    y: 2.3174992,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pernety",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024903",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267614680,
    x: 48.8349651,
    y: 2.3202192,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Temstet Ouizman",
      "ref:FR:FINESS": "750024887",
      source: "survey; Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267615923,
    x: 48.8404648,
    y: 2.3176439,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "8 rue duchef de la ville",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "750022634",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267622365,
    x: 48.8458269,
    y: 2.3260465,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Slakmon",
      "ref:FR:FINESS": "750013047",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267634068,
    x: 48.8465962,
    y: 2.327388,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Trégouet",
      "ref:FR:FINESS": "750012924",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267812303,
    x: 48.8333262,
    y: 2.3146227,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benhamou",
      "ref:FR:FINESS": "750023632",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267812304,
    x: 48.8306221,
    y: 2.3181154,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Didot Alésia",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750023723",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267812305,
    x: 48.8288,
    y: 2.3079462,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Renoir",
      "ref:FR:FINESS": "750024986",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-09-12",
    },
  },
  {
    type: "node",
    id: 3267812306,
    x: 48.8283718,
    y: 2.3248315,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rive-Gauche",
      "ref:FR:FINESS": "750023673",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267812307,
    x: 48.829355,
    y: 2.3173059,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "66",
      "addr:postcode": "75014",
      "addr:street": "Rue Didot",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Village Didot",
      "ref:FR:FINESS": "750024150",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3267812308,
    x: 48.8286172,
    y: 2.3165992,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Pen Ar c Hra",
      "ref:FR:FINESS": "750024184",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267812309,
    x: 48.832793,
    y: 2.3037268,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Sa 08:30-13:00,14:00-19:30; Su off",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750025272",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267815086,
    x: 48.8165826,
    y: 2.334908,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Patrick Fabre",
      opening_hours: "Mo 14:00-19:30, Tu-Fr 08:30-19:30, Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009707",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267824151,
    x: 48.8355156,
    y: 2.3316471,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Raspail 14e",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024820",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267824268,
    x: 48.8314371,
    y: 2.343798,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-06-03",
      dispensing: "yes",
      name: "Pharmacie de la Glacière",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750022402",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-06-29",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3267837786,
    x: 48.8391333,
    y: 2.3498059,
    tags: {
      alt_name: "Pharmacie Zahalka",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Médard",
      "ref:FR:FINESS": "750011553",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
    },
  },
  {
    type: "node",
    id: 3267837787,
    x: 48.8415439,
    y: 2.3497318,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du 5e",
      opening_hours: "Mo 10:00-20:00, Tu-Sa 09:30-20:00",
      "ref:FR:FINESS": "750011504",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267845006,
    x: 48.8398678,
    y: 2.3566388,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Olivier Césaréo",
      "ref:FR:FINESS": "750011025",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267865707,
    x: 48.836528,
    y: 2.3502387,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Arago",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750022303",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267865708,
    x: 48.8370856,
    y: 2.3509326,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Leroy",
      opening_hours: "Mo-Fr 09:00-19:00",
      "opening_hours:covid19": "Mo-Fr 09:00-19:00",
      "ref:FR:FINESS": "750011587",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3267879729,
    x: 48.8243871,
    y: 2.3574705,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Bœuf",
      "ref:FR:FINESS": "750023087",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3267879730,
    x: 48.8260836,
    y: 2.3528276,
    tags: {
      "addr:housenumber": "164",
      "addr:street": "Rue de Tolbiac",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Peupliers",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750022782",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2013;Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3267928746,
    x: 48.8214555,
    y: 2.3699509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ivry Massena",
      "ref:FR:FINESS": "750023384",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267928747,
    x: 48.8234438,
    y: 2.3659965,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Olympiades Massena",
      "ref:FR:FINESS": "750022717",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267950263,
    x: 48.8376543,
    y: 2.3732405,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Abbenhaim",
      "ref:FR:FINESS": "750022451",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267962760,
    x: 48.8409688,
    y: 2.2658163,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      "contact:phone": "+33 1 42 88 33 37",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Garigliano",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:30-13:00,15:00-19:30",
      "ref:FR:FINESS": "750028367",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267984359,
    x: 48.8481157,
    y: 2.2714017,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rémusat",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750028235",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3267984360,
    x: 48.8506879,
    y: 2.271648,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Fontaine Pharma",
      opening_hours: "Tu-Sa 09:00-20:00; Mo 14:00-20:00",
      phone: "+33 1 45 27 01 62",
      "ref:FR:FINESS": "750027823",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3267984661,
    x: 48.8493912,
    y: 2.2754031,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Versailles-Mirabeau",
      opening_hours: "Tu-Sa 09:00-19:30; Mo 14:00-19:30",
      "opening_hours:covid19": "Tu-Sa 09:00-19:30; Mo 14:00-19:30",
      "ref:FR:FINESS": "750028300",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268002428,
    x: 48.8663312,
    y: 2.2735664,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dalibard",
      "ref:FR:FINESS": "750028482",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268014552,
    x: 48.8782012,
    y: 2.2945395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 24",
      opening_hours: "Mo-Fr 09:00-19:45; Sa 09:00-19:30",
      phone: "+33 1 43 80 11 96",
      "ref:FR:FINESS": "750030884",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268014553,
    x: 48.8771861,
    y: 2.2925485,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Clay",
      "ref:FR:FINESS": "750029472",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268014554,
    x: 48.880663,
    y: 2.2922492,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lebon",
      "ref:FR:FINESS": "750030686",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3268014555,
    x: 48.8767367,
    y: 2.2948514,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mac Mahon",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:30-20:00",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00; Sa 09:30-20:00",
      phone: "+33 1 43 80 35 25",
      "ref:FR:FINESS": "750030850",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268024465,
    x: 48.8816635,
    y: 2.2921283,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 45 74 24 41",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bayen",
      opening_hours:
        "Mo 10:00-19:45; Tu-Fr 08:30-19:45; Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750029605",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciebayen.fr",
    },
  },
  {
    type: "node",
    id: 3268024466,
    x: 48.8841175,
    y: 2.2951026,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ly Cong-Kieu",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031122",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 3268024854,
    x: 48.881389,
    y: 2.3017616,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Dr Levy",
      opening_hours: "Mo-Th 08:30-21:00; Fr 08:30-19:00; Su 09:30-19:00",
      operator: "Maxi Pharma",
      "ref:FR:FINESS": "750029803",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268024855,
    x: 48.8813258,
    y: 2.2983571,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fourcroy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030322",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268024856,
    x: 48.8798448,
    y: 2.3021779,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Croix d'Or",
      "ref:FR:FINESS": "750030017",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268024857,
    x: 48.8823431,
    y: 2.2996679,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Plantes",
      "ref:FR:FINESS": "750030066",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268030034,
    x: 48.8786073,
    y: 2.2988738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lorraine",
      "ref:FR:FINESS": "750031601",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268040200,
    x: 48.8792696,
    y: 2.2976506,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du marché Poncelet",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-17:00",
      "ref:FR:FINESS": "750031254",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268058785,
    x: 48.8860631,
    y: 2.3037205,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bermot Elkeslassi",
      "ref:FR:FINESS": "750031866",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268058786,
    x: 48.883965,
    y: 2.3005761,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Berlant",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750031387",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268058787,
    x: 48.8727673,
    y: 2.2969966,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Derhy",
      "ref:FR:FINESS": "750014714",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268058788,
    x: 48.8741131,
    y: 2.3005904,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Friedland",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 10:00-18:00",
      phone: "+33 1 45 61 12 78",
      "ref:FR:FINESS": "750014995",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268058791,
    x: 48.8720895,
    y: 2.3135012,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Faubourg Saint-Honoré",
      "opening_hours:covid19": "Mo-Sa 10:00-19:00",
      "ref:FR:FINESS": "750014862",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3268058792,
    x: 48.8774923,
    y: 2.3059009,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc Monceau",
      "ref:FR:FINESS": "750014813",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268063417,
    x: 48.8859245,
    y: 2.3098377,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "description:covid19":
        "Au 2 mai : gel hydroalcoolique disponible si vous apportez votre flacon vide (n'ont pas de flacon). Masques, thermomètres, gants disponibles.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Briot",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 08:00-19:00",
      "ref:FR:FINESS": "750030520",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268063418,
    x: 48.8876908,
    y: 2.3098561,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lapiczak",
      "ref:FR:FINESS": "750031072",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268063419,
    x: 48.8878408,
    y: 2.3059757,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Péreire Wagram",
      "ref:FR:FINESS": "750031106",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084313,
    x: 48.8770027,
    y: 2.3152869,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Issoulie",
      "ref:FR:FINESS": "750015547",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084314,
    x: 48.8848843,
    y: 2.3157039,
    tags: {
      "access:covid19": "yes",
      "addr:housenumber": "48",
      "addr:street": "rue Legendre",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de La Croix d'Or",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      phone: "+33 147630692",
      "ref:FR:FINESS": "750030736",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084315,
    x: 48.8779735,
    y: 2.3156876,
    tags: {
      alt_name: "Pharmacie Dufour",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Malesherbes",
      "ref:FR:FINESS": "750015224",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084316,
    x: 48.8750834,
    y: 2.3159276,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Miromesnil",
      "ref:FR:FINESS": "750015331",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084317,
    x: 48.8755374,
    y: 2.3143555,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Homéopathique de Messine",
      "opening_hours:covid19": "Mo-Su,PH 00:00-23:55",
      "ref:FR:FINESS": "750015281",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084318,
    x: 48.8750147,
    y: 2.3190578,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rogé-Cavailles",
      opening_hours: "Mo-Fr 08:00-20:00; Sa,Su 09:00-19:30",
      "opening_hours:covid19": "Mo-Fr 08:00-20:00; Sa,Su 09:00-19:30",
      "ref:FR:FINESS": "750015026",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084319,
    x: 48.8761976,
    y: 2.3178307,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Augustin",
      "ref:FR:FINESS": "750015174",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268084320,
    x: 48.8871097,
    y: 2.3135302,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:30-13:00, 14:00-19:30",
      "ref:FR:FINESS": "750030504",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130876,
    x: 48.8711342,
    y: 2.3313636,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Opéra",
      opening_hours: "Mo-Th 08:00-21:00; Fr-Su 09:00-22:00",
      "ref:FR:FINESS": "750016974",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130877,
    x: 48.8718551,
    y: 2.3283293,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Caumartin",
      "ref:FR:FINESS": "750015760",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130878,
    x: 48.8738329,
    y: 2.3356364,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Haggiac",
      "ref:FR:FINESS": "750016214",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130879,
    x: 48.8732432,
    y: 2.3343795,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Harrane",
      "ref:FR:FINESS": "750016180",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130880,
    x: 48.8726025,
    y: 2.334294,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Haussmann-Opéra",
      opening_hours: "Mo-Fr 08:00-21:00; Sa 09:30-20:00",
      "ref:FR:FINESS": "750016131",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130881,
    x: 48.8677478,
    y: 2.3330021,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Opéra Casanova",
      "ref:FR:FINESS": "750008997",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130882,
    x: 48.8661454,
    y: 2.331113,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Honoré",
      "ref:FR:FINESS": "750008625",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130883,
    x: 48.8689573,
    y: 2.3392078,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Bourse",
      "ref:FR:FINESS": "750046468",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268130884,
    x: 48.8688057,
    y: 2.3297132,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Capucines",
      "ref:FR:FINESS": "750008930",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268133372,
    x: 48.861421,
    y: 2.3421399,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "115",
      "addr:postcode": "75001",
      "addr:street": "Rue Saint-Honoré",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Honoré",
      opening_hours: "Mo-Fr 08:00-21:00, Sa,Su 09:00-21:00",
      "opening_hours:covid19": "Mo-Fr 08:00-21:00, Sa,Su 09:00-21:00",
      phone: "+33145081587",
      "ref:FR:FINESS": "750008815",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3268302547,
    x: 48.8288432,
    y: 2.6440296,
    tags: {
      "addr:city": "Croissy-Beaubourg",
      "addr:postcode": "77183",
      "addr:street": "Rond-Point de Roccasecca",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Croissy-Beaubourg",
      opening_hours: "Mo-Fr 09:30-20:00; Sa 09:00-19:30",
      phone: "+33 1 60 06 61 45",
      "ref:FR:FINESS": "770005981",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960261,
    x: 48.87947,
    y: 2.3335264,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Berelet",
      "ref:FR:FINESS": "750015695",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960262,
    x: 48.8907224,
    y: 2.3257272,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bloch",
      opening_hours: "Mo-Sa 09:30-09:30",
      "ref:FR:FINESS": "750030256",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960263,
    x: 48.8869964,
    y: 2.3232146,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Burbot",
      opening_hours: "Mo 11:00-20:00; Tu-Fr 08:30-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030553",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-08-30",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3268960264,
    x: 48.8848786,
    y: 2.3269713,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 3 Quartiers",
      opening_hours: "Mo-Fr 09:00-20:00;Sa 09:00-13:00",
      "opening_hours:covid19": "same",
      operator: "Châtel Brigitte",
      phone: "+33 1 45 22 16 48",
      "ref:FR:FINESS": "750032302",
      source: "Celtipharm - 10/2014",
      "survey:date": "2017-07-04",
    },
  },
  {
    type: "node",
    id: 3268960265,
    x: 48.8910433,
    y: 2.3246815,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dauny",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030306",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960266,
    x: 48.8929864,
    y: 2.3268273,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Paris",
      "addr:housenumber": "73",
      "addr:postcode": "75017",
      "addr:street": "Rue Guy Môquet",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Guy Môquet",
      opening_hours: "Mo-Sa 08:45-20:00",
      "opening_hours:covid19": "Mo-Sa 08:45-20:00",
      "ref:FR:FINESS": "750030454",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960269,
    x: 48.8921733,
    y: 2.3179808,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Zaidi",
      "ref:FR:FINESS": "750029670",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960270,
    x: 48.8864597,
    y: 2.3278197,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hippodrome",
      "ref:FR:FINESS": "750032120",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3268960272,
    x: 48.8921366,
    y: 2.3273392,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "78",
      "addr:postcode": "75018",
      "addr:street": "Avenue de Saint-Ouen",
      amenity: "pharmacy",
      "contact:phone": "+33 1 46 27 61 96",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Les Alizés",
      opening_hours: "Mo-Su 09:45-20:00",
      "ref:FR:FINESS": "750033987",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000162,
    x: 48.8911368,
    y: 2.3401386,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie Marcadet",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750032955",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000163,
    x: 48.883486,
    y: 2.3321884,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de la Place Blanche",
      opening_hours: "Mo-Sa 09:00-21:00; Su,PH 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-21:00; Su,PH 09:00-20:00",
      "ref:FR:FINESS": "750015661",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000164,
    x: 48.8803813,
    y: 2.3401661,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie Masse",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      phone: "+33 1 48 78 33 14",
      "ref:FR:FINESS": "750016891",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000165,
    x: 48.8902021,
    y: 2.3362687,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Carette",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032807",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000166,
    x: 48.8788518,
    y: 2.3399394,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale Martyrs",
      opening_hours: "Mo-Fr 08:30-20:30, Sa,Su,PH 09:00-20:00",
      "ref:FR:FINESS": "750016396",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000168,
    x: 48.8908011,
    y: 2.3346412,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Darmon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032567",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000170,
    x: 48.8925368,
    y: 2.3433906,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gondard Casabianca",
      "ref:FR:FINESS": "750033417",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000171,
    x: 48.8890228,
    y: 2.3343651,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "43",
      "addr:postcode": "75018",
      "addr:street": "Rue Caulaincourt",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lichte",
      phone: "+33 1 46 06 50 97",
      "ref:FR:FINESS": "750032070",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000172,
    x: 48.8828427,
    y: 2.3365686,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lorenzo Charrier",
      opening_hours: "Mo-Sa 09:30-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032336",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000173,
    x: 48.8926144,
    y: 2.3452196,
    tags: {
      "addr:postcode": "75018",
      alt_name: "Pharmacie Poiriel",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jules Joffrin",
      "ref:FR:FINESS": "750032732",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000174,
    x: 48.8913982,
    y: 2.3346563,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "63",
      "addr:postcode": "75018",
      "addr:street": "Rue Damrémont",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Radja",
      "ref:FR:FINESS": "750032534",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000175,
    x: 48.8813839,
    y: 2.3408821,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Randriamiary",
      opening_hours: "Mo-Sa 09:00-13:00; Mo-Fr 14:00-20:00",
      "ref:FR:FINESS": "750016297",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000176,
    x: 48.8779431,
    y: 2.3424201,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rodier",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "750016743",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000177,
    x: 48.8789901,
    y: 2.3370287,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Georges",
      opening_hours: "Mo 10:00-20:00, Tu-Fr 08:30-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "750016545",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000178,
    x: 48.8819337,
    y: 2.3388813,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Artistes",
      opening_hours: "Mo-Fr 08:30-20:30;Sa,Su 09:00-20:30",
      "opening_hours:covid19": "open",
      phone: "+33148784043",
      "ref:FR:FINESS": "750015844",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciedesartistes.fr",
    },
  },
  {
    type: "node",
    id: 3269000179,
    x: 48.8808537,
    y: 2.3375391,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arts",
      "ref:FR:FINESS": "750016925",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269000180,
    x: 48.8934928,
    y: 2.3360826,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie les Grandes Carrières",
      "ref:FR:FINESS": "750032583",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269014574,
    x: 48.8722582,
    y: 2.3480167,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fougère",
      opening_hours: "Mo-Fr 08:45-19:30",
      "ref:FR:FINESS": "750017444",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3269014575,
    x: 48.8747809,
    y: 2.3407405,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Miesen",
      opening_hours: "Mo-Fr 08:30-19:45; Sa 09:30-13:30",
      "ref:FR:FINESS": "750015992",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3269014576,
    x: 48.8667273,
    y: 2.347091,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Moderne du Sentier",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 45 08 53 16",
      "ref:FR:FINESS": "750009243",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3269014577,
    x: 48.8660036,
    y: 2.3469427,
    tags: {
      amenity: "pharmacy",
      "contact:fax": "+33 1 42 36 00 05",
      "contact:phone": "+33 1 42 36 81 00",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Montorgeuil",
      opening_hours: "Mo-Sa 08:30-21:00, PH,Su 08:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750009193",
      source: "Celtipharm - 10/2014",
      website: "https://aprium-pharmacie.fr/aprium-express",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3270723630,
    x: 48.8645631,
    y: 2.3500542,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Internationale Centrale",
      opening_hours: "Mo-Fr 10:00-19:00",
      "ref:FR:FINESS": "750009276",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270723631,
    x: 48.8701344,
    y: 2.3544509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Scala",
      "ref:FR:FINESS": "750018343",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776656,
    x: 48.8714658,
    y: 2.3533801,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie 45 Faubourg Saint-Denis",
      opening_hours: "Mo-Sa 08:30-20:30",
      phone: "+33 1 47 70 34 87",
      "ref:FR:FINESS": "750017527",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776657,
    x: 48.8727042,
    y: 2.355029,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Compain",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017212",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776658,
    x: 48.8771691,
    y: 2.349388,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Conseil la Fayette",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017865",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776659,
    x: 48.8520514,
    y: 2.3648715,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Henri IV",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:00-19:00",
      "ref:FR:FINESS": "750010225",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3270776660,
    x: 48.8787013,
    y: 2.34668,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Homéopatique Maubeuge",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "750016461",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3270776761,
    x: 48.8846835,
    y: 2.3411677,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hourquet Fovet",
      "ref:FR:FINESS": "750034217",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776762,
    x: 48.8734248,
    y: 2.354938,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Mestre",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750017543",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776764,
    x: 48.8727993,
    y: 2.357943,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "750017642",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776765,
    x: 48.8556777,
    y: 2.3579794,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rivoli",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010654",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776766,
    x: 48.8583232,
    y: 2.364632,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Gilles",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:30-19:30",
      "ref:FR:FINESS": "750009946",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3270776767,
    x: 48.8751088,
    y: 2.3509692,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hauteville",
      "opening_hours:covid19": "open",
      operator: "Vincent Prospert",
      phone: "+33 1 47 70 21 40",
      "ref:FR:FINESS": "750017824",
      source: "local_knowledge",
    },
  },
  {
    type: "node",
    id: 3270776768,
    x: 48.8704289,
    y: 2.3551851,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Strasbourg",
      "ref:FR:FINESS": "750018376",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776769,
    x: 48.8567295,
    y: 2.3558132,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Hôtel de Ville",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750010142",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3270776770,
    x: 48.8687618,
    y: 2.3559159,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Croix de Malte",
      opening_hours: "Mo-Fr 08:15-21:00; Sa 08:30-20:30",
      "ref:FR:FINESS": "750009730",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776771,
    x: 48.8658286,
    y: 2.3606477,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Carreau du Temple",
      "ref:FR:FINESS": "750009862",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776772,
    x: 48.855906,
    y: 2.3565385,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marais Saint-Paul",
      "ref:FR:FINESS": "750010282",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3270776773,
    x: 48.8744321,
    y: 2.3568584,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-05-26",
      dispensing: "yes",
      name: "Pharmacie Lafayette du Soleil",
      opening_hours: "Mo-Fr 08:00-21:00; Sa 09:00-21:00; Su 10:00-21:00",
      "opening_hours:covid19":
        "Mo-Fr 08:00-21:00; Sa 09:00-21:00; Su 10:00-21:00",
      "ref:FR:FINESS": "750018392",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776774,
    x: 48.8638238,
    y: 2.3585199,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Temple",
      opening_hours:
        "Mo-Fr 08:30-12:30, 13:30-19:30; Sa 09:00-12:30, 14:00-17:00",
      "ref:FR:FINESS": "750009847",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3270776775,
    x: 48.8771569,
    y: 2.3482577,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Écalle",
      "ref:FR:FINESS": "750017030",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3275139701,
    x: 48.8631993,
    y: 2.4041024,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      noname: "yes",
      "ref:FR:FINESS": "750036436",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3280643761,
    x: 48.7683326,
    y: 2.2521754,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Verts Côteaux",
      "ref:FR:FINESS": "920011244",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3280695118,
    x: 48.7971015,
    y: 2.2983415,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dampierre",
      "ref:FR:FINESS": "920009024",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3284988010,
    x: 48.8374257,
    y: 2.2577208,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte de Saint-Cloud",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750028037",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286282636,
    x: 48.8840049,
    y: 2.3468986,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ahoudayg",
      "ref:FR:FINESS": "750032351",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286282637,
    x: 48.887264,
    y: 2.3475826,
    tags: {
      "addr:housenumber": "2",
      "addr:street": "Rue Ramey",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Zribri",
      "ref:FR:FINESS": "750033771",
    },
  },
  {
    type: "node",
    id: 3286282638,
    x: 48.8831344,
    y: 2.3472146,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Internationale de Paris",
      opening_hours: "Mo-Fr 09:00-21:00; Sa 09:30-20:30",
      "ref:FR:FINESS": "750016610",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286282643,
    x: 48.8820386,
    y: 2.3461591,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie d'Anvers",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750016875",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286282644,
    x: 48.8838749,
    y: 2.3447014,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie d'Orsel",
      opening_hours: "Mo-Sa 09:30-19:30",
      "ref:FR:FINESS": "750033607",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286282645,
    x: 48.8856441,
    y: 2.3472442,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Galeries Dufayel",
      opening_hours:
        "Mo 14:00-20:30; Tu-Fr 09:30-20:30; Sa 09:30-13:30,15:00-19:00",
      "ref:FR:FINESS": "750032385",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286287466,
    x: 48.8885767,
    y: 2.3468977,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Casalonga",
      opening_hours: "Tu-Fr 08:30-20:00, Mo 14:00-20:00, Sa 09:00-20:00",
      "opening_hours:covid19":
        "Tu-Fr 08:30-20:00, Mo 14:00-20:00, Sa 09:00-20:00",
      "ref:FR:FINESS": "750032450",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286287467,
    x: 48.8909628,
    y: 2.3443799,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hermel",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032716",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286287468,
    x: 48.8898586,
    y: 2.3432421,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "62",
      "addr:postcode": "75018",
      "addr:street": "Rue Custine",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Weinstein",
      opening_hours: "Mo-Sa 08:30-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 06 20 57",
      "ref:FR:FINESS": "750032484",
      source: "Celtipharm - 10/2014",
      website: "http://www.pharmaciecustine.fr",
    },
  },
  {
    type: "node",
    id: 3286368363,
    x: 48.8915262,
    y: 2.3491203,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie 75",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "750033367",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368365,
    x: 48.8795579,
    y: 2.3535539,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Anglaise",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:30-19:30",
      "ref:FR:FINESS": "750017246",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286368366,
    x: 48.8796192,
    y: 2.3488789,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 08:00-20:00, Sa 09:00-19:00",
      "ref:FR:FINESS": "750016495",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286368367,
    x: 48.8873365,
    y: 2.3499378,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "48",
      "addr:postcode": "75018",
      "addr:street": "Boulevard Barbès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Château Rouge",
      "ref:FR:FINESS": "750031973",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368368,
    x: 48.8795477,
    y: 2.3496189,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cohen Solal",
      opening_hours: "Mo-Fr 08:00-19:30, Sa 09:00-13:00",
      "ref:FR:FINESS": "750017477",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368369,
    x: 48.890246,
    y: 2.3513255,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Delaby",
      "ref:FR:FINESS": "750033656",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368370,
    x: 48.8884734,
    y: 2.3498615,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "64",
      "addr:postcode": "75018",
      "addr:street": "Boulevard Barbès",
      amenity: "pharmacy",
      "description:covid19": "Pharmacie de garde",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Espace Conseil",
      opening_hours: "Mo-Su 08:00-02:00",
      phone: "+33 1 46 06 02 61",
      "ref:FR:FINESS": "750032005",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368371,
    x: 48.892647,
    y: 2.3491395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Européenne",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750033482",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368372,
    x: 48.8705337,
    y: 2.3609389,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Goldfarb",
      "ref:FR:FINESS": "750018095",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368373,
    x: 48.8868,
    y: 2.3535164,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "2",
      "addr:postcode": "75018",
      "addr:street": "Rue Léon",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hadjali",
      "ref:FR:FINESS": "750032856",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368375,
    x: 48.8836473,
    y: 2.3398403,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Providence",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750033003",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368376,
    x: 48.8902278,
    y: 2.3493675,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "65",
      "addr:postcode": "75018",
      "addr:street": "Boulevard Barbès",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Marcadet",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      operator: "Harchouche",
      phone: "+33 1 46 06 96 30",
      "ref:FR:FINESS": "750032021",
    },
  },
  {
    type: "node",
    id: 3286368379,
    x: 48.8817514,
    y: 2.3508051,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nord-Magenta",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750018160",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368380,
    x: 48.8732431,
    y: 2.3617124,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Périn",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018061",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368383,
    x: 48.8880916,
    y: 2.3513096,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tran Van Jivanji",
      "ref:FR:FINESS": "750033755",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368385,
    x: 48.8810556,
    y: 2.3496319,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Dunkerque",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750016024",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286368387,
    x: 48.8834379,
    y: 2.3488637,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Carrefour",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750016594",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368388,
    x: 48.8853902,
    y: 2.3498085,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Progrès",
      "ref:FR:FINESS": "750031957",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368389,
    x: 48.8946949,
    y: 2.3474146,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Smplon",
      "ref:FR:FINESS": "750033516",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286368390,
    x: 48.8918681,
    y: 2.3463492,
    tags: {
      "addr:housenumber": "43",
      "addr:postcode": "75018",
      "addr:street": "Rue Simart",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square Clignancourt",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 08:30-19:30",
      phone: "+33 1 46 06 38 66",
      "ref:FR:FINESS": "750034084",
    },
  },
  {
    type: "node",
    id: 3286375844,
    x: 48.8901334,
    y: 2.3595521,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Brunet",
      "ref:FR:FINESS": "750033300",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286375845,
    x: 48.8858013,
    y: 2.3591698,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Sefiani",
      "ref:FR:FINESS": "750033037",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286375846,
    x: 48.8915441,
    y: 2.3596492,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Chapelle",
      "ref:FR:FINESS": "750032237",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286386311,
    x: 48.8685216,
    y: 2.36681,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Berthelot Fourreau",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "750018863",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3286386315,
    x: 48.8839077,
    y: 2.3750794,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pariente",
      "ref:FR:FINESS": "750034787",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286386316,
    x: 48.890559,
    y: 2.3759603,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Les Orgues",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034951",
      source: "survey 2017",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286386317,
    x: 48.8833351,
    y: 2.3716094,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Wizman",
      "ref:FR:FINESS": "750034753",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286386318,
    x: 48.899089,
    y: 2.3794216,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie ZAC Claude Bernard",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036907",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286386319,
    x: 48.8929938,
    y: 2.3774104,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Ourcq",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035032",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286393919,
    x: 48.8739053,
    y: 2.3745589,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bui Champenois",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018442",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286393920,
    x: 48.8732607,
    y: 2.3752743,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lellouche",
      "opening_hours:covid19": "Tu-Fr 10:00-13:00, 15:00-18:00; Sa 10:00-13:00",
      "ref:FR:FINESS": "750018426",
      source: "survey; Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286404154,
    x: 48.8692817,
    y: 2.3691088,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-10-25",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Ma Pharmacie République",
      opening_hours: "Mo-Su 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018897",
      source: "survey; Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3286404155,
    x: 48.8684612,
    y: 2.372393,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dahan",
      "opening_hours:covid19": "Mo-Sa 09:00-13:30,14:30-19:00",
      "ref:FR:FINESS": "750019630",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286404157,
    x: 48.8657244,
    y: 2.3702031,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché Popincourt",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 9:00-19:30",
      phone: "+33147001876",
      "ref:FR:FINESS": "750011009",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286420962,
    x: 48.8838456,
    y: 2.3967316,
    tags: {
      amenity: "pharmacy",
      brand: "leader santé",
      "contact:email": "pharmacie@fouche.fr",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fouché",
      opening_hours: "Mo-Fr 09:30-13:00,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034720",
      source:
        "Celtipharm - 10/2014;https://opendata.paris.fr/explore/dataset/coronavirus-commercants-parisiens-livraison-a-domicile",
    },
  },
  {
    type: "node",
    id: 3286430492,
    x: 48.8679653,
    y: 2.4009222,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Japkowicz",
      "ref:FR:FINESS": "750036022",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286430493,
    x: 48.8569651,
    y: 2.4104184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nadjar",
      "ref:FR:FINESS": "750035933",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286524178,
    x: 48.8632827,
    y: 2.3874969,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ménimontant",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036170",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286524179,
    x: 48.862315,
    y: 2.3855996,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Coudenhove",
      opening_hours: "Mo 14:00-20:00; Tu-Sa 9:00-13:00, 14:00-20:00",
      phone: "+33147007652",
      "ref:FR:FINESS": "750018780",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286543309,
    x: 48.8570305,
    y: 2.3818008,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sébag",
      "ref:FR:FINESS": "750020422",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286543310,
    x: 48.8566193,
    y: 2.379442,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "750019192",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567588,
    x: 48.850918,
    y: 2.3989279,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie du XXe",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "750035867",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567589,
    x: 48.8521281,
    y: 2.3764111,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Allouche",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00; Sa 09:00-13:30,14:30-20:00",
      "ref:FR:FINESS": "750019143",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567590,
    x: 48.8570029,
    y: 2.3854109,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Beaudoin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750019242",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567591,
    x: 48.8530589,
    y: 2.3773519,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Celnik",
      "ref:FR:FINESS": "750019176",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567592,
    x: 48.8520476,
    y: 2.4031571,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale d'Avron",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "750035487",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567593,
    x: 48.8583089,
    y: 2.3892572,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mont-Louis",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "750019291",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286567596,
    x: 48.8468431,
    y: 2.4031703,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dufour",
      "ref:FR:FINESS": "750021735",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567597,
    x: 48.8540407,
    y: 2.3820722,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Emrik",
      "ref:FR:FINESS": "750018640",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567598,
    x: 48.8537646,
    y: 2.3822311,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Faidherbe",
      "ref:FR:FINESS": "750018830",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567599,
    x: 48.8481243,
    y: 2.4116336,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fitoussi",
      "ref:FR:FINESS": "750035917",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567600,
    x: 48.8551416,
    y: 2.386833,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fourneuf",
      opening_hours: "Mo-Sa 09:00-13:00; Mo-Sa 14:00-20:00",
      "ref:FR:FINESS": "750018681",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567601,
    x: 48.8527109,
    y: 2.3745984,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Générale",
      "ref:FR:FINESS": "750018590",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567602,
    x: 48.8479906,
    y: 2.4058932,
    tags: {
      alt_name: "Pharmacie Koury",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pyrénées-Vincennes",
      opening_hours:
        "Mo-Fr 08:30-20:00; Sa 08:30-19:30; Aug Mo-Fr 09:00-19:30; Aug Sa 09:00-19:00",
      "ref:FR:FINESS": "750036501",
      source: "Celtipharm - 10/2014",
      "source:name": "survey",
    },
  },
  {
    type: "node",
    id: 3286567603,
    x: 48.8535362,
    y: 2.3798034,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Le Quoc",
      "ref:FR:FINESS": "750018624",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567604,
    x: 48.8535047,
    y: 2.3930465,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Philippe Auguste",
      opening_hours: "Mo-Fr 09:00-14:00,15:00-19:00, Sa 09:00-12:00",
      "ref:FR:FINESS": "750019713",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567605,
    x: 48.8551739,
    y: 2.3882193,
    tags: {
      amenity: "pharmacy",
      "contact:email": "contact@pharmacieparischaronne.com",
      "contact:phone": "+33 1 43 71 09 56",
      "contact:website": "http://pharmacieparischaronne.com",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Paris-Charonne",
      "ref:FR:FINESS": "750018715",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567606,
    x: 48.855018,
    y: 2.3863592,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande pharmacie de Charonne",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750018665",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567608,
    x: 48.8468405,
    y: 2.3992911,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Valet",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "750021883",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3286567609,
    x: 48.8548702,
    y: 2.3846652,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Voltaire-Charonne",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "750020497",
      source: "Celtipharm - 10/2014",
      "toilets:wheelchair": "no",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3286567610,
    x: 48.8545213,
    y: 2.3710469,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Zaghroun",
      "ref:FR:FINESS": "750018814",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567611,
    x: 48.8530836,
    y: 2.4089949,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Croix Saint-Simon",
      "ref:FR:FINESS": "750035537",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567612,
    x: 48.8509655,
    y: 2.3779483,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Semeuse",
      "ref:FR:FINESS": "750018913",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286567613,
    x: 48.8545677,
    y: 2.3963211,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Boulevard de Charonne",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750035883",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3286571161,
    x: 48.8398366,
    y: 2.4007072,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Drai et Souffir",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750021867",
      source: "Celtipharm - 10/2014;survey 2015",
    },
  },
  {
    type: "node",
    id: 3286601763,
    x: 48.8651506,
    y: 2.3949849,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Coilliaux",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00; PH off",
      "opening_hours:covid19": "open",
      phone: "+331 46 36 41 06",
      "ref:FR:FINESS": "750036832",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286610443,
    x: 48.8903971,
    y: 2.3715296,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hassan",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034522",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286610484,
    x: 48.8921367,
    y: 2.3791587,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Madar",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750034621",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286611493,
    x: 48.8988362,
    y: 2.3678046,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte d'Aubervilliers",
      "ref:FR:FINESS": "750033201",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3286612783,
    x: 48.8554465,
    y: 2.3076645,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bonnel",
      "ref:FR:FINESS": "750013930",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3287386184,
    x: 48.818149,
    y: 2.3783169,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dubreuil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940010416",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3287386186,
    x: 48.8895292,
    y: 2.3399573,
    tags: {
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mathieu",
      "ref:FR:FINESS": "750032104",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3287387608,
    x: 48.8952492,
    y: 2.346915,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "SELARL Pharmacie du Marché",
      "ref:FR:FINESS": "750033557",
      source: "survey; Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3293307293,
    x: 48.8773172,
    y: 2.7048374,
    tags: {
      alt_name: "Pharmacie ROUX",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 30 00 47",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Fontaine",
      opening_hours:
        "Mo 14:00-19:30; Tu-Fr 08:30-13:00,14:00-19:30; Sa 08:45-13:00,14:00-19:30",
      "ref:FR:FINESS": "770007342",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3298286535,
    x: 48.8891098,
    y: 2.3835643,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Aquarius",
      "ref:FR:FINESS": "750035305",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 12/2015",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3300429585,
    x: 48.9396815,
    y: 2.0177025,
    tags: {
      "addr:city": "Carrières-sous-Poissy",
      "addr:housenumber": "188",
      "addr:postcode": "78955",
      "addr:street": "Rue des Écoles",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo-Fr 09:30-12:30,14:30-19:30; Sa 09:30-12:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "780005526",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3302555470,
    x: 49.0916363,
    y: 2.7513026,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "600005185",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3304399757,
    x: 48.9458932,
    y: 2.3668152,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Université",
      "ref:FR:FINESS": "930014782",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3320054422,
    x: 48.9151748,
    y: 2.3669238,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Olivetti",
      "ref:FR:FINESS": "930004270",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330199453,
    x: 48.8692591,
    y: 2.1864897,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Croix de Lorraine",
      opening_hours:
        "Tu-Fr 08:30-20:00; Mo 09:30-20:00; Sa 09:00-12:30,15:00-19:00",
      "opening_hours:covid19":
        "Tu-Fr 08:30-20:00; Mo 09:30-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "920020062",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      "takeaway:covid19": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3330751344,
    x: 48.8916025,
    y: 2.2909421,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie de Levallois",
      opening_hours: "Mo-Sa 08:30-20:30",
      "ref:FR:FINESS": "920018678",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3330751345,
    x: 48.9265313,
    y: 2.2733199,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie des 4 Routes",
      "ref:FR:FINESS": "920009297",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751349,
    x: 48.8976852,
    y: 2.2828246,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Azerrad",
      "ref:FR:FINESS": "920015781",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3330751351,
    x: 48.9133432,
    y: 2.2731943,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bena",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920008364",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751353,
    x: 48.8900083,
    y: 2.2829789,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bonneau",
      "ref:FR:FINESS": "920015625",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751354,
    x: 48.8954768,
    y: 2.2928051,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Commeinhes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016060",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751355,
    x: 48.8926915,
    y: 2.2896795,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "920015641",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751356,
    x: 48.9149495,
    y: 2.2677972,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Descazeaux Neaux",
      "ref:FR:FINESS": "920009495",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751357,
    x: 48.9161902,
    y: 2.2825496,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Drighes",
      "ref:FR:FINESS": "920008281",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751358,
    x: 48.8886032,
    y: 2.3559443,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Doudeauville",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750032633",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751360,
    x: 48.9320234,
    y: 2.2674268,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Labrousse",
      "ref:FR:FINESS": "920013141",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751362,
    x: 48.9145582,
    y: 2.2743338,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Landau",
      "ref:FR:FINESS": "920009313",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751364,
    x: 48.9190283,
    y: 2.2804018,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lifert",
      "ref:FR:FINESS": "920009362",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751367,
    x: 48.9244715,
    y: 2.2749055,
    tags: {
      amenity: "pharmacy",
      "description:covid19": "fermé le samedi après-midi (heure à préciser)",
      dispensing: "yes",
      name: "Pharmacie Montagny",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00;Sa 09:00-12:00",
      "ref:FR:FINESS": "920009263",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751369,
    x: 48.9160469,
    y: 2.2764145,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nouvelle",
      "ref:FR:FINESS": "920009347",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751370,
    x: 48.9060986,
    y: 2.286318,
    tags: {
      alt_name: "Pharmacie PIOT VALLOTTON",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont d'Asnières",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "920008547",
      source: "Celtipharm - 10/2014",
      website: "https://www.mypharmactiv.fr/pharmacie/pharmacie-pont-asnieres",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3330751371,
    x: 48.8978774,
    y: 2.2922066,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Raspail",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920016045",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751377,
    x: 48.8933299,
    y: 2.2886324,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920016094",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751378,
    x: 48.9101327,
    y: 2.2862088,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours:
        "Mo,Tu,Th,Fr 08:30-20:00, We,Sa 08:30-13:00, We 14:00-20:00, Sa 14:00-19:00",
      "ref:FR:FINESS": "920008240",
      source: "Celtipharm - 10/2014; survey 2016",
    },
  },
  {
    type: "node",
    id: 3330751379,
    x: 48.9275322,
    y: 2.2744233,
    tags: {
      "addr:city": "Asnières-sur-Seine",
      "addr:housenumber": "3",
      "addr:postcode": "92600",
      "addr:street": "Avenue de la Redoute",
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-11-14",
      dispensing: "yes",
      name: "Pharmacie du Flore",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "920008745",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751380,
    x: 48.8911334,
    y: 2.2887261,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Palais des Sports",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "920015831",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751381,
    x: 48.8870667,
    y: 2.2839335,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "920015930",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3330751382,
    x: 48.8963102,
    y: 2.2766495,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vinci",
      "ref:FR:FINESS": "920015880",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3330751383,
    x: 48.9085504,
    y: 2.2611218,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie la Cigogne",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:30-13:00,14:30-19:30",
      "ref:FR:FINESS": "920009545",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3332165636,
    x: 48.865077,
    y: 2.4318309,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Clos Français",
      "ref:FR:FINESS": "930009923",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336645295,
    x: 48.8969671,
    y: 2.308895,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bourée",
      "ref:FR:FINESS": "920012465",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336645296,
    x: 48.8970909,
    y: 2.3077353,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jehouani",
      "ref:FR:FINESS": "920012663",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336666151,
    x: 48.8331332,
    y: 2.2484035,
    tags: {
      "addr:city": "Boulogne-Billancourt",
      "addr:housenumber": "23",
      "addr:postcode": "92100",
      "addr:street": "Rue Barthélemy Danjou",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tabet",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-13:00, 15:00-19:30",
      phone: "+33 1 46 21 78 42",
      "ref:FR:FINESS": "920009594",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336666152,
    x: 48.8292689,
    y: 2.2476738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie KOK",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920010360",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336666153,
    x: 48.8295558,
    y: 2.2491991,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours:
        "Tu-Fr 09:00-13:00, 14:00-19:45; Mo 10:30-13:00, 14:00-19:45; Sa 09:00-13:00, 14:30-19:00",
      "opening_hours:covid19":
        "Tu-Fr 09:00-13:00, 14:00-19:45; Mo 10:30-13:00, 14:00-19:45; Sa 09:00-13:00, 14:30-19:00",
      phone: "+33 1 46 20 24 70",
      "ref:FR:FINESS": "920010303",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3336875293,
    x: 48.9117288,
    y: 2.1798448,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie les Catelaines",
      opening_hours:
        "Sa 09:00-19:30; Mo 09:00-13:00,14:30-20:00; Tu-Fr 09:00-13:00,14:30-20:00",
      "ref:FR:FINESS": "780005641",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3336879783,
    x: 48.9194295,
    y: 2.4204463,
    tags: {
      "addr:city": "Drancy",
      "addr:housenumber": "60",
      "addr:postcode": "93700",
      "addr:street": "Rue Saint-Stenay",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie de l'Avenir",
      "ref:FR:FINESS": "930007620",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3337646097,
    x: 48.9070618,
    y: 2.4692154,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Damasse",
      "ref:FR:FINESS": "930006200",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3337646098,
    x: 48.9562537,
    y: 2.4757303,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Parinor",
      "ref:FR:FINESS": "930004734",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3337646099,
    x: 48.9267687,
    y: 2.4725067,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 4 Tours",
      "ref:FR:FINESS": "930005772",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3338010068,
    x: 49.003262,
    y: 2.5645887,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Roissy 2",
      "ref:FR:FINESS": "930015987",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3341284393,
    x: 48.9356751,
    y: 2.3312075,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920021755",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3342640209,
    x: 48.8400449,
    y: 2.3970276,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "contact@pharmacie-lombardie.fr",
      healthcare: "pharmacy",
      name: "Pharmacie Lamblardie",
      opening_hours: "Mo-Fr 8 :30-20:00 ;Sa 9 :00-19:30",
      "opening_hours:covid19": "same",
      phone: "+33143438797",
      "ref:FR:FINESS": "750021552",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-05-18",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3342640210,
    x: 48.8394305,
    y: 2.3921717,
    tags: {
      alt_name: "Pharmacie VANNEUVILLE",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Reuilly",
      opening_hours: "Mo 14:30-19:30; Tu-Fr 9:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "750022006",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3342640212,
    x: 48.8286984,
    y: 2.2656081,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "zibi.herve@freesurf.fr",
      name: "Pharmacie du Val de Seine",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      phone: "+33 1 46 42 21 03",
      "ref:FR:FINESS": "920015344",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3349813469,
    x: 48.7780603,
    y: 2.2596101,
    tags: {
      "access:covid19": "yes",
      alt_name: "Pharmacie FLECK",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Cité",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920018892",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3350798706,
    x: 49.0397508,
    y: 2.8057695,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770011542",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3352646220,
    x: 48.8796614,
    y: 2.5933557,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "43",
      "addr:postcode": "77500",
      "addr:street": "Rue Gambetta",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 21 22 44",
      "ref:FR:FINESS": "770005031",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3352646230,
    x: 48.8856344,
    y: 2.6216029,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "180",
      "addr:postcode": "77500",
      "addr:street": "Rue des Cités",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Guiot",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 17 43",
      "ref:FR:FINESS": "770004927",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacieguiot.pharmavie.fr/",
    },
  },
  {
    type: "node",
    id: 3366579022,
    x: 49.125978,
    y: 2.3699743,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      opening_hours:
        "Mo-Fr 08:30-12:30, 14:30-20:00; Sa 08:30-12:30, 14:30-19:30",
      phone: "+33 1 30 35 40 26",
      "ref:FR:FINESS": "950013391",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3368670627,
    x: 48.9434225,
    y: 2.2489752,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ben Chaabane",
      "ref:FR:FINESS": "950005033",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3376221893,
    x: 49.0321693,
    y: 2.1031104,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre Commercial d'Eragny",
      "ref:FR:FINESS": "950007484",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3378183770,
    x: 48.8076614,
    y: 2.2418627,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Val Fleury",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "920016581",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3378215846,
    x: 48.5254465,
    y: 2.0093725,
    tags: {
      "addr:city": "Dourdan",
      "addr:postcode": "91410",
      "addr:street": "Rue de la Croix Saint-Jacques",
      alt_name: "Pharmacie Coquand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 81 04 61",
      "contact:phone": "+33 1 64 59 86 77",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Croix Saint-Jacques",
      "ref:FR:FINESS": "910007384",
      since: "1989-07-31",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3380811571,
    x: 48.8058211,
    y: 2.2455409,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du PARC",
      "ref:FR:FINESS": "920016730",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3387854339,
    x: 48.8853379,
    y: 2.4036816,
    tags: {
      "addr:city": "Le Pré-Saint-Gervais",
      "addr:country": "FR",
      "addr:postcode": "93310",
      "addr:street": "Rue André Joineau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Grande Pharmacie du Pré-Saint-Gervais",
      opening_hours: "Mo-Fr 09:00-20:30, Sa 09:00-13:00,14:30-20:00",
      "ref:FR:FINESS": "930013222",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "434928743",
      "ref:FR:SIRET": "43492874300013",
      source: "Celtipharm - 10/2014",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3389885535,
    x: 48.8056974,
    y: 2.3153087,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Galien Santé",
      "ref:FR:FINESS": "920009180",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
    },
  },
  {
    type: "node",
    id: 3393910780,
    x: 48.6139151,
    y: 2.3763276,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Xénidis Briard",
      opening_hours: "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00",
      phone: "+33160864187",
      "ref:FR:FINESS": "910005750",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3400264137,
    x: 48.8010608,
    y: 2.314167,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Devaux - Legibre",
      "ref:FR:FINESS": "920009065",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3400267591,
    x: 48.8000604,
    y: 2.3131227,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Madeleine",
      "ref:FR:FINESS": "920009115",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
    },
  },
  {
    type: "node",
    id: 3400295141,
    x: 48.8007597,
    y: 2.3239211,
    tags: {
      "addr:city": "Cachan",
      "addr:housenumber": "11",
      "addr:postcode": "94230",
      "addr:street": "Avenue Aristide Briand",
      alt_name: "Pharmacie Kéou",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Grange Ory",
      opening_hours:
        "Mo 10:00-13:00,14:30-19:30; Tu-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,15:00-19:00",
      phone: "+33 1 70 25 51 75",
      "ref:FR:FINESS": "940006273",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3401860405,
    x: 48.9378847,
    y: 2.356135,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bougha",
      "ref:FR:FINESS": "930014451",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3402390696,
    x: 48.6424892,
    y: 2.2291046,
    tags: {
      "addr:city": "Marcoussis",
      "addr:housenumber": "3",
      "addr:postcode": "91460",
      "addr:street": "Boulevard Nelaton",
      alt_name: "SELARL Pharmacie de l'Église",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 49 89 33",
      "contact:phone": "+33 1 69 01 04 50",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de l'Église",
      "ref:FR:FINESS": "910009984",
      since: "2005-07-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2015",
    },
  },
  {
    type: "node",
    id: 3402625328,
    x: 48.6420327,
    y: 2.2334858,
    tags: {
      "addr:city": "Marcoussis",
      "addr:housenumber": "30",
      "addr:postcode": "91460",
      "addr:street": "Rue Alfred Dubois",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 80 69 97",
      "contact:phone": "+33 1 69 01 74 01",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Vallet",
      "ref:FR:FINESS": "910010016",
      since: "2006-01-09",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2015",
    },
  },
  {
    type: "node",
    id: 3422205186,
    x: 48.8576893,
    y: 2.4217772,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Muller",
      "ref:FR:FINESS": "930005657",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3424526808,
    x: 48.8579754,
    y: 2.4349971,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Maarek",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930010657",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3433933729,
    x: 48.7622726,
    y: 2.40197,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940014525",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3437652742,
    x: 48.8037595,
    y: 2.3206989,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Rond Point",
      "ref:FR:FINESS": "920009214",
      source: "Celtipharm - 10/2014",
      "source:coordinates": "survey",
    },
  },
  {
    type: "node",
    id: 3438942651,
    x: 48.8710532,
    y: 2.1947854,
    tags: {
      "addr:city": "Rueil-Malmaison",
      "addr:housenumber": "80",
      "addr:postcode": "92500",
      "addr:street": "Avenue du Président Pompidou",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 51 29 77",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "opening_hours:covid19": "Mo 14:30-19:30; Tu-Sa 09:00-12:30, 14:30-19:30",
      "ref:FR:FINESS": "920019726",
      source: "Celtipharm - 10/2014",
      website: "https://www.pharmaciedesecoles92500.fr/",
    },
  },
  {
    type: "node",
    id: 3439604941,
    x: 48.7959028,
    y: 2.321995,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Lumières",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:30-12:30,15:00-19:00",
      "ref:FR:FINESS": "940006307",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3440565912,
    x: 49.1118022,
    y: 2.4218741,
    tags: {
      "addr:housenumber": "26",
      "addr:street": "Rue Charles de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Froissant Roch",
      "ref:FR:FINESS": "950009670",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3440565913,
    x: 49.1137117,
    y: 2.4223648,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gobert",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950009654",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3440593430,
    x: 48.7933047,
    y: 2.2963618,
    tags: {
      "addr:city": "Fontenay-aux-Roses",
      "addr:housenumber": "45",
      "addr:postcode": "92260",
      "addr:street": "Rue Marx Dormoy",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mutschler",
      phone: "+33.1 46 61 06 61",
      "ref:FR:FINESS": "920014263",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3446474995,
    x: 48.8054968,
    y: 2.3579874,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Issoufaly",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940010846",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3447936196,
    x: 49.0166317,
    y: 2.209154,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Verdun",
      "ref:FR:FINESS": "950013144",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3450389512,
    x: 48.9386804,
    y: 2.3728948,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Ferme",
      "ref:FR:FINESS": "930014303",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2015;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3450884902,
    x: 48.88087,
    y: 2.4256454,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930009105",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3451091139,
    x: 48.7791851,
    y: 2.3389751,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tomasino",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 08:30-12:30,14:30-19:30; Sa 09:30-13:00,14:30-19:30",
      "ref:FR:FINESS": "940009871",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3452507613,
    x: 48.8946527,
    y: 2.4783413,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "930006770",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3473156858,
    x: 48.7959116,
    y: 2.4253635,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "Mo-Sa 09:00-19:30",
      operator: "Hamard Christian",
      phone: "+33 1 43 78 58 90",
      "ref:FR:FINESS": "940005382",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3476221617,
    x: 48.9368244,
    y: 2.3560293,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Populaire",
      "ref:FR:FINESS": "930014402",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476622653,
    x: 48.9352638,
    y: 2.3558385,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ambrogi",
      "ref:FR:FINESS": "930014386",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476742391,
    x: 48.9329994,
    y: 2.3552472,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "930014766",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476748897,
    x: 48.9365043,
    y: 2.3549133,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "La Grande Pharmacie centrale",
      "ref:FR:FINESS": "930014683",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476751794,
    x: 48.9306229,
    y: 2.3562728,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte de Paris",
      "ref:FR:FINESS": "930014337",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476795382,
    x: 48.9380341,
    y: 2.3561525,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Compas",
      "ref:FR:FINESS": "930014436",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476795383,
    x: 48.9366527,
    y: 2.3476477,
    tags: {
      "addr:city": "Saint-Denis",
      "addr:housenumber": "3",
      "addr:postcode": "93200",
      "addr:street": "Rue Dézobry",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Elleuch",
      "ref:FR:FINESS": "930014253",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476795384,
    x: 48.9361967,
    y: 2.3497526,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Moderne",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-19:30; Su 09:00-13:30; Sa 15:00-19:30",
      "ref:FR:FINESS": "930014287",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476795385,
    x: 48.9414342,
    y: 2.3510156,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Morain",
      "ref:FR:FINESS": "930014170",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476795386,
    x: 48.9367783,
    y: 2.3516993,
    tags: {
      amenity: "pharmacy",
      "contact:facebook": "https://www.facebook.com/ipharmrepublique/",
      "contact:phone": "+33 1 48 20 08 72",
      "contact:website":
        "https://pharmaciedelarepublique-saintdenis.mesoigner.fr/",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la République",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00; Su 09:00-19:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "930014717",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 3476872829,
    x: 48.9407046,
    y: 2.3717564,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie DRAI",
      "ref:FR:FINESS": "930014501",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476872830,
    x: 48.9368308,
    y: 2.3709153,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gandziri Gandziri",
      "ref:FR:FINESS": "930014816",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3476872831,
    x: 48.9259116,
    y: 2.3672286,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "930014220",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3479342432,
    x: 49.0075219,
    y: 2.8267392,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lim Quan Choy",
      "ref:FR:FINESS": "770809374",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3483465588,
    x: 49.121287,
    y: 2.4272769,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3485637484,
    x: 48.6830984,
    y: 2.1692935,
    tags: {
      "access:covid19": "no",
      "addr:city": "Les Ulis",
      "addr:district": "Essonne",
      "addr:housename": "4",
      "addr:postcode": "91940",
      "addr:street": "Avenue du Berry",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 28 02 79",
      "contact:phone": "+33 1 69 07 33 01",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Ulis",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910014877",
      start_date: "1988-08-01",
    },
  },
  {
    type: "node",
    id: 3485639898,
    x: 48.6588147,
    y: 2.3797457,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "10",
      "addr:postcode": "91350",
      "addr:street": "Chemin du Moulin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 43 68 39",
      "contact:phone": "+33 1 69 43 20 06",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Besnier Guesnay",
      "ref:FR:FINESS": "910009133",
      since: "1989-03-24",
    },
  },
  {
    type: "node",
    id: 3485639899,
    x: 48.6391052,
    y: 2.2704154,
    tags: {
      "addr:city": "Montlhéry",
      "addr:housenumber": "15",
      "addr:postcode": "91310",
      "addr:street": "Place du Marché",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 49 74 12",
      "contact:phone": "+33 1 69 01 00 27",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910010941",
      since: "2004-07-30",
    },
  },
  {
    type: "node",
    id: 3485639900,
    x: 48.7002217,
    y: 2.467751,
    tags: {
      "addr:city": "Montgeron",
      "addr:housenumber": "125",
      "addr:postcode": "91230",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 52 14",
      "contact:phone": "+33 1 69 03 52 11",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Davenet",
      "ref:FR:FINESS": "910010842",
      since: "2006-04-01",
    },
  },
  {
    type: "node",
    id: 3485639901,
    x: 48.5981179,
    y: 2.4977067,
    tags: {
      "addr:city": "Saintry-sur-Seine",
      "addr:housenumber": "124",
      "addr:postcode": "91250",
      "addr:street": "Route de Melun",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 89 30 21",
      "contact:phone": "+33 1 69 89 30 20",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Declert",
      "ref:FR:FINESS": "910012996",
      since: "2009-10-14",
    },
  },
  {
    type: "node",
    id: 3485639902,
    x: 48.6391594,
    y: 2.2711372,
    tags: {
      "addr:city": "Montlhéry",
      "addr:housenumber": "14",
      "addr:postcode": "91310",
      "addr:street": "Place du Marché",
      alt_name: "SELAS Pharmacie Delatour",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 01 00 45",
      "contact:phone": "+33 1 69 01 00 45",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Delatour",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910010925",
      since: "1982-08-13",
    },
  },
  {
    type: "node",
    id: 3485639903,
    x: 48.712902,
    y: 2.4646533,
    tags: {
      "addr:city": "Crosne",
      "addr:housenumber": "12 Bis",
      "addr:postcode": "91560",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 48 07 51",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Eugène Brunet",
      "ref:FR:FINESS": "910007285",
      since: "1993-03-08",
    },
  },
  {
    type: "node",
    id: 3485639904,
    x: 48.6083524,
    y: 2.3088662,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Brétigny-sur-Orge",
      "contact:fax": "+33 1 60 84 15 84",
      "contact:housenumber": "1 Bis",
      "contact:phone": "+33 1 60 84 69 97",
      "contact:postcode": "91220",
      "contact:street": "Rue Jean Jaurès",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Jean Jaurès",
      "opening_hours:covid19":
        "Mo,We-Su 09:00-12:30,14:30-18:00; Tu 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "910006055",
      since: "2006-06-05",
    },
  },
  {
    type: "node",
    id: 3485639906,
    x: 48.6341024,
    y: 2.2669029,
    tags: {
      "addr:city": "Linas",
      "addr:housenumber": "14",
      "addr:postcode": "91310",
      "addr:street": "Rue de la Division Leclerc",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 01 00 13",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Masri Sidani",
      "ref:FR:FINESS": "910009604",
      since: "1986-10-31",
    },
  },
  {
    type: "node",
    id: 3485639907,
    x: 48.6358196,
    y: 2.4291283,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "16 Bis",
      "addr:postcode": "91000",
      "addr:street": "Place Jules Vallès",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 18 89",
      "contact:phone": "+33 1 60 78 41 02",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie NGuevo",
      "ref:FR:FINESS": "910008473",
      since: "2006-01-03",
    },
  },
  {
    type: "node",
    id: 3485639908,
    x: 48.6977548,
    y: 2.5247196,
    tags: {
      "addr:city": "Épinay-sous-Sénart",
      "addr:housenumber": "1",
      "addr:postcode": "91860",
      "addr:street": "Avenue Victor Hugo",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 43 87 79",
      "contact:phone": "+33 1 60 47 04 07",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "910007921",
      since: "1986-09-16",
    },
  },
  {
    type: "node",
    id: 3485639909,
    x: 48.6428579,
    y: 2.2914426,
    tags: {
      "addr:city": "Longpont-sur-Orge",
      "addr:housenumber": "10",
      "addr:postcode": "91310",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 01 69 00",
      "contact:phone": "+33 1 64 49 31 81",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Pascaline Dardenne",
      "ref:FR:FINESS": "910009935",
      since: "1990-02-08",
    },
  },
  {
    type: "node",
    id: 3485639913,
    x: 48.6272957,
    y: 2.4290988,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "13",
      "addr:postcode": "91000",
      "addr:street": "Allée Jacquard",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 36 22 99",
      "contact:phone": "+33 1 69 36 22 88",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie des Terrasses",
      "ref:FR:FINESS": "910814599",
      since: "1997-03-19",
    },
  },
  {
    type: "node",
    id: 3485639915,
    x: 48.6951329,
    y: 2.2982435,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Longjumeau",
      "contact:fax": "+33 1 64 48 99 99",
      "contact:housenumber": "13",
      "contact:phone": "+33 1 69 09 87 75",
      "contact:postcode": "91160",
      "contact:street": "Avenue du Général de Gaulle",
      dispensing: "yes",
      name: "Pharmacie du Théatre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009802",
      since: "1995-12-18",
    },
  },
  {
    type: "node",
    id: 3485639943,
    x: 48.6989068,
    y: 2.3333933,
    tags: {
      "addr:city": "Morangis",
      "addr:housenumber": "10",
      "addr:postcode": "91420",
      "addr:street": "Rue Blaise Pascal",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 09 76 30",
      "contact:phone": "+33 1 64 54 86 83",
      dispensing: "yes",
      name: "SNC Pharmacie Delattre Dib",
      "ref:FR:FINESS": "910017656",
      since: "2006-08-01",
    },
  },
  {
    type: "node",
    id: 3485639944,
    x: 48.6884121,
    y: 2.1147339,
    tags: {
      "addr:city": "Gif-sur-Yvette",
      "addr:housenumber": "11",
      "addr:postcode": "91190",
      "addr:street": "Place du Marché Neuf",
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 12 56 21",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 08:30-13:00,14:30-20:00; Sa 08:30-13:00,14:30-19:30",
      "opening_hours:covid19":
        "Mo-Fr 08:30-13:00,14:30-20:00; Sa 08:30-13:00,14:30-19:30",
      phone: "+33 1 60 12 56 21",
      "ref:FR:FINESS": "910008853",
      since: "2004-01-02",
    },
  },
  {
    type: "node",
    id: 3485639946,
    x: 48.6892785,
    y: 2.2891875,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "163",
      "addr:postcode": "91160",
      "addr:street": "Grande Rue",
      alt_name: "SNC Pharmacie Brenaut",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 09 23 71",
      "contact:phone": "+33 1 64 48 95 63",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Brenaut",
      "ref:FR:FINESS": "910009885",
      since: "1991-01-16",
    },
  },
  {
    type: "node",
    id: 3485639951,
    x: 48.7123054,
    y: 2.3727557,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "180",
      "addr:postcode": "91200",
      "addr:street": "Avenue François Mitterand",
      alt_name: "SNC Pharmacie du Centre",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 09 69",
      "contact:phone": "+33 1 69 38 23 26",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910005354",
      since: "1992-09-25",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3485639957,
    x: 48.6761856,
    y: 2.3514352,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "2",
      "addr:postcode": "91600",
      "addr:street": "Place de la Gare",
      amenity: "pharmacy",
      "contact:phone": "+33169050243",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "910013275",
      since: "1980-08-27",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3485639959,
    x: 48.4836948,
    y: 2.3475124,
    tags: {
      "addr:city": "La Ferté-Alais",
      "addr:housenumber": "2",
      "addr:postcode": "91590",
      "addr:street": "Place de la Libération",
      alt_name: "Pharmacie Liliane Degreyter",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 23 31 64",
      "contact:phone": "+33 1 64 57 78 49",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Grande Pharmacie",
      "ref:FR:FINESS": "910008671",
      since: "1993-01-15",
    },
  },
  {
    type: "node",
    id: 3485639966,
    x: 48.7000499,
    y: 2.3590153,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "2",
      "addr:postcode": "91200",
      "addr:street": "Rue des Oiseaux",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 84 75 97",
      "contact:phone": "+33 1 69 38 63 50",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Paris",
      "ref:FR:FINESS": "910005420",
      since: "2005-10-01",
    },
  },
  {
    type: "node",
    id: 3485639970,
    x: 48.7087816,
    y: 2.2699997,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Champlan",
      "addr:housenumber": "2",
      "addr:postcode": "91160",
      "addr:street": "Rue du Trou Mahet",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 48 85 48",
      "contact:phone": "+33 1 64 48 84 84",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Hingant",
      "opening_hours:covid19": "Mo-Sa 13:30-20:30",
      "ref:FR:FINESS": "910006600",
      since: "1994-01-05",
    },
  },
  {
    type: "node",
    id: 3485639972,
    x: 48.6137687,
    y: 2.4850396,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "2",
      "addr:postcode": "91100",
      "addr:street": "Rue Ferdinand Buisson",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 75 10 20",
      "contact:phone": "+33 1 60 75 00 90",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Marie Reynaud",
      "ref:FR:FINESS": "910006832",
      since: "1996-09-12",
    },
  },
  {
    type: "node",
    id: 3485639977,
    x: 48.7129656,
    y: 2.4802776,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "2",
      "addr:postcode": "91330",
      "addr:street": "Rue Pierre de Coubertin",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 48 82 70",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Frédérique Swiecznik",
      "ref:FR:FINESS": "910014711",
      since: "1987-01-29",
    },
  },
  {
    type: "node",
    id: 3485639978,
    x: 48.652188,
    y: 2.3912848,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "2",
      "addr:postcode": "91350",
      "addr:street": "Rue Saint-Exupéry",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 06 88 08",
      "contact:phone": "+33 1 69 06 52 06",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Besnier Minot",
      "ref:FR:FINESS": "910008986",
      since: "1973-03-20",
    },
  },
  {
    type: "node",
    id: 3485639979,
    x: 48.6555588,
    y: 2.392821,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "2",
      "addr:postcode": "91350",
      "addr:street": "Rue Vlaminck",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 06 56 00",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Mboum",
      "ref:FR:FINESS": "910009182",
      since: "1988-12-15",
    },
  },
  {
    type: "node",
    id: 3485639982,
    x: 48.4343623,
    y: 2.1632851,
    tags: {
      "addr:city": "Étampes",
      "addr:housenumber": "20",
      "addr:postcode": "91150",
      "addr:street": "Place Notre-Dame",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 94 74 54",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Centrale Delamorinière",
      "ref:FR:FINESS": "910008101",
      since: "1983-09-28",
    },
  },
  {
    type: "node",
    id: 3485639987,
    x: 48.6683436,
    y: 2.3615899,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "21",
      "addr:postcode": "91170",
      "addr:street": "Avenue de Provence",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 24 22 99",
      "contact:phone": "+33 1 69 24 34 23",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Provence",
      "ref:FR:FINESS": "910014380",
      since: "1990-08-09",
    },
  },
  {
    type: "node",
    id: 3485639988,
    x: 48.715497,
    y: 2.4918264,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "22",
      "addr:postcode": "91330",
      "addr:street": "Rue Charles de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 83 50",
      "contact:phone": "+33 1 69 48 50 62",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Pascal Léger",
      "ref:FR:FINESS": "910014547",
      since: "1993-01-14",
    },
  },
  {
    type: "node",
    id: 3485639992,
    x: 48.5534781,
    y: 2.1218719,
    tags: {
      "addr:city": "Saint-Chéron",
      "addr:housenumber": "24",
      "addr:postcode": "91530",
      "addr:street": "Rue Charles de Gaulle",
      alt_name: "SNC Pharmacie des Trois Vallées",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 56 64 48",
      "contact:phone": "+33 1 64 56 67 36",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie des Trois Vallées",
      "ref:FR:FINESS": "910012293",
      since: "1984-06-27",
    },
  },
  {
    type: "node",
    id: 3485646102,
    x: 48.6101052,
    y: 2.478089,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "27",
      "addr:postcode": "91100",
      "addr:street": "Rue Feray",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 96 27 97",
      "contact:phone": "+33 1 64 96 10 68",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Perriches Sayanoff",
      "ref:FR:FINESS": "910006816",
      since: "1989-06-20",
    },
  },
  {
    type: "node",
    id: 3485646104,
    x: 48.7363005,
    y: 2.261395,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "28",
      "addr:postcode": "91300",
      "addr:street": "Allée Albert Thomas",
      alt_name: "Pharmacie de Villaine",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 20 58 22;+33 1 69 30 38 76",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Anne Wittmer",
      opening_hours: "Tu-Fr 08:00-12:15,14:30-18:45;Sa 08:00-20:00",
      "opening_hours:covid19":
        "Mo 14:30-18:45 ;Tu-Fr 09:00-12:15,14:30-18:45;Sa 09:00-12:30,14:30-17:30",
      "ref:FR:FINESS": "910010164",
      since: "1991-12-20",
    },
  },
  {
    type: "node",
    id: 3485646105,
    x: 48.6837815,
    y: 2.3452176,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "28",
      "addr:postcode": "91600",
      "addr:street": "Boulevard Aristide Briand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 96 00 03",
      "contact:phone": "+33 1 69 96 71 48",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Philippe Martin",
      "ref:FR:FINESS": "910013127",
      since: "1990-03-02",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
    },
  },
  {
    type: "node",
    id: 3485646106,
    x: 48.6668351,
    y: 2.3537261,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "28",
      "addr:postcode": "91390",
      "addr:street": "Rue de Savigny",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 04 60 20",
      "contact:phone": "+33 1 69 04 60 20",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "910011212",
      since: "1997-01-07",
    },
  },
  {
    type: "node",
    id: 3485646110,
    x: 48.6537347,
    y: 2.3419305,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "28",
      "addr:postcode": "91390",
      alt_name: "EURL Pharmacie Beauséjour",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 04 13 61",
      "contact:phone": "+33 1 69 04 43 89",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Beauséjour",
      "ref:FR:FINESS": "910011196",
      since: "1994-11-21",
    },
  },
  {
    type: "node",
    id: 3485646111,
    x: 48.6148935,
    y: 2.3814056,
    tags: {
      "addr:city": "Bondoufle",
      "addr:housenumber": "29",
      "addr:postcode": "91070",
      "addr:street": "Rue Charles de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 86 62 46",
      "contact:phone": "+33 1 60 86 41 31",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Ramel",
      "ref:FR:FINESS": "910005800",
      since: "2008-10-15",
    },
  },
  {
    type: "node",
    id: 3485646113,
    x: 48.6051665,
    y: 2.3138875,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Brétigny-sur-Orge",
      "contact:fax": "+33 1 69 88 49 87",
      "contact:housenumber": "3",
      "contact:phone": "+33 1 69 88 93 13",
      "contact:postcode": "91220",
      "contact:street": "Avenue Charles de Gaulle",
      dispensing: "yes",
      name: "Pharmacie du Rond-point Jean Mermoz",
      "ref:FR:FINESS": "910017482",
      since: "2000-11-21",
    },
  },
  {
    type: "node",
    id: 3485646114,
    x: 48.6860716,
    y: 2.4120196,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "3 Bis",
      "addr:postcode": "91210",
      "addr:street": "Rue de Mainville",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 18 91",
      "contact:phone": "+33 1 69 03 71 28",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "910007657",
      since: "1991-09-02",
    },
  },
  {
    type: "node",
    id: 3485646116,
    x: 48.6920921,
    y: 2.499391,
    tags: {
      "addr:city": "Brunoy",
      "addr:housenumber": "3",
      "addr:postcode": "91800",
      "addr:street": "Rue des Grès",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 46 56 77",
      "contact:phone": "+33 1 60 46 02 77",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Patrick Touboul",
      opening_hours: "Mo-Fr 09:00-19:30",
      "ref:FR:FINESS": "910006337",
      since: "1981-06-09",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3485646133,
    x: 48.5891739,
    y: 2.4536505,
    tags: {
      "addr:city": "Villabé",
      "addr:housenumber": "34",
      "addr:postcode": "91100",
      "addr:street": "Avenue du 8 Mai 1945",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 86 28 70",
      "contact:phone": "+33 1 60 86 14 73",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Kolkiewicz",
      "ref:FR:FINESS": "910013911",
      since: "1981-12-11",
    },
  },
  {
    type: "node",
    id: 3485646134,
    x: 48.6816,
    y: 2.14516,
    tags: {
      "addr:city": "Gometz-le-Châtel",
      "addr:housenumber": "35",
      "addr:postcode": "91940",
      "addr:street": "Route de Chartres",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 28 23 88",
      "contact:phone": "+33 1 69 28 20 05",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Genuist",
      "opening_hours:covid19": "open",
      operator: "Agnès et Alexandre GENUIST",
      "ref:FR:FINESS": "910018332",
      since: "2001-05-15",
    },
  },
  {
    type: "node",
    id: 3485646135,
    x: 48.7016938,
    y: 2.3715679,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "41",
      "addr:postcode": "91200",
      "addr:street": "Avenue François Mitterand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 38 27 95",
      "contact:phone": "+33 1 69 38 27 95",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Centrale d'Athis-Mons",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-19:00; Su 09:00-19:30",
      "ref:FR:FINESS": "910005552",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "88342801300014",
      since: "1990-07-27",
    },
  },
  {
    type: "node",
    id: 3485646139,
    x: 48.5803133,
    y: 2.224689,
    tags: {
      "addr:city": "Égly",
      "addr:housenumber": "36",
      "addr:postcode": "91520",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 83 12 03",
      "contact:phone": "+33 1 64 90 21 31",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Daix et Naudin",
      "ref:FR:FINESS": "910007806",
      since: "2008-04-01",
    },
  },
  {
    type: "node",
    id: 3485646146,
    x: 48.6766631,
    y: 2.3799288,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "39",
      "addr:postcode": "91170",
      "addr:street": "Boulevard Husson",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 05 24 31",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie des Lacs",
      "ref:FR:FINESS": "910014315",
      since: "1987-07-07",
    },
  },
  {
    type: "node",
    id: 3485646148,
    x: 48.6848519,
    y: 2.3302322,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "4",
      "addr:postcode": "91600",
      "addr:street": "Place Beaumarchais",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 05 94 88",
      "contact:phone": "+33 1 69 05 07 09",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Marie Bourquin",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "910013192",
      since: "1991-04-17",
    },
  },
  {
    type: "node",
    id: 3485646152,
    x: 48.6150532,
    y: 2.3054228,
    tags: {
      "addr:city": "Brétigny-sur-Orge",
      "addr:housenumber": "4",
      "addr:postcode": "91220",
      "addr:street": "Allée des Cèdres",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 85 17 26",
      "contact:phone": "+33 1 60 84 47 54",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Fontaine",
      "ref:FR:FINESS": "910005982",
      since: "2007-10-15",
    },
  },
  {
    type: "node",
    id: 3485646155,
    x: 48.5911626,
    y: 2.4771758,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "4",
      "addr:postcode": "91100",
      "addr:street": "Boulevard John Fitzgerald Kennedy",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 96 06 15",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Jean Palliser",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910006907",
      since: "1985-08-06",
    },
  },
  {
    type: "node",
    id: 3485646157,
    x: 48.6761502,
    y: 2.3533184,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "4",
      "addr:postcode": "91600",
      "addr:street": "Rue Louis-Jacques Mézard",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 44 38 23",
      "contact:phone": "+33 1 69 44 37 87",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "910013291",
      since: "1994-08-10",
    },
  },
  {
    type: "node",
    id: 3485646162,
    x: 48.4340646,
    y: 2.1631009,
    tags: {
      "addr:city": "Étampes",
      "addr:housenumber": "41",
      "addr:postcode": "91150",
      "addr:street": "Place du Marché Nôtre-Dame",
      alt_name: "Pharmacie de la Fontaine",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 92 83 80",
      "contact:phone": "+33 1 64 94 00 88",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie François",
      "ref:FR:FINESS": "910008135",
      since: "1995-03-27",
    },
  },
  {
    type: "node",
    id: 3485646165,
    x: 48.4497273,
    y: 2.1769216,
    tags: {
      "addr:city": "Morigny-Champigny",
      "addr:housenumber": "43",
      "addr:postcode": "91150",
      "addr:street": "Rue des Ponts",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 94 38 57",
      "contact:phone": "+33 1 64 94 06 75",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Corduant Colbeau",
      "ref:FR:FINESS": "910011030",
      since: "2005-12-01",
    },
  },
  {
    type: "node",
    id: 3485646166,
    x: 48.706858,
    y: 2.3278458,
    tags: {
      "addr:city": "Morangis",
      "addr:district": "Essonne",
      "addr:housenumber": "43",
      "addr:postcode": "91420",
      "addr:street": "Rue du Général Leclerc",
      alt_name: "SARL Pharmacie Haquette",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 34 77 52",
      "contact:phone": "+33 1 69 09 60 56",
      dispensing: "yes",
      name: "Pharmacie Haquette",
      "ref:FR:FINESS": "910010974",
      start_date: "2009-03-30",
    },
  },
  {
    type: "node",
    id: 3485646173,
    x: 48.5145813,
    y: 2.3413995,
    tags: {
      "addr:city": "Itteville",
      "addr:housenumber": "48 Bis",
      "addr:postcode": "91760",
      "addr:street": "Rue Saint-Germain",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 90 90 48",
      "contact:phone": "+33 1 64 93 10 25",
      dispensing: "yes",
      name: "Pharmacie Mongenet Parolini",
      "ref:FR:FINESS": "910009323",
      since: "1990-11-23",
    },
  },
  {
    type: "node",
    id: 3485646177,
    x: 48.6003676,
    y: 2.4244397,
    tags: {
      "addr:city": "Lisses",
      "addr:housenumber": "48",
      "addr:postcode": "91090",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      "contact:fax": "+33 9 71 70 13 73",
      "contact:phone": "+33 1 64 97 62 74",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Village",
      "ref:FR:FINESS": "910009687",
      since: "2009-05-04",
    },
  },
  {
    type: "node",
    id: 3485646181,
    x: 48.6975095,
    y: 2.4315796,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "5",
      "addr:postcode": "91270",
      "addr:street": "Place Anatole France",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 54 08",
      "contact:phone": "+33 1 69 03 41 08",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Fourchtein",
      "ref:FR:FINESS": "910013630",
      since: "1986-05-07",
    },
  },
  {
    type: "node",
    id: 3485646185,
    x: 48.6395776,
    y: 2.3627598,
    tags: {
      "addr:city": "Fleury-Mérogis",
      "addr:housenumber": "5",
      "addr:postcode": "91700",
      "addr:street": "Place du 8 Mai 1945",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 25 03 13",
      "contact:phone": "+33 1 60 15 80 83",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Grande Pharmacie de Fleury",
      "ref:FR:FINESS": "910008721",
      since: "2008-12-01",
    },
  },
  {
    type: "node",
    id: 3485646189,
    x: 48.6874137,
    y: 2.3966931,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "52",
      "addr:postcode": "91210",
      "addr:street": "Boulevard du Général de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 33 68",
      "contact:phone": "+33 1 69 42 33 68",
      dispensing: "yes",
      name: "Pharmacie Danièle Pontois",
      "ref:FR:FINESS": "910007566",
      since: "1993-09-17",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2021",
    },
  },
  {
    type: "node",
    id: 3485646192,
    x: 48.6381841,
    y: 2.3117532,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "53",
      "addr:postcode": "91240",
      "addr:state": "Essonne",
      "addr:street": "Rue de Sainte-Geneviève-des-Bois",
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 15 74 73",
      dispensing: "yes",
      name: "Pharmacie Goudenège",
      "ref:FR:FINESS": "910012897",
      since: "1990-03-29",
    },
  },
  {
    type: "node",
    id: 3485646193,
    x: 48.685966,
    y: 2.3492691,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "54",
      "addr:postcode": "91600",
      "addr:street": "Boulevard Aristide Briand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 44 65 93",
      "contact:phone": "+33 1 69 44 63 07",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Nghiem Phu Anh Phiet",
      "ref:FR:FINESS": "910017607",
      since: "2000-10-30",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
    },
  },
  {
    type: "node",
    id: 3485646197,
    x: 48.6918111,
    y: 2.431438,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "56",
      "addr:postcode": "91210",
      "addr:street": "Chemin Vert",
      amenity: "pharmacy",
      "contact:fax": "+33 9 62 26 18 81",
      "contact:phone": "+33 1 69 03 49 50",
      dispensing: "yes",
      name: "Pharmacie des Bergeries",
      "ref:FR:FINESS": "910007517",
      since: "1969-06-16",
    },
  },
  {
    type: "node",
    id: 3485646198,
    x: 48.5482511,
    y: 2.3661598,
    tags: {
      "addr:city": "Vert-le-Petit",
      "addr:housenumber": "56",
      "addr:postcode": "91710",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 93 53 99",
      "contact:phone": "+33 1 64 93 26 14",
      dispensing: "yes",
      name: "Pharmacie Alain Vercelot",
      "ref:FR:FINESS": "910013614",
      since: "1980-12-24",
    },
  },
  {
    type: "node",
    id: 3485646202,
    x: 48.6952056,
    y: 2.4803028,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "59",
      "addr:postcode": "91330",
      "addr:street": "Avenue Pasteur",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 92 32",
      "contact:phone": "+33 1 69 48 92 32",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Taillis",
      "ref:FR:FINESS": "910014513",
      since: "1992-03-30",
    },
  },
  {
    type: "node",
    id: 3485646203,
    x: 48.6532635,
    y: 2.3726446,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "6",
      "addr:postcode": "91350",
      "addr:street": "Place aux Herbes",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 45 43 44",
      dispensing: "yes",
      fixme: "Déplacer au 6 de la PLace aux herbes",
      is_in: "Essonne",
      name: "Pharmacie de la Place aux Herbes",
      "ref:FR:FINESS": "910009083",
      since: "2008-02-04",
    },
  },
  {
    type: "node",
    id: 3485646206,
    x: 48.565749,
    y: 2.1713938,
    tags: {
      "addr:city": "Breuillet",
      "addr:housenumber": "6",
      "addr:postcode": "91650",
      "addr:street": "Rue de l'Ancienne Poste",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 58 73 67",
      "contact:phone": "+33 1 64 58 64 47",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910006105",
      since: "1992-08-10",
    },
  },
  {
    type: "node",
    id: 3485646208,
    x: 48.7148372,
    y: 2.435447,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "6",
      "addr:postcode": "91270",
      "addr:street": "Rue de la Longueraie",
      alt_name: "SELAS Pharmacie Centrale de Valdoly",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 70 07",
      "contact:phone": "+33 1 69 42 59 65",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Centrale du Val d'Oly",
      "ref:FR:FINESS": "910013796",
      since: "2004-11-01",
    },
  },
  {
    type: "node",
    id: 3485646210,
    x: 48.687044,
    y: 2.3526506,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "6",
      "addr:postcode": "91600",
      "addr:street": "Avenue des Écoles",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Jean-Yves Caufment",
      opening_hours: "Mo-Fr 9:00-12:30,14:00-20:00; Sa 9:00-12:30,14:00-18:00",
      phone: "+33 1 69 96 03 98",
      "ref:FR:FINESS": "910013242",
      since: "1994-12-13",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
      website: "https://www.pharmacie-savigny-orge.fr/",
    },
  },
  {
    type: "node",
    id: 3485646212,
    x: 48.6568979,
    y: 2.3303069,
    tags: {
      "addr:city": "Villemoisson-sur-Orge",
      "addr:housenumber": "6",
      "addr:postcode": "91360",
      "addr:street": "Place de la Libération",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 51 16 60",
      "contact:phone": "+33 1 69 04 05 15",
      dispensing: "yes",
      name: "Pharmacie Édith Loude",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910014125",
      since: "1981-08-30",
    },
  },
  {
    type: "node",
    id: 3485646219,
    x: 48.635306,
    y: 2.304672,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "62",
      "addr:postcode": "91240",
      "addr:street": "Rue de Montlhéry",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 01 07 10",
      "contact:phone": "+33 1 69 01 07 10",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Cardon Labrune",
      "ref:FR:FINESS": "910012863",
      since: "1988-06-06",
    },
  },
  {
    type: "node",
    id: 3485646221,
    x: 48.7063521,
    y: 2.4567686,
    tags: {
      "addr:city": "Montgeron",
      "addr:housenumber": "65",
      "addr:postcode": "91230",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 03 51 30",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910010776",
      since: "2010-02-01",
    },
  },
  {
    type: "node",
    id: 3485646223,
    x: 48.7317965,
    y: 2.2230481,
    tags: {
      "addr:city": "Igny",
      "addr:housenumber": "67",
      "addr:postcode": "91430",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 41 13 03",
      "contact:phone": "+33 1 69 41 09 20",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Fayolle",
      "ref:FR:FINESS": "910009257",
      since: "1992-02-28",
    },
  },
  {
    type: "node",
    id: 3485646233,
    x: 48.6557858,
    y: 2.4089025,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "7",
      "addr:postcode": "91130",
      "addr:street": "Place Jacques Brel",
      amenity: "pharmacy",
      "contact:fax": "+33 1 70 64 66 53",
      "contact:phone": "+33 1 69 06 21 36",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Nguyen N Goc",
      "ref:FR:FINESS": "910012012",
      since: "1990-12-17",
    },
  },
  {
    type: "node",
    id: 3485646234,
    x: 48.7076427,
    y: 2.3967566,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "7",
      "addr:postcode": "91200",
      "addr:street": "Cours Joseph Dewalle",
      alt_name: "SELARL Pharmacie du Val d'Athis",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 57 06 65",
      "contact:phone": "+33 1 69 38 36 08",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie du Val d'Athis",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      phone: "+33 1 69 38 36 08",
      "ref:FR:FINESS": "910005271",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "48169274700019",
      since: "1991-09-25",
    },
  },
  {
    type: "node",
    id: 3485646235,
    x: 48.4346804,
    y: 2.1638527,
    tags: {
      "addr:city": "Étampes",
      "addr:housenumber": "7",
      "addr:postcode": "91150",
      "addr:street": "Place Notre-Dame",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 94 63 37",
      "contact:phone": "+33 1 64 94 46 42",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Notre-Dame",
      "ref:FR:FINESS": "910008085",
      start_date: "2006-09-01",
    },
  },
  {
    type: "node",
    id: 3485646236,
    x: 48.694447,
    y: 2.3093936,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Chilly-Mazarin",
      "contact:fax": "+33 1 64 54 81 76",
      "contact:housenumber": "72",
      "contact:phone": "+33 1 69 09 63 55",
      "contact:postcode": "91380",
      "contact:street": "Rue de Gravigny",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Escalas",
      "ref:FR:FINESS": "910006634",
      since: "1991-07-04",
    },
  },
  {
    type: "node",
    id: 3485646239,
    x: 48.716099,
    y: 2.4455146,
    tags: {
      "addr:city": "Montgeron",
      "addr:housenumber": "72",
      "addr:postcode": "91230",
      "addr:street": "Avenue Jean Jaurès",
      alt_name: "SELURL Pharmacie Petit",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 40 71 73",
      "contact:phone": "+33 1 69 03 61 14",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Petit",
      "ref:FR:FINESS": "910010875",
      since: "2006-10-23",
    },
  },
  {
    type: "node",
    id: 3485646240,
    x: 48.5216564,
    y: 2.265558,
    tags: {
      "addr:city": "Lardy",
      "addr:housenumber": "73",
      "addr:postcode": "91510",
      "addr:street": "Grande Rue",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 27 40 78",
      "contact:phone": "+33 1 69 27 40 24",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Bourgoin",
      opening_hours: "Mo-Fr 09:00-12:15,14:30-20:30",
      "ref:FR:FINESS": "910009505",
      since: "2009-05-01",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3485646245,
    x: 48.4353149,
    y: 2.3771195,
    tags: {
      "addr:city": "Boutigny-sur-Essonne",
      "addr:housenumber": "8",
      "addr:postcode": "91820",
      "addr:street": "Place du Général de Gaulle",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 57 81 43",
      "contact:phone": "+33 1 64 57 92 00",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de Boutigny",
      "ref:FR:FINESS": "910005917",
      since: "2008-01-14",
    },
  },
  {
    type: "node",
    id: 3485646248,
    x: 48.7004089,
    y: 2.3257402,
    tags: {
      "addr:city": "Morangis",
      "addr:housenumber": "80",
      "addr:postcode": "91420",
      "addr:street": "Avenue Gabriel Péri",
      alt_name: "SNC Pharmacie Queau Bessaud",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 09 12 40",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Queau Bessaud",
      "ref:FR:FINESS": "910010990",
      since: "2005-02-01",
    },
  },
  {
    type: "node",
    id: 3485646249,
    x: 48.7434577,
    y: 2.2624522,
    tags: {
      "addr:city": "Verrières-le-Buisson",
      "addr:housenumber": "81",
      "addr:postcode": "91370",
      "addr:street": "Rue d'Estienne d'Orves",
      amenity: "pharmacy",
      "contact:fax": "+33 1 58 94 34 00",
      "contact:phone": "+33 1 69 20 23 42",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Marianne",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,15:00-19:30",
      "ref:FR:FINESS": "910013515",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3485646251,
    x: 48.5950293,
    y: 2.4681729,
    tags: {
      "addr:city": "Corbeil-Essonnes",
      "addr:housenumber": "83",
      "addr:postcode": "91100",
      "addr:street": "Rue de la Papeterie",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 96 10 55",
      "contact:phone": "+33 1 64 96 08 40",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Guillaume Lydenne",
      "ref:FR:FINESS": "910006972",
      since: "1990-09-20",
    },
  },
  {
    type: "node",
    id: 3485646252,
    x: 48.5899631,
    y: 2.217451,
    tags: {
      "addr:city": "Ollainville",
      "addr:housenumber": "83",
      "addr:postcode": "91340",
      "addr:street": "Rue de la République",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 90 45 91",
      "contact:phone": "+33 1 64 90 36 12",
      dispensing: "yes",
      name: "Pharmacie Jaffré",
      "ref:FR:FINESS": "910011311",
    },
  },
  {
    type: "node",
    id: 3485646253,
    x: 48.6808073,
    y: 2.3428178,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:district": "Essonne",
      "addr:housenumber": "84",
      "addr:postcode": "91600",
      "addr:street": "Avenue Charles de Gaulle",
      alt_name: "SELARL Pharmacie Thai",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 05 87 07",
      "contact:phone": "+33 1 69 05 96 80",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Thai",
      "ref:FR:FINESS": "910013226",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
      start_date: "1993-09-02",
    },
  },
  {
    type: "node",
    id: 3485646257,
    x: 48.6960046,
    y: 2.2949476,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "89",
      "addr:postcode": "91160",
      "addr:street": "Rue du Président François Mitterand",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 54 01 77",
      "contact:phone": "+33 1 64 48 81 13",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Bedin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009836",
      since: "1983-05-31",
    },
  },
  {
    type: "node",
    id: 3485646260,
    x: 48.6930234,
    y: 2.2929193,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "9",
      "addr:postcode": "91160",
      "addr:street": "Place de l'Église",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 48 88 44",
      description: "Pharmacie Aroune Lalanne",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009786",
      since: "1983-02-08",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2019",
    },
  },
  {
    type: "node",
    id: 3485646262,
    x: 48.6515143,
    y: 2.3807598,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "9",
      "addr:postcode": "91350",
      "addr:street": "Place du Damier",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 45 44 48",
      "contact:phone": "+33 1 69 45 44 07",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Claude Amiel",
      "ref:FR:FINESS": "910009034",
      since: "1986-12-29",
    },
  },
  {
    type: "node",
    id: 3485646272,
    x: 48.6788316,
    y: 2.411098,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "96",
      "addr:postcode": "91210",
      "addr:street": "Boulevard Henri Barbusse",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 96 14",
      "contact:phone": "+33 1 69 42 30 51",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Christiane Jolivet",
      "ref:FR:FINESS": "910007616",
      since: "1988-01-15",
    },
  },
  {
    type: "node",
    id: 3485646275,
    x: 48.7033,
    y: 2.42783,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "1",
      "addr:postcode": "91270",
      "addr:street": "Place du 8 Mai 1945",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 28 90",
      "contact:phone": "+33 1 69 03 17 48",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Agnès Menard",
      "ref:FR:FINESS": "910013747",
      since: "1989-11-07",
    },
  },
  {
    type: "node",
    id: 3485646277,
    x: 48.6275116,
    y: 2.4475619,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "1",
      "addr:postcode": "91000",
      "addr:street": "Place du Parc aux Lièvres",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 27 67",
      "contact:phone": "+33 1 60 77 27 67",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Bernard Le Coz",
      "ref:FR:FINESS": "910008432",
      since: "1992-08-10",
    },
  },
  {
    type: "node",
    id: 3485646278,
    x: 48.6742737,
    y: 2.338568,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "1",
      "addr:postcode": "91600",
      "addr:street": "Avenue Henri Ouzilleau",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 44 13 42",
      "contact:phone": "+33 1 69 05 46 08",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Charles Marais",
      "ref:FR:FINESS": "910013077",
      since: "1991-01-16",
    },
  },
  {
    type: "node",
    id: 3485646279,
    x: 48.515278,
    y: 2.2694686,
    tags: {
      "addr:city": "Janville-sur-Juine",
      "addr:housenumber": "1",
      "addr:postcode": "91510",
      "addr:street": "Rue Alexandre Thorin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 82 86 04",
      "contact:phone": "+33 1 60 82 86 03",
      dispensing: "yes",
      name: "Pharmacie Charrin & Schom",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910018464",
    },
  },
  {
    type: "node",
    id: 3485646280,
    x: 48.705,
    y: 2.3336,
    tags: {
      "addr:city": "Morangis",
      "addr:housenumber": "10",
      "addr:postcode": "91420",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 09 07 11",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Croisille Decreuse",
      "ref:FR:FINESS": "910011014",
      since: "1991-07-09",
    },
  },
  {
    type: "node",
    id: 3485646281,
    x: 48.7550366,
    y: 2.2158189,
    tags: {
      "addr:city": "Bièvres",
      "addr:housenumber": "4",
      "addr:postcode": "91570",
      "addr:street": "Place Édouard Chennevière",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 19 08 76",
      "contact:phone": "+33 1 69 41 21 86",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Darmon Landry",
      "ref:FR:FINESS": "910005701",
      since: "1990-12-06",
    },
  },
  {
    type: "node",
    id: 3485646282,
    x: 48.6929753,
    y: 2.4128065,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "143",
      "addr:postcode": "91210",
      "addr:street": "Boulevard Henri Barbusse",
      alt_name: "SELARL Pharmacie Dusic Bui",
      amenity: "pharmacy",
      "contact:fax": "+33 9 71 70 40 92",
      "contact:phone": "+33 1 69 03 71 60",
      dispensing: "yes",
      name: "Pharmacie Dusic Bui",
      "ref:FR:FINESS": "910007632",
      since: "2010-02-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2021",
    },
  },
  {
    type: "node",
    id: 3485646283,
    x: 48.5810811,
    y: 2.2388157,
    tags: {
      "addr:city": "Égly",
      "addr:housenumber": "12",
      "addr:postcode": "91520",
      "addr:street": "Rue de la Croix d'Égly",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 90 27 76",
      "contact:phone": "+33 1 64 90 27 76",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Emmanuelle Dambrine",
      "ref:FR:FINESS": "910007772",
      since: "1991-06-28",
    },
  },
  {
    type: "node",
    id: 3485646284,
    x: 48.6798244,
    y: 2.4077193,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "15",
      "addr:postcode": "91210",
      "addr:street": "Place Saint-Rémy",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 20 34",
      "contact:phone": "+33 1 69 42 20 34",
      dispensing: "yes",
      name: "Pharmacie Marsallon Auge",
      "ref:FR:FINESS": "910007707",
      since: "1985-09-19",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3485646285,
    x: 48.6864799,
    y: 2.4191821,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "134",
      "addr:postcode": "91210",
      "addr:street": "Rue Pierre Brossolette",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 01 98",
      "contact:phone": "+33 1 69 03 79 97",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Meunier",
      "ref:FR:FINESS": "910007756",
      since: "2006-10-01",
    },
  },
  {
    type: "node",
    id: 3485646286,
    x: 48.6902061,
    y: 2.2884133,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "162",
      "addr:postcode": "91160",
      "addr:street": "Rue du Président François Mitterrand",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 48 98 11",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Ta",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009851",
      since: "1987-08-19",
    },
  },
  {
    type: "node",
    id: 3485646287,
    x: 48.6483611,
    y: 2.4498013,
    tags: {
      "addr:city": "Soisy-sur-Seine",
      "addr:housenumber": "11",
      "addr:postcode": "91450",
      "addr:street": "Boulevard de Vandeul",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 75 00 34",
      "contact:phone": "+33 1 60 75 00 34",
      dispensing: "yes",
      name: "Pharmacie Zinck",
      opening_hours: "Mo 10:00-12:30,14:30-19:30;Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910013424",
      since: "1976-03-31",
    },
  },
  {
    type: "node",
    id: 3485646289,
    x: 48.5916979,
    y: 2.2582769,
    tags: {
      "addr:city": "Saint-Germain-lès-Arpajon",
      "addr:housenumber": "1 Bis",
      "addr:postcode": "91180",
      "addr:street": "Rue Salvador Allende",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 83 35 75",
      "contact:phone": "+33 1 60 83 35 75",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de l'Orge",
      "ref:FR:FINESS": "910012624",
      since: "2007-12-30",
    },
  },
  {
    type: "node",
    id: 3485646290,
    x: 48.7057302,
    y: 2.4224772,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "1",
      "addr:postcode": "91270",
      "addr:street": "Place du 14 Juillet",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 40 06",
      "contact:phone": "+33 1 69 03 40 06",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Croix Blanche",
      "ref:FR:FINESS": "910013846",
      since: "1989-02-10",
    },
  },
  {
    type: "node",
    id: 3485646291,
    x: 48.6840182,
    y: 2.1700391,
    tags: {
      "addr:city": "Les Ulis",
      "addr:housenumber": "128",
      "addr:postcode": "91940",
      "addr:street": "Avenue des Champs Lasniers",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 07 48 46",
      "contact:phone": "+33 1 69 07 60 62",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Pyramide",
      "ref:FR:FINESS": "910014927",
      since: "1994-10-17",
    },
  },
  {
    type: "node",
    id: 3485646292,
    x: 48.6905386,
    y: 2.2941512,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "12",
      "addr:postcode": "91160",
      "addr:street": "Rue du Docteur Roux",
      alt_name: "SELAS Pharmacie des Côteaux",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 54 09 08",
      "contact:phone": "+33 1 69 09 15 03",
      dispensing: "yes",
      name: "Pharmacie des Côteaux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009752",
      since: "2005-04-04",
    },
  },
  {
    type: "node",
    id: 3485646693,
    x: 48.6878654,
    y: 2.1154659,
    tags: {
      "addr:city": "Gif-sur-Yvette",
      "addr:housenumber": "1",
      "addr:postcode": "91190",
      "addr:street": "Place du Marché Neuf",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 12 35 15",
      "contact:phone": "+33 1 60 12 35 15",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Sa 09:00-13:00, Mo-Fr 14:00-20:00, Sa 14:00-19:30",
      "opening_hours:covid19":
        "Mo-Fr 08:30-13:00,14:00-20:00; Sa 08:30-13:00,14:00-19:30",
      "ref:FR:FINESS": "910008820",
      since: "1983-01-11",
    },
  },
  {
    type: "node",
    id: 3485646694,
    x: 48.692126,
    y: 2.5350386,
    tags: {
      "addr:city": "Boussy-Saint-Antoine",
      "addr:housenumber": "112 Bis",
      "addr:postcode": "91800",
      "addr:street": "Rue Georges Coubard",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 00 46 26",
      "contact:phone": "+33 1 69 00 86 44",
      dispensing: "yes",
      name: "Pharmacie du Vieux Pont",
      "ref:FR:FINESS": "910005883",
      since: "1979-10-15",
    },
  },
  {
    type: "node",
    id: 3485646739,
    x: 48.6905827,
    y: 2.5290698,
    tags: {
      "addr:city": "Boussy-Saint-Antoine",
      "addr:postcode": "91800",
      "addr:street": "Esplanade de la Ferme",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 00 58 09",
      "contact:phone": "+33 1 69 00 58 09",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Dominique Voituriez",
      "ref:FR:FINESS": "910005867",
      since: "1995-11-13",
    },
  },
  {
    type: "node",
    id: 3485646752,
    x: 48.7252004,
    y: 2.276929,
    tags: {
      "addr:city": "Massy",
      "addr:postcode": "91300",
      "addr:street": "Avenue de l'Europe",
      alt_name: "SELARL Pharmacie Yaiche",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 13 05 96",
      "contact:phone": "+33 1 69 30 01 52",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Daniel",
      opening_hours: "Mo-Sa 08:00-20:30",
      "opening_hours:covid19": "Mo-Sa 09:00-14:00,15:00-19:00",
      "ref:FR:FINESS": "910010123",
      since: "2007-07-13",
    },
  },
  {
    type: "node",
    id: 3485646756,
    x: 48.7279767,
    y: 2.3681708,
    tags: {
      "addr:city": "Paray-Vieille-Poste",
      "addr:postcode": "91550",
      amenity: "pharmacy",
      "contact:fax": "+33 1 49 75 75 81",
      "contact:phone": "+33 1 49 75 74 70",
      dispensing: "yes",
      fixme: "Vérifier localisation dans l'aérogare",
      level: "1",
      name: "Pharmacie Orly 4",
      "name:it": "Orly 4",
      "ref:FR:FINESS": "910011790",
      source: "Celtipharm - 10/2014",
      start_date: "1976-04-13",
    },
  },
  {
    type: "node",
    id: 3485646757,
    x: 48.7527689,
    y: 2.2711642,
    tags: {
      "addr:city": "Verrières-le-Buisson",
      "addr:postcode": "91370",
      "addr:street": "Place des Prés-Hauts",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 13 93 24",
      "contact:phone": "+33 1 69 20 34 01",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie des Prés Hauts",
      opening_hours: "Mo-Fr 09:00-13:00, 14:00-19:30; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33169203401",
      "ref:FR:FINESS": "910013465",
      since: "1984-02-01",
    },
  },
  {
    type: "node",
    id: 3485646824,
    x: 48.6998404,
    y: 2.3191554,
    tags: {
      "addr:city": "Chilly-Mazarin",
      "addr:housenumber": "18",
      "addr:postcode": "91380",
      "addr:street": "Rue de la Passerelle",
      alt_name: "Pharmacie Rousseau & Haas",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 10 94 69",
      "contact:phone": "+33 1 69 09 08 60",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Passerelle",
      "ref:FR:FINESS": "910006766",
      since: "1991-08-08",
    },
  },
  {
    type: "node",
    id: 3485646849,
    x: 48.7077946,
    y: 2.4145267,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "2",
      "addr:postcode": "91270",
      "addr:street": "Rue Molière",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 40 01 45",
      "contact:phone": "+33 1 69 42 30 73",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "910013895",
      since: "2007-04-01",
    },
  },
  {
    type: "node",
    id: 3485646889,
    x: 48.596614,
    y: 2.4256557,
    tags: {
      "addr:city": "Lisses",
      "addr:housenumber": "3",
      "addr:postcode": "91090",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 86 38 27",
      "contact:phone": "+33 1 60 86 16 22",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Dubuy",
      "ref:FR:FINESS": "910015197",
      since: "1997-09-16",
    },
  },
  {
    type: "node",
    id: 3485647010,
    x: 48.7166538,
    y: 2.4579197,
    tags: {
      "addr:city": "Crosne",
      "addr:housenumber": "35 Bis",
      "addr:postcode": "91560",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 83 91 76",
      "contact:phone": "+33 1 69 48 25 85",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Stanek Larroque",
      "ref:FR:FINESS": "910007335",
      since: "1999-04-22",
    },
  },
  {
    type: "node",
    id: 3485647023,
    x: 48.6373294,
    y: 2.4203545,
    tags: {
      "addr:city": "Évry",
      "addr:housenumber": "4",
      "addr:postcode": "91000",
      "addr:street": "Place Camille Guérin",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 77 48 23",
      "contact:phone": "+33 1 60 77 48 23",
      dispensing: "yes",
      name: "Pharmacie Evelyne Brière Ciret",
      "ref:FR:FINESS": "910008606",
      since: "1990-12-14",
    },
  },
  {
    type: "node",
    id: 3485647025,
    x: 48.6839271,
    y: 2.3152501,
    tags: {
      "addr:city": "Longjumeau",
      "addr:housenumber": "4",
      "addr:postcode": "91160",
      "addr:street": "Place de la Charmille",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 48 57 10",
      dispensing: "yes",
      name: "Pharmacie Colin",
      "ref:FR:FINESS": "910009737",
      since: "1988-02-05",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2019",
    },
  },
  {
    type: "node",
    id: 3485647030,
    x: 48.5958199,
    y: 2.255283,
    tags: {
      "addr:city": "Saint-Germain-lès-Arpajon",
      "addr:housenumber": "4",
      "addr:postcode": "91180",
      "addr:street": "Résidence Louis Babin",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 90 01 05",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "910012590",
      since: "1991-08-08",
    },
  },
  {
    type: "node",
    id: 3485647034,
    x: 48.6763111,
    y: 2.3423464,
    tags: {
      "addr:city": "Savigny-sur-Orge",
      "addr:housenumber": "40",
      "addr:postcode": "91600",
      "addr:street": "Rue Vigier",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 05 44 25",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Gaye",
      "ref:FR:FINESS": "910013341",
      since: "1987-06-25",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2016",
    },
  },
  {
    type: "node",
    id: 3485647037,
    x: 48.7036644,
    y: 2.4312102,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:district": "Essonne",
      "addr:housenumber": "42",
      "addr:postcode": "91270",
      "addr:street": "Rue Maurice Marion",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 03 40 74",
      dispensing: "yes",
      name: "Pharmacie Brigitte Garcin",
      "ref:FR:FINESS": "910013812",
      start_date: "1996-07-15",
    },
  },
  {
    type: "node",
    id: 3485647042,
    x: 48.6554696,
    y: 2.3860402,
    tags: {
      "addr:city": "Grigny",
      "addr:housenumber": "46",
      "addr:postcode": "91350",
      "addr:street": "Route de Corbeil",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 06 31 91",
      "contact:phone": "+33 1 69 06 24 96",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Lassoued Bassen",
      "ref:FR:FINESS": "910009000",
      since: "1990-08-09",
    },
  },
  {
    type: "node",
    id: 3485647060,
    x: 48.6397516,
    y: 2.3026933,
    tags: {
      "addr:city": "Saint-Michel-sur-Orge",
      "addr:housenumber": "5",
      "addr:postcode": "91240",
      "addr:street": "Rue du Haras",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 01 14 48",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Girard Berton",
      "ref:FR:FINESS": "910012830",
      since: "1984-03-27",
    },
  },
  {
    type: "node",
    id: 3485647068,
    x: 48.6510021,
    y: 2.4149815,
    tags: {
      "addr:city": "Ris-Orangis",
      "addr:housenumber": "55",
      "addr:postcode": "91130",
      "addr:street": "Avenue de la Libération",
      alt_name: "SELARL Pharmacie H & B",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 06 04 97",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie H & B",
      "ref:FR:FINESS": "910011980",
      since: "2008-08-01",
    },
  },
  {
    type: "node",
    id: 3485647070,
    x: 48.6940072,
    y: 2.5130465,
    tags: {
      "addr:city": "Épinay-sous-Sénart",
      "addr:housenumber": "55",
      "addr:postcode": "91860",
      "addr:street": "Rue Sainte-Geneviève",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 45 61",
      "contact:phone": "+33 1 60 46 49 37",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Ohana",
      opening_hours: "Tu-Sa 08:30-13:00,14:00-19:30; Su 09:00-13:00",
      "ref:FR:FINESS": "910007871",
      since: "2007-01-16",
    },
  },
  {
    type: "node",
    id: 3485647073,
    x: 48.6591102,
    y: 2.3665195,
    tags: {
      "addr:city": "Viry-Châtillon",
      "addr:housenumber": "56",
      "addr:postcode": "91170",
      "addr:street": "Avenue Jean Mermoz",
      alt_name: "S.N.C Pharmacie Nicolas",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 24 31 16",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Nicolas",
      "ref:FR:FINESS": "910014331",
      since: "1983-09-01",
    },
  },
  {
    type: "node",
    id: 3485647296,
    x: 48.6747674,
    y: 2.4114334,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "65",
      "addr:postcode": "91210",
      "addr:street": "Boulevard Henri Barbusse",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 42 87 62",
      "contact:phone": "+33 1 69 03 46 30",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Mousseau",
      "ref:FR:FINESS": "910007582",
      since: "1995-01-20",
    },
  },
  {
    type: "node",
    id: 3485647304,
    x: 48.7049444,
    y: 2.3635027,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "7",
      "addr:postcode": "91200",
      "addr:street": "Rue des Froides Bouillies",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 38 59 17",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Kayal",
      phone: "+33 1 60 48 32 31",
      "ref:FR:FINESS": "910005370",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "85368417300018",
      since: "2005-01-10",
    },
  },
  {
    type: "node",
    id: 3485647318,
    x: 48.5330472,
    y: 2.3460115,
    tags: {
      "addr:city": "Itteville",
      "addr:housenumber": "8",
      "addr:postcode": "91760",
      "addr:street": "Place de la Commune de Paris",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 93 15 62",
      "contact:phone": "+33 1 64 93 15 62",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie du Domaine",
      "ref:FR:FINESS": "910009307",
      since: "1991-08-12",
    },
  },
  {
    type: "node",
    id: 3485647419,
    x: 48.7029522,
    y: 2.4426285,
    tags: {
      "addr:city": "Mongeron",
      "addr:postcode": "91230",
      "addr:street": "Place du Soleil",
      alt_name: "SELARL Pharmacie Bellesort",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 03 42 82",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Bellesort",
      "ref:FR:FINESS": "910010891",
      since: "2006-08-28",
    },
  },
  {
    type: "node",
    id: 3485647428,
    x: 48.7346713,
    y: 2.2939427,
    tags: {
      "addr:city": "Massy",
      "addr:postcode": "91300",
      "addr:street": "Allée de Biarritz",
      alt_name: "Pharmacie Biddine",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 20 33 71",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Soleil",
      opening_hours: "Mo-Fr 08:00-20:00;Sa 08:00-19:30",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00;Sa 09:00-19:30",
      "ref:FR:FINESS": "910010362",
      since: "1989-07-27",
    },
  },
  {
    type: "node",
    id: 3487521815,
    x: 48.7176099,
    y: 2.2480165,
    tags: {
      alt_name: "Pharmacie Inaoui",
      amenity: "pharmacy",
      "contact:city": "Palaiseau",
      "contact:fax": "+33 1 60 10 31 26",
      "contact:housenumber": "27",
      "contact:phone": "+33 1 60 14 01 24",
      "contact:postcode": "91120",
      "contact:street": "Rue de Paris",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011642",
      since: "1967-03-20",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3487524737,
    x: 48.6944585,
    y: 2.1520566,
    tags: {
      "addr:city": "Bures-sur-Yvette",
      "addr:housenumber": "27 Bis",
      "addr:postcode": "91440",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 07 68 46",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Omnes",
      "ref:FR:FINESS": "910006485",
      since: "1970-01-21",
    },
  },
  {
    type: "node",
    id: 3500180618,
    x: 48.8120097,
    y: 2.0515673,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Buffon",
      "ref:FR:FINESS": "780007860",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3500180619,
    x: 48.8082479,
    y: 2.0483849,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Fromion",
      "opening_hours:covid19": "open",
      phone: "+33 1 34 60 28 85",
      "ref:FR:FINESS": "780007910",
      source: "Celtipharm - 10/2014",
      website: "https://pharmaciefromion.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 3500180620,
    x: 48.8111689,
    y: 2.0479414,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Temstet",
      "ref:FR:FINESS": "780007944",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3500180621,
    x: 48.8140164,
    y: 2.052198,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Parc Montaigne",
      "ref:FR:FINESS": "780007894",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501372050,
    x: 48.8001692,
    y: 2.0366469,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bonna Weber",
      "ref:FR:FINESS": "780005195",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501372051,
    x: 48.8057634,
    y: 2.0563789,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hameau",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780012993",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501372052,
    x: 48.799403,
    y: 2.0366941,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre Commercial du Bois d'Arcy",
      "ref:FR:FINESS": "780005161",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501388395,
    x: 48.8081716,
    y: 2.05932,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Choucroun",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780012910",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501417885,
    x: 48.8007628,
    y: 2.0258926,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bollet",
      "ref:FR:FINESS": "780005211",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3501425432,
    x: 48.8188743,
    y: 2.0039622,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Poisson",
      "ref:FR:FINESS": "780015996",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3502872765,
    x: 48.8450265,
    y: 2.4324486,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tran",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016215",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3503266470,
    x: 48.8139476,
    y: 2.2956201,
    tags: {
      "addr:city": "Malakoff",
      "addr:housenumber": "55",
      "addr:postcode": "92240",
      "addr:street": "Rue Paul Vaillant-Couturier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sine Ndefeu",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-13:00,15:00-20:00; Sa 09:00-12:30,15:00-19:30",
      operator: "Jean-pierre Balteaux",
      phone: "+33 1 42 53 41 62",
      "ref:FR:FINESS": "920016375",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3514401553,
    x: 49.0445392,
    y: 2.1077728,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lebigre",
      "ref:FR:FINESS": "950011874",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3517750832,
    x: 48.8790241,
    y: 2.4332331,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des 5 Communes",
      "ref:FR:FINESS": "930013685",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3517750833,
    x: 48.882509,
    y: 2.4344125,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930013750",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3517750872,
    x: 48.8833692,
    y: 2.4249356,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen Mat",
      "ref:FR:FINESS": "930013602",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3517750881,
    x: 48.8835995,
    y: 2.4410131,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930013552",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3523626087,
    x: 48.7983411,
    y: 2.3179261,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pasteur",
      "ref:FR:FINESS": "920009081",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526177338,
    x: 48.7764761,
    y: 2.0028509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Beauté",
      "ref:FR:FINESS": "780014064",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526177339,
    x: 48.7739046,
    y: 2.0052621,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014114",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526198336,
    x: 48.7757627,
    y: 2.0007396,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014080",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526208228,
    x: 48.7730657,
    y: 1.9875908,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Boissière",
      "ref:FR:FINESS": "780014130",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526208229,
    x: 48.7838267,
    y: 1.9850997,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Plaine de Neauphle",
      "ref:FR:FINESS": "780014031",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526599277,
    x: 49.1080285,
    y: 2.3485295,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lawniczak",
      "ref:FR:FINESS": "950011791",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526658537,
    x: 48.7632455,
    y: 2.0557921,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Abtan Hagege",
      "ref:FR:FINESS": "780016325",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526658538,
    x: 48.7747759,
    y: 2.0506909,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Ho",
      note: "1 place jacques brel",
      "ref:FR:FINESS": "780008264",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3526658539,
    x: 48.7681693,
    y: 2.0534689,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pleskoff Adda",
      "ref:FR:FINESS": "780011110",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528783574,
    x: 48.7844809,
    y: 2.044731,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Adda Piquet",
      "ref:FR:FINESS": "780010997",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528783575,
    x: 48.7579725,
    y: 2.0394254,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Martin",
      "ref:FR:FINESS": "780016374",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528783576,
    x: 48.7553539,
    y: 2.0604522,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Bretonnière",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780017794",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528788036,
    x: 48.8275052,
    y: 2.0003072,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Avignon",
      "ref:FR:FINESS": "780016077",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528788037,
    x: 48.8258187,
    y: 1.9936957,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Latge",
      "ref:FR:FINESS": "780006847",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528788038,
    x: 48.8225823,
    y: 1.9871987,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Plaete",
      "ref:FR:FINESS": "780006730",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528966817,
    x: 48.8236981,
    y: 1.9906614,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Imbert Duthoit",
      "ref:FR:FINESS": "780006763",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3528966818,
    x: 48.8219573,
    y: 1.9897083,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "780006862",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3529010319,
    x: 48.8538531,
    y: 1.9925576,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cottenceau",
      "ref:FR:FINESS": "780006375",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3529010320,
    x: 48.8563418,
    y: 2.019417,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Saint-Nom",
      "ref:FR:FINESS": "780013462",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3529010321,
    x: 48.8447558,
    y: 2.0634929,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Village",
      "ref:FR:FINESS": "780011524",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3536308799,
    x: 48.8144563,
    y: 2.151105,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Thouvenin",
      opening_hours: "Mo 15:00-19:30; Tu-Sa 09:00-12:30, 15:00-19:30",
      "ref:FR:FINESS": "780015061",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3536308800,
    x: 48.8060557,
    y: 2.1345166,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Versailles",
      "addr:housenumber": "73",
      "addr:postcode": "78000",
      "addr:street": "Rue de la Paroisse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Belvédère",
      opening_hours: "Mo-Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015491",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3536372165,
    x: 48.8018346,
    y: 2.1613862,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cluzel",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015145",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3541202746,
    x: 48.7972327,
    y: 2.1857996,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "24 Bis",
      name: "Pharmacie Boulamery",
      "ref:FR:FINESS": "780014346",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3541202750,
    x: 48.8003155,
    y: 2.1848251,
    tags: {
      "addr:city": "Viroflay",
      "addr:housenumber": "8",
      "addr:postcode": "78220",
      "addr:street": "Boulevard de la Libération",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des Trois Gares",
      opening_hours:
        "Mo 09:00-12:30; Tu-Sa 09:30-12:30; Mo-Fr 14:30-20:00; Sa 14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+331 30 24 40 20",
      "ref:FR:FINESS": "780016226",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacie-viroflay.com/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3541558602,
    x: 48.8585853,
    y: 2.4378479,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Barsan",
      "ref:FR:FINESS": "930010004",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3545048293,
    x: 48.7159006,
    y: 2.49143,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "2",
      "addr:postcode": "91330",
      "addr:street": "Rue de l'Église",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 48 45 43",
      "contact:phone": "+33169484543",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Vermersch Bérenguer",
      "ref:FR:FINESS": "910014562",
      since: "1987-09-24",
    },
  },
  {
    type: "node",
    id: 3548268544,
    x: 48.628843,
    y: 2.5775985,
    tags: {
      "addr:city": "Moissy-Cramayel",
      "addr:housenumber": "524",
      "addr:postcode": "77550",
      "addr:street": "Avenue de l'Europe",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Jatteau",
      phone: "+33 1 64 40 49 61",
      "ref:FR:FINESS": "770005858",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556696906,
    x: 48.9058485,
    y: 2.30131,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cherif",
      "ref:FR:FINESS": "920012564",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556696907,
    x: 48.9004355,
    y: 2.3062542,
    tags: {
      "addr:housenumber": "2",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cohen",
      phone: "+33 1 47 37 12 98",
      "ref:FR:FINESS": "920012630",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556696908,
    x: 48.8997618,
    y: 2.3058679,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Encaoua",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012424",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 3556696909,
    x: 48.9000404,
    y: 2.3064832,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hassan",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012440",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 3556696910,
    x: 48.9046429,
    y: 2.2985306,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Monnet",
      "ref:FR:FINESS": "920012325",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556696911,
    x: 48.9041367,
    y: 2.3026716,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Avenir",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 09:00-19:30",
      "ref:FR:FINESS": "920012531",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556696912,
    x: 48.9023164,
    y: 2.3041918,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "920012515",
      source: "Celtipharm - 10/2014",
      "survey:date": "2020-10-24",
    },
  },
  {
    type: "node",
    id: 3556696913,
    x: 48.9028444,
    y: 2.30991,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Allées",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012614",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556697842,
    x: 48.9031217,
    y: 2.3124069,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-13:00,14:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012762",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556697843,
    x: 48.9001031,
    y: 2.3144405,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Galien",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012812",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805640,
    x: 48.9182811,
    y: 2.3790986,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Coman",
      "ref:FR:FINESS": "930004551",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805641,
    x: 48.9031092,
    y: 2.3483521,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Feuilly Higelin",
      "ref:FR:FINESS": "930014907",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805642,
    x: 48.91377,
    y: 2.382452,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lambez Azzoulay",
      "opening_hours:covid19": "open",
      phone: "+33148340124",
      "ref:FR:FINESS": "930004635",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805643,
    x: 48.9152427,
    y: 2.3770003,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie NGO",
      "ref:FR:FINESS": "930004353",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805644,
    x: 48.9088453,
    y: 2.3395919,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "930015003",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805645,
    x: 48.9093538,
    y: 2.3074488,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "position précise à vérifier",
      healthcare: "pharmacy",
      name: "Pharmacie de la Voie Romaine",
      "ref:FR:FINESS": "920012341",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805646,
    x: 48.9144666,
    y: 2.3573115,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont de Soissons",
      "ref:FR:FINESS": "930014873",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556805647,
    x: 48.9169388,
    y: 2.3307317,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Vieux Saint-Ouen",
      "ref:FR:FINESS": "930015250",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825900,
    x: 48.9142222,
    y: 2.3921272,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bokobza",
      "ref:FR:FINESS": "930004650",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825901,
    x: 48.9012125,
    y: 2.3897753,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale de la Vilette",
      "ref:FR:FINESS": "930012554",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825902,
    x: 48.9196367,
    y: 2.3956754,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Furmansky",
      "ref:FR:FINESS": "930004304",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825903,
    x: 48.9150022,
    y: 2.3859201,
    tags: {
      "addr:city": "Aubervilliers",
      "addr:housenumber": "27",
      "addr:postcode": "93300",
      "addr:street": "Rue Charron",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lepage",
      "ref:FR:FINESS": "930004155",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825904,
    x: 48.9168915,
    y: 2.3898853,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie LUU",
      "ref:FR:FINESS": "930004320",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825905,
    x: 48.9003046,
    y: 2.3949265,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Magenta",
      "ref:FR:FINESS": "930012786",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825906,
    x: 48.9103845,
    y: 2.37919,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Meyer",
      "ref:FR:FINESS": "930004502",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825907,
    x: 48.9057314,
    y: 2.3897472,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Moderne",
      "ref:FR:FINESS": "930004437",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825908,
    x: 48.9079256,
    y: 2.3880981,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen",
      "ref:FR:FINESS": "930004403",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825909,
    x: 48.9019684,
    y: 2.3857862,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Porte de la Villette",
      "ref:FR:FINESS": "930004700",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825911,
    x: 48.9122804,
    y: 2.4010656,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Sultan",
      "ref:FR:FINESS": "930004684",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825912,
    x: 48.9088402,
    y: 2.3980189,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vidal Duvernet",
      "ref:FR:FINESS": "930012604",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825913,
    x: 48.9177598,
    y: 2.3850637,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "930004171",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825914,
    x: 48.9023483,
    y: 2.3892908,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "930004254",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556825915,
    x: 48.918537,
    y: 2.3938286,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Pont Blanc",
      "ref:FR:FINESS": "930004221",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855883,
    x: 48.8913555,
    y: 2.4047658,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Assaad",
      "ref:FR:FINESS": "930012802",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855884,
    x: 48.8925319,
    y: 2.4092085,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cohen de Lara",
      "ref:FR:FINESS": "930012703",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855885,
    x: 48.8938648,
    y: 2.4175421,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du port de Pantin",
      opening_hours: "Mo-Su 09:00-21:00",
      "ref:FR:FINESS": "930012752",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855886,
    x: 48.8950167,
    y: 2.4012043,
    tags: {
      "addr:city": "Pantin",
      "addr:housenumber": "55",
      "addr:postcode": "93500",
      "addr:street": "Rue Hoche",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hoche",
      "ref:FR:FINESS": "930012521",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855887,
    x: 48.8882741,
    y: 2.4013665,
    tags: {
      "addr:city": "Le Pré-Saint-Gervais",
      "addr:country": "FR",
      "addr:housenumber": "39",
      "addr:postcode": "93310",
      "addr:street": "Rue de Stalingrad",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hoffman",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930013354",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "378731871",
      "ref:FR:SIRET": "37873187100018",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3556855888,
    x: 48.8913052,
    y: 2.4021542,
    tags: {
      alt_name: "Pharmacie SETROUK",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Métro",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930012653",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855889,
    x: 48.9147606,
    y: 2.380894,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930004536",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556855890,
    x: 48.8908258,
    y: 2.4015856,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Porte de Pantin",
      "ref:FR:FINESS": "930012620",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912114,
    x: 48.8693756,
    y: 2.4155795,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Abassi",
      "ref:FR:FINESS": "930005467",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912115,
    x: 48.8626761,
    y: 2.4194674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bonne Nouvelle",
      "ref:FR:FINESS": "930005632",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912116,
    x: 48.8946797,
    y: 2.4254675,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Calvet Simon",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930012455",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912117,
    x: 48.8585159,
    y: 2.4361662,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie Croix de Chavaux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930011085",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912118,
    x: 48.8558352,
    y: 2.422994,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Eccel Danjon",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 08:30-11:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "930010863",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912119,
    x: 48.8720855,
    y: 2.4265375,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Errasti",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930005616",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912121,
    x: 48.8549138,
    y: 2.4165187,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Heinz",
      "ref:FR:FINESS": "930010970",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912122,
    x: 48.8599845,
    y: 2.4204324,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Itoumba",
      "ref:FR:FINESS": "930005517",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912123,
    x: 48.8572098,
    y: 2.4320357,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Khaless",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930010707",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912124,
    x: 48.8456541,
    y: 2.4288043,
    tags: {
      alt_name: "Pharmacie Marteau",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Petit Parc",
      opening_hours: "Mo-Sa 08:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016231",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556912125,
    x: 48.8552969,
    y: 2.4199547,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Paul Bert",
      "opening_hours:covid19": "Mo-Su,PH 10:00-20:00",
      "ref:FR:FINESS": "930010954",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912126,
    x: 48.8707249,
    y: 2.4212177,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Pharmacie Schneider",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930005673",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912127,
    x: 48.8557002,
    y: 2.4221873,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tran",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:30-20:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "930010244",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556912128,
    x: 48.8682839,
    y: 2.4330427,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Noue",
      "ref:FR:FINESS": "930009972",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556931134,
    x: 48.8761915,
    y: 2.4491843,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Grande Pharmacie des Castors",
      "ref:FR:FINESS": "930011135",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556931135,
    x: 48.8714456,
    y: 2.43845,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Le Pape",
      opening_hours: "Mo-Sa 09:30-19:30",
      "ref:FR:FINESS": "930013651",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556931136,
    x: 48.864064,
    y: 2.441885,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Vieux Montreuil",
      "ref:FR:FINESS": "930011002",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556933965,
    x: 48.8763398,
    y: 2.4367745,
    tags: {
      "addr:housenumber": "1",
      amenity: "pharmacy",
      "contact:phone": "+33 1 48 45 07 75",
      dispensing: "yes",
      name: "Pharmacie Debut",
      "ref:FR:FINESS": "930013537",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "419470265",
      "ref:FR:SIRET": "41947026500010",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3556944093,
    x: 48.8612357,
    y: 2.4593277,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Awong",
      "ref:FR:FINESS": "930010525",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556944094,
    x: 48.8634701,
    y: 2.4493297,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Cecilon",
      "ref:FR:FINESS": "930011150",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556944095,
    x: 48.8625965,
    y: 2.4659389,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kerdja",
      "ref:FR:FINESS": "930011234",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556944096,
    x: 48.8588337,
    y: 2.463705,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie TRAN",
      "ref:FR:FINESS": "930010905",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556948453,
    x: 48.8529504,
    y: 2.4433541,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Ma Pharmacie",
      "ref:FR:FINESS": "930010285",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556948454,
    x: 48.8469531,
    y: 2.4525593,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "Livraison uniquement aux personnes fragiles et COVID+",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Villette",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 09:00-13:00, 14:00-20:00; Sa 09:00-13:00, 14:00-19:30",
      "opening_hours:covid19": "Sa 09:00-12:30,14:00-19:45",
      phone: "+33 1 43 28 16 64",
      "ref:FR:FINESS": "940015977",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556948455,
    x: 48.8554719,
    y: 2.4393646,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Herold",
      opening_hours: "Mo-Sa 08:00-20:00",
      "ref:FR:FINESS": "930011051",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975107,
    x: 48.8529337,
    y: 2.4572463,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bellenger",
      "ref:FR:FINESS": "940009152",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975108,
    x: 48.8504832,
    y: 2.4754532,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bertrand",
      opening_hours:
        "Mo-Fr 08:45-13:00,14:00-20:00; Sa 09:30-13:00,15:00-19:00",
      "ref:FR:FINESS": "940008972",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975109,
    x: 48.8493082,
    y: 2.4576252,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Dalayrac",
      opening_hours: "Mo-Fr 08:30-20:00, Sa 08:30-19:30",
      "opening_hours:covid19": "Mo-Fr 08:30-20:00, Sa 08:30-19:30",
      "ref:FR:FINESS": "940008956",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556975110,
    x: 48.8438943,
    y: 2.4662063,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Fraysse RER",
      opening_hours:
        "Mo 08:30-12:30,14:30-20:00; Tu-Fr 08:30-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "940009053",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3556975111,
    x: 48.8400908,
    y: 2.484657,
    tags: {
      "addr:city": "Nogent-sur-Marne",
      "addr:housenumber": "32",
      "addr:postcode": "94130",
      "addr:street": "Boulevard Galliéni",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gallieni",
      opening_hours: "Mo-Sa 08:00-20:00",
      "opening_hours:covid19": "Mo-Sa 08:00-20:00",
      phone: "+33 1 48 73 01 47",
      "ref:FR:FINESS": "940011810",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975112,
    x: 48.8380819,
    y: 2.4851675,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Louis Joseph",
      "ref:FR:FINESS": "940011893",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975113,
    x: 48.8527636,
    y: 2.4569447,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Betty Taieb",
      "ref:FR:FINESS": "940009137",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975114,
    x: 48.8466291,
    y: 2.4708282,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Paulin",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009087",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556975115,
    x: 48.8369244,
    y: 2.4831102,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Schemoul",
      "ref:FR:FINESS": "940011760",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556985032,
    x: 48.8382245,
    y: 2.4903418,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lemitre",
      "ref:FR:FINESS": "940011877",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556992345,
    x: 48.8648507,
    y: 2.4784928,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie F Malonga",
      "ref:FR:FINESS": "930010426",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556992346,
    x: 48.8559629,
    y: 2.482184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Godkine",
      "ref:FR:FINESS": "940008873",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556992347,
    x: 48.8613313,
    y: 2.473886,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Ruffins",
      "ref:FR:FINESS": "930010590",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556992348,
    x: 48.8382522,
    y: 2.4952093,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont de Mulhouse",
      "ref:FR:FINESS": "940012693",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3556992349,
    x: 48.833671,
    y: 2.4914485,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Port",
      "opening_hours:covid19": "off",
      "ref:FR:FINESS": "940011927",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557024500,
    x: 48.8198578,
    y: 2.4806402,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gallieni",
      "ref:FR:FINESS": "940010663",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557645969,
    x: 48.790633,
    y: 2.3199489,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Tchaparian",
      opening_hours:
        "Mo-Fr 09:00-13:00, 14:30-20:00; Sa 09:00-13:00, 14:30-19:30",
      operator: "Pharmacie Tchaparian",
      phone: "+33 1 47 40 88 41",
      "ref:FR:FINESS": "940006323",
      "ref:FR:SIRET": "79747744500010",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3557801626,
    x: 48.8254347,
    y: 2.3941502,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Bercy II",
      opening_hours: "Mo-Sa 10:00-20:30",
      "opening_hours:covid19": "Mo-Sa 09:30-19:00",
      "ref:FR:FINESS": "940007503",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3557801627,
    x: 48.8202874,
    y: 2.4154354,
    tags: {
      "addr:city": "Charenton-le-Pont",
      "addr:housenumber": "54",
      "addr:postcode": "94220",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "opening_hours:covid19": "open",
      operator: "Aprium Pharmacie",
      "ref:FR:FINESS": "940007370",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801628,
    x: 48.9256307,
    y: 2.3783616,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Etoth",
      "ref:FR:FINESS": "930007356",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801630,
    x: 48.9314709,
    y: 2.3827278,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lossele",
      "ref:FR:FINESS": "930014832",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801631,
    x: 48.9277805,
    y: 2.3905274,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mafranc",
      "ref:FR:FINESS": "930007570",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801632,
    x: 48.8937253,
    y: 2.4348305,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie MAI",
      "ref:FR:FINESS": "930013636",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3557801633,
    x: 48.8134948,
    y: 2.4267695,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mbiama",
      "ref:FR:FINESS": "940011380",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801637,
    x: 48.8153154,
    y: 2.4296164,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pucci",
      "ref:FR:FINESS": "940011596",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801638,
    x: 48.8183954,
    y: 2.420619,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale de la Poste",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940013964",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801639,
    x: 48.8100079,
    y: 2.4141616,
    tags: {
      "addr:city": "Alfortville",
      "addr:housenumber": "106",
      "addr:postcode": "94140",
      "addr:street": "Rue Véron",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Roustan",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-13:00,14:30-19:00",
      "payment:cards": "yes",
      "payment:cash": "yes",
      "payment:credit_cards": "yes",
      "payment:debit_cards": "yes",
      "payment:maestro": "yes",
      "payment:mastercard": "yes",
      "payment:visa_electron": "yes",
      phone: "+33 1 43 75 28 73",
      "ref:FR:FINESS": "940005622",
      source: "Celtipharm - 10/2014",
      website: "http://pharmacie-alfortville-roustan.fr/",
    },
  },
  {
    type: "node",
    id: 3557801640,
    x: 48.9209273,
    y: 2.3829664,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vesselle",
      "ref:FR:FINESS": "930007422",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801641,
    x: 48.810871,
    y: 2.4318505,
    tags: {
      alt_name: "Pharmacie YOUK TAN",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Métropolis",
      "ref:FR:FINESS": "940011646",
      source: "Celtipharm - 10/2014",
      "source:position": "survey",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3557801644,
    x: 48.805777,
    y: 2.4186725,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Hôtel de Ville",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "940005523",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801645,
    x: 48.9274806,
    y: 2.3850057,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Courneuve",
      "ref:FR:FINESS": "930007455",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801646,
    x: 48.8197037,
    y: 2.4061065,
    tags: {
      "addr:city": "Charenton-le-Pont",
      "addr:housenumber": "58 bis",
      "addr:postcode": "94220",
      "addr:street": "Quai des Carrières",
      amenity: "pharmacy",
      "contact:phone": "+33143684350",
      dispensing: "yes",
      name: "Pharmacie des Quais",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007305",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801648,
    x: 48.9029774,
    y: 2.3746985,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Millenaire",
      "ref:FR:FINESS": "930004387",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801649,
    x: 48.8155136,
    y: 2.4208223,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Rond-Point",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:00-13:00, 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      phone: "+33 1 43 68 09 18",
      "ref:FR:FINESS": "940011364",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3557801650,
    x: 48.7995205,
    y: 2.4252098,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Émile Zola",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940005366",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558035885,
    x: 48.9321918,
    y: 2.3919245,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Verlaine",
      "ref:FR:FINESS": "930007554",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558035886,
    x: 48.9215913,
    y: 2.4015103,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Razafinjatovo",
      "ref:FR:FINESS": "930004122",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558035887,
    x: 48.9304355,
    y: 2.3960703,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Tchoulague",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930007604",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558035888,
    x: 48.9227879,
    y: 2.406895,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "930007372",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558053107,
    x: 48.9213657,
    y: 2.4108924,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie HEAP",
      opening_hours: "Mo 14:00-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "930007521",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558053108,
    x: 48.9197633,
    y: 2.4091594,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Soleil",
      "ref:FR:FINESS": "930007471",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558057642,
    x: 48.9172881,
    y: 2.4064923,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Depin",
      "ref:FR:FINESS": "930004601",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558089418,
    x: 48.9184391,
    y: 2.4149606,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bachouel",
      "ref:FR:FINESS": "930006457",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558089419,
    x: 48.9103614,
    y: 2.416926,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Kanmegni",
      "ref:FR:FINESS": "930006432",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558093826,
    x: 48.9171234,
    y: 2.4363334,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Estop",
      "ref:FR:FINESS": "930008107",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558093827,
    x: 48.9143074,
    y: 2.4361129,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nigelle",
      "ref:FR:FINESS": "930008123",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558093828,
    x: 48.9209646,
    y: 2.4152921,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vally",
      "ref:FR:FINESS": "930007406",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558103897,
    x: 48.9341222,
    y: 2.4254486,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "930006903",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558132695,
    x: 48.8096673,
    y: 2.4525323,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Condorcet",
      "ref:FR:FINESS": "940011265",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3558132696,
    x: 48.7967417,
    y: 2.448325,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Echat",
      "ref:FR:FINESS": "940008402",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3563062693,
    x: 48.6241995,
    y: 2.5823336,
    tags: {
      "addr:city": "Moissy-Cramayel",
      "addr:housenumber": "239",
      "addr:postcode": "77550",
      "addr:street": "Rue des Epinettes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Lugny",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 88 86 10",
      "ref:FR:FINESS": "770009074",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3563071493,
    x: 48.6229522,
    y: 2.5935198,
    tags: {
      "addr:city": "Moissy-Cramayel",
      "addr:postcode": "77550",
      "addr:street": "Place de la Fontaine",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Fontaine (Philippe Cramer)",
      phone: "+33 1 60 60 19 70",
    },
  },
  {
    type: "node",
    id: 3563079193,
    x: 48.6285252,
    y: 2.589589,
    tags: {
      "addr:city": "Moissy-Cramayel",
      "addr:housenumber": "81",
      "addr:postcode": "77550",
      "addr:street": "Place du 14 Juillet 1789",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arcades (Chauderlot Benamran)",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 88 73 88",
      "ref:FR:FINESS": "770009041",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3572969213,
    x: 48.6428019,
    y: 2.8358653,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Branger",
      "ref:FR:FINESS": "770012581",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3578208906,
    x: 48.7392542,
    y: 2.7744241,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nouvelle",
      "ref:FR:FINESS": "770002178",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3578208907,
    x: 48.7401629,
    y: 2.7647958,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "770012193",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3578221340,
    x: 48.740172,
    y: 2.7384476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "770007045",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3578221341,
    x: 48.7404683,
    y: 2.7261321,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Victor Hugo",
      "ref:FR:FINESS": "770007060",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3578221342,
    x: 48.7415955,
    y: 2.7327597,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Eole",
      "ref:FR:FINESS": "770007011",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3585307703,
    x: 48.7920278,
    y: 2.2972412,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      name: "Pharmacie Scarron",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920014065",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3587887122,
    x: 48.5144603,
    y: 2.6346152,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Khalil",
      "ref:FR:FINESS": "770006096",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3609115746,
    x: 49.0186546,
    y: 2.3820587,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Château",
      "ref:FR:FINESS": "950007237",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3609141875,
    x: 49.0273006,
    y: 2.3693723,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "950007203",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3609385132,
    x: 48.9022995,
    y: 2.2432366,
    tags: {
      "addr:housenumber": "116",
      amenity: "pharmacy",
      brand: "Pharmactiv",
      "contact:phone": "+33 1 43 33 13 54",
      "contact:website": "http://www.pharmacie-marceau.fr/",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Marceau",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      operator: "Frédéric Roussel",
      "ref:FR:FINESS": "920013760",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3610382596,
    x: 48.938109,
    y: 1.9988857,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bazin",
      opening_hours: "Mo-Sa 09:00-12:30, 14:30-19:30",
      "ref:FR:FINESS": "780015947",
    },
  },
  {
    type: "node",
    id: 3613051583,
    x: 48.8699759,
    y: 2.3529531,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Porte Saint-Denis",
      opening_hours: "24/7",
      phone: "+33 1 47 70 06 70",
      "ref:FR:FINESS": "750017493",
      source: "Celtipharm - 10/2014",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3623282843,
    x: 48.8388147,
    y: 2.3501978,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "2",
      "addr:postcode": "75005",
      "addr:street": "Rue Claude Bernard",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Muraz",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "750010753",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3633073493,
    x: 48.8095582,
    y: 2.1898148,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-10-17",
      dispensing: "yes",
      name: "Pharmacie Sarran",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30, Sa 09:00-13:00,14:00-19:30",
      "ref:FR:FINESS": "920011780",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3634003468,
    x: 48.7859282,
    y: 2.3025802,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Carrefour",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "20014214",
      source: "Celtipharm - 10/2014;survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3634790770,
    x: 48.7859282,
    y: 2.3025802,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Carrefour",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "920014214",
      source: "Celtipharm - 10/2014;survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3635877449,
    x: 48.801696,
    y: 2.4029577,
    tags: {
      "addr:housenumber": "10",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Anatole France",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 82 39 51",
      "ref:FR:FINESS": "940016330",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3643186592,
    x: 48.7390926,
    y: 2.2897243,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Tô Minh Luân",
      "ref:FR:FINESS": "920008091",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3655211402,
    x: 48.8626208,
    y: 2.3735952,
    tags: {
      alt_name: "Pharmacie des Simples",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Huynh",
      opening_hours:
        "Mo-Fr 9:00-13:00, 14:30-20:00; Sa 9:00-13:00, 14:30-19:00",
      "opening_hours:covid19":
        "Mo-Fr 9:00-13:00, 14:30-20:00; Sa 9:00-13:00, 14:30-19:00",
      "ref:FR:FINESS": "750019010",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3656458344,
    x: 48.8104439,
    y: 2.3279688,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Grande Pharmacie",
      opening_hours: "Mo-Sa 10:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940005754",
      source: "survey 2015",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 3670602829,
    x: 48.7693636,
    y: 2.4019493,
    tags: {
      "addr:city": "Thiais",
      "addr:housenumber": "25",
      "addr:postcode": "94320",
      "addr:street": "Boulevard de Stalingrad",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ghemri",
      "ref:FR:FINESS": "940014491",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3670602830,
    x: 48.7672517,
    y: 2.414878,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Deux Ponts",
      "ref:FR:FINESS": "940008121",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3676399738,
    x: 48.9421967,
    y: 2.1635453,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hiriart",
      opening_hours: "Mo-Fr 09:00-12:30,14:00-19:00; Sa 09:00-12:30",
      "ref:FR:FINESS": "780013793",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3676399739,
    x: 48.9409571,
    y: 2.1649831,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Printemps",
      "ref:FR:FINESS": "780013942",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3679387949,
    x: 48.9447862,
    y: 2.2516518,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de France",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950005017",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3682556855,
    x: 48.869654,
    y: 2.3110375,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19": "Livraison si nécessaire la journée",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Matignon",
      "opening_hours:covid19": "Mo-Su 08:00-02:00",
      "ref:FR:FINESS": "750015075",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3684201300,
    x: 48.7893989,
    y: 2.3147099,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Medyouf",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920009230",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3685664295,
    x: 48.7898114,
    y: 2.3124705,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie les Bas Longchamps",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-19:00",
      phone: "+33 1 46 63 04 49",
      "ref:FR:FINESS": "920009131",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3686594396,
    x: 48.5380919,
    y: 2.6594031,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Robert",
      "ref:FR:FINESS": "770008746",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3687014250,
    x: 48.7851262,
    y: 2.3083141,
    tags: {
      "addr:city": "Bagneux",
      "addr:housenumber": "56",
      "addr:postcode": "92220",
      "addr:street": "Avenue de Bourg-la-Reine",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nguyen Neyraud",
      opening_hours:
        "Mo 12:30-19:30; Tu-Fr 10:00-19:30; Sa 10:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920008943",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 3687862579,
    x: 48.9363788,
    y: 2.1631854,
    tags: {
      "addr:city": "Sartrouville",
      "addr:housenumber": "80",
      "addr:postcode": "78500",
      "addr:street": "Rue Louise Michel",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo 14:15-19:00; Tu-Fr 09:00-12:30,14:15-19:30; Sa 09:00-12:00,14:15-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 39 14 14 05",
      "ref:FR:FINESS": "780013777",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3687934729,
    x: 48.9387594,
    y: 2.1577342,
    tags: {
      "addr:city": "Sartrouville",
      "addr:housenumber": "74",
      "addr:postcode": "78500",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      phone: "+33 1 39 14 23 74",
      "ref:FR:FINESS": "780013744",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3688329452,
    x: 48.8648448,
    y: 2.3775618,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Assoun",
      opening_hours: "Mo 10:00-20:30; Tu-Fr 09:00-20:30; Sa 09:00-20:00",
      phone: "+33143573456",
      "ref:FR:FINESS": "750019895",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3698707255,
    x: 48.5397667,
    y: 2.6597048,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Soleil",
      "ref:FR:FINESS": "770008563",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3702639959,
    x: 48.873761,
    y: 2.3354031,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie La Fayette",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 11:00-19:30",
    },
  },
  {
    type: "node",
    id: 3708375839,
    x: 48.7652941,
    y: 2.2639705,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "920011145",
    },
  },
  {
    type: "node",
    id: 3715901001,
    x: 49.0909684,
    y: 2.3736222,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Carnelle",
      "ref:FR:FINESS": "950005553",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3720256091,
    x: 48.8096106,
    y: 2.2910818,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Picard",
      "ref:FR:FINESS": "920011491",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3721254121,
    x: 48.9047435,
    y: 2.2336086,
    tags: {
      "addr:housenumber": "21",
      amenity: "pharmacy",
      "contact:phone": "+33 1 42 42 60 83",
      "contact:website": "https://www.pharmaciedeschampsphilippe.fr",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Champs Philippe",
      opening_hours:
        "Mo-Fr 09:30-13:00,14:30-20:00; Sa 09:30-13:00,14:30-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920014644",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3731124928,
    x: 48.7625996,
    y: 2.2829607,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Petit Châtenay",
      opening_hours:
        "Mo 14:00-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "920011194",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3731906558,
    x: 49.0183986,
    y: 2.1982854,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Marroniers",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950005454",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3741459955,
    x: 49.0575532,
    y: 2.0644484,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Aufranc",
      "ref:FR:FINESS": "950010744",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3750570326,
    x: 48.9872158,
    y: 2.1895712,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Montigny Village",
      "ref:FR:FINESS": "950010181",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3765278887,
    x: 48.9837088,
    y: 2.6170682,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bourg",
      "ref:FR:FINESS": "770008910",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 3765799531,
    x: 48.8145941,
    y: 2.2408439,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pierre Vacher",
      "ref:FR:FINESS": "920016631",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3765893788,
    x: 48.8652197,
    y: 2.20473,
    tags: {
      "addr:city": "Rueil-Malmaison",
      "addr:housenumber": "165",
      "addr:postcode": "92500",
      "addr:street": "Avenue du 18 Juin 1940",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 51 03 75",
      "delivery:covid19": "yes",
      "description:covid19":
        "Livraison au cas par cas : téléphoner pour se renseigner",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lalanne Martin",
      "opening_hours:covid19": "Tu-Sa 09:00-12:30,14:30-18:30",
      "ref:FR:FINESS": "920019692",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 3765893791,
    x: 48.8278746,
    y: 2.1845254,
    tags: {
      "addr:city": "Ville-d'Avray",
      "addr:housenumber": "16",
      "addr:postcode": "92410",
      "addr:street": "Rue de Marnes",
      amenity: "pharmacy",
      "contact:phone": "+33 1 47 09 06 56",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Collège",
      "opening_hours:covid19": "Mo 14:30-19:30; Tu-Sa 09:30-12:30,14:30-19:30",
      "ref:FR:FINESS": "920021482",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3767104302,
    x: 48.7699819,
    y: 2.5200714,
    tags: {
      "addr:housenumber": "17-21",
      "addr:postcode": "94370",
      "addr:street": "Rue du Moutier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Moutier",
      "ref:FR:FINESS": "940014244",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3776036657,
    x: 49.1707774,
    y: 2.246262,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gambier",
      "ref:FR:FINESS": "600007785",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3781207674,
    x: 48.7499804,
    y: 2.3232878,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie BONODEAU",
      "ref:FR:FINESS": "940009483",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3786237328,
    x: 48.8960799,
    y: 2.2571832,
    tags: {
      amenity: "pharmacy",
      "description:covid19": "ferme plus tôt",
      dispensing: "yes",
      name: "Pharmacie Hérold",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-14:00",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "920013877",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3796784253,
    x: 48.6533354,
    y: 2.6338483,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 05 23 22",
      dispensing: "yes",
      name: "Pharmacie Marnat",
      "ref:FR:FINESS": "770006492",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3799688284,
    x: 48.9305604,
    y: 2.2079901,
    tags: {
      "addr:housenumber": "144",
      "addr:postcode": "95870",
      "addr:street": "Rue Maurice Berteaux",
      amenity: "pharmacy",
      "contact:phone": "+33 1 39 82 32 48",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo 14:30-20:00 ; Tu-Sa 09:00-12:30,14:30-20:00 ; Su off",
      "ref:FR:FINESS": "950005884",
      "ref:FR:NAF": "4773Z",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3808219904,
    x: 49.0052024,
    y: 1.9084126,
    tags: {
      "addr:city": "Meulan-en-Yvelines",
      "addr:housenumber": "34",
      "addr:postcode": "78250",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      operator: "M. TRIACCA",
      "ref:FR:FINESS": "780010641",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3824932172,
    x: 48.4082173,
    y: 2.6893807,
    tags: {
      "addr:city": "Fontainebleau",
      "addr:housenumber": "6",
      "addr:postcode": "77300",
      "addr:street": "Rue de Grande Bretagne",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Faisanderie",
      phone: "+33 1 64 22 22 13",
      "ref:FR:FINESS": "770006740",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 3830000959,
    x: 48.9587859,
    y: 2.3136255,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Hamiafo",
      "ref:FR:FINESS": "930008404",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3830647162,
    x: 48.7800011,
    y: 2.2732164,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie",
      "ref:FR:FINESS": "920019031",
    },
  },
  {
    type: "node",
    id: 3837645756,
    x: 48.7070185,
    y: 2.0713345,
    tags: {
      "addr:city": "Saint-Rémy-lès-Chevreuse",
      "addr:housenumber": "15",
      "addr:postcode": "78470",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-11-25",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Du Rhodon",
      opening_hours: "Mo-Sa 08:30-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780013512",
    },
  },
  {
    type: "node",
    id: 3841477706,
    x: 48.9464437,
    y: 2.5614794,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Oiknine Suissa",
      "ref:FR:FINESS": "930016621",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3841765060,
    x: 49.0703468,
    y: 2.8017691,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
      "ref:FR:FINESS": "770011476",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3841765061,
    x: 49.0754475,
    y: 2.7887997,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 01 08 81",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Sources",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "770011443",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3845567467,
    x: 48.6620304,
    y: 2.3467087,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "4",
      "addr:postcode": "91390",
      "addr:street": "Rue Vapereau",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 04 61 94",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Éric Martin",
      "ref:FR:FINESS": "910011246",
      since: "1984-08-10",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2015",
    },
  },
  {
    type: "node",
    id: 3846298545,
    x: 49.0971162,
    y: 2.5181219,
    tags: {
      "addr:housenumber": "137B",
      "addr:street": "Avenue Henri Barbusse",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Ysieux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950009803",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3859257033,
    x: 48.8259855,
    y: 2.2385548,
    tags: {
      "addr:housenumber": "42",
      "addr:street": "Avenue Pierre Lefaucheux",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Trapèze",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3868100844,
    x: 48.93121,
    y: 2.42603,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930006986",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3875326489,
    x: 48.9097673,
    y: 2.5501491,
    tags: {
      alt_name: "Pharmacie COHEN",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Marronniers",
      "ref:FR:FINESS": "930007067",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3880270052,
    x: 48.7294964,
    y: 2.2758585,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "99",
      "addr:postcode": "91300",
      "addr:street": "Rue Gabriel Péri",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 12 22 13",
      "contact:phone": "+33 1 69 20 04 22",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Clocher",
      opening_hours:
        "Mo-Fr 08:30-19:30; Sa 09:00-19:00;2022 Aug 01-31 09:00-13:00,14:00-19:00",
      "opening_hours:covid19":
        "Mo-Sa 08:30-13:00; Mo-Fr 14:30-19:00; Sa 14:30-19:00",
      operator: "S.N.C. PHARM DU CLOCHER",
      owner: "Anne-Claire Leroux",
      "ref:FR:FINESS": "910010347",
      "ref:FR:SIRET": "482 136 769 00015",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3894158196,
    x: 49.0459068,
    y: 2.5102085,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Porte",
      "ref:FR:FINESS": "950009621",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3895531456,
    x: 49.0570686,
    y: 2.5011062,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dehée",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950011312",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3895612568,
    x: 48.8881845,
    y: 2.450649,
    tags: {
      amenity: "pharmacy",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 3895695114,
    x: 49.0991134,
    y: 2.521428,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Ethodet",
      "ref:FR:FINESS": "950007906",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3901511586,
    x: 48.8138945,
    y: 2.278451,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Clos",
      opening_hours: "Mo-Fr 08:30-20:15; Sa 09:00-13:00, 15:00-19:00",
      "ref:FR:FINESS": "920016292",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3905020277,
    x: 48.8504248,
    y: 2.2853175,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      opening_hours:
        "Mo-Fr 09:00-14:30,15:00-20:00; Sa 09:00-14:30,15:00-19:30",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 3911195012,
    x: 48.5650073,
    y: 2.6274286,
    tags: {
      "addr:city": "Vert-Saint-Denis",
      "addr:housenumber": "7",
      "addr:postcode": "77240",
      "addr:street": "Avenue du Bois Vert",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bois Vert",
      phone: "+33 1 64 41 78 85",
      "ref:FR:FINESS": "770012680",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 3914008628,
    x: 49.0609657,
    y: 2.1692262,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Jardins de Bonneville",
      "ref:FR:FINESS": "950009951",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 3927836581,
    x: 48.8782951,
    y: 2.3537083,
    tags: {
      alt_name_1: "Pharmacie Ouaknine",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale du Nord",
      opening_hours: "Mo-Fr 09:00-21:00; Sa 09:30-20:00; PH,Su 10:00-20:00",
      "ref:FR:FINESS": "750017881",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 3932893455,
    x: 48.9992468,
    y: 2.2404326,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950010991",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales et de la Santé - 2014-05-05",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3948194879,
    x: 48.9151455,
    y: 2.5807429,
    tags: {
      "addr:city": "Coubron",
      "addr:housenumber": "150",
      "addr:postcode": "93470",
      "addr:street": "Rue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Zaoui",
      "ref:FR:FINESS": "930007273",
      shop: "chemist",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3951473206,
    x: 48.836803,
    y: 2.6724791,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770005411",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 3954234392,
    x: 48.917455,
    y: 2.6019043,
    tags: {
      "addr:city": "Courtry",
      "addr:housenumber": "12",
      "addr:postcode": "77181",
      "addr:street": "Rue du Général de Gaulle",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "La mairie propose un service de livraison à domicile. Contactez le CCAS au 01 64 26 60 11 ou au 06 74 78 46 53 ou par mail : ccas@ville-courtry.fr",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Escale Santé 77",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 23 67",
      "ref:FR:FINESS": "770005874",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 3957457113,
    x: 48.9080694,
    y: 2.5441643,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
    },
  },
  {
    type: "node",
    id: 3967122018,
    x: 48.9197435,
    y: 2.5390331,
    tags: {
      "addr:city": "Livry-Gargan",
      "addr:housenumber": "53",
      "addr:postcode": "93190",
      "addr:street": "Avenue du Maréchal Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bitton",
      "ref:FR:FINESS": "930009501",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3979350880,
    x: 48.8101907,
    y: 2.5715961,
    tags: {
      "addr:city": "Le Plessis-Trévise",
      "addr:housenumber": "3",
      "addr:postcode": "94420",
      "addr:street": "Allée des Ambalais",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du MARCHE",
      "ref:FR:FINESS": "940009269",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 3983969308,
    x: 48.8708797,
    y: 2.3993215,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "La Pharmacie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750036816",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 2015-12-18",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 3985076857,
    x: 48.7396143,
    y: 2.4237615,
    tags: {
      "addr:city": "Villeneuve-le-Roi",
      "addr:housenumber": "12",
      "addr:postcode": "94290",
      "addr:street": "Rue Hyppolite Caillat",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 00:00-00:00",
      "opening_hours:covid19": "Mo-Fr 00:00-00:00",
      operator: "Pharmacie de la Gare",
      "ref:FR:FINESS": "940015241",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 3992393522,
    x: 48.755359,
    y: 2.3226921,
    tags: {
      "addr:housenumber": "10",
      "addr:postcode": "94260",
      "addr:street": "Place Pierre et Marie Curie",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "opening_hours:covid19": "open",
      phone: "+33 1 46 66 18 31",
      "ref:FR:FINESS": "940009533",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4002002400,
    x: 48.8739983,
    y: 2.3405946,
    tags: {
      "addr:housenumber": "19",
      "addr:street": "Rue Drouot",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Drouot",
      network: "Votre Pharmacie",
      opening_hours: "Mo-Fr 08:30-19:30; Sa 09:15-13:15",
      "ref:FR:FINESS": "750015976",
    },
  },
  {
    type: "node",
    id: 4002311889,
    x: 48.948026,
    y: 2.3525594,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "930014550",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4011925365,
    x: 48.8985572,
    y: 2.4788783,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "93",
      "addr:postcode": "93140",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      "contact:phone": "+33 1 48 47 01 88",
      dispensing: "yes",
      name: "Pharmacie Barrault",
      "ref:FR:FINESS": "930006754",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "39899711400010",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4011925366,
    x: 48.9101032,
    y: 2.4862401,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "33",
      "addr:postcode": "93140",
      "addr:street": "Rue Fontaine",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rosier",
      "ref:FR:FINESS": "930006572",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "33884597700010",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4011925367,
    x: 48.8984531,
    y: 2.4872163,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "106",
      "addr:postcode": "93140",
      "addr:street": "Rue Louis-Auguste Blanqui",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Saules",
      "ref:FR:FINESS": "930006671",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "37793195100018",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4011925368,
    x: 48.9023344,
    y: 2.4792889,
    tags: {
      "addr:city": "Bondy",
      "addr:country": "FR",
      "addr:housenumber": "26",
      "addr:postcode": "93140",
      "addr:street": "Rue Roger Salengro",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "930006804",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "38373603000016",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4014793311,
    x: 48.7134526,
    y: 2.3959289,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:postcode": "91200",
      "addr:street": "Avenue Henri Dunant",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "phamacie.duclosnollet@perso.alliadis.net",
      name: "Pharmacie du Clos Nollet",
      opening_hours: "Mo-Fr 06:30-13:00,15:00-20:00; Sa 08:00-13:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 69 38 88 37",
      "ref:FR:FINESS": "910005487",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "39377986300025",
      source: "Celtipharm - 10/2014",
      website:
        "https://www.pharmaciengiphar.com/resultat-trouver-une-pharmacie/pharmacie-clos-nollet?cp",
    },
  },
  {
    type: "node",
    id: 4027001901,
    x: 48.7856847,
    y: 2.4312272,
    tags: {
      "addr:city": "Alfortville",
      "addr:country": "FR",
      "addr:housenumber": "5",
      "addr:postcode": "94140",
      "addr:street": "Rue de Grenoble",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mulard",
      "ref:FR:FINESS": "940005432",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "378748743",
      "ref:FR:SIRET": "37874874300010",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4027001902,
    x: 48.7909093,
    y: 2.4276126,
    tags: {
      "addr:city": "Alfortville",
      "addr:country": "FR",
      "addr:postcode": "94140",
      "addr:street": "Rue de Londres",
      amenity: "pharmacy",
      "contact:phone": "+33 1 43 75 20 17",
      dispensing: "yes",
      fixme: "the right place?",
      name: "Pharmacie du Grand Ensemble",
      "ref:FR:FINESS": "940005317",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "434514147",
      "ref:FR:SIRET": "43451414700017",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4027001903,
    x: 48.7861787,
    y: 2.4253895,
    tags: {
      "addr:city": "Alfortville",
      "addr:country": "FR",
      "addr:postcode": "94140",
      amenity: "pharmacy",
      "contact:fax": "+33 1 43 76 03 03",
      "contact:phone": "+33 1 43 76 03 03",
      dispensing: "yes",
      name: "Pharmacie les Goujons",
      "ref:FR:FINESS": "940005655",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "451469159",
      "ref:FR:SIRET": "45146915900011",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4029617490,
    x: 48.7785142,
    y: 2.2918822,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Plus Pharmacie Centrale de Sceaux",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920020534",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4031243075,
    x: 48.7895313,
    y: 2.4658064,
    tags: {
      "addr:city": "Créteil",
      "addr:country": "FR",
      "addr:postcode": "94000",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dahan",
      "ref:FR:FINESS": "940008501",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "325652923",
      "ref:FR:SIRET": "32565292300013",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4031243077,
    x: 48.7715025,
    y: 2.460642,
    tags: {
      "addr:city": "Créteil",
      "addr:country": "FR",
      "addr:postcode": "94000",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 43 99 44 72",
      name: "Pharmacie de la Source",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008832",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "481279610",
      "ref:FR:SIRET": "48127961000010",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4031243078,
    x: 48.7746863,
    y: 2.4563558,
    tags: {
      "addr:city": "Créteil",
      "addr:country": "FR",
      "addr:postcode": "94000",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Lac",
      "ref:FR:FINESS": "940008550",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIREN": "532775178",
      "ref:FR:SIRET": "32023457800039",
      source: "Celtipharm - 10/2014",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4031417539,
    x: 48.9021402,
    y: 2.5110134,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie LE PAPE",
      "ref:FR:FINESS": "930012935",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 4035512971,
    x: 48.9031899,
    y: 2.8118553,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770006443",
      source: "survey 2016",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4038906092,
    x: 48.8795145,
    y: 2.3459962,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Turgot",
      opening_hours: "Mo-Fr 08:30-20:30; Sa 09:00-20:00",
      "ref:FR:FINESS": "750016727",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4044268135,
    x: 48.9327174,
    y: 2.2965764,
    tags: {
      "addr:city": "Gennevilliers",
      "addr:country": "FR",
      "addr:housenumber": "5",
      "addr:postcode": "92230",
      "addr:street": "Place Jean Grandel",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.village92@gmail.com",
      name: "Pharmacie du Village",
      opening_hours: "Mo-Fr 09:00-12:30;Mo-Fr 14:30-19:30;Sa 09:00-12:30",
      "payment:contactless": "yes",
      "payment:mastercard": "yes",
      "payment:visa": "yes",
      phone: "+33 1 47 98 98 67",
      "ref:FR:FINESS": "920014925",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4069246906,
    x: 48.796372,
    y: 2.277588,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie M Bappe",
      "name:fr": "Pharmacie M'Bappe",
      opening_hours: "Mo-Fr 09:00-13:00, 14:00-19:30; Sa 09:00-13:00",
      "ref:FR:FINESS": "920011442",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 4070148959,
    x: 48.8755033,
    y: 2.3158643,
    tags: {
      amenity: "pharmacy",
    },
  },
  {
    type: "node",
    id: 4072548374,
    x: 48.8125844,
    y: 2.8293049,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770012714",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4080227592,
    x: 48.7808584,
    y: 2.2391428,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Coriat",
      "ref:FR:FINESS": "920012143",
      source: "Celtipharm - 10/2014;survey",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4080227593,
    x: 48.7852347,
    y: 2.2379959,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Clamart",
      "ref:FR:FINESS": "920012176",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 4116262344,
    x: 48.8126577,
    y: 2.3711863,
    tags: {
      "addr:city": "Ivry-sur-Seine",
      "addr:housenumber": "8",
      "addr:postcode": "94200",
      "addr:street": "Place du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place",
      "ref:FR:FINESS": "940010242",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4122637925,
    x: 49.0917937,
    y: 2.5680609,
    tags: {
      "addr:city": "Saint-Witz",
      "addr:housenumber": "37",
      "addr:postcode": "95470",
      "addr:street": "Rue du Haut de Senlis",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de Montmélian",
      "ref:FR:FINESS": "950012047",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4144326630,
    x: 48.8745699,
    y: 2.4831583,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930013834",
      source: "survey 10-2017;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4144368975,
    x: 48.8243252,
    y: 2.5097908,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940006901",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 06/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4168140912,
    x: 48.7928037,
    y: 2.3649131,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Arcades",
      opening_hours: "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "940014780",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4191878273,
    x: 48.8985019,
    y: 2.2467008,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-04-30",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Sa 08:30-20:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920013711",
      source: "Microsoft Bing satellite;Celtipharm - 10/2014",
      "source:amenity": "survey",
      "source:name": "survey",
      "takeaway:covid19": "yes",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 4192136502,
    x: 48.9468136,
    y: 2.1468047,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780009296",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4192136503,
    x: 48.9462688,
    y: 2.1461293,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Longueil",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780009312",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4192136504,
    x: 48.946814,
    y: 2.1458089,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780009346",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4192136505,
    x: 48.9474805,
    y: 2.1481162,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
      "ref:FR:FINESS": "780009262",
      source: "survey",
      "source:ref:FR:FINESS": "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4203252104,
    x: 48.7847157,
    y: 2.3451693,
    tags: {
      "addr:housenumber": "81",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benouaiche",
      opening_hours: "Mo-Sa 08:30-21:30",
      phone: "+33 1 46 65 85 18",
      "ref:FR:FINESS": "940009921",
      website: "https://www.pharmacie-benouaiche-94.fr/",
    },
  },
  {
    type: "node",
    id: 4220276301,
    x: 48.8118188,
    y: 2.2708553,
    tags: {
      "addr:city": "Clamart",
      "addr:housenumber": "202 bis",
      "addr:postcode": "92140",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33146424342",
      name: "Pharmacie de la Fourche",
      "ref:FR:FINESS": "920012044",
      "ref:FR:SIRET": "50329535400016",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4224510035,
    x: 48.7852469,
    y: 2.3283774,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      phone: "+33 1 46 64 05 46",
      "ref:FR:FINESS": "940006422",
      "ref:FR:SIRET": "81512262700012",
      source: "survey",
      website:
        "http://ordonnances.leadersante.fr/pharmacie-du-parc-94230-cachan/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4225422316,
    x: 49.1553722,
    y: 2.4422265,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Lamorlaye",
      "ref:FR:FINESS": "600004832",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4230211498,
    x: 48.886857,
    y: 2.2093423,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Source",
      "ref:FR:FINESS": "920017662",
      source: "survey 2016",
    },
  },
  {
    type: "node",
    id: 4242004257,
    x: 48.8670512,
    y: 2.4938874,
    tags: {
      "addr:city": "Rosny-sous-Bois",
      "addr:housenumber": "142",
      "addr:postcode": "93110",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hamouch",
      "ref:FR:FINESS": "930013917",
      source: "survey:2016-06-13;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4242004260,
    x: 48.8704834,
    y: 2.4878911,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "930013883",
      source: "survey:2016-06-13;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4259039838,
    x: 48.8926276,
    y: 2.1041253,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Eaux Vives",
      "ref:FR:FINESS": "780011664",
    },
  },
  {
    type: "node",
    id: 4260916714,
    x: 49.01987,
    y: 2.2411099,
    tags: {
      "addr:city": "Saint-Leu-la-Forêt",
      "addr:housenumber": "86",
      "addr:postcode": "95320",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jochemczak",
      "ref:FR:FINESS": "950011775",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4295888395,
    x: 48.8556285,
    y: 2.4777739,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Larris",
    },
  },
  {
    type: "node",
    id: 4301973762,
    x: 48.8486738,
    y: 2.1509674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Plateau",
      opening_hours: "Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-19:30",
      "ref:FR:FINESS": "780005765",
    },
  },
  {
    type: "node",
    id: 4307155407,
    x: 48.8686079,
    y: 2.3251595,
    tags: {
      "addr:housenumber": "12",
      "addr:street": "Rue du Chevalier de Saint-George",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Gaidoz Sylvie",
      opening_hours: "Mo-Fr 09:00-19:00; Sa 10:00-19:00",
      phone: "+33 1 42 60 86 14",
      "ref:FR:FINESS": "750008765",
      "type:FR:FINESS": "620",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 4318447642,
    x: 48.51053,
    y: 2.6845978,
    tags: {
      "addr:city": "Livry-sur-Seine",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770007615",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4323282290,
    x: 48.7761445,
    y: 2.3576436,
    tags: {
      "addr:housenumber": "55",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie du Rugby",
      "opening_hours:covid19": "Mo-Sa 09:00-19:00",
      "ref:FR:FINESS": "940010002",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4323304989,
    x: 48.77706,
    y: 2.3542126,
    tags: {
      "addr:city": "L'Haÿ-les-Roses",
      "addr:housenumber": "129",
      "addr:postcode": "94240",
      "addr:street": "Rue de Bicêtre",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des Roses",
      opening_hours: "Mo-Sa 09:00-12:00, 14:00-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-12:00, 14:00-18:00",
      phone: "+33141801445",
      "ref:FR:FINESS": "940009772",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4323306689,
    x: 48.778691,
    y: 2.354597,
    tags: {
      "addr:housenumber": "155",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009806",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4330973391,
    x: 48.8545886,
    y: 2.517909,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33143088413",
      healthcare: "pharmacy",
      name: "Pharmacie du Baobab",
      phone: "+33143089312",
      "ref:FR:FINESS": "930011556",
      "ref:FR:SIRET": "45324466700011",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4336154800,
    x: 48.9183328,
    y: 2.1870896,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2020-10-18",
      dispensing: "yes",
      name: "Pharmacie des Fermettes",
      opening_hours:
        "Mo-Fr 08:30-12:30,14:30-20:00; Sa 08:30-12:30,14:30-19:00",
      "ref:FR:FINESS": "780008496",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4336550690,
    x: 48.9092678,
    y: 2.2366344,
    tags: {
      "addr:housenumber": "10",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      opening_hours: "Mo-Fr 08:00-19:30; Sa 09:00-14:00",
      "ref:FR:FINESS": "920013265",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4336565290,
    x: 48.9197159,
    y: 2.1890025,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "780008561",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4340702592,
    x: 48.922849,
    y: 2.2171315,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bords de Seine",
      opening_hours: "Mo-Fr 08:00-20:00; Sa 09:00-19:00",
    },
  },
  {
    type: "node",
    id: 4340719989,
    x: 48.925725,
    y: 2.2114326,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950005702",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4344595545,
    x: 48.9992552,
    y: 2.4138957,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950013540",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4359176228,
    x: 48.5275502,
    y: 2.6493031,
    tags: {
      "addr:city": "Dammarie-les-Lys",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Corbin",
      "ref:FR:FINESS": "770006112",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4360611038,
    x: 48.8409527,
    y: 2.3217978,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bienvenu",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750024432",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4361978272,
    x: 48.9878395,
    y: 2.2990976,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950012765",
    },
  },
  {
    type: "node",
    id: 4361978278,
    x: 48.98628,
    y: 2.3003288,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950012815",
    },
  },
  {
    type: "node",
    id: 4380204991,
    x: 48.8446049,
    y: 2.3549542,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie",
      "ref:FR:FINESS": "750011272",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4384345854,
    x: 48.7871332,
    y: 2.3537429,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Rabarison",
      "ref:FR:FINESS": "940015092",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 4392772723,
    x: 48.7737939,
    y: 2.2534801,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carbillet",
      "ref:FR:FINESS": "920018991",
    },
  },
  {
    type: "node",
    id: 4398757289,
    x: 48.8218976,
    y: 2.3665741,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie Massena 13",
      "opening_hours:covid19": "open",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4405971144,
    x: 48.7658959,
    y: 2.4053959,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Louis",
      "ref:FR:FINESS": "940008071",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 4409237390,
    x: 48.9540899,
    y: 2.3357156,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie des Tilleuls",
      "ref:FR:FINESS": "930016936",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4412247688,
    x: 48.410827,
    y: 2.7862923,
    tags: {
      "addr:housenumber": "13",
      "addr:street": "Place Greffülhe",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Creton",
      opening_hours:
        "Mo 14:30-19:00; Tu-Fr 09:00-12:15,14:30-19:00; Sa 09:00-12:15",
      "ref:FR:FINESS": "770011930",
      website: "http://pharmacie-creton.fr",
    },
  },
  {
    type: "node",
    id: 4419375970,
    x: 48.8507992,
    y: 2.3753321,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Homéopathique Ledru-Rollin",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 43 47 31 30",
      "ref:FR:FINESS": "750021636",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 2015-12-18",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4423774070,
    x: 48.9249381,
    y: 2.2204873,
    tags: {
      "addr:city": "Bezons",
      "addr:postcode": "95870",
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950005652",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4430972990,
    x: 48.8435614,
    y: 2.6459698,
    tags: {
      "addr:city": "Torcy",
      "addr:housenumber": "14",
      "addr:postcode": "77200",
      "addr:street": "Rue Pablo Neruda",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Lac",
      phone: "+33 1 64 80 40 76",
      "ref:FR:FINESS": "770012094",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4437674689,
    x: 48.8608441,
    y: 2.4415731,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Leconte",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:30-19:30",
      "ref:FR:FINESS": "930010061",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 01/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4438210358,
    x: 48.7318593,
    y: 2.2876786,
    tags: {
      "addr:city": "Massy",
      "addr:housenumber": "15",
      "addr:postcode": "91300",
      "addr:street": "Place de France",
      alt_name: "SELARL Pharmacie de l'Opéra Massy",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 13 92 43",
      "contact:phone": "+33 1 69 20 04 71",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Opéra Massy",
      opening_hours: "Mo-Sa 08:30-19:30",
      "opening_hours:covid19": "Mo-Sa 08:30-18:30",
      "ref:FR:FINESS": "910010297",
      since: "2006-07-14",
    },
  },
  {
    type: "node",
    id: 4459314803,
    x: 48.9012519,
    y: 2.3897719,
    tags: {
      "addr:city": "Pantin",
      "addr:housenumber": "30",
      "addr:postcode": "93500",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale La Villette",
    },
  },
  {
    type: "node",
    id: 4465903281,
    x: 48.7541845,
    y: 2.3017069,
    tags: {
      amenity: "pharmacy",
      "check_date:opening_hours": "2021-03-24",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Métro",
      opening_hours: "Mo-Sa 08:30-20:00",
      "ref:FR:FINESS": "920007861",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4469879140,
    x: 48.7841428,
    y: 2.118437,
    tags: {
      "addr:street": "Route des Docks",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Satory",
      "ref:FR:FINESS": "780014825",
    },
  },
  {
    type: "node",
    id: 4471507017,
    x: 48.7259576,
    y: 2.2465339,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pileu",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      operator: "Voitus et Dudart",
      phone: "+33160142615",
      "ref:FR:FINESS": "910011527",
    },
  },
  {
    type: "node",
    id: 4499282523,
    x: 48.9658932,
    y: 2.2833741,
    tags: {
      "addr:city": "Saint-Gratien",
      "addr:housenumber": "70",
      "addr:postcode": "95210",
      "addr:street": "Rue Berthie Albrecht",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Vau",
      opening_hours:
        "Mo-Fr 08:45-12:30, 14:30-19:45; Sa 09:00-12:30, 14:30-19:30",
      phone: "+33 1 39 89 20 69",
      "ref:FR:FINESS": "950011544",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4499340796,
    x: 48.9684125,
    y: 2.285625,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Forum",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950011593",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4504746131,
    x: 48.9696845,
    y: 2.2885574,
    tags: {
      "addr:city": "Saint-Gratien",
      "addr:housenumber": "40",
      "addr:postcode": "95210",
      "addr:street": "Rue de la Soeur Angèle",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Référence",
      opening_hours:
        "Mo-Fr 08:30-12:30, 13:30-20:00; Sa 09:00-12:30, 14:00-19:00",
      phone: "+33 1 39 89 37 33",
      "ref:FR:FINESS": "950011643",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4510247491,
    x: 48.856123,
    y: 2.7459874,
    tags: {
      "addr:housenumber": "39",
      "addr:postcode": "77600",
      "addr:street": "Avenue du Chêne Saint-Fiacre",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lamontagne",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "770003259",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4511331244,
    x: 48.7888789,
    y: 2.4817412,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Pie",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "940013576",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4515573947,
    x: 48.8914004,
    y: 2.3239088,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Dauny",
    },
  },
  {
    type: "node",
    id: 4524909379,
    x: 48.7827006,
    y: 2.3808963,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Avenue",
      "ref:FR:FINESS": "940016447",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909380,
    x: 48.7938881,
    y: 2.396147,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016827",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909381,
    x: 48.8126056,
    y: 2.3838729,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Martin",
      "ref:FR:FINESS": "940010135",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909382,
    x: 48.7511445,
    y: 2.4880268,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cholay Colteau",
      "ref:FR:FINESS": "940011075",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909383,
    x: 48.7520891,
    y: 2.4872651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centre Limeil",
      "ref:FR:FINESS": "940011042",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909384,
    x: 48.7499492,
    y: 2.4808396,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Degrandi",
      "ref:FR:FINESS": "940011141",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909385,
    x: 48.746211,
    y: 2.4684781,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre-Ville",
      "ref:FR:FINESS": "940014590",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909386,
    x: 48.7932921,
    y: 2.4346817,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de TNK",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011513",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909387,
    x: 48.8100295,
    y: 2.4364234,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "940011224",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909388,
    x: 48.8098525,
    y: 2.4442377,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011240",
      source: "SIRENE 2016-06",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4524909489,
    x: 48.8223739,
    y: 2.5104656,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la République",
      "ref:FR:FINESS": "940007057",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909490,
    x: 48.8209039,
    y: 2.5083362,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bourdais",
      "ref:FR:FINESS": "940006836",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909491,
    x: 48.8194507,
    y: 2.4858006,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Fourchette",
      "ref:FR:FINESS": "940007107",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909492,
    x: 48.8193936,
    y: 2.4876419,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Soussy",
      "ref:FR:FINESS": "940007123",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909493,
    x: 48.8175632,
    y: 2.4946919,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007156",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909494,
    x: 48.8145027,
    y: 2.5078108,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Poste",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "940006950",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909495,
    x: 48.8128848,
    y: 2.5005855,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Marne",
      "ref:FR:FINESS": "940006802",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909496,
    x: 48.8087786,
    y: 2.5389196,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940007032",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909508,
    x: 48.8014872,
    y: 2.5410884,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Proxipharma",
      "ref:FR:FINESS": "940007651",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909511,
    x: 48.7964193,
    y: 2.530473,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007636",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909512,
    x: 48.7903909,
    y: 2.5346987,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Château",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940012362",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909513,
    x: 48.7724226,
    y: 2.518258,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Clos de Pacy",
      "ref:FR:FINESS": "940014160",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909515,
    x: 48.7726292,
    y: 2.4870506,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie CHEAV",
      "ref:FR:FINESS": "940006067",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524909555,
    x: 48.7780063,
    y: 2.4727925,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie TEP SALY",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940008287",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956958,
    x: 48.7754532,
    y: 2.3610571,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Meguader",
      "ref:FR:FINESS": "940007784",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956962,
    x: 48.7622772,
    y: 2.3279528,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre Charcot",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940009350",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4524956963,
    x: 48.7589855,
    y: 2.3225149,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Tuilerie",
      "ref:FR:FINESS": "940009301",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956974,
    x: 48.7349701,
    y: 2.4200454,
    tags: {
      alt_name: "Pharmacie Centrale",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Duchesne",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940015191",
      source: "SIRENE 2016-06",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 4524956975,
    x: 48.7242876,
    y: 2.4187356,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jonchery",
      "ref:FR:FINESS": "940005267",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956976,
    x: 48.805791,
    y: 2.5774901,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940012776",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956977,
    x: 48.808972,
    y: 2.5742457,
    tags: {
      "addr:city": "Le Plessis-Trévise",
      "addr:housenumber": "19",
      "addr:postcode": "94420",
      "addr:street": "Avenue Ardouin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie GRANDCOURT et CHAUPAL",
      "ref:FR:FINESS": "940012743",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956978,
    x: 48.8104673,
    y: 2.5667059,
    tags: {
      "addr:city": "Le Plessis-Trévise",
      "addr:housenumber": "40",
      "addr:postcode": "94420",
      "addr:street": "Avenue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie De Oliveira",
      "ref:FR:FINESS": "940012826",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524956979,
    x: 48.7028379,
    y: 2.5443724,
    tags: {
      amenity: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957194,
    x: 48.737023,
    y: 2.5540727,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de MAROLLES GROSBOIS",
      "ref:FR:FINESS": "940011695",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957195,
    x: 48.7221934,
    y: 2.5344156,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours:
        "Mo-Fr 09:30-12:30,14:30-20:00; Sa 09:30-12:30,14:30-18:30",
      "opening_hours:covid19":
        "Mo-Fr 09:30-12:30,14:30-20:00; Sa 09:30-12:30,14:30-18:30",
      phone: "+33 1 45 99 02 38",
      "ref:FR:FINESS": "940014715",
      source: "SIRENE 2016-06",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4524957198,
    x: 48.7262267,
    y: 2.4458887,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957200,
    x: 48.7331402,
    y: 2.4481697,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Carnot",
      "ref:FR:FINESS": "940015381",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957201,
    x: 48.7354749,
    y: 2.4502217,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940015464",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957231,
    x: 48.7722344,
    y: 2.3741865,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940016363",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524957232,
    x: 48.7669305,
    y: 2.3812656,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Romain Gary",
      "ref:FR:FINESS": "940014376",
      source: "SIRENE 2016-06;survey 11/2018",
    },
  },
  {
    type: "node",
    id: 4524965044,
    x: 48.8055931,
    y: 2.3765233,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la POSTE",
      "ref:FR:FINESS": "940010481",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524965046,
    x: 48.7780236,
    y: 2.3432829,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "940009954",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524965047,
    x: 48.7744332,
    y: 2.3485724,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Corolle",
      "name:it": "Corolle",
      "ref:FR:FINESS": "940009822",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4524965048,
    x: 48.7713881,
    y: 2.3613894,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bittante",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940007800",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103926,
    x: 48.5425893,
    y: 2.6408069,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Guerrier",
      "ref:FR:FINESS": "770008381",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103927,
    x: 48.5403652,
    y: 2.6247807,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770008365",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103928,
    x: 48.5288999,
    y: 2.546908,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770011245",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103929,
    x: 48.5309349,
    y: 2.5412822,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770011294",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103930,
    x: 48.5568068,
    y: 2.5310448,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie D-Douchez",
      "ref:FR:FINESS": "770013530",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103931,
    x: 48.5965764,
    y: 2.5813364,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "770011781",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103932,
    x: 48.663367,
    y: 2.5665102,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770005460",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525103933,
    x: 48.6662029,
    y: 2.5590639,
    tags: {
      "addr:city": "Combs-la-Ville",
      "addr:housenumber": "30",
      "addr:postcode": "77380",
      "addr:street": "Place de l'An 2000",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du Bel Air",
      opening_hours:
        "Tu-Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "770005593",
    },
  },
  {
    type: "node",
    id: 4525103934,
    x: 48.6662959,
    y: 2.5468474,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la GARE",
      "ref:FR:FINESS": "770005510",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525219088,
    x: 48.6940564,
    y: 2.6171233,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770004091",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525220995,
    x: 48.3885747,
    y: 2.8476047,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770012615",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525220998,
    x: 48.4194395,
    y: 2.7328858,
    tags: {
      "addr:housenumber": "27",
      "addr:street": "Avenue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770003630",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525220999,
    x: 48.4039286,
    y: 2.7208028,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770003697",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525221004,
    x: 48.4674807,
    y: 2.6062888,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770004315",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525221005,
    x: 48.5214087,
    y: 2.6617017,
    tags: {
      "addr:city": "La Rochette",
      "addr:housenumber": "69",
      "addr:postcode": "77000",
      "addr:street": "Rue Honoré Daumier",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Forum Santé",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      phone: "+33 1 64 37 23 43",
      "ref:FR:FINESS": "770010890",
    },
  },
  {
    type: "node",
    id: 4525282027,
    x: 48.8113108,
    y: 2.6221314,
    tags: {
      "addr:city": "Émerainville",
      "addr:housenumber": "5",
      "addr:postcode": "77184",
      "addr:street": "Place de l'Europe",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 05 52 40",
      "ref:FR:FINESS": "770006393",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282028,
    x: 48.8508312,
    y: 2.637655,
    tags: {
      "addr:city": "Torcy",
      "addr:postcode": "77200",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Clos de l'Arche",
      opening_hours: "Mo-Sa 09:00-12:30,15:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 05 28 49",
      "ref:FR:FINESS": "770012060",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282029,
    x: 48.8506952,
    y: 2.6511738,
    tags: {
      "addr:city": "Torcy",
      "addr:housenumber": "51",
      "addr:postcode": "77200",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 05 29 07",
      "ref:FR:FINESS": "770012144",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282030,
    x: 48.8544008,
    y: 2.6674456,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770011591",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282031,
    x: 48.8404755,
    y: 2.701344,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770004174",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282032,
    x: 48.8353207,
    y: 2.7100832,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie O Vert",
      opening_hours: "Mo-Fr 08:30-21:00; Sa 09:00-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770016269",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525282033,
    x: 48.8791852,
    y: 2.7087618,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770007474",
      source: "SIRENE 2016-06",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4525284312,
    x: 48.7954845,
    y: 2.6046791,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "43",
      "addr:postcode": "77340",
      "addr:street": "Avenue Charles Rouxel",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Coquelicots",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 28 50 45",
      "ref:FR:FINESS": "770010395",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525284313,
    x: 48.7886609,
    y: 2.6135736,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "96",
      "addr:postcode": "77340",
      "addr:street": "Rue des Berchères",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Berchères",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770010361",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525859416,
    x: 48.9737325,
    y: 2.6415687,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770008811",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525859417,
    x: 49.05361,
    y: 2.6842165,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770006260",
      source: "SIRENE 2016-06;survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 12/2015",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4525859418,
    x: 49.0536716,
    y: 2.6767711,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "770006245",
      source: "SIRENE 2016-06;survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Affaires sociales, de la Santé et des Droits des Femmes - 12/2015",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 4525859420,
    x: 48.9451767,
    y: 2.6862074,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770005346",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525859433,
    x: 48.9398028,
    y: 2.6768613,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770005361",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525859434,
    x: 48.9397524,
    y: 2.6163599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770012946",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4525859435,
    x: 48.9386361,
    y: 2.6053664,
    tags: {
      amenity: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4532824955,
    x: 48.9109356,
    y: 2.4175468,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      source: "Observations personnelles",
    },
  },
  {
    type: "node",
    id: 4534591198,
    x: 48.9136107,
    y: 2.4809299,
    tags: {
      "addr:city": "Bondy",
      "addr:housenumber": "26",
      "addr:postcode": "93140",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale de Bondy",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 48 47 51 88",
      "ref:FR:FINESS": "930006820",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4543362999,
    x: 48.9724354,
    y: 2.3082134,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      opening_hours: "Mo-Su 08:00-20:30",
      "ref:FR:FINESS": "950007336",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4543363049,
    x: 48.9753482,
    y: 2.3082831,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Diep Perdereau",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-20:00",
      "ref:FR:FINESS": "950010413",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4547647220,
    x: 48.8276456,
    y: 2.1173896,
    tags: {
      "addr:city": "Le Chesnay",
      "addr:housenumber": "2",
      "addr:postcode": "78150",
      "addr:street": "Avenue Charles de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Trianon",
      phone: "+33139541041",
      "ref:FR:FINESS": "780006425",
    },
  },
  {
    type: "node",
    id: 4559016962,
    x: 48.9885675,
    y: 2.3185848,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "950010512",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4559016985,
    x: 48.9883252,
    y: 2.3192244,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place du Marché",
      "ref:FR:FINESS": "950010546",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4559017494,
    x: 48.9886777,
    y: 2.3210977,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Saint-Jacques",
      "ref:FR:FINESS": "950010561",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4569466031,
    x: 48.8389808,
    y: 2.5589724,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930011937",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466032,
    x: 48.8826989,
    y: 2.5260516,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930008883",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466033,
    x: 48.8824516,
    y: 2.5241395,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930016472",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466034,
    x: 48.8726693,
    y: 2.5119595,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930011283",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466035,
    x: 48.8608678,
    y: 2.5056908,
    tags: {
      "addr:city": "Neuilly-Plaisance",
      "addr:housenumber": "9",
      "addr:postcode": "93360",
      "addr:street": "Rue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Fr 09:00-20:00, Sa 09:00-19:00, Su Closed",
      "opening_hours:covid19": "open",
      "payment:cash": "yes",
      "payment:mastercard": "yes",
      "payment:visa": "yes",
      phone: "+33 1 43 00 10 73",
      "ref:FR:FINESS": "930011333",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466036,
    x: 48.862515,
    y: 2.503964,
    tags: {
      "addr:city": "Neuilly-Plaisance",
      "addr:housenumber": "32",
      "addr:postcode": "93360",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale Foch",
      opening_hours:
        "Mo-Fr 09:00-12:30; Mo-Fr 14:00-20:00; Sa 09:00-19:00; Su off",
      "opening_hours:covid19": "Mo-Fr 09:00-20:00",
      "payment:cash": "yes",
      "payment:contactless": "yes",
      "payment:mastercard": "yes",
      "payment:visa": "yes",
      phone: "+33 1 43 00 10 33",
      "ref:FR:FINESS": "930011382",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466037,
    x: 48.8777774,
    y: 2.4598842,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Jacquillat",
      "ref:FR:FINESS": "930009907",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466038,
    x: 48.8769724,
    y: 2.4564166,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Leymarie",
      "ref:FR:FINESS": "930009857",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466039,
    x: 48.8979862,
    y: 2.3586027,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "750032252",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466040,
    x: 48.879997,
    y: 2.3312883,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie Brelet",
      opening_hours: "Mo-Fr 08:40-20:00; Sa 09:00-13:00",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466041,
    x: 48.8737506,
    y: 2.319847,
    tags: {
      amenity: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466042,
    x: 48.8463155,
    y: 2.2722006,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466051,
    x: 48.8474426,
    y: 2.3931795,
    tags: {
      amenity: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4569466053,
    x: 48.8327337,
    y: 2.3578394,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982304,
    x: 48.858323,
    y: 2.5813018,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930009022",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982305,
    x: 48.8705017,
    y: 2.5545171,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930011622",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982306,
    x: 48.8993361,
    y: 2.5169564,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930013503",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982307,
    x: 48.895635,
    y: 2.5271473,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930013453",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982308,
    x: 48.8841757,
    y: 2.5113226,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Arts",
      "ref:FR:FINESS": "930016373",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982309,
    x: 48.8906338,
    y: 2.5124412,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "930013404",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982310,
    x: 48.8925775,
    y: 2.512941,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Jardin Anglais",
      "ref:FR:FINESS": "930013420",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982311,
    x: 48.8885243,
    y: 2.5111856,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Outrebon Selarl",
      "ref:FR:FINESS": "930016506",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982312,
    x: 48.8781268,
    y: 2.4788476,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982313,
    x: 48.8731211,
    y: 2.4838356,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Benichou",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930013867",
      source: "survey 10-2017",
    },
  },
  {
    type: "node",
    id: 4571982314,
    x: 48.870444,
    y: 2.4847961,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "930014022",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982315,
    x: 48.8868542,
    y: 2.4688281,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mme Lafage",
      "ref:FR:FINESS": "930012232",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982316,
    x: 48.9946089,
    y: 2.3786487,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950012690",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982317,
    x: 48.995308,
    y: 2.4156898,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950005272",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982318,
    x: 49.0206509,
    y: 2.4557599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Olympiades",
      "ref:FR:FINESS": "950009035",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982319,
    x: 49.0320231,
    y: 2.4679129,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950009001",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4571982326,
    x: 49.0329611,
    y: 2.4736918,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950008953",
      source: "SIRENE 2016-06",
    },
  },
  {
    type: "node",
    id: 4597079549,
    x: 48.8452527,
    y: 2.1740586,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Plateau de Saint-Cucufa",
      "ref:FR:FINESS": "920021417",
      "ref:FR:SIRET": "80011356500017",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4597822699,
    x: 48.8365016,
    y: 2.6500981,
    tags: {
      "access:covid19": "yes",
      "addr:city": "Lognes",
      "addr:housenumber": "69",
      "addr:postcode": "77185",
      "addr:street": "Boulevard du Segrais",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmaciedusegrais@gmail.com",
      healthcare: "pharmacy",
      name: "Pharmacie du Segrais",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      phone: "+33 1 60 17 33 13",
      "ref:FR:FINESS": "770007730",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      "survey:date": "2020-10-22",
      "type:FR:FINESS": "620",
      website: "https://pharmaciedusegrais-lognes.epharmacie.pro/",
    },
  },
  {
    type: "node",
    id: 4604346393,
    x: 48.8829146,
    y: 2.5144903,
    tags: {
      "addr:city": "Villemomble",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Grande Rue",
      "ref:FR:FINESS": "930016456",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4614184040,
    x: 48.9106352,
    y: 2.4271308,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie El Haimer",
      "ref:FR:FINESS": "930006267",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4614203907,
    x: 48.9126049,
    y: 2.4155558,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacenter",
      "ref:FR:FINESS": "930006184",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4618630198,
    x: 48.6593856,
    y: 2.5644563,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie",
    },
  },
  {
    type: "node",
    id: 4621500930,
    x: 48.764885,
    y: 2.3911336,
    tags: {
      "addr:city": "Thiais",
      "addr:housenumber": "7",
      "addr:postcode": "94320",
      "addr:street": "Place du Marché",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      "ref:FR:FINESS": "940014392",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4623206901,
    x: 48.7349878,
    y: 2.4186367,
    tags: {
      "addr:city": "Villeneuve-le-Roi",
      "addr:housenumber": "84",
      "addr:postcode": "94290",
      "addr:street": "Rue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Londo",
      "ref:FR:FINESS": "940015225",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4628937734,
    x: 48.8763594,
    y: 2.2227234,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Liberté",
      "opening_hours:covid19":
        "Mo 15:30-19:30; Tu-Fr 09:00-12:00,15:30-19:30; Sa 09:00-12:30,15:30-19:00",
      phone: "+33 1 45 06 19 09",
      "ref:FR:FINESS": "920021052",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4643910202,
    x: 48.9024203,
    y: 2.3930148,
    tags: {
      "addr:city": "Pantin",
      "addr:housenumber": "81",
      "addr:postcode": "93500",
      "addr:street": "Avenue Édouard Vaillant",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 4 Communes",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      operator: "Benque Sdika",
      phone: "+33 1 48 45 80 24",
      "ref:FR:FINESS": "930012505",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4701790608,
    x: 48.9510753,
    y: 2.3241583,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Presles",
      "ref:FR:FINESS": "930008651",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4701813455,
    x: 48.9541275,
    y: 2.3109872,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930008636",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4727376108,
    x: 48.9584951,
    y: 2.3268639,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      "ref:FR:FINESS": "930008354",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4741705775,
    x: 48.8692431,
    y: 2.4092765,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 4760052284,
    x: 49.0463781,
    y: 2.0337674,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie du Belvédère",
      "ref:FR:FINESS": "950006304",
      "ref:FR:SIRET": "53898681100015",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4783388129,
    x: 48.7941043,
    y: 2.3998546,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940016645",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4793436066,
    x: 48.8912386,
    y: 2.4546061,
    tags: {
      "addr:city": "Noisy-le-Sec",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "930012307",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4826774936,
    x: 48.8764669,
    y: 2.3569609,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale de l'Est",
      opening_hours: "Mo-Fr 08:30-20:30, Sa 09:00-20:00",
      "ref:FR:FINESS": "750017840",
      source: "survey 2017",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4835264038,
    x: 49.0482077,
    y: 2.0339108,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Mairie",
      opening_hours: "Mo-Sa 08:45-20:00",
      "opening_hours:covid19": "Mo-Sa 08:45-20:00",
      "ref:FR:FINESS": "950006254",
      "ref:FR:SIRET": "38027140300012",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4835397996,
    x: 48.8158908,
    y: 2.3677825,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Nguyen Thi",
      "ref:FR:FINESS": "940010580",
      source: "Celtipharm - 10/2014;survey",
    },
  },
  {
    type: "node",
    id: 4843656921,
    x: 48.8830394,
    y: 2.3438996,
    tags: {
      "addr:housenumber": "70",
      "addr:postcode": "75018",
      "addr:street": "Boulevard de Rochechouart",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Monge",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 183810804",
      "ref:FR:FINESS": "750016644",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012",
      website: "https://pharmacie-monge.fr/",
    },
  },
  {
    type: "node",
    id: 4852656060,
    x: 48.5305036,
    y: 2.2931276,
    tags: {
      "addr:city": "Lardy",
      "addr:postcode": "91510",
      "addr:street": "Place des Droits de l'Homme",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 82 74 73",
      "contact:phone": "+33 1 60 82 77 15",
      dispensing: "yes",
      name: "Pharmacie de la gare",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "910002518",
      since: "2002-01-07",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
    },
  },
  {
    type: "node",
    id: 4866295022,
    x: 48.6362052,
    y: 2.3194599,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Hamadi Zinoune",
      "ref:FR:FINESS": "910012715",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4866562640,
    x: 48.8096534,
    y: 1.8826627,
    tags: {
      "addr:city": "Villiers-Saint-Frédéric",
      "addr:housenumber": "30",
      "addr:postcode": "78640",
      "addr:street": "Place du Pontel",
      alt_name: "Pharmacie Ghérardi",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pontel",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      phone: "+33134896157",
      "ref:FR:FINESS": "780016093",
    },
  },
  {
    type: "node",
    id: 4883221366,
    x: 49.0372095,
    y: 2.0791467,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Lacombe",
      "ref:FR:FINESS": "950006171",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4889915216,
    x: 48.7669685,
    y: 2.3192273,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Bourdier",
      "ref:FR:FINESS": "940009970",
    },
  },
  {
    type: "node",
    id: 4895746522,
    x: 48.7777736,
    y: 2.2932681,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie OSTENC",
      "name:en": "Phamarcie",
      "name:fr": "Phamarcie",
      "ref:FR:FINESS": "920020500",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4904689831,
    x: 48.8712633,
    y: 2.3602818,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Azur",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750018111",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4908117889,
    x: 48.9506568,
    y: 2.1363192,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Platanes",
      "ref:FR:FINESS": "780009247",
      source: "Celtipharm - 10/2014",
      "toilets:wheelchair": "yes",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 4919200224,
    x: 48.8624788,
    y: 2.5122751,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Plaisance",
      opening_hours: "Mo-Sa 09:00-12:30, Mo-Fr 14:00-20:00, Sa 14:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930011416",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 4919767221,
    x: 48.8675096,
    y: 2.3449745,
    tags: {
      amenity: "pharmacy",
      healthcare: "pharmacy",
      name: "Apotek",
      "name:fr": "apotek",
    },
  },
  {
    type: "node",
    id: 4921078181,
    x: 49.0303476,
    y: 2.0831575,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Touleuses",
      "ref:FR:FINESS": "950006072",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4921879925,
    x: 48.8655959,
    y: 2.510761,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930011432",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 4931091428,
    x: 48.9009963,
    y: 2.2167994,
    tags: {
      alt_name: "Pharmacie Germain",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Université",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:30-18:00",
      operator: "JP Germain",
      "ref:FR:FINESS": "920018025",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4943649271,
    x: 48.9771312,
    y: 1.9834986,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Marronniers",
      phone: "+33 1 30658743",
      "ref:FR:FINESS": "780014742",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4961757121,
    x: 48.8468833,
    y: 2.2902804,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "103",
      "addr:postcode": "75015",
      "addr:street": "Avenue Émile Zola",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Zola Orthopédie",
      "name:fr": "Zola orthopédie",
      opening_hours: "Mo 13:30-18:30, Tu-Fr 09:30-18:30, Su 09:30-12:30",
      "opening_hours:covid19": "off",
      phone: "+33 1 45 79 08 07",
      website: "http://www.zola-orthopedie.com",
    },
  },
  {
    type: "node",
    id: 4962307630,
    x: 48.9231152,
    y: 2.2551195,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cohen André",
      opening_hours: "Mo-Sa 09:00-20:00",
      "ref:FR:FINESS": "920012861",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 4977922531,
    x: 48.74384,
    y: 2.5036438,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Marguerie",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "940011026",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 4993929342,
    x: 48.8149955,
    y: 2.1936847,
    tags: {
      "addr:city": "Chaville",
      "addr:postcode": "92370",
      "addr:street": "Avenue Roger Salengro",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "920011830",
    },
  },
  {
    type: "node",
    id: 5018794094,
    x: 48.7762506,
    y: 2.5520703,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Pépinière",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-19:30",
      phone: "+33 1 45 90 57 95",
      "ref:FR:FINESS": "940019334",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5034174672,
    x: 48.9859423,
    y: 2.4476632,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "950008854",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5034697268,
    x: 49.0350045,
    y: 2.0006907,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Saint-Martin",
      "ref:FR:FINESS": "950015727",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5054749133,
    x: 48.9205324,
    y: 2.4877442,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930004882",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5067471095,
    x: 48.8860805,
    y: 2.1973689,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Boule",
      "ref:FR:FINESS": "920017647",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5109524072,
    x: 48.7170455,
    y: 2.2341474,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011592",
    },
  },
  {
    type: "node",
    id: 5121464552,
    x: 48.9962475,
    y: 2.415042,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950013565",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5124939402,
    x: 48.937844,
    y: 2.4290048,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "930006937",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5128752836,
    x: 48.9239435,
    y: 2.2602184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920012846",
      source: "knowledge",
    },
  },
  {
    type: "node",
    id: 5153685405,
    x: 48.7863668,
    y: 2.3325347,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Plaine",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-12:30,14:30-19:30; Su off",
      phone: "+33 1 46 65 68 60",
      "ref:FR:FINESS": "940006505",
      "ref:FR:SIRET": "34524871000039",
      source: "Celtipharm - 10/2014",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5156708867,
    x: 49.0003579,
    y: 2.4162358,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Molière",
      "ref:FR:FINESS": "950013441",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5161340430,
    x: 48.7057618,
    y: 2.2121113,
    tags: {
      "addr:city": "Palaiseau",
      "addr:district": "Essonne",
      "addr:housenumber": "6",
      "addr:postcode": "91120",
      "addr:street": "Rue Charles Péguy",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 69312647",
      healthcare: "pharmacy",
      name: "Pharmacie de Lozère",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00, 14:00-19:00; Su 9:00-13:00, 14:00-18:30",
      phone: "+33 1 60103298",
      "ref:FR:FINESS": "910011493",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012;local knowledge",
      start_date: "1985-08-05",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5174172049,
    x: 48.879576,
    y: 2.3575623,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmanord",
      "ref:FR:FINESS": "750017295",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5201799927,
    x: 48.9385938,
    y: 2.1363151,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Guehennec",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "780010526",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 5201799931,
    x: 48.9429374,
    y: 2.1276229,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Mesnil",
      phone: "+33 1 39620500",
      "ref:FR:FINESS": "780010492",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      website: "http://pharmacie-dumesnil.fr/",
    },
  },
  {
    type: "node",
    id: 5204546413,
    x: 48.7826641,
    y: 2.303191,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mauge",
      "ref:FR:FINESS": "920020450",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5208606626,
    x: 48.684519,
    y: 2.1780253,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Courdimanche",
      "ref:FR:FINESS": "910014794",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5214658526,
    x: 48.7912285,
    y: 2.2603641,
    tags: {
      amenity: "pharmacy",
      "contact:housenumber": "38",
      "contact:postcode": "92140",
      "contact:street": "rue de la porte de Trivaux",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmavance",
      "ref:FR:FINESS": "920012226",
    },
  },
  {
    type: "node",
    id: 5220479895,
    x: 48.9769687,
    y: 2.3886774,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "resurvey",
      name: "Pharmacie Cuvelier",
      "ref:FR:FINESS": "950012492",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5220479896,
    x: 48.9784764,
    y: 2.3924196,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "resurvey",
      healthcare: "pharmacy",
      name: "Pharmacie Moghem",
      "ref:FR:FINESS": "950012724",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5220479897,
    x: 48.9798024,
    y: 2.3893596,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fixme: "resurvey",
      name: "Pharmacie de l'Eden",
      "ref:FR:FINESS": "950012740",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5223821938,
    x: 48.7037237,
    y: 2.191659,
    tags: {
      alt_name: "SELARL Pharmacie du Guichet",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 46 68 66",
      "contact:phone": "+33 1 69 28 53 59",
      "delivery:covid19": "yes",
      "description:covid19": "Livraison : contacter pour confirmer",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Guichet",
      opening_hours:
        "Mo-Fr 08:30-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30,14:30-19:00",
      "ref:FR:FINESS": "910011345",
      since: "2010-01-04",
    },
  },
  {
    type: "node",
    id: 5223899423,
    x: 48.840914,
    y: 2.6324598,
    tags: {
      "addr:city": "Lognes",
      "addr:postcode": "77185",
      "addr:street": "Cours des Lacs",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Sa 09:00-13:00,14:30-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 17 23 70",
      "ref:FR:FINESS": "770007714",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5228235856,
    x: 48.8190276,
    y: 2.531695,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "A3",
      "ref:FR:FINESS": "940006653",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5253909100,
    x: 48.6952549,
    y: 2.2983391,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Théâtre",
      "opening_hours:covid19": "open",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5255745911,
    x: 48.5195629,
    y: 2.640856,
    tags: {
      "addr:city": "Dammarie-les-Lys",
      "addr:housenumber": "259",
      "addr:postcode": "77190",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lombardi",
      "ref:FR:FINESS": "770006211",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 5262504933,
    x: 48.3940545,
    y: 2.3764783,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Maisse",
      "contact:housenumber": "49",
      "contact:phone": "+33 1 64 99 50 35",
      "contact:postcode": "91720",
      "contact:street": "Grande Rue",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Nagels Regnault",
      "ref:FR:FINESS": "910009950",
      since: "1980-08-28",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5262507935,
    x: 48.4024766,
    y: 2.4704763,
    tags: {
      alt_name: "SELAS Pharmacie de la Place du Marché",
      amenity: "pharmacy",
      "contact:city": "Milly-la-Forêt",
      "contact:fax": "+33 1 64 98 79 81",
      "contact:housenumber": "25",
      "contact:phone": "+33 1 64 98 85 13",
      "contact:postcode": "91490",
      "contact:street": "Place du Marché",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Place du Marché",
      "ref:FR:FINESS": "910010610",
      since: "2009-12-01",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 5262525657,
    x: 48.402864,
    y: 2.469865,
    tags: {
      alt_name: "SELURL Pharmacie Valérie Méchin;SNC Méchin",
      amenity: "pharmacy",
      "contact:city": "Milly-la-Forêt",
      "contact:fax": "+33 1 64 98 77 75",
      "contact:housenumber": "48",
      "contact:phone": "+33 1 64 98 80 97",
      "contact:postcode": "91490",
      "contact:street": "Grande Rue",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Valérie Méchin",
      "ref:FR:FINESS": "910010644",
      since: "1980-04-16",
    },
  },
  {
    type: "node",
    id: 5262569446,
    x: 48.4926686,
    y: 2.1911062,
    tags: {
      alt_name: "Pharmacie Delouvée",
      amenity: "pharmacy",
      "contact:city": "Étréchy",
      "contact:fax": "+33 1 76 50 11 34",
      "contact:housenumber": "48",
      "contact:phone": "+33 1 60 80 30 06",
      "contact:postcode": "91580",
      "contact:street": "Grande Rue",
      dispensing: "yes",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "910008267",
      since: "1983-12-27",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5262605819,
    x: 48.587286,
    y: 2.2473652,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Arpajon",
      "contact:fax": "+33 1 60 83 07 23",
      "contact:housenumber": "5",
      "contact:phone": "+33 1 69 26 91 52",
      "contact:postcode": "91290",
      "contact:street": "Boulevard Abel Cornaton",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Catherine Gervais",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "910005222",
      since: "1995-10-04",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 5262611692,
    x: 48.5887816,
    y: 2.2491261,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Arpajon",
      "contact:fax": "+33 1 60 83 98 54",
      "contact:housenumber": "2",
      "contact:phone": "+33 1 64 90 01 46",
      "contact:postcode": "91290",
      "contact:street": "Place du Marché",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Centrale Guilleman",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "910005206",
      since: "1983-08-07",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 5262617126,
    x: 48.5889621,
    y: 2.2494212,
    tags: {
      "addr:city": "Arpajon",
      "addr:housenumber": "25",
      "addr:postcode": "91290",
      "addr:street": "Place du Marché",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 26 03 90",
      "contact:phone": "+33 1 64 90 00 15",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Georges Schom",
      opening_hours:
        "Mo-Fr 08:30-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:30",
      "ref:FR:FINESS": "910005172",
      since: "1990-10-08",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 5262642140,
    x: 48.590576,
    y: 2.2483153,
    tags: {
      alt_name: "Pharmacie Gervais SNC",
      amenity: "pharmacy",
      "contact:city": "Arpajon",
      "contact:fax": "+33 1 64 90 00 22",
      "contact:housenumber": "55",
      "contact:phone": "+33 1 64 90 00 22",
      "contact:postcode": "91290",
      "contact:street": "Grande Rue",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Gervais",
      "ref:FR:FINESS": "910005156",
      since: "1979-01-29",
      source:
        "cadastre-dgi-fr source : Direction Generale des Impots - Cadastre. Mise a jour : 2014",
    },
  },
  {
    type: "node",
    id: 5262676548,
    x: 48.5858322,
    y: 2.262163,
    tags: {
      "addr:city": "La Norville",
      "addr:housenumber": "60 F",
      "addr:postcode": "91290",
      "addr:street": "Chemin de la Garenne",
      amenity: "pharmacy",
      "contact:fax": "+33 1 64 90 39 39",
      "contact:phone": "+33 1 64 90 39 39",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      is_in: "Essonne",
      name: "Pharmacie Françoise Taconnet",
      "ref:FR:FINESS": "910011261",
      since: "1990-03-02",
    },
  },
  {
    type: "node",
    id: 5262683484,
    x: 48.562067,
    y: 2.2996304,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Marolles-en-Hurepoix",
      "contact:housenumber": "31",
      "contact:phone": "+33 1 64 91 89 49",
      "contact:postcode": "91630",
      "contact:street": "Grande Rue",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie Philippe Maudet",
      "ref:FR:FINESS": "910010040",
      since: "1990-02-06",
    },
  },
  {
    type: "node",
    id: 5263467283,
    x: 48.6000697,
    y: 2.3047397,
    tags: {
      alt_name: "Pharmacie Sylvia Rostaing",
      amenity: "pharmacy",
      "contact:city": "Brétigny-sur-Orge",
      "contact:fax": "+33 1 60 84 57 53",
      "contact:housenumber": "11",
      "contact:phone": "+33 1 60 84 72 72",
      "contact:postcode": "91220",
      "contact:street": "Avenue du Colonel Rozanoff",
      dispensing: "yes",
      is_in: "Essonne",
      name: "Pharmacie de la Moinerie",
      "opening_hours:covid19":
        "Mo 14:30-18:30; Tu-Fr 09:00-12:00,14:30-18:30; Sa 09:00-12:00",
      "ref:FR:FINESS": "910006006",
      since: "1983-02-08",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 5263493895,
    x: 48.6102597,
    y: 2.3056741,
    tags: {
      alt_name: "Pharmacie Toupin Prat Brigitte",
      amenity: "pharmacy",
      "contact:city": "Brétigny-sur-Orge",
      "contact:district": "Essonne",
      "contact:fax": "+33 1 60 84 95 02",
      "contact:housenumber": "16",
      "contact:phone": "+33 1 60 84 24 54",
      "contact:postcode": "91220",
      "contact:street": "Place du Marché Couvert",
      dispensing: "yes",
      name: "Pharmacie du Marché Couvert",
      "ref:FR:FINESS": "910006022",
      source: "Celtipharm - 10/2014",
      start_date: "1988-04-26",
    },
  },
  {
    type: "node",
    id: 5263891654,
    x: 48.7013928,
    y: 2.1895839,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Orsay",
      "contact:fax": "+33 1 69 28 63 90",
      "contact:housenumber": "8",
      "contact:phone": "+33 1 69 28 63 59",
      "contact:postcode": "91400",
      "contact:street": "Rue Charles De Gaulle",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Benoit Mothe",
      opening_hours: "Mo-Sa 08:30-13:00,14:00-19:30",
      "ref:FR:FINESS": "910014349",
      since: "2007-05-14",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2014",
    },
  },
  {
    type: "node",
    id: 5266809143,
    x: 48.8117086,
    y: 2.5704874,
    tags: {
      "addr:city": "Le Plessis-Trévise",
      "addr:housenumber": "8",
      "addr:postcode": "94420",
      "addr:street": "Avenue Ardouin",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie DAVID",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940012727",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5294644345,
    x: 48.914785,
    y: 2.2302684,
    tags: {
      alt_name: "Pharmacie des Quatre Chemins",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 42 42 22 71",
      "ref:FR:FINESS": "920013174",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5308711245,
    x: 48.8056832,
    y: 1.8912754,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Becquart",
      "ref:FR:FINESS": "780008710",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5312486579,
    x: 48.8428604,
    y: 2.2107374,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Pharmacie de Montretout",
      opening_hours: "Mo-Fr 09:00-19:45;Sa 09:00-19:00;PH closed",
      "opening_hours:covid19": "Mo-Fr 09:00-19:45;Sa 09:00-19:00;PH closed",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 5316304889,
    x: 48.7670096,
    y: 2.3677651,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Girardot",
      "ref:FR:FINESS": "940007750",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5316498464,
    x: 48.8395383,
    y: 2.1862864,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      level: "0",
      name: "Garches Santé Beauté",
      opening_hours: "Tu-Sa 10:00-14:30,13:00-19:30;PH closed",
      wheelchair: "limited",
    },
  },
  {
    type: "node",
    id: 5316726625,
    x: 48.8469695,
    y: 2.2133147,
    tags: {
      amenity: "pharmacy",
      level: "0",
      name: "Parapharmacie Saint-Cloud",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5318782905,
    x: 49.0045788,
    y: 1.909825,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont",
      "ref:FR:FINESS": "780010591",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5337992105,
    x: 48.9378792,
    y: 2.2073643,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bienvenu",
      "ref:FR:FINESS": "950005850",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5337993228,
    x: 48.9334379,
    y: 2.2200113,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Brigadières",
      "ref:FR:FINESS": "950005785",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5379350905,
    x: 48.9102623,
    y: 2.2406334,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Cosson",
      opening_hours:
        "Mo-Fr 09:30-12:30,13:15-20:00; Sa 09:30-12:30,15:00-19:00",
      "ref:FR:FINESS": "920013240",
      source: "Celtipharm - 10/2014",
      wheelchair: "no",
    },
  },
  {
    type: "node",
    id: 5404131321,
    x: 48.9152088,
    y: 2.2527001,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Tu-Sa 09:00-12:30, 14:15-19:30; Mo 14:15-19:30",
      "ref:FR:FINESS": "920013091",
      source: "survey 2018",
    },
  },
  {
    type: "node",
    id: 5408185494,
    x: 48.5392687,
    y: 2.6613319,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pech Fiancette",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770008696",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5408185495,
    x: 48.5396963,
    y: 2.6588677,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jadot Strauss",
      "ref:FR:FINESS": "770008597",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5419789507,
    x: 49.1431985,
    y: 2.4692576,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Coye le Forêt",
      opening_hours:
        "Mo-Fr 09:00-12:30, 14:30-19:30; Sa 09:00-12:30, 15:00-18:30",
      "ref:FR:FINESS": "600003750",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5426858441,
    x: 48.9713932,
    y: 2.3999628,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950008367",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5459704422,
    x: 48.6371916,
    y: 2.3204173,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale du Bois des Roches - Phovea",
    },
  },
  {
    type: "node",
    id: 5469180912,
    x: 49.0017612,
    y: 2.4210027,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 39 87 33 31",
      healthcare: "pharmacy",
      name: "Pharmacie de la Fauconnière",
      opening_hours:
        "Mo-Fr 09:00-12:30,15:00-20:00; Sa 09:00-12:30,15:00-19:30",
      phone: "+33 1 39 85 83 00",
      "ref:FR:FINESS": "950008821",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5469356831,
    x: 49.0029326,
    y: 2.4235139,
    tags: {
      "addr:city": "Gonesse",
      "addr:housenumber": "5",
      "addr:postcode": "95500",
      "addr:street": "Square des Sports",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 39 85 11 55",
      healthcare: "pharmacy",
      name: "Pharmacie Zerouali",
      opening_hours: "09:00-12:30,14:00-20:00",
      operator: "Pharmacie Square des Sports",
      phone: "+33 1 39 85 17 92",
      "ref:FR:FINESS": "950008722",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5486299143,
    x: 49.166531,
    y: 2.2439897,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 34 70 50 09",
      dispensing: "yes",
      name: "Pharmacie Trigoulet-Masclet",
      opening_hours:
        "Mo-Fr 08:30-12:30,12:30-19:30; Sa 08:30-12:30,12:30-18:30",
      "ref:FR:FINESS": "600004766",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5525362045,
    x: 48.721005,
    y: 2.5330167,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Église",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30,14:30-19:30",
      "ref:FR:FINESS": "940014665",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5550110633,
    x: 48.8142278,
    y: 2.4019683,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gambetta",
      "ref:FR:FINESS": "940010291",
    },
  },
  {
    type: "node",
    id: 5552768691,
    x: 48.8931392,
    y: 2.3138666,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Jardins du Palais",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "750030652",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 10/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5561825294,
    x: 48.9414276,
    y: 2.4445438,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930005723",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5561825320,
    x: 48.9530944,
    y: 2.4425939,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Cités",
      "ref:FR:FINESS": "930005921",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5563876748,
    x: 48.9917352,
    y: 2.0902517,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Battellerie",
      "payment:cash": "yes",
      "ref:FR:FINESS": "780007142",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5569988204,
    x: 48.967449,
    y: 2.4109291,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950008607",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5569988529,
    x: 48.9550829,
    y: 2.4164236,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Des Ailes d'Or",
      "ref:FR:FINESS": "930008206",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5569988539,
    x: 48.9549329,
    y: 2.4177202,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930008222",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5574625353,
    x: 48.7820846,
    y: 2.6009072,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "74",
      "addr:postcode": "77340",
      "addr:street": "Rue Lucien Brunet",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Malcurat",
      opening_hours: "Mo-Sa 09:00-12:30,14:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 28 38 38",
      "ref:FR:FINESS": "770010460",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5595220001,
    x: 48.8059423,
    y: 2.6174693,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "4",
      "addr:postcode": "77340",
      "addr:street": "Rue de l'Est",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo-Fr 08:00-20:30; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 29 70 91",
      "ref:FR:FINESS": "770010445",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5603048240,
    x: 48.7147049,
    y: 2.2453716,
    tags: {
      "addr:city": "Palaiseau",
      "addr:housenumber": "96",
      "addr:postcode": "91120",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 14 28 38",
      "contact:phone": "+33 1 60 14 28 38",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Mairie",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:00-19:30; Sa 09:00-12:30,14:00-19:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011675",
      since: "1985-10-02",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5603048258,
    x: 48.713228,
    y: 2.2443503,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 14 00 42",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nahon",
      opening_hours: "Aug Mo-Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011691",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5603048271,
    x: 48.7119564,
    y: 2.2429116,
    tags: {
      amenity: "pharmacy",
      "contact:fax": "+33 1 60 14 61 60",
      "contact:phone": "+33 1 60 14 03 50",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Filleron Serres Cambot",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910011741",
    },
  },
  {
    type: "node",
    id: 5626585275,
    x: 49.1721156,
    y: 2.7339074,
    tags: {
      "addr:city": "Baron",
      "addr:housenumber": "4",
      "addr:postcode": "60300",
      "addr:street": "Place de la Republique",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Dufour",
      "ref:FR:FINESS": "600007306",
      source: "survey 2017",
    },
  },
  {
    type: "node",
    id: 5630815321,
    x: 48.4463667,
    y: 2.6020625,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770003762",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5712258794,
    x: 48.9805475,
    y: 2.2707088,
    tags: {
      "addr:housenumber": "437",
      "addr:street": "Rue du Général Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacie.ermont@gmail.com",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie de la Gare d'Ermont-Eaubonne",
      opening_hours: "Mo-Fr 07:30-21:00; Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 30 72 74 80",
      "ref:FR:FINESS": "950007666",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5735818971,
    x: 48.8022863,
    y: 2.2641425,
    tags: {
      "addr:city": "Clamart",
      "addr:housenumber": "29",
      "addr:postcode": "92140",
      "addr:street": "Rue Paul Vaillant-Couturier",
      amenity: "pharmacy",
      "contact:email": "pharmacieducentreclamart@orange.fr",
      "contact:phone": "+33 1 46 42 03 74",
      dispensing: "yes",
      name: "Pharmacie Du Centre Clamart",
      opening_hours: "Mo-Fr 9:00-12:30,14:00-19:30; Sa 9:00-13:00,15:00-19:00",
      "ref:FR:FINESS": "920012127",
      source: "Celtipharm - 10/2014",
      website: "https://pharmacieducentreclamart.mesoigner.fr/",
    },
  },
  {
    type: "node",
    id: 5756802507,
    x: 48.9597299,
    y: 2.3273683,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Morisseau",
      "ref:FR:FINESS": "950006817",
      source: "local knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5756802840,
    x: 48.96385,
    y: 2.32928,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Kpama",
      "ref:FR:FINESS": "950006684",
      source: "local knowledge;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5772483108,
    x: 48.4847747,
    y: 2.696668,
    tags: {
      "access:covid19": "no",
      "addr:housenumber": "39",
      "addr:postcode": "77590",
      "addr:street": "Avenue Gallieni",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Martin-Delory",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770004711",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5794522325,
    x: 48.7144827,
    y: 2.4790686,
    tags: {
      "addr:city": "Yerres",
      "addr:postcode": "91330",
      "addr:street": "Rue Pierre de Coubertin",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Rives de l'Yerres",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 08:30-19:30",
      wheelchair: "designated",
    },
  },
  {
    type: "node",
    id: 5795840998,
    x: 48.9389003,
    y: 2.2431086,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Porte Saint-Germain",
      "ref:FR:FINESS": "950004671",
      source: "survey",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5798869672,
    x: 48.8484535,
    y: 2.3998864,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Deux Colonnes",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 43 73 08 10",
      "ref:FR:FINESS": "750036923",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 01/2018",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5816656941,
    x: 48.9292498,
    y: 2.2142735,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale & Parapharmacie",
      "ref:FR:FINESS": "950005736",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5824383323,
    x: 48.7501718,
    y: 2.3041047,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Pont",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920007945",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 5831606085,
    x: 48.9685129,
    y: 2.255349,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de l'Église",
      opening_hours: "Mo-Sa 09:00-12:30, 15:00-19:30",
      "ref:FR:FINESS": "950012146",
      source: "survey 2018",
    },
  },
  {
    type: "node",
    id: 5839111746,
    x: 48.8137154,
    y: 2.1359265,
    tags: {
      "addr:city": "Versailles",
      "addr:housenumber": "81",
      "addr:postcode": "78000",
      "addr:street": "Rue du Maréchal Foch",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Square",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:00",
      "ref:FR:FINESS": "780015244",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5843769286,
    x: 48.8482666,
    y: 2.2970944,
    tags: {
      "addr:housenumber": "13",
      "addr:postcode": "75015",
      "addr:street": "Rue du Commerce",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Monge",
      opening_hours: "Mo-Su 08:00-21:00",
      "opening_hours:covid19": "Mo-Su 08:00-21:00",
      phone: "+33 145753335",
      "ref:FR:FINESS": "750026254",
      source: "survey 2018",
      website: "https://pharmacie-monge.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5850967086,
    x: 48.8547824,
    y: 2.2949979,
    tags: {
      amenity: "pharmacy",
      dispensing: "no",
      name: "Parapharmacie Eiffel Para",
      opening_hours: "Mo-Su 10:30-19:30",
      source: "survey 2018",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 5856769157,
    x: 48.83486,
    y: 1.9919801,
    tags: {
      amenity: "pharmacy",
      description: "Drive seulement",
      dispensing: "yes",
      name: "Pharmacie NGUYEN",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780016044",
      source: "Celtipharm - 10/2014",
      "takeaway:covid19": "only",
    },
  },
  {
    type: "node",
    id: 5862993477,
    x: 49.0039542,
    y: 2.3924973,
    tags: {
      "addr:city": "Villiers-le-Bel",
      "addr:housenumber": "1",
      "addr:postcode": "95400",
      "addr:street": "Place de la Tolinette",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Noémie",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+331 34 19 91 11",
      "ref:FR:FINESS": "950013615",
      source: "Le ministère des solidarités et de la santé - 08/2018",
      website: "http://pharmacienoemie.ceido.com/",
    },
  },
  {
    type: "node",
    id: 5863375738,
    x: 48.9812135,
    y: 2.0030209,
    tags: {
      "addr:city": "Triel-sur-Seine",
      "addr:housenumber": "153",
      "addr:postcode": "78510",
      "addr:street": "Rue Paul Doumer",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Delvallee",
      opening_hours: "Mo-Tu 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "780014262",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      website:
        "http://ordonnances.leadersante.fr/pharmacie-delvallee-78510-triel-sur-seine/",
    },
  },
  {
    type: "node",
    id: 5868149487,
    x: 48.843998,
    y: 2.2606789,
    tags: {
      amenity: "pharmacy",
      name: "Pharmavance",
      opening_hours: "Mo-Fr 09:00-18:00",
    },
  },
  {
    type: "node",
    id: 5878441622,
    x: 48.8191857,
    y: 2.2997264,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Poste",
    },
  },
  {
    type: "node",
    id: 5878638922,
    x: 48.7538979,
    y: 2.3270738,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Cerisaie",
      "ref:FR:FINESS": "940018328",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 5879604944,
    x: 48.9269924,
    y: 2.2146276,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "950005686",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5885539074,
    x: 48.9151688,
    y: 2.2465284,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "920012945",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 5936742699,
    x: 48.8232919,
    y: 2.5105396,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940007073",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 08/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5968695159,
    x: 48.9468059,
    y: 2.1691687,
    tags: {
      "addr:housenumber": "13",
      "addr:street": "Place Nationale",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place Nationnale",
      "ref:FR:FINESS": "780013827",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 5968922017,
    x: 48.9401136,
    y: 2.0222817,
    tags: {
      "addr:city": "Carrières-sous-Poissy",
      "addr:housenumber": "376",
      "addr:postcode": "78955",
      "addr:street": "Rue Daniel Blervaque",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "not:brand:wikidata": "Q1547749",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:30-12:30,14:30-19:00",
      "opening_hours:covid19": "same",
      phone: "+33 1 39 65 53 30",
      "ref:FR:FINESS": "780005591",
      "ref:FR:SIRET": "83757133000021",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5978089794,
    x: 48.8734209,
    y: 2.3242032,
    tags: {
      amenity: "pharmacy",
      "contact:email": "pharmaciedutertre08@gmail.com",
      "contact:phone": "+33142653593",
      "delivery:covid19": "appointment",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Arcade",
      opening_hours: "Mo-Fr 8:00-19:30; Sa 10:00-19:00",
      "opening_hours:covid19": "Mo-Fr 9:30-18:00; Sa 10:00-14:00",
      "ref:FR:FINESS": "750014490",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 10/2018",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 5994771337,
    x: 48.9725273,
    y: 2.3069868,
    tags: {
      "addr:city": "Enghien-les-Bains",
      "addr:housenumber": "15bis",
      "addr:postcode": "95880",
      "addr:street": "Rue de l'Arrivée",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours:
        "Mo 10:00-13:00, 15:00-20:00; Tu, Th-Fr 09:00-13:00, 15:00-20:00; We, Sa 09:30-13:00, 15:00-19:30",
      "ref:FR:FINESS": "950007252",
    },
  },
  {
    type: "node",
    id: 5994876238,
    x: 48.7833514,
    y: 2.3578567,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Piscine",
      "ref:FR:FINESS": "940015142",
    },
  },
  {
    type: "node",
    id: 6001202687,
    x: 48.8361846,
    y: 2.6360451,
    tags: {
      "addr:city": "Lognes",
      "addr:housenumber": "16",
      "addr:postcode": "77185",
      "addr:street": "Place des Colliberts",
      amenity: "pharmacy",
      brand: "Proxipharma",
      dispensing: "yes",
      healthcare: "pharmacy",
      opening_hours: "Mo-Fr 09:00-20:30; Sa 09:00-13:30,14:30-19:30",
      phone: "+33 1 64 11 01 30",
      "ref:FR:FINESS": "770007763",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6001585973,
    x: 48.9611102,
    y: 2.0689985,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Achères",
      "contact:housenumber": "3",
      "contact:postcode": "78260",
      "contact:street": "Avenue de Stalingrad",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "780004776",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6005361858,
    x: 48.6974058,
    y: 2.2946432,
    tags: {
      alt_name: "Pharmacie Chiep",
      amenity: "pharmacy",
      "contact:phone": "+33 1 64 48 80 96",
      dispensing: "yes",
      name: "Pharmacie Lhermitte",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910009703",
    },
  },
  {
    type: "node",
    id: 6035186910,
    x: 48.7664304,
    y: 2.3880018,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940014343",
      source:
        "Le ministère des solidarités et de la santé - 08/2018;survey 11/2018",
    },
  },
  {
    type: "node",
    id: 6061282825,
    x: 48.9792558,
    y: 2.3831467,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950012625",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6068585088,
    x: 48.9773466,
    y: 2.3946883,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de la Gare",
      "ref:FR:FINESS": "950008433",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6068585101,
    x: 48.9743167,
    y: 2.390445,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950008482",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6072716190,
    x: 48.9529661,
    y: 2.3134938,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Ilo",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "930008602",
    },
  },
  {
    type: "node",
    id: 6076713000,
    x: 49.0069929,
    y: 2.1971649,
    tags: {
      "addr:city": "Montigny-lès-Cormeilles",
      "addr:housenumber": "12",
      "addr:postcode": "95370",
      "addr:street": "Rue de la Gare",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Croix Blanche",
      "ref:FR:FINESS": "950010066",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6098164296,
    x: 48.8804531,
    y: 2.1847223,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "Mo-Fr 09:00-13:00,14:30-19:00; Sa 09:00-12:00",
      "ref:FR:FINESS": "920019874",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 6119541285,
    x: 48.8844771,
    y: 2.5320517,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "930008867",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6130413180,
    x: 49.0359981,
    y: 2.4605735,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950015537",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6154065913,
    x: 48.9065831,
    y: 2.4382362,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Leadersanté",
      "ref:FR:FINESS": "930006416",
      source: "Le ministère des solidarités et de la santé - 10/2018;survey",
    },
  },
  {
    type: "node",
    id: 6182169612,
    x: 48.6124663,
    y: 2.4172459,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "910009653",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6183143445,
    x: 48.9804226,
    y: 2.0022455,
    tags: {
      "addr:city": "Triel-sur-Seine",
      "addr:housenumber": "6",
      "addr:postcode": "78510",
      "addr:street": "Rue du Pont",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du MArché",
      opening_hours: "Mo-Sa 08:30-12:30,14:30-19:45",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780014296",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      website: "https://www.pharmacie-triel.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 6247443439,
    x: 49.014943,
    y: 2.2203919,
    tags: {
      amenity: "pharmacy",
      brand: "PharmaVie",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "PharmaVie",
    },
  },
  {
    type: "node",
    id: 6255870413,
    x: 48.9015776,
    y: 2.8118227,
    tags: {
      amenity: "pharmacy",
      description: "Para & Pharmacie du centre - Orthopédie",
      dispensing: "yes",
      name: "Para & Pharmacie du centre",
      opening_hours:
        "Mo-Fr 09:00-12:15,14:30-19:15; Sa 09:00-12:30,14:30-19:15",
      "ref:FR:FINESS": "770006476",
      source: "survey 01/2019",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 6261981909,
    x: 49.0040941,
    y: 2.1277276,
    tags: {
      "addr:city": "Herblay-sur-Seine",
      "addr:housenumber": "419",
      "addr:postcode": "95220",
      "addr:street": "Route de Conflans",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Lilas",
      phone: "+33 1 39 78 94 80",
      "ref:FR:FINESS": "950007328",
      "ref:FR:SIREN": "849538582",
      "ref:FR:SIRET": "84953858200016",
      website: "https://www.alphega-pharmacie.fr/",
    },
  },
  {
    type: "node",
    id: 6269490392,
    x: 48.9694752,
    y: 2.2861227,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Poncet",
      opening_hours: "Mo-Sa 09:00-12:30, 14:30-20:00",
      phone: "+33 1 39 89 96 11",
      "ref:FR:FINESS": "950011627",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6335943920,
    x: 48.868128,
    y: 2.1940295,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      dispensing: "yes",
      name: "Pharmacie Beauregard",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "920019676",
      source: "Le ministère des solidarités et de la santé - 10/2018",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 6337559877,
    x: 49.0187956,
    y: 2.2434075,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "950011742",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6377956262,
    x: 48.8567947,
    y: 2.5316653,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Berges",
      "ref:FR:FINESS": "930011705",
      source: "Le ministère des solidarités et de la santé - 10/2018",
    },
  },
  {
    type: "node",
    id: 6388896145,
    x: 48.5930645,
    y: 2.4697283,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Papeterie",
    },
  },
  {
    type: "node",
    id: 6466353450,
    x: 48.8173926,
    y: 2.3756052,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Mouhib",
      opening_hours: "Mo-Sa 09:30-12:30; Mo-Fr 14:00-20:00; Sa 14:30-19:30",
      phone: "+33 1 46 72 26 43",
      "ref:FR:FINESS": "940010341",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      website:
        "https://pharmacies.pharmaciengiphar.com/pharmacie/pharmacie-mouhib/0120145000",
    },
  },
  {
    type: "node",
    id: 6466992070,
    x: 48.6830415,
    y: 2.164891,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Amonts",
      opening_hours: "Mo-Sa 09:00-13:00, 14:30-19:30",
      source: "survey",
    },
  },
  {
    type: "node",
    id: 6499916906,
    x: 48.8783804,
    y: 2.1939531,
    tags: {
      amenity: "pharmacy",
      "contact:email": "pharmaciedanton92500@gmail.com",
      "contact:phone": "+33 1 47 51 29 59",
      "delivery:covid19": "yes",
      "description:covid19":
        "Préparation commandes sur ordonnances envoyées par mail",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Danton",
      "opening_hours:covid19":
        "Mo-Fr 09:00-13:00,14:30-19:30; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "920019627",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 6513060285,
    x: 48.9898886,
    y: 2.290525,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre Commercial Mirabeau",
      "ref:FR:FINESS": "950012880",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6514667385,
    x: 48.8371091,
    y: 2.1053957,
    tags: {
      "addr:housenumber": "31",
      "addr:postcode": "78150",
      "addr:street": "Rue de Sabretache",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Bourg",
      phone: "+33 1 30 24 06 70",
      "ref:FR:FINESS": "780024733",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6549746020,
    x: 48.7877469,
    y: 2.3049512,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Galien Santé",
      opening_hours: "Mo-Sa 09:00-13:00, Mo-Fr 14:30-19:30",
    },
  },
  {
    type: "node",
    id: 6577743170,
    x: 48.732679,
    y: 2.2889184,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 11 83 76",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Place de France",
      opening_hours: "Mo-Fr 08:30-20:30;Sa 08:00-20:00",
      "opening_hours:covid19": "Mo-Fr 08:30-20:30;Sa 09:00-20:00",
      "ref:FR:FINESS": "910010313",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6712554237,
    x: 48.8049978,
    y: 2.3700861,
    tags: {
      "access:covid19": "no",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Lasry",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940014814",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "source:name": "survey",
      "source:position": "survey",
      "takeaway:covid19": "yes",
    },
  },
  {
    type: "node",
    id: 6716344233,
    x: 48.6226564,
    y: 2.1247859,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "910006170",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6771415239,
    x: 48.7539117,
    y: 2.3030478,
    tags: {
      "addr:city": "Antony",
      "addr:housenumber": "26",
      "addr:postcode": "92160",
      "addr:street": "Rue Auguste Mounié",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Marché",
      "ref:FR:FINESS": "920007846",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 6774200672,
    x: 48.8145729,
    y: 2.3524928,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940009566",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6779521344,
    x: 48.9358368,
    y: 2.3395313,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Kann",
      opening_hours: "Mo 14:30-19:30; Tu,Sa 09:00-12:30; 14:30-19:30",
      "opening_hours:covid19":
        "Mo-Fr 10:00-12:30,15:00-18:30; Sa 10:00-12:30,15:00-18:00",
      operator: "Jennifer Kann",
      phone: "+33148200541",
      "ref:FR:FINESS": "930009055",
    },
  },
  {
    type: "node",
    id: 6855813767,
    x: 48.8448986,
    y: 2.1470359,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Leufeuvre Auvret",
      "ref:FR:FINESS": "780005880",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6881256993,
    x: 49.0449949,
    y: 2.0685922,
    tags: {
      "addr:city": "Cergy",
      "addr:housenumber": "8",
      "addr:postcode": "95000",
      "addr:street": "Les Linandes Pourpres",
      amenity: "pharmacy",
      description: "Pharmacie Buggia et Greau",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Coteaux",
      "name:en": "Pharmacie des Coteaux",
      "name:fr": "Pharmacie des Coteaux",
      note: 'May appear as "Pharmacie Buggia et Greau"',
      opening_hours: "Mo-Fr 09:00-13:00, 14:00-20:00",
      phone: "+33134259834",
      "ref:FR:FINESS": "950006155",
      source: "local knowledge",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 6897804585,
    x: 49.0163751,
    y: 2.2432536,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:00-12:30, 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      phone: "+33 1 39 95 69 87",
      "ref:FR:FINESS": "950011692",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6916200750,
    x: 48.9024787,
    y: 2.1352193,
    tags: {
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Pages",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "780015897",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 6924110900,
    x: 48.8926505,
    y: 2.5808602,
    tags: {
      "addr:city": "Chelles",
      "addr:postcode": "77500",
      "addr:street": "Route de Montfermeil",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Plain Champ",
      opening_hours:
        "Mo 14:00-19:30; Tu-Fr 09:00-12:30,14:00-19:30; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 72 02 03",
    },
  },
  {
    type: "node",
    id: 6924127526,
    x: 48.8862284,
    y: 2.6073245,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "90",
      "addr:postcode": "77500",
      "addr:street": "Avenue de Claye",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "Accueil à l'extérieur. Maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Mont Chalats",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 67 62",
      "ref:FR:FINESS": "770004943",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6924136262,
    x: 48.8699084,
    y: 2.6407853,
    tags: {
      "addr:city": "Vaires-sur-Marne",
      "addr:housenumber": "2",
      "addr:postcode": "77360",
      "addr:street": "Rond-Point de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Le Quan",
      opening_hours: "Tu-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 34 40",
      "ref:FR:FINESS": "770012375",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 6924147119,
    x: 48.8735561,
    y: 2.6378115,
    tags: {
      "addr:city": "Vaires-sur-Marne",
      "addr:housenumber": "23",
      "addr:postcode": "77360",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Berthault Christophe",
      opening_hours: "Tu-Sa 09:00-12:30,14:45-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 14 55",
      "ref:FR:FINESS": "770012326",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 6924305290,
    x: 48.8753648,
    y: 2.5827208,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "58bis",
      "addr:postcode": "77500",
      "addr:street": "Avenue de la Résistance",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Gare",
      opening_hours: "Mo 14:00-19:45; Tu-Fr 09:00-19:45; Sa 09:00-19:15",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 08 01 87",
      "ref:FR:FINESS": "770005163",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6927085097,
    x: 48.8783686,
    y: 2.5915175,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "5",
      "addr:postcode": "77500",
      "addr:street": "Rue Gambetta",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770004992",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6950592466,
    x: 48.8522518,
    y: 2.6027797,
    tags: {
      "addr:city": "Champs-sur-Marne",
      "addr:housenumber": "18",
      "addr:postcode": "77420",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Paris",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 68 11 57",
      "ref:FR:FINESS": "770004547",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 6958679026,
    x: 48.8756488,
    y: 2.4693481,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "930013784",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 6988507502,
    x: 48.8508989,
    y: 2.4202433,
    tags: {
      "addr:housenumber": "13",
      "addr:street": "Rue de Valmy",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Valmy",
      "opening_hours:covid19": "Mo-Fr 09:00-19:30",
    },
  },
  {
    type: "node",
    id: 6990552596,
    x: 48.6466419,
    y: 1.8572857,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Timsit",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 7000685285,
    x: 48.9821009,
    y: 2.2852077,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Eaubonne",
      "contact:housenumber": "127",
      "contact:phone": "+33 1 39 59 91 08",
      "contact:postcode": "95600",
      "contact:street": "Boulevard de La Republique",
      "contact:website":
        "https://www.pharmanity.com/pharmacie/pharmacie-richard-eaubonne-ph15069",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Champ de Courses",
      opening_hours:
        "Mo 14:30-19:30; Tu-Fr 09:00-12:30, 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      "ref:FR:FINESS": "950007153",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7012717906,
    x: 48.8887211,
    y: 2.3146325,
    tags: {
      "addr:housenumber": "3",
      "addr:street": "Place Françoise Dorin",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Grande Pharmarcie des Batignolles",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "Mo-Sa 09:00-20:00",
      operator: "Aprium",
      phone: "+33 1 71 18 30 60",
      website: "https://batignolles.aprium-pharmacie.fr",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 7017043085,
    x: 48.9607545,
    y: 2.5742104,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Tremblay-en-France",
      "contact:phone": "+33 1 48 61 11 43",
      "contact:postcode": "93290",
      "contact:street": "Rue Hector Berlioz",
      dispensing: "yes",
      name: "Pharmacie Des Cottages",
      opening_hours:
        "Mo-Fr 08:30-12:30, 14:30-19:30; Sa 09:00-12:30, 14:30-19:00",
      "ref:FR:FINESS": "930016050",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7017219085,
    x: 49.1467261,
    y: 2.2841675,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Persan",
      "contact:housenumber": "12",
      "contact:phone": "+33 1 34 70 04 36",
      "contact:postcode": "95340",
      "contact:street": "Avenue Jean Jaures",
      dispensing: "yes",
      name: "Pharmacie Do Pham",
      opening_hours: "Mo-Sa 09:00-13:00, 14:30-20:00",
      "ref:FR:FINESS": "950010892",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7017346486,
    x: 48.9964548,
    y: 2.4264794,
    tags: {
      amenity: "pharmacy",
      "contact:city": "Gonesse",
      "contact:housenumber": "98",
      "contact:phone": "+33 1 39 85 07 67",
      "contact:postcode": "95500",
      "contact:street": "Avenue des Myosotis",
      dispensing: "yes",
      name: "Pharmacie Des Marronniers",
      opening_hours: "Mo-Sa 08:00-12:30, 14:30-19:30",
      "ref:FR:FINESS": "950008805",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7020866094,
    x: 48.8335625,
    y: 2.3149622,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie de l'Ouest",
    },
  },
  {
    type: "node",
    id: 7023401177,
    x: 48.9276362,
    y: 2.2858254,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "920014974",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7035428536,
    x: 48.9810442,
    y: 1.9129092,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      note: "A vérifier avec le nom de fichier Finess: Pharmacie de la Vigne Blanche",
      "ref:FR:FINESS": "780011326",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7043447893,
    x: 49.0051421,
    y: 1.9110923,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Écoles",
      "ref:FR:FINESS": "780010674",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7058335674,
    x: 48.7613113,
    y: 2.2900184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Parc",
    },
  },
  {
    type: "node",
    id: 7060325981,
    x: 48.6591361,
    y: 2.3413512,
    tags: {
      "addr:city": "Morsang-sur-Orge",
      "addr:housenumber": "105",
      "addr:postcode": "91390",
      "addr:street": "Rue Jean Raynal",
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 04 28 30",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Philippe Camusson",
      "ref:FR:FINESS": "910011113",
      since: "1980-07-09",
    },
  },
  {
    type: "node",
    id: 7069623213,
    x: 49.0037876,
    y: 2.2598987,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Centre",
      "ref:FR:FINESS": "950011981",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7069887802,
    x: 48.9364481,
    y: 2.3470319,
    tags: {
      "addr:city": "Saint-Denis",
      "addr:housenumber": "16",
      "addr:postcode": "93200",
      "addr:street": "Rue Auguste Delaune",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 7112443840,
    x: 48.8058222,
    y: 2.6160603,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "5bis",
      "addr:postcode": "77340",
      "addr:street": "Avenue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la République",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 28 51 17",
      "ref:FR:FINESS": "770010593",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7112443841,
    x: 48.775464,
    y: 2.6092256,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:postcode": "77340",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie La Francilienne",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 7112443842,
    x: 48.8048151,
    y: 2.5999172,
    tags: {
      "addr:city": "Pontault-Combault",
      "addr:housenumber": "2",
      "addr:postcode": "77340",
      "addr:street": "Avenue de Caminha",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Seicha",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 29 44 38",
      website: "https://pharmacieseicha.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 7112453889,
    x: 48.8078452,
    y: 2.6173413,
    tags: {
      "addr:city": "Émerainville",
      "addr:postcode": "77184",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 7125012798,
    x: 48.6511495,
    y: 2.4580606,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie Des Meillottes",
      opening_hours:
        "Mo 09:00-12:30,15:00-20:00; Tu-Fr 08:30-12:30,15:00-20:00; Sa 09:00-13:00,15:00-19:00",
      phone: "+33 1 60 75 27 81",
    },
  },
  {
    type: "node",
    id: 7125719655,
    x: 49.0817441,
    y: 2.0355037,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Choain",
      "ref:FR:FINESS": "950005900",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 7137603232,
    x: 48.7926202,
    y: 2.2708485,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 7139769795,
    x: 49.1571777,
    y: 2.3241447,
    tags: {
      "addr:city": "Bruyères-sur-Oise",
      "addr:postcode": "95820",
      "addr:street": "Rue de Morangles",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "950006007",
      source: "local knowledge",
    },
  },
  {
    type: "node",
    id: 7146685498,
    x: 48.705724,
    y: 2.370188,
    tags: {
      "addr:city": "Athis-Mons",
      "addr:housenumber": "9",
      "addr:postcode": "91200",
      "addr:street": "Avenue de Morangis",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmaciedelabelleetoile@orange.fr",
      healthcare: "pharmacy",
      name: "Pharmacie de la Belle Étoile",
      phone: "+33 1 69 72 73 27",
      "ref:FR:FINESS": "910005321",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "80336051000025",
    },
  },
  {
    type: "node",
    id: 7178346847,
    x: 48.8380076,
    y: 2.5211041,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      email: "pharmacieducentreville@gmail.com",
      name: "Pharmacie du Centre Ville",
      opening_hours:
        "Mo 09:30-12:30,14:30-19:45; Tu-Th 09:00-12:30,14:30-19:45; Fr 09:00-12:30,14:30-19:30; Sa 09:00-13:00,14:30-19:30",
      phone: "+33 1 47 06 88 75",
      "ref:FR:FINESS": "940006232",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7211402896,
    x: 48.8976621,
    y: 2.1640457,
    tags: {
      "addr:city": "Chatou",
      "addr:housenumber": "37",
      "addr:postcode": "78400",
      "addr:street": "Avenue Gambetta",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gambetta",
      "ref:FR:FINESS": "780006110",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7294974595,
    x: 48.7800821,
    y: 2.2283204,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Pelletier",
      "ref:FR:FINESS": "920012275",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7308864804,
    x: 48.9229365,
    y: 2.2522899,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 42 42 64 44",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours:
        "Tu-Fr 09:00-12:30, 14:30-20:00; Mo 14:00-20:00; Sa 09:00-12:30, 14:30-19:30",
      "opening_hours:covid19": "same",
      "ref:FR:FINESS": "920013042",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 7325141075,
    x: 48.8718605,
    y: 2.5782117,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "25",
      "addr:postcode": "77500",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Jules Ferry",
      opening_hours:
        "Mo 14:30-20:00; Tu-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 08 01 58",
      "ref:FR:FINESS": "770005080",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      website: "https://pharmaciejulesferry-chelles.com/",
    },
  },
  {
    type: "node",
    id: 7340845837,
    x: 48.684808,
    y: 2.1872136,
    tags: {
      alt_name: "SELARL Pharmacie Mondétour",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33 1 69 07 58 20",
      healthcare: "pharmacy",
      name: "Pharmacie de Mondétour",
      opening_hours:
        "Mo-Fr 14:00-19:30 || Tu-Fr 08:30-13:00; Sa 09:00-13:00,14:30-19:00",
      "opening_hours:covid19": "same",
      operator: "Jérôme Messent",
      phone: "+33 1 69 07 88 47",
      "ref:FR:FINESS": "910011394",
      since: "1992-03-01",
    },
  },
  {
    type: "node",
    id: 7341633459,
    x: 48.8594063,
    y: 2.2145107,
    tags: {
      "addr:city": "Saint-Cloud",
      "addr:housenumber": "51 bis",
      "addr:postcode": "92210",
      "addr:street": "Rue du Val d'Or",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Val d'Or",
      opening_hours: "Mo-Sa 09:00-13:00, 14:30-19:30",
      "opening_hours:covid19": "open",
      phone: "+33147713483",
      "ref:FR:FINESS": "920020401",
    },
  },
  {
    type: "node",
    id: 7343403354,
    x: 48.7466109,
    y: 2.4466322,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Passerelle",
      "ref:FR:FINESS": "940015530",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7346141033,
    x: 49.0216873,
    y: 2.2200745,
    tags: {
      "addr:housenumber": "1",
      "addr:street": "Rue Stephen Hawking",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Sarments",
      "opening_hours:covid19": "open",
    },
  },
  {
    type: "node",
    id: 7347329831,
    x: 48.8069706,
    y: 2.2541651,
    tags: {
      "addr:city": "Clamart",
      "addr:housenumber": "58",
      "addr:postcode": "92140",
      "addr:street": "Avenue Adolphe Schneider",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Zaidan",
      "ref:FR:FINESS": "920012291",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7349867540,
    x: 48.6152392,
    y: 2.5055768,
    tags: {
      "addr:city": "Saint-Pierre-du-Perray",
      "addr:postcode": "91280",
      "addr:street": "Avenue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Viard",
      "opening_hours:covid19": "Mo-Sa 09:00-12:30,14:30-19:30",
      operator: "Mme Viard",
      phone: "+33160757461",
      "ref:FR:FINESS": "910012913",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7349913345,
    x: 48.616328,
    y: 2.5034578,
    tags: {
      "addr:city": "Saint-Pierre-du-Perray",
      "addr:housenumber": "45",
      "addr:postcode": "91280",
      "addr:street": "Avenue du Général de Gaulle",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie des Cèdres",
      phone: "+33160754992",
    },
  },
  {
    type: "node",
    id: 7350889980,
    x: 48.9913049,
    y: 2.2746114,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Orangerie",
      opening_hours: "Mo-Sa 09:00-12:30, 14:30-19:30",
      phone: "+33 1 34 06 10 00",
      "ref:FR:FINESS": "950014043",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 7350912382,
    x: 48.8844548,
    y: 2.2298649,
    tags: {
      "addr:city": "Puteaux",
      "addr:housenumber": "180",
      "addr:postcode": "92800",
      "addr:street": "Rue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande pharmacie Bergères République",
      note: "nouvel emplacement, pas sûr que les ref (FINESS) soient restées identiques",
      opening_hours: "Mo-Sa 09:00-12:30, Mo-Fr 14:00-20:00, Sa 14:00-19:30",
      "ref:FR:FINESS": "920019197",
      source: "Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 7352414248,
    x: 48.6143891,
    y: 2.5067958,
    tags: {
      "addr:city": "Saint-Pierre-du-Perray",
      "addr:housenumber": "15",
      "addr:postcode": "91280",
      "addr:street": "Rue du Commerce",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Vialat Aliquot",
      phone: "+33160759870",
      "ref:FR:FINESS": "910012947",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7352528708,
    x: 48.605022,
    y: 2.2987121,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Sorbiers",
      "opening_hours:covid19":
        "Mo-We,Fr-Su 09:00-12:30,14:00-18:00; Th 09:00-12:30,14:00-20:00",
      phone: "+33 1 60 84 06 53",
      "ref:FR:FINESS": "910005966",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7358954702,
    x: 48.8772739,
    y: 2.6137819,
    tags: {
      "addr:city": "Chelles",
      "addr:postcode": "77500",
      "addr:street": "Avenue du Gendarme Castermant",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pyramide",
      opening_hours: "Mo-Sa 09:00-21:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 26 79 99",
      "ref:FR:FINESS": "770015022",
      "ref:FR:SIRET": "42304599600018",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
      website: "https://www.pharmacie-pyramide.com/",
    },
  },
  {
    type: "node",
    id: 7358954704,
    x: 48.8786737,
    y: 2.5924946,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "22",
      "addr:postcode": "77500",
      "addr:street": "Rue Gambetta",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gambetta",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:00-20:00; Sa 09:00-13:00,14:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 21 07 58",
      "ref:FR:FINESS": "770005015",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      website: "https://www.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 7359112150,
    x: 48.8547079,
    y: 2.5823093,
    tags: {
      "addr:city": "Champs-sur-Marne",
      "addr:housenumber": "1",
      "addr:postcode": "77420",
      "addr:street": "Avenue des Pyramides",
      amenity: "pharmacy",
      dispensing: "yes",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 05 22 35",
      "ref:FR:FINESS": "770004562",
      "ref:FR:SIRET": "78963368200016",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
      website: "https://www.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 7359233743,
    x: 48.8496017,
    y: 2.6168421,
    tags: {
      "addr:city": "Noisiel",
      "addr:postcode": "77186",
      "addr:street": "Cours des 2 Parcs",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des 2 Parcs",
      opening_hours: "Mo-Sa 09:00-12:30,14:30-19:30",
      "opening_hours:covid19": "Mo-Sa 09:00-18:00",
      phone: "+33 1 60 05 28 31",
    },
  },
  {
    type: "node",
    id: 7361663534,
    x: 48.8874619,
    y: 2.5096765,
    tags: {
      "addr:housenumber": "42",
      "addr:street": "avenue du Raincy",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Villa",
      opening_hours: "Tu-Sa 09:00-19:00",
      phone: "+33148541573",
      "ref:FR:FINESS": "930016522",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7364758926,
    x: 48.7289462,
    y: 2.2444712,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 60 10 31 00",
      fixme: "a verifier sur place si pharmacie",
      healthcare: "pharmacy",
      name: "E.Leclerc Parapharmacie",
      "opening_hours:covid19": "Mo-Sa 08:30-20:00",
    },
  },
  {
    type: "node",
    id: 7368443366,
    x: 48.4826196,
    y: 2.3483093,
    tags: {
      "addr:city": "La Ferté-Alais",
      "addr:housenumber": "12",
      "addr:postcode": "91590",
      "addr:street": "Rue Sainte-Barbe",
      alt_name: "SNC Pharmacie du Marché",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 90 15 03",
      "contact:phone": "+33 1 64 57 60 85",
      dispensing: "yes",
      name: "Pharmacie du Marché",
      "opening_hours:covid19": "Mo 14:00-19:00; Tu-Sa 09:00-19:00",
      "ref:FR:FINESS": "910008705",
      since: "1992-02-10",
    },
  },
  {
    type: "node",
    id: 7372804446,
    x: 48.8509614,
    y: 2.6548552,
    tags: {
      "addr:city": "Torcy",
      "addr:housenumber": "14",
      "addr:postcode": "77200",
      "addr:street": "Rue de Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du Vieux Torcy",
      opening_hours: "Mo-Fr 09:00-19:30; Sa 09:00-19:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 80 07 80",
      "ref:FR:FINESS": "770012110",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7379475305,
    x: 48.8732551,
    y: 2.1640765,
    tags: {
      "addr:housenumber": "286",
      "addr:street": "Avenue Napoléon Bonaparte",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Bonaparte",
      opening_hours: "Mo-Sa 09:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "920019890",
      source: "Le ministère des solidarités et de la santé - 03/2019",
    },
  },
  {
    type: "node",
    id: 7397461610,
    x: 48.4291155,
    y: 2.7558247,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "770011641",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "takeaway:covid19": "no",
    },
  },
  {
    type: "node",
    id: 7419624098,
    x: 48.8884913,
    y: 2.4944509,
    tags: {
      "addr:city": "Villemomble",
      "addr:housenumber": "89",
      "addr:postcode": "93250",
      "addr:street": "Rue de la Fosse aux Bergers",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Fosse aux Bergers",
      phone: "+33148550880",
      "ref:FR:FINESS": "930016407",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7425453037,
    x: 48.576362,
    y: 2.1913822,
    tags: {
      "addr:city": "Breuillet",
      "addr:postcode": "91650",
      amenity: "pharmacy",
      "contact:phone": "+33164584700",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Port Sud",
      opening_hours: "Mo 14:30-19:30; Tu-Sa 09:00-13:00,14:30-19:30",
      "opening_hours:covid19": "same",
    },
  },
  {
    type: "node",
    id: 7429043760,
    x: 48.7477344,
    y: 2.4807732,
    tags: {
      "addr:city": "Limeil-Brévannes",
      "addr:housenumber": "1",
      "addr:postcode": "94450",
      "addr:street": "Rue Julie-Victoire Daubié",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Tilleuls",
      note: "Téléconsultation",
      opening_hours: "Mo-Su 09:00-12:30; 14:30-21:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011869",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "takeaway:covid19": "no",
      "type:FR:FINESS": "620",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 7459088309,
    x: 48.6370309,
    y: 2.5122071,
    tags: {
      "addr:city": "Tigery",
      "addr:housenumber": "16",
      "addr:postcode": "91250",
      "addr:street": "place du plessis saucourt",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Tigery",
      opening_hours: "Mo-Su 09:00-20:00",
      "ref:FR:FINESS": "910021682",
      "ref:FR:SIRET": "81270543200019",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7483194545,
    x: 48.8066002,
    y: 2.4389794,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Leadersanté Au 62",
      opening_hours:
        "Mo-Fr 08:45-12:45,14:00-20:00; Sa 09:00-13:00,14:00-20:00",
      "opening_hours:covid19": "open",
      "ref:FR:FINESS": "940011414",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 7548549485,
    x: 48.8438487,
    y: 2.3722372,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Gamma",
      opening_hours: "Mo-Fr 8:00-19:30;Sa 8:00-13:00",
      "ref:FR:FINESS": "750020745",
      "survey:date": "2020-05-22",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 7553585685,
    x: 49.0230484,
    y: 2.2330617,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Vaucelles",
      phone: "+33139600485",
      "ref:FR:FINESS": "950013060",
      "ref:FR:SIRET": "31317570500026",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7589028501,
    x: 48.9142742,
    y: 2.411884,
    tags: {
      "addr:city": "Pantin",
      "addr:housenumber": "7",
      "addr:postcode": "93500",
      "addr:street": "Rue Martin Luther King",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie des Courtillières",
      phone: "+33 1 48 37 47 27",
      "ref:FR:FINESS": "930012471",
      "ref:FR:SIRET": "82909528000014",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7691184318,
    x: 48.9324928,
    y: 2.3003778,
    tags: {
      "addr:city": "Gennevilliers",
      "addr:country": "FR",
      "addr:housenumber": "21",
      "addr:postcode": "92230",
      "addr:street": "Rue Félicie",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "183719059",
      name: "Pharmacie Félicie",
      phone: "+33147983919",
      "ref:FR:FINESS": "920014792",
      "ref:FR:SIRET": "41062872100012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
      website:
        "https://www.ville-gennevilliers.fr/94-27217/cadre-de-vie/economie/annuaire-des-entreprises/ficheAnnu/selarl-pharmacie-felicie.htm",
    },
  },
  {
    type: "node",
    id: 7695690413,
    x: 48.9188875,
    y: 2.2956035,
    tags: {
      "addr:city": "Gennevilliers",
      "addr:country": "FR",
      "addr:housenumber": "74",
      "addr:postcode": "92230",
      "addr:street": "Avenue Gabriel Péri",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Gabriel-Péri",
      phone: "+33 1 47 93 94 58",
      "ref:FR:FINESS": "920014826",
      "ref:FR:SIRET": "75368377000014",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7734457294,
    x: 48.9247418,
    y: 2.364148,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Stade de France",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 10:00-15:00",
      "ref:FR:FINESS": "930014634",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 7766256643,
    x: 48.9481313,
    y: 2.5248509,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33143848162",
      healthcare: "pharmacy",
      level: "0",
      name: "Pharmacie de la Gare",
      phone: "+33143848084",
      "ref:FR:FINESS": "930015557",
      "ref:FR:SIRET": "38820025500023",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7803794880,
    x: 48.8319241,
    y: 2.3750716,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "141",
      "addr:postcode": "75013",
      "addr:street": "Avenue de France",
      amenity: "pharmacy",
      "contact:email": "ordo@pharmacief.com",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie F",
      opening_hours: "Mo-Sa 09:00-20:00",
      website: "https://pharmacief.com",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 7813811457,
    x: 48.850035,
    y: 2.3926833,
    tags: {
      amenity: "pharmacy",
      name: "iPharm",
    },
  },
  {
    type: "node",
    id: 7821320767,
    x: 48.7345093,
    y: 2.2841736,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Paris-Sud",
      "ref:FR:FINESS": "920008042",
      "ref:FR:SIRET": "50788859200015",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7860790685,
    x: 49.0748784,
    y: 2.1047387,
    tags: {
      "addr:housenumber": "1",
      "addr:postcode": "95300",
      "addr:street": "Rue du Moutier",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Moutier",
      opening_hours: "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30",
      phone: "+33130326335",
      "ref:FR:FINESS": "950007450",
      "ref:FR:SIRET": "80385992500015",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7933581177,
    x: 49.0105605,
    y: 2.2090114,
    tags: {
      "addr:city": "Taverny",
      "addr:housenumber": "9",
      "addr:postcode": "95150",
      "addr:street": "Rue des Peupliers",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33139959581",
      healthcare: "pharmacy",
      name: "Pharmacie Sainte-Honorine",
      phone: "+33139958938",
      "ref:FR:FINESS": "950013045",
      "ref:FR:SIRET": "82076230000011",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7970534266,
    x: 49.0049107,
    y: 2.3967627,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "950013631",
      "ref:FR:SIRET": "33182577800011",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 7973109548,
    x: 49.0048577,
    y: 2.2988184,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950004333",
      "ref:FR:SIRET": "32372912900029",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8069346963,
    x: 48.8583224,
    y: 2.3500024,
    tags: {
      "addr:city": "Paris",
      "addr:housenumber": "18",
      "addr:postcode": "75004",
      "addr:street": "Rue Saint-Martin",
      alt_name: "Pharmacie Jan",
      amenity: "pharmacy",
      "contact:email": "pharmaciedelatour75003@gmail.com",
      "contact:phone": "+331 48 87 74 70",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Tour",
      opening_hours: "Mo-Fr 08:30-20:00; Sa 09:00-20:00",
      "ref:FR:FINESS": "750010480",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 8088610742,
    x: 49.0173103,
    y: 2.0908069,
    tags: {
      "addr:city": "Éragny-sur-Oise",
      "addr:housenumber": "4",
      "addr:postcode": "95610",
      "addr:street": "Rue de la Papeterie",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Nature",
      operator: "Réseau Pharm",
      "ref:FR:FINESS": "950007567",
      "ref:FR:SIRET": "80254076500012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8089026861,
    x: 48.6383454,
    y: 2.3606024,
    tags: {
      "addr:housenumber": "24",
      "addr:street": "Rue Rosa Parks",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Grande Pharmacie de Fleury-Mérogis",
      opening_hours: "Mo-Su 08:30-20:30",
      phone: "+33160158083",
    },
  },
  {
    type: "node",
    id: 8100945978,
    x: 48.9500086,
    y: 2.2581305,
    tags: {
      "addr:housenumber": "1",
      "addr:street": "Rue de la République",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Patrick Guetta",
      phone: "+33139806752",
      "ref:FR:FINESS": "950005116",
      "ref:FR:SIRET": "38540229200010",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8101026147,
    x: 48.9506918,
    y: 2.2634552,
    tags: {
      "addr:housenumber": "49",
      "addr:street": "Avenue de Stalingrad",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33134102017",
      healthcare: "pharmacy",
      name: "Pharmacie d'Orgemont",
      phone: "+33134102017",
      "ref:FR:FINESS": "950004382",
      "ref:FR:SIRET": "44968000800017",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8104688652,
    x: 48.7001838,
    y: 2.4207305,
    tags: {
      "addr:city": "Vigneux-sur-Seine",
      "addr:housenumber": "60",
      "addr:postcode": "91270",
      "addr:street": "Avenue Henri Barbusse",
      alt_name: "Pharmacie de la Liberté",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 03 83 15",
      "contact:phone": "+33 1 69 03 00 59",
      dispensing: "yes",
      name: "Pharmacie Louati",
      opening_hours: "PH,Mo-Su 09:00-22:00",
      "ref:FR:FINESS": "910013713",
      start_date: "1986-03-20",
    },
  },
  {
    type: "node",
    id: 8108042963,
    x: 48.8847748,
    y: 2.4078959,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33148432587",
      healthcare: "pharmacy",
      name: "Pharmacie Conti",
      phone: "+33148458341",
      "ref:FR:FINESS": "930013305",
      "ref:FR:SIRET": "38900919200011",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8113062863,
    x: 48.8530406,
    y: 2.3538,
    tags: {
      "addr:city": "Paris",
      alt_name: "Pharmacie Debasc",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Île Saint-Louis",
      "ref:FR:FINESS": "750010266",
    },
  },
  {
    type: "node",
    id: 8146892540,
    x: 48.710929,
    y: 2.4863451,
    tags: {
      "addr:city": "Yerres",
      "addr:housenumber": "2",
      "addr:postcode": "91330",
      "addr:street": "Rue du Maréchal Juin",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      email: "pharmaciecentrale.yerres@yahoo.com",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      opening_hours: "Mo-Sa 09:00-13:00,14:00-20:00",
      phone: "+33 1 69 48 10 96",
      "ref:FR:FINESS": "910014745",
      "ref:FR:SIRET": "48031911000023",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
      website: "https://www.facebook.com/pharmaciecentraleyerres/",
    },
  },
  {
    type: "node",
    id: 8151190786,
    x: 49.0097804,
    y: 2.041961,
    tags: {
      amenity: "pharmacy",
      healthcare: "pharmacy",
      name: "Pharmacie du Bien-Être",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-20:00; Sa 09:00-12:30,14:30-19:30",
    },
  },
  {
    type: "node",
    id: 8157626800,
    x: 48.8683702,
    y: 2.6880475,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "770011575",
      "ref:FR:SIRET": "34329886500019",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8184307094,
    x: 48.8813085,
    y: 2.7041576,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33164305221",
      phone: "+33164303530",
      "ref:FR:FINESS": "770010346",
      "ref:FR:SIRET": "84181075700010",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8193867386,
    x: 48.8761618,
    y: 2.6977812,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      phone: "+33160077765",
      "ref:FR:FINESS": "770007375",
      "ref:FR:SIRET": "38964630800012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8193927232,
    x: 48.8778424,
    y: 2.7048777,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33164304412",
      name: "Pharmacie de l'Abbaye",
      phone: "+33164300096",
      "ref:FR:FINESS": "770007441",
      "ref:FR:SIRET": "49341137500014",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8202638681,
    x: 48.8674287,
    y: 2.8133687,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Magny",
      "ref:FR:FINESS": "770014660",
      "ref:FR:SIRET": "41993039100026",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8203451982,
    x: 48.882765,
    y: 2.8506715,
    tags: {
      "access:covid19": "yes",
      amenity: "pharmacy",
      "delivery:covid19": "no",
      dispensing: "yes",
      name: "Pharmacie Saint-Germain",
      "opening_hours:covid19": "Mo-Sa 09:00-18:00",
      "ref:FR:FINESS": "770011344",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2012",
    },
  },
  {
    type: "node",
    id: 8205926384,
    x: 48.8851421,
    y: 2.8320587,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33160046473",
      phone: "+33160042455",
      "ref:FR:FINESS": "770009397",
      "ref:FR:SIRET": "85048208400017",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215031648,
    x: 48.8190711,
    y: 2.5189309,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940006620",
      "ref:FR:SIRET": "34931154800011",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215031649,
    x: 48.8122245,
    y: 2.5448558,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33148807672",
      phone: "+33148807672",
      "ref:FR:FINESS": "940006786",
      "ref:FR:SIRET": "53863138300014",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215031661,
    x: 48.8179509,
    y: 2.4821206,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33148860739",
      phone: "+33142838505",
      "ref:FR:FINESS": "940010614",
      "ref:FR:SIRET": "45270496800018",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215031684,
    x: 48.8180883,
    y: 2.5464597,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Perroquets",
      phone: "+33148804730",
      "ref:FR:FINESS": "940006554",
      "ref:FR:SIRET": "43865867600012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215031685,
    x: 48.8131028,
    y: 2.5508596,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "940007172",
      "ref:FR:SIRET": "38882925100015",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8215065761,
    x: 48.821333,
    y: 2.5109761,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8220269156,
    x: 48.8477207,
    y: 2.5327097,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33143030652",
      phone: "+33143030652",
      "ref:FR:FINESS": "930012117",
      "ref:FR:SIRET": "49317386800016",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8220281840,
    x: 48.8482043,
    y: 2.5487793,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930012034",
      "ref:FR:SIRET": "38913766200018",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8220529264,
    x: 48.8492358,
    y: 2.5651787,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930011853",
      "ref:FR:SIRET": "33432219500010",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8220529267,
    x: 48.8482002,
    y: 2.5703019,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Noisy-Cormiers",
      "ref:FR:FINESS": "930012000",
      "ref:FR:SIRET": "53062139000018",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8221186201,
    x: 48.8047895,
    y: 2.5520139,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33148808762",
      phone: "+33148806418",
      "ref:FR:FINESS": "940006687",
      "ref:FR:SIRET": "49011188700017",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8222380946,
    x: 48.848731,
    y: 2.5522023,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930012067",
      "ref:FR:SIRET": "83282184700015",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8222500532,
    x: 48.8297521,
    y: 2.566697,
    tags: {
      "addr:housenumber": "13",
      "addr:postcode": "93160",
      "addr:street": "Rue des Halles",
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie du Centre",
      opening_hours: "Mo-Fr 09:00-20:00; Sa 09:00-19:30",
      "ref:FR:FINESS": "930011887",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 8222500547,
    x: 48.8389543,
    y: 2.5532448,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "930012133",
      "ref:FR:SIRET": "51779160400013",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8224488345,
    x: 48.7936191,
    y: 2.5546787,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8224488348,
    x: 48.7954135,
    y: 2.5337622,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Terrasses",
      "ref:FR:FINESS": "940007537",
      "ref:FR:SIRET": "43272253600021",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8225467072,
    x: 48.8064216,
    y: 2.551928,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33148808762",
      phone: "+33148806418",
      "ref:FR:FINESS": "940006687",
      "ref:FR:SIRET": "49011188700017",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8230642935,
    x: 48.8800218,
    y: 2.6267041,
    tags: {
      "addr:city": "Brou-sur-Chantereine",
      "addr:housenumber": "37",
      "addr:postcode": "77177",
      "addr:street": "Avenue Jean Jaurès",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de l'avenue",
      opening_hours:
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-18:30",
      "opening_hours:covid19":
        "Mo-Fr 09:00-12:30,14:30-19:30; Sa 09:00-12:30,14:30-18:30",
      phone: "+33 1 60 20 11 17",
      "ref:FR:FINESS": "770004141",
      source:
        "cadastre-dgi-fr source : Direction Générale des Impôts - Cadastre. Mise à jour : 2011",
    },
  },
  {
    type: "node",
    id: 8231279812,
    x: 48.8689901,
    y: 2.5735041,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "76",
      "addr:postcode": "77500",
      "addr:street": "Avenue du Maréchal Foch",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Foch",
      opening_hours:
        "Mo 14:00-20:30; Tu-Fr 09:00-12:30,14:00-20:30; Sa 09:00-12:30,14:00-19:30",
      "opening_hours:covid19": "open",
      phone: "+33 1 64 26 29 16",
      "ref:FR:FINESS": "770005213",
    },
  },
  {
    type: "node",
    id: 8231279815,
    x: 48.8698442,
    y: 2.5801302,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "28",
      "addr:postcode": "77500",
      "addr:street": "Avenue du Général de Gaulle",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Biard Olivier",
      opening_hours:
        "Mo 14:15-20:30; Tu-Fr 09:00-12:15,14:15-20:30; Sa 09:00-12:15,14:15-20:00",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 08 08 15",
      "ref:FR:FINESS": "770004976",
      website: "https://pharmaciebiard-chelles.epharmacie.pro/",
    },
  },
  {
    type: "node",
    id: 8231279816,
    x: 48.904556,
    y: 2.5862921,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "146",
      "addr:postcode": "77500",
      "addr:street": "Avenue des Sciences",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      email: "pharmacielescoudreaux@leadersante.fr",
      healthcare: "pharmacy",
      name: "Pharmacie Les Coudreaux",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 20 00 02",
      "ref:FR:FINESS": "770004893",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      website:
        "http://ordonnances.leadersante.fr/pharmacie-les-coudreaux-77500-chelles/",
    },
  },
  {
    type: "node",
    id: 8231288520,
    x: 48.8768385,
    y: 2.5847449,
    tags: {
      "addr:city": "Chelles",
      "addr:housenumber": "36",
      "addr:postcode": "77500",
      "addr:street": "Avenue de la Résistance",
      amenity: "pharmacy",
      "delivery:covid19": "yes",
      "description:covid19":
        "maladie chronique ou besoin régulier de médicaments sans pouvoir vous rendre en pharmacie ? Contactez votre pharmacie, un volontaire envoyé par la mairie de Chelles assurera la livraison.",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Laporte",
      "opening_hours:covid19": "open",
      phone: "+33 1 60 08 00 68",
      "ref:FR:FINESS": "770005130",
    },
  },
  {
    type: "node",
    id: 8234901155,
    x: 48.7828696,
    y: 2.6556979,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie du centre commercial",
      "ref:FR:FINESS": "770010940",
      "ref:FR:SIRET": "53500162200021",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8235163498,
    x: 48.7961464,
    y: 2.6503414,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Gare",
      "ref:FR:FINESS": "770011062",
      "ref:FR:SIRET": "84051463200016",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8237172456,
    x: 48.7635143,
    y: 2.5358147,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie Couffignal",
      "ref:FR:FINESS": "940014095",
      "ref:FR:SIRET": "88894649800019",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8237172467,
    x: 48.7745152,
    y: 2.5261118,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33145904070",
      phone: "+33145901872",
      "ref:FR:FINESS": "940014111",
      "ref:FR:SIRET": "49531788500012",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8237390207,
    x: 48.8971385,
    y: 2.3996186,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie du RER",
    },
  },
  {
    type: "node",
    id: 8241064503,
    x: 48.8665083,
    y: 2.7594486,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de la Frênaie",
      "ref:FR:FINESS": "770018885",
      source: "Le ministère des solidarités et de la santé - 08/2018",
    },
  },
  {
    type: "node",
    id: 8244884101,
    x: 48.8786411,
    y: 2.7633066,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie de Chessy",
      phone: "+33160434488",
      "ref:FR:FINESS": "770005262",
      "ref:FR:SIRET": "40175398300022",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8250139051,
    x: 48.870284,
    y: 2.3769391,
    tags: {
      "addr:city": "Paris",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Veaucouleurs",
      "opening_hours:covid19": "open",
      operator: "Ho Marie Christiane",
      "ref:FR:FINESS": "750039208",
      source: "survey;Celtipharm - 10/2014",
    },
  },
  {
    type: "node",
    id: 8263848007,
    x: 48.6480179,
    y: 2.4169439,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
    },
  },
  {
    type: "node",
    id: 8319371955,
    x: 48.7111952,
    y: 2.1616257,
    tags: {
      amenity: "pharmacy",
      "contact:phone": "+33 1 69 82 92 51",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Laurence Guichet",
      opening_hours: "Mo-Fr 09:00-19:30;Sa 09:00-13:00;15:00-19:00",
    },
  },
  {
    type: "node",
    id: 8375105984,
    x: 48.7931045,
    y: 2.2845914,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Boucicaut",
      opening_hours:
        "Mo-Fr 09:00-13:00,14:30-20:00; Sa 09:00-13:00,14:30-19:00",
      "ref:FR:FINESS": "920014131",
      "ref:FR:SIRET": "79992536700010",
      source: "Le ministère des solidarités et de la santé - 03/2019",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8442400203,
    x: 48.6805961,
    y: 2.4008225,
    tags: {
      "addr:city": "Draveil",
      "addr:housenumber": "26",
      "addr:postcode": "91210",
      "addr:street": "Rue du Port aux Dames",
      amenity: "pharmacy",
      "contact:fax": "+33 1 69 83 26 51",
      "contact:phone": "+33 1 69 42 30 14",
      dispensing: "yes",
      name: "Pharmacie Haussmann",
      "ref:FR:FINESS": "910007723",
      since: "2007-02-01",
      source:
        "cadastre-dgi-fr source : Direction Générale des Finances Publiques - Cadastre. Mise à jour : 2021",
      "toilets:wheelchair": "no",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 8493099198,
    x: 48.4961854,
    y: 2.799881,
    tags: {
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      "ref:FR:FINESS": "770004810",
      "ref:FR:SIRET": "52818651300024",
      source: "Le ministère des solidarités et de la santé - 2021-01",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8511381158,
    x: 48.8839275,
    y: 2.2252131,
    tags: {
      "addr:city": "Puteaux",
      "addr:housenumber": "6",
      "addr:postcode": "92800",
      "addr:street": "Route des Fusillés de la Résistance",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Hiriart",
    },
  },
  {
    type: "node",
    id: 8513136634,
    x: 48.8700103,
    y: 2.5667384,
    tags: {
      "addr:city": "Gagny",
      "addr:housenumber": "53",
      "addr:postcode": "93220",
      "addr:street": "Avenue Roger Salengro",
      amenity: "pharmacy",
      brand: "Pharmacie Principale",
      "brand:wikidata": "Q1547749",
      "brand:wikipedia": "fr:Groupe PP Holding",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Principale",
      opening_hours: "Mo-Su 09:00-20:00",
      "opening_hours:covid19": "same",
      operator: "Népenttiès Pharmacie",
      "ref:FR:FINESS": "930008966",
      "ref:FR:SIRET": "34260998900013",
      source: "Le ministère des solidarités et de la santé - 2021-01",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8513522246,
    x: 48.8926467,
    y: 2.3426611,
    tags: {
      "addr:city": "Paris",
      "addr:postcode": "75018",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Gondard Casabianca",
      operator: "Pharmodel",
    },
  },
  {
    type: "node",
    id: 8517721186,
    x: 48.8599539,
    y: 2.447313,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 8559212391,
    x: 48.7622994,
    y: 2.4111727,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      phone: "+33148847477",
      "ref:FR:FINESS": "940008006",
      "ref:FR:SIRET": "33909485600020",
      source: "Le ministère des solidarités et de la santé - 2021-01",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8600265503,
    x: 48.9611577,
    y: 2.0570032,
    tags: {
      "addr:city": "Achères",
      "addr:housenumber": "3",
      "addr:postcode": "78260",
      "addr:street": "Avenue Wolfgang Amadeus Mozart",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Le Grand Cèdre",
      opening_hours: "Mo-Sa 09:00-19:00",
      phone: "+33139113961",
      website: "https://grandepharmacieduccdacheres.pharminfo.fr/",
    },
  },
  {
    type: "node",
    id: 8647414487,
    x: 49.0129567,
    y: 2.205597,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Plaine",
      "ref:FR:FINESS": "950013011",
      "ref:FR:SIRET": "34258353100013",
      source: "Le ministère des solidarités et de la santé - 2021-03",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8652920816,
    x: 48.8765467,
    y: 2.4234147,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      name: "Pharmacie des Cliniques",
      "ref:FR:FINESS": "930005566",
      source: "survey",
      "source:ref:FR:FINESS":
        "data.gouv.fr:Ministère des Solidarités et de la Santé - 2021-01",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8677265199,
    x: 48.8017085,
    y: 2.2657857,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Pierre et Marie Curie",
      "ref:FR:FINESS": "920012093",
      "ref:FR:SIRET": "84155186400025",
      source: "Le ministère des solidarités et de la santé - 2021-03",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8702205905,
    x: 48.9300395,
    y: 2.1972316,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33139689002",
      healthcare: "pharmacy",
      name: "Pharmacie Lalam",
      "ref:FR:FINESS": "780008660",
      "ref:FR:SIRET": "75056512900020",
      source: "Le ministère des solidarités et de la santé - 2021-03",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8728358228,
    x: 48.8078978,
    y: 2.5089235,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie de Champignol",
      "ref:FR:FINESS": "940013865",
    },
  },
  {
    type: "node",
    id: 8761857698,
    x: 48.7531393,
    y: 2.3047578,
    tags: {
      "addr:city": "Antony",
      "addr:housenumber": "8",
      "addr:postcode": "92160",
      "addr:street": "Avenue de la Division Leclerc",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Bader M.",
      "ref:FR:FINESS": "920007911",
      "ref:FR:SIRET": "32867532700014",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8764885791,
    x: 48.9597686,
    y: 2.2387359,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33139825943",
      name: "Pharmacie des Coteaux",
      phone: "+33139825764",
      "ref:FR:FINESS": "950004820",
      "ref:FR:SIRET": "81746415900018",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8768384826,
    x: 48.9709342,
    y: 2.3209862,
    tags: {
      "addr:city": "Deuil-la-Barre",
      "addr:street": "Avenue Mathieu Chazotte",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "pharmacie du marchée",
    },
  },
  {
    type: "node",
    id: 8772549955,
    x: 49.0231605,
    y: 2.1539595,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Samson",
      phone: "+33134646240",
      "ref:FR:FINESS": "950010942",
      "ref:FR:SIRET": "45046315300012",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8805135846,
    x: 48.9348374,
    y: 2.3324814,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Centrale",
      "ref:FR:FINESS": "920021706",
      "ref:FR:SIRET": "49226946900036",
      source: "Le ministère des solidarités et de la santé - 2021-03",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8805234142,
    x: 48.9344551,
    y: 2.3287521,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de L'hopital",
      "ref:FR:FINESS": "920022621",
      "ref:FR:SIRET": "79538457700024",
      source: "Le ministère des solidarités et de la santé - 2021-03",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8846042718,
    x: 49.1389161,
    y: 2.809667,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33344880666",
      phone: "+33344880033",
      "ref:FR:FINESS": "600004154",
      "ref:FR:SIRET": "51120806800011",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8852558789,
    x: 48.7826192,
    y: 2.2274057,
    tags: {
      "addr:housenumber": "12;14",
      "addr:street": "Place Simone Veil",
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie de l’Écoquartier",
      opening_hours: "Mo-Sa 09:00-20:00",
      operator: "Aprium",
      phone: "+33146260133",
      "ref:FR:NAF": "4773Z",
      "ref:FR:SIRET": "85372121500020",
      start_date: "2021-04-01",
      website: "https://ecoquartier.aprium-pharmacie.fr/",
      wheelchair: "yes",
    },
  },
  {
    type: "node",
    id: 8863560068,
    x: 48.5850342,
    y: 2.3045539,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Univers Pharmacie",
    },
  },
  {
    type: "node",
    id: 8865134388,
    x: 48.6528034,
    y: 2.2747034,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33169103172",
      name: "Pharmacie des Echassons",
      phone: "+33169103172",
      "ref:FR:FINESS": "910009901",
      "ref:FR:SIRET": "81236430500019",
      source: "Le ministère des solidarités et de la santé - 2021-06",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8889978181,
    x: 48.9201992,
    y: 2.2287373,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33141190659",
      name: "Pharmacie des Grèves",
      phone: "+33147801068",
      "ref:FR:FINESS": "920013315",
      "ref:FR:SIRET": "84379487600014",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8891927117,
    x: 48.8759301,
    y: 2.1694708,
    tags: {
      "addr:housenumber": "262",
      "addr:postcode": "92500",
      "addr:street": "Avenue Napoléon Bonaparte",
      amenity: "pharmacy",
      facebook: "https://m.facebook.com/pharmaciebonaparte/",
      name: "Pharmacie Bonaparte",
      opening_hours: "Mo-Sa 09:00-20:00",
      phone: "+33 1 57 69 32 02",
    },
  },
  {
    type: "node",
    id: 8919976622,
    x: 48.9643277,
    y: 2.2560505,
    tags: {
      amenity: "pharmacy",
    },
  },
  {
    type: "node",
    id: 8936615426,
    x: 48.8265323,
    y: 1.9597991,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 8938066003,
    x: 48.9360498,
    y: 2.5131785,
    tags: {
      "addr:street": "Rue Pierre Brossolette",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Rougemont",
    },
  },
  {
    type: "node",
    id: 8938106300,
    x: 48.9299063,
    y: 2.5403578,
    tags: {
      "addr:city": "Sevran",
      "addr:housenumber": "92",
      "addr:postcode": "93270",
      "addr:street": "Avenue de Livry",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie Les Trèfles",
      "ref:FR:FINESS": "930015466",
      "ref:FR:SIRET": "82057105700018",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8938106310,
    x: 48.934578,
    y: 2.5346104,
    tags: {
      "addr:city": "Sevran",
      "addr:housenumber": "6",
      "addr:postcode": "93270",
      "addr:street": "Avenue de Livry",
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de Livry",
      "ref:FR:FINESS": "930015433",
      "ref:FR:SIRET": "75050346800019",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8942673705,
    x: 48.7804835,
    y: 2.1969183,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      "ref:FR:FINESS": "780014510",
      "ref:FR:SIRET": "83080633700028",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8953312152,
    x: 48.9987892,
    y: 2.1514943,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      "ref:FR:FINESS": "950009183",
      "ref:FR:SIRET": "35016943900014",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 8959840018,
    x: 48.8354794,
    y: 2.2301491,
    tags: {
      "addr:housenumber": "186 bis",
      "addr:street": "Rue Gallieni",
      amenity: "pharmacy",
      name: "Pharmacie Gallieni",
    },
  },
  {
    type: "node",
    id: 9008726715,
    x: 48.7551355,
    y: 2.3058511,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      drive_through: "no",
      healthcare: "pharmacy",
      name: "Pharmacie Bader",
      "ref:FR:FINESS": "920007762",
      "ref:FR:SIRET": "32175942500010",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 9015402138,
    x: 48.7541411,
    y: 2.2971131,
    tags: {
      "addr:city": "Antony",
      "addr:housenumber": "9",
      "addr:postcode": "92160",
      "addr:street": "Rue de l'Église",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33957257306",
      healthcare: "pharmacy",
      name: "Pharmacie de l'Église",
      phone: "+33980842359",
      "ref:FR:FINESS": "920007994",
      "ref:FR:SIRET": "53101665700011",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
      website: "https://pharmaciedeleglise92.pharminfo.fr",
    },
  },
  {
    type: "node",
    id: 9051936428,
    x: 49.0367334,
    y: 2.3324309,
    tags: {
      "addr:city": "Domont",
      "addr:postcode": "95330",
      "addr:street": "Rue Aristide Briand",
      amenity: "pharmacy",
      dispensing: "yes",
      fax: "+33139912668",
      healthcare: "pharmacy",
      phone: "+33139911011",
      "ref:FR:FINESS": "950006882",
      "ref:FR:SIRET": "48934754200016",
      source: "Le ministère des solidarités et de la santé - 2021-07",
      "type:FR:FINESS": "620",
    },
  },
  {
    type: "node",
    id: 9073455345,
    x: 48.7845617,
    y: 2.302545,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie des Blagis",
    },
  },
  {
    type: "node",
    id: 9102042838,
    x: 48.7936885,
    y: 2.4558594,
    tags: {
      amenity: "pharmacy",
      name: "Pharmacie Champeval",
      operator: "Suprapharm",
    },
  },
  {
    type: "node",
    id: 9121877936,
    x: 48.6769361,
    y: 2.5573856,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
      name: "Pharmacie de la Grande Ferme",
    },
  },
  {
    type: "node",
    id: 9122553879,
    x: 49.0001183,
    y: 2.3620759,
    tags: {
      amenity: "pharmacy",
      dispensing: "yes",
      healthcare: "pharmacy",
    },
  },
  {
    type: "node",
    id: 9152231545,
    x: 48.9760491,
    y: 2.3882268,
    tags: {
      amenity: "pharmacy",
    },
  },
];
