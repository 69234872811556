import React from "react";
import { Box, Modal } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import { usePlaySound } from "../../Global/CustomHooks";
import SVGTombStone from "../../SVG/Character/tombstone.svg";
import "./SkinsSelector.less";
import { toast } from "react-toastify";

const ALL_SKINS = [
  { id: "base", name: "Standard" },
  { id: "1", name: "Superman" },
  { id: "2", name: "Batman" },
  { id: "3", name: "Carl" },
  { id: "4", name: "Flash" },
  { id: "5", name: "Robocop" },
  { id: "6", name: "Wolverine" },
  { id: "7", name: "Mario" },
  { id: "8", name: "Thor" },
  { id: "9", name: "?" },
  { id: "10", name: "Hulk" },
  { id: "11", name: "E.T" },
  { id: "12", name: "Spartiate" },
  { id: "13", name: "Policier" },
  { id: "14", name: "Jigsaw" },
  { id: "15", name: "Luigi" },
  { id: "16", name: "Chicken Little" },
  { id: "17", name: "Power ranger" },
  { id: "18", name: "Mr Pringles" },
  { id: "19", name: "Medic" },
  { id: "20", name: "Kobe Bryant" },
  { id: "21", name: "Pizzaïolo" },
  { id: "22", name: "Kyle Broflovski" },
  { id: "23", name: "Cyclope" },
];
export const ALL_SKINS_IMG = [];
const importAllSvgInFolder = (r) => {
  r.keys().forEach((item) => {
    ALL_SKINS_IMG[item.replace("./", "").replace(".svg", "")] = r(item).default;
  });
  // console.log("-- IMPORTED SKINS = ", ALL_SKINS_IMG);
};
importAllSvgInFolder(require.context("../../SVG/Character/", false, /\.svg$/));

export const getUserSkin = (user) => {
  return user.pv > 0 ? ALL_SKINS_IMG[user.currentSkin] : SVGTombStone;
};

const SkinsSelector = ({ socket, user, isModalOpen, setIsModalOpen }) => {
  const playSound = usePlaySound();

  const changeSkin = (id) => {
    if (id !== user.currentSkin) {
      playSound("click");
      socket.emit("user.changeSkin", id, (isOk) => {
        if (!isOk) {
          toast.error("Erreur : Vous ne possédez pas ce skin", {
            toastId: "skinUnavailable",
          });
        }
      });
    }
  };

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Box className="skinsSelectorWrapper">
        <h2>Skins</h2>
        <div className="skinHorizontalScroll">
          {ALL_SKINS.map((item, i) => {
            const img = ALL_SKINS_IMG[item.id];
            const isLocked = !(user?.skinsId[item.id] || i === 0);
            return (
              <div className="skinCard" key={item.id}>
                <img
                  src={img}
                  loading="lazy"
                  alt="skin"
                  className={`skinImg ${isLocked ? "locked" : ""}`}
                  onClick={() => changeSkin(item.id)}
                />
                <div className="name" onClick={() => changeSkin(item.id)}>
                  {isLocked ? "?????" : item.name}
                </div>
                {user.currentSkin === item.id && (
                  <CheckCircleOutlineOutlinedIcon className="selected" />
                )}
              </div>
            );
          })}
        </div>
      </Box>
    </Modal>
  );
};

export default SkinsSelector;
