import ChestOpenSound from "../Sounds/chestOpen.mp3";
import ClickSound from "../Sounds/click.mp3";
import EquipArmorSound from "../Sounds/equipArmor.mp3";
import EquipSwordSound from "../Sounds/equipSword.mp3";
import GoldSound from "../Sounds/gold.mp3";
import ItemPickUpSound from "../Sounds/itemPickup.mp3";
import PotionSound from "../Sounds/usePotion.mp3";
import DeleteSound from "../Sounds/delete.mp3";
import ErrorSound from "../Sounds/error.mp3";
import ConcentrationSound from "../Sounds/concentration.mp3";
import ConcentrationEndSound from "../Sounds/concentrationInverse.mp3";
import AddPotionSound from "../Sounds/addPotion.mp3";
import FightTheme from "../Sounds/fightTheme.mp3";
import PlayerDeath from "../Sounds/playerDeath.wav";
import MonsterDeath from "../Sounds/monsterDeath.mp3";
import BuySound from "../Sounds/buy.mp3";
import ReviveSound from "../Sounds/revive.mp3";

// Sounds
export const SOUNDS = {
  chest: ChestOpenSound,
  click: ClickSound,
  equipArmor: EquipArmorSound,
  equipSword: EquipSwordSound,
  gold: GoldSound,
  pickUp: ItemPickUpSound,
  potion: PotionSound,
  delete: DeleteSound,
  error: ErrorSound,
  concentration: ConcentrationSound,
  concentrationEnd: ConcentrationEndSound,
  addPotion: AddPotionSound,
  fightTheme: FightTheme,
  playerDeath: PlayerDeath,
  monsterDeath: MonsterDeath,
  buy: BuySound,
  revive: ReviveSound,
};

export const isProduction = process.env.NODE_ENV === "production";
export const PORT = 7773;
export const VERSION = "0.1";
export const BASE_URL = isProduction
  ? `https://maps.idontcare.fr:${PORT}`
  : `http://localhost:${PORT}`;

export const INVENT_LINE_SIZE = 4;

// ENUM
export const BOURSE = "bourse";
export const CHEST = "chest";
export const PNJ = "PNJ";
export const POTION = "potion";
export const JUNK = "junk";
export const MOB = "mob";
export const OTHERS = "others";
export const SKIN = "skin";

// PNJ :
export const CARPENTER = "carpenter";
export const SPY = "spy";
export const PHARMACY = "pharmacy";

// ACTIONS
export const HEAL = "Heal";
export const BUY = "Buy";

// Menus
export const INVENT = "Invent";
export const MAP = "Map";
export const SKILLS = "Skills";

// Zooms
export const MAX_ZOOM = 18;
export const MIN_ZOOM = 15;

//Skills
export const AUTO_ATK = "auto_atk";
export const BLOCK = "block";
export const CONCENTRATION = "concentration";
export const MAX_LIFE = "maxLife";
export const VISION = "vision";

// Colors:
export const VERT_POSITIF = "#32af3b";
export const ROUGE_NEGATIF = "#d72222";
export const COLOR_CRITIQUE = "#000000";

// EQUIPEMENT
export const SHIELD = "shield";
export const EQUIPEMENT = "equipement";
export const SWORD = "sword";
export const HELMET = "helmet";
export const ARMOR = "armor";
export const GLOVE = "glove";
export const LEGS = "legs";

// Coef roundness
export const COEF_ROUDNESS = 0.0006;
// Equivalent of ~= 1 m en coordonnées
export const ONE_METER_COORD = 0.00000899;
