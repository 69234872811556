import React, { useCallback } from "react";

import Invent from "../../SVG/backpack.svg";
import Skills from "../../SVG/skills.svg";
import Maps from "../../SVG/map.svg";
import "./Menu.less";
import { INVENT, MAP, SKILLS } from "../../Global/Constantes";

const MENU = [
  { label: "", svg: Invent, id: INVENT },
  { label: "", svg: Maps, id: MAP },
  { label: "", svg: Skills, id: SKILLS },
];

const Menu = ({ badges, setIndex, index }) => {
  const renderMenus = useCallback(() => {
    const onClickMenu = (i) => {
      setIndex(i);
    };
    return MENU.map((m, i) => (
      <div key={m.svg} className="menu" onClick={() => onClickMenu(i)}>
        <div className={`imageWrapper ${index === i && "active"}`}>
          <img
            src={m.svg}
            alt="invent"
            className={`icon ${index === i && "active"}`}
          />
          {!!badges[m.id] && badges[m.id] > 0 && (
            <span className="badge">{badges[m.id]}</span>
          )}
        </div>
      </div>
    ));
  }, [badges, index, setIndex]);

  return (
    <div
      className="menuWrapper"
      style={{ backgroundColor: "black" }} //user.isDarkMode ? "black" : "#d5d7d3" }}
    >
      {renderMenus()}
    </div>
  );
};

export default Menu;
