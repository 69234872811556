export const formatNumber = (number, decimal = 0) => {
  const floatValue = parseFloat(number);
  if (isNaN(floatValue)) {
    return "-";
  }
  return separateThousands(floatValue.toFixed(decimal));
};

/**
 * Fonction qui renvoie un random entre [min, max[
 */
export const rand = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const separateThousands = (number) => {
  const dotNumber = dotSeparator(number);
  const floatValue = parseFloat(dotNumber);
  if (isNaN(floatValue)) {
    return "-";
  }
  const tab = dotNumber.split(".");
  let partieEntiere = tab[0];

  // Si la partie entière à plus de 3 chiffres on met des espaces comme séparateur des millers
  if (partieEntiere.length > 3) {
    partieEntiere = partieEntiere.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return `${partieEntiere}${!!tab[1] ? "," + tab[1] : ""}`;
};

export const dotSeparator = (number) => {
  if (!!number) {
    return number.toString().replace(/,/g, ".").replace(/ /g, "");
  }
  return "-";
};

export const countUtilsDecimals = (value) => {
  const floatValue = parseFloat(dotSeparator(value));
  if (isNaN(floatValue)) {
    return 0;
  }
  if (floatValue % 1 !== 0) {
    return floatValue.toString().split(".")[1].length;
  }
  return 0;
};

export const coordToMeter = (dist) => {
  return getDistance_m({ x: 0, y: 0 }, { x: 0 + dist, y: 0 });
};

export const isInZone = (zone, { x, y }) => {
  return (
    y >= zone.lonMin && y <= zone.lonMax && x >= zone.latMin && x <= zone.latMax
  );
};

export const getDistance_m = (a, b) => {
  // Rayon de la terre à l'équateur pour les calculs de distances
  const EARTH_RADIUS = 6378137;
  const lng1 = a.x;
  const lng2 = b.x;

  const lat1 = a.y;
  const lat2 = b.y;

  const deg2rad = (x) => {
    return (Math.PI * x) / 180;
  };

  // CONVERSION
  const rlo1 = deg2rad(lng1);
  const rla1 = deg2rad(lat1);
  const rlo2 = deg2rad(lng2);
  const rla2 = deg2rad(lat2);

  const dlo = (rlo2 - rlo1) / 2;
  const dla = (rla2 - rla1) / 2;
  const mid =
    Math.sin(dla) * Math.sin(dla) +
    Math.cos(rla1) * Math.cos(rla2) * (Math.sin(dlo) * Math.sin(dlo));
  const d = 2 * Math.atan2(Math.sqrt(mid), Math.sqrt(1 - mid));
  return EARTH_RADIUS * d;
};
