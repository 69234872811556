import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import "./Inventaire.less";
import SVGStar from "../../SVG/star.svg";
import SVGEquip from "../../SVG/equip.svg";
import SVGUnEquip from "../../SVG/unequip.svg";
import { ReactComponent as SVGKey } from "../../SVG/key.svg";
import { ReactComponent as SVGBoire } from "../../SVG/boire.svg";
import GoldSVG from "../../SVG/gold.svg";
import {
  CHEST,
  GLOVE,
  HELMET,
  INVENT,
  POTION,
  SHIELD,
  SWORD,
} from "../../Global/Constantes";
import { ITEMS_METADATAS } from "../../Global/MetaDatas";
import { toast } from "react-toastify";
import { usePlaySound } from "../../Global/CustomHooks";

const ItemMenu = ({
  item,
  user,
  socket,
  closeMenu = () => {},
  setDataToDelete = () => {},
  menuAnchor,
  isEquiped = false,
  isShop = false,
  setBadges = () => {},
}) => {
  const playSound = usePlaySound();
  const renderEffects = (effects) => {
    if (effects?.length > 0) {
      return effects.map((effect, index) => (
        <div key={index} className="effects" style={{ color: effect.color }}>
          {effect.txt}
        </div>
      ));
    }
  };

  const utiliserItem = () => {
    switch (item.type) {
      case CHEST:
        playSound("chest");
        socket.emit("chest.open", item.id, item.level, () => {
          // Open dialog to show drop ?
        });
        break;
      case POTION:
        playSound("potion");
        socket.emit("potion.use", item.id, item.level);
        break;
      case SWORD:
      case SHIELD:
      case HELMET:
      case GLOVE:
        socket.emit(
          "item.toggleEquip",
          item.id,
          isEquiped,
          ({ isOk, code, message }) => {
            if (!isOk) {
              playSound("error");
              toast.error(message, { toastId: code });
            } else {
              playSound("equipSword");
            }
          }
        );
        break;
      default:
        break;
    }
    closeMenu();
  };

  const renderScore = (level) => {
    let stars = [];
    for (let i = 0; i <= level; i++) {
      stars.push(
        <img key={i} src={SVGStar} alt="star" className="starScoreSVG" />
      );
    }
    return <div className="starContainer">Score : {stars.map((s) => s)}</div>;
  };

  const buySkin = (idSkin, price) => {
    if (user?.gold >= price) {
      socket.emit("skin.buy", idSkin, price, () => {
        setBadges((old) => ({
          ...old,
          [INVENT]: old[INVENT] + 1,
        }));
        closeMenu();
        playSound("buy");
      });
    }
  };

  const getMenusForItem = () => {
    if (!item) {
      return null;
    }
    const type = item.type;
    const mainffects = ITEMS_METADATAS[type].effects || [];
    const metas = ITEMS_METADATAS[type][item.level];
    const metasEffects = metas?.effects || [];
    const customEffects = item?.effects || [];
    const effects = [...mainffects, ...metasEffects, ...customEffects];

    const mainInfos = (
      <div key={"datas"} className="dataWrapper">
        <div className="itemTitle">{metas.title}</div>
        {item.canEquip && renderScore(item.level)}
        {renderEffects(effects)}
        <div className="itemDescription">{metas.description}</div>
      </div>
    );
    let availableMenus = [mainInfos];
    switch (type) {
      case CHEST:
        availableMenus.push(
          <MenuItem key={"openChest"} onClick={utiliserItem}>
            <ListItemIcon>
              <SVGKey className="menuItemIcon" />
            </ListItemIcon>
            <div className="myPolice">Ouvrir le coffre</div>
          </MenuItem>
        );
        break;
      case POTION:
        availableMenus.push(
          <MenuItem key={"usePotion"} onClick={utiliserItem}>
            <ListItemIcon>
              <SVGBoire className="menuItemIcon" />
            </ListItemIcon>
            <div className="myPolice">Boire</div>
          </MenuItem>
        );
        break;
      default:
        break;
    }

    if (item.canEquip) {
      availableMenus.push(
        <MenuItem key={"equip"} onClick={utiliserItem}>
          <ListItemIcon>
            <img
              src={isEquiped ? SVGUnEquip : SVGEquip}
              alt="equip"
              className="menuItemIcon"
            />
          </ListItemIcon>
          <div className="myPolice">{isEquiped ? "Déséquiper" : "Equiper"}</div>
        </MenuItem>
      );
    }
    if (!item.canEquip || !isEquiped) {
      availableMenus.push(
        <MenuItem
          key={"delete"}
          onClick={() => {
            playSound("click");
            setDataToDelete({ item, title: metas.title });
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          <div className="myPolice">Détruire</div>
        </MenuItem>
      );
    }

    // Dans le cas d'un shop on overides tout !
    // Sans oublier de remettre le mainInfos
    if (isShop) {
      const price = item.price || ITEMS_METADATAS[item.type][item.level].price;
      availableMenus = [mainInfos];
      availableMenus.push(
        <MenuItem key={"buy"} onClick={() => buySkin(item.level, price)}>
          <ListItemIcon>
            <img src={GoldSVG} alt="buy" className="menuItemIcon" />
          </ListItemIcon>
          <div className="myPolice">{price}</div>
        </MenuItem>
      );
    }

    return availableMenus;
  };

  return (
    <Menu
      anchorEl={menuAnchor}
      open={!!item}
      onClose={closeMenu}
      className="itemMenuWrapper"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      hideBackdrop={false}
    >
      {getMenusForItem()}
    </Menu>
  );
};

export default ItemMenu;
