import React, { useState } from "react";
import { MapContainer } from "react-leaflet";

import "./Map.less";
import "./LeafletCSS.less";
import { MAX_ZOOM } from "../../Global/Constantes";
import Controls from "./Controls";
import MapContent from "./MapContent";
import ModalPnj from "./ModalPnj/ModalPnj";
import ModalFight from "./ModalFight/ModalFight";

const MapWrapper = ({
  user,
  socket,
  bourses,
  setBourses,
  zone,
  setZone,
  skills,
  setChests,
  chests,
  setBadges,
  invent,
  mobs,
  setMobs,
  equiped,
  others,
}) => {
  const [zoom, setZoom] = useState(MAX_ZOOM);
  const [pnjData, setPnjData] = useState(false);
  const initFight = user?.fightData?.monster?.pv
    ? { ...user?.fightData, startImmediatly: true }
    : null;
  const [fightData, setFightData] = useState(initFight);
  const [hiddenLayers, setHiddenLayers] = useState({});

  return (
    <div className="mapContainer">
      <MapContainer
        center={{ lng: 0, lat: 0 }} // just init
        zoom={MAX_ZOOM}
        className="map"
        zoomControl={false}
        scrollWheelZoom={user.isAdmin}
        touchZoom={false}
        dragging={false}
        doubleClickZoom={false}
        zoomSnap={false}
      >
        <MapContent
          user={user}
          socket={socket}
          bourses={bourses}
          setBourses={setBourses}
          chests={chests}
          setChests={setChests}
          zone={zone}
          setZone={setZone}
          zoom={zoom}
          skills={skills}
          setPnjData={setPnjData}
          setFightData={setFightData}
          setBadges={setBadges}
          invent={invent}
          hiddenLayers={hiddenLayers}
          mobs={mobs}
          setMobs={setMobs}
          others={others}
        />
      </MapContainer>
      <Controls
        zoom={zoom}
        setZoom={setZoom}
        socket={socket}
        user={user}
        skills={skills}
        hiddenLayers={hiddenLayers}
        setHiddenLayers={setHiddenLayers}
      />
      <ModalPnj
        pnjData={pnjData}
        setPnjData={setPnjData}
        user={user}
        socket={socket}
        setBadges={setBadges}
        zone={zone}
      />
      {!!fightData && (
        <ModalFight
          fightData={fightData}
          setFightData={setFightData}
          user={user}
          socket={socket}
          setBadges={setBadges}
          equiped={equiped}
          skills={skills}
        />
      )}
    </div>
  );
};

export default MapWrapper;
