import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import PersonIcon from "@mui/icons-material/Person";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import "./NameTag.less";
import { IconButton } from "@mui/material";

const NameTag = ({ initValue, setServerName, id }) => {
  const [isModal, setIsModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [name, setName] = useState(initValue);

  const openModal = () => {
    setIsModal(true);
  };

  const closeIfOk = () => {
    if (name.length > 0) {
      setIsModal(false);
      if (name !== initValue) {
        setServerName(name);
      }
      setIsCopied(false);
    } else {
      setIsError(true);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setName(value.substr(0, 1).toUpperCase() + value.substr(1, value.length));

    if (value.length > 0) {
      setIsError(false);
    }
  };

  const copyId = () => {
    if (navigator.clipboard) {
      setIsCopied(true);
      navigator.clipboard.writeText(id);
    }
  };

  return (
    <div className="NameTag">
      <PersonIcon onClick={openModal} />
      <div onClick={openModal} className="pseudo">
        {name}
      </div>
      <Dialog
        open={isModal}
        onClose={closeIfOk}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <div className="warningModalText">
            Attention le pseudo ne peut être changé qu'une seule fois !
          </div>
          <TextField
            autoFocus
            label="Nouveau pseudo"
            variant="outlined"
            value={name}
            error={isError}
            onChange={handleChange}
          />
          <div className="encart_id">
            Id du joueur (pour se reconnecter) :<br />
            <span className="id">{id}</span>
            <IconButton className="closeBtn" onClick={copyId}>
              <FileCopyIcon color="primary" />
            </IconButton>
            {isCopied && <span className="copiedTxt">Copié !</span>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeIfOk}
            color="primary"
            disabled={name.length === 0}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NameTag;
