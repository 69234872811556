import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AdminModal.less";
import CloseIcon from "@mui/icons-material/Close";

const AdminModal = ({ socket, user, isModalOpen, setIsModalOpen, others }) => {
  const [idSelected, setIdSelected] = useState(user._id);
  const changeGold = (delta) => {
    socket.emit("user.addToField", idSelected, "gold", delta);
  };

  const changeInfluence = (delta) => {
    socket.emit("user.addToField", idSelected, "zoneInfluence", delta);
  };

  const changePV = (delta) => {
    socket.emit("user.addToField", idSelected, "pv", delta);
  };

  const clearInvent = () => {
    socket.emit("user.updateField", idSelected, "invent", []);
  };

  const givePotion = () => {
    socket.emit("admin.givePotion", idSelected);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="wrapperModal"
    >
      <div className="AdminStuffModal">
        <IconButton className="closeBtn" onClick={() => setIsModalOpen(false)}>
          <CloseIcon color="primary" />
        </IconButton>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>MY JSON DATAS :</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="datas">
              Données utilisteur :
              <pre className="preTxt">{JSON.stringify(user, null, 5)}</pre>
            </div>
          </AccordionDetails>
        </Accordion>

        <FormControl fullWidth className="userChoice">
          <InputLabel id="choixUser">Joueur</InputLabel>
          <Select
            labelId="choixUser"
            value={idSelected}
            label="Joueur"
            onChange={(e) => setIdSelected(e.target.value)}
          >
            {others.map((o) => (
              <MenuItem value={o._id} key={o._id}>
                {o._id === user._id && "(/me) "}
                {o.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <Button variant="outlined" onClick={() => changeGold(-100)}>
            - 100g
          </Button>
          <Button variant="outlined" onClick={() => changeGold(+100)}>
            + 100g
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={() => changeInfluence(-10)}>
            -- influence
          </Button>
          <Button variant="outlined" onClick={() => changeInfluence(+10)}>
            ++ influence
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={() => changePV(-10)}>
            - 10PV
          </Button>
          <Button variant="outlined" onClick={() => changePV(+10)}>
            + 10PV
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={givePotion}>
            Potion ++
          </Button>
        </div>
        <div className="bottom">
          <Button variant="outlined" onClick={clearInvent}>
            Clear invent
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminModal;
