import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { toast } from "react-toastify";

import "./Inscription.less";

const Inscription = ({ setName, name, setId, socket }) => {
  const [recupId, setRecupId] = useState("");

  const callInscription = (e) => {
    if (e) {
      e.preventDefault();
    }
    socket.emit("user.create", name, ({ code, _id, message }) => {
      if (code === 200) {
        setId(_id);
        toast.success(`Bienvenue parmis nous ${name} !`);
      } else {
        toast.error(message);
      }
    });
  };

  const getMyIdBack = (e) => {
    if (e) {
      e.preventDefault();
    }
    setId(recupId);
  };

  return (
    <div className="wrapper">
      <div className="title">Maps</div>
      <form
        className="form"
        noValidate
        autoComplete="off"
        onSubmit={callInscription}
      >
        <div className="subtitle">Inscription</div>
        <div className="flexRow">
          <TextField
            label="Pseudo"
            variant="outlined"
            value={name}
            onChange={(e) =>
              setName(
                e.target.value.substr(0, 1).toUpperCase() +
                  e.target.value.substr(1, e.target.value.length)
              )
            }
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={callInscription}
            disabled={name.length === 0}
          >
            <ArrowForwardIcon />
          </Button>
        </div>
      </form>
      <form
        className="form"
        noValidate
        autoComplete="off"
        onSubmit={getMyIdBack}
      >
        <div className="subtitle">Récupération de votre compte</div>
        <div className="flexRow">
          <TextField
            label="Id"
            variant="outlined"
            value={recupId}
            onChange={(e) => setRecupId(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={getMyIdBack}
            disabled={recupId.length === 0}
          >
            <ArrowForwardIcon />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Inscription;
