import { useCallback, useEffect, useState } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import SwipeableViews from "react-swipeable-views";
import IconButton from "@mui/material/IconButton";

import SVGArrowRight from "../../../SVG/arrowRight.svg";
import "./ModalPnj.less";
import { BUY, HEAL, SKIN } from "../../../Global/Constantes";
import HealerButton from "./Actions/HealerButton/HealerButton";
import BottomModal from "../../Modals/BottomModal";
import Shop from "../../Shop/Shop";

const ModalPnj = ({ pnjData, setPnjData, user, socket, setBadges, zone }) => {
  const [index, setIndex] = useState(0);
  const [texts, setTexts] = useState([]);

  const replaceAll = useCallback(
    (tab) => {
      return tab.map((txt) =>
        txt.replace(/{username}/gi, user?.username || "")
      );
    },
    [user.username]
  );
  const closeModal = () => setPnjData(false);

  useEffect(() => {
    if (!!pnjData) {
      setTexts(replaceAll(pnjData.txts));
      setIndex(0);
    }
  }, [pnjData, replaceAll]);

  const getSkinsToBuy = useCallback(() => {
    return zone?.skinsToSell.map((id) => {
      const hasAlready = user?.skinsId[id];
      return { level: id, type: SKIN, hasAlready };
    });
  }, [user?.skinsId, zone?.skinsToSell]);

  const renderAction = () => {
    switch (pnjData.action) {
      case HEAL:
        return (
          <HealerButton
            key="heal"
            closeModal={closeModal}
            setBadges={setBadges}
            user={user}
            socket={socket}
          />
        );
      case BUY:
        const itemsToBuy = getSkinsToBuy();
        return (
          <Shop
            key="shop"
            items={itemsToBuy}
            setBadges={setBadges}
            user={user}
            socket={socket}
          />
        );
      default:
        return <div />;
    }
  };

  const maxIndex = texts.length + (!!pnjData.action ? 1 : 0);
  const hasNextSwipe = maxIndex > index + 1;
  return (
    <BottomModal isOpen={!!pnjData} onClose={closeModal}>
      <div className="pnjModalWrapper">
        <div className="iconPnjWrapper">
          {!!pnjData && <img src={pnjData.icon} alt="" className="iconPNJ" />}
          <div className="pnjName">
            <div>{pnjData.pnjName}</div>
          </div>
        </div>
        <div className="modalContent">
          <SwipeableViews
            resistance
            index={index}
            onChangeIndex={(index) => setIndex(index)}
            disabled
            animateTransitions={false}
          >
            {texts.map((txt, i) => {
              if (i === index) {
                return (
                  <div className="txtWrapper" key={i}>
                    <div className="txtAndNextWrapper">
                      <TypeWriterEffect
                        textStyle={{ fontSize: 16 }}
                        startDelay={100}
                        cursorColor="white"
                        text={txt || ""}
                        typeSpeed={40}
                        hideCursorAfterText
                      />
                      {hasNextSwipe && (
                        <IconButton
                          color="secondary"
                          className="arrowRightWrapper"
                          onClick={() => setIndex(index + 1)}
                        >
                          <img
                            src={SVGArrowRight}
                            alt=">"
                            className="arrowRight"
                          />
                        </IconButton>
                      )}
                    </div>
                  </div>
                );
              } else {
                return <div key={i} />;
              }
            })}
            {renderAction()}
          </SwipeableViews>
        </div>
      </div>
    </BottomModal>
  );
};

export default ModalPnj;
