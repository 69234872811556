/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  TileLayer,
  useMap,
  Marker,
  Circle,
  Rectangle,
  Tooltip,
} from "react-leaflet";
import { toast } from "react-toastify";
import L from "leaflet";

import "./Map.less";
import "./LeafletCSS.less";
import { getDistance_m, isInZone } from "../../Global/Utils";
import {
  useDebounce,
  usePlaySound,
  useSlowUpdateValue,
} from "../../Global/CustomHooks";

import SVGInfiniTree from "../../SVG/PNJ/infiniTree.svg";
import {
  BOURSE,
  BUY,
  CARPENTER,
  CHEST,
  HEAL,
  INVENT,
  INVENT_LINE_SIZE,
  MOB,
  ONE_METER_COORD,
  OTHERS,
  PHARMACY,
  SPY,
} from "../../Global/Constantes";
import { allPharmacies } from "./Datas/Pharmacy";
import { ITEMS_METADATAS } from "../../Global/MetaDatas";
import { ALL_SKINS_IMG, getUserSkin } from "../Inventaire/SkinsSelector";

const DELTA_Y = 0.00038;
const DELTA_X = 0.00038;
const PI = Math.PI;

const iconInfiniTree = L.icon({ iconUrl: SVGInfiniTree, iconSize: [50, 50] });

const PNJS_BY_ANGLES = [
  // { teta: 0, type: CARPENTER},
  // { teta: PI / 2, type: CARPENTER},
  // { teta: PI, type: CARPENTER},
  // { teta: -PI / 2, type: CARPENTER},
  { teta: PI / 4, type: CARPENTER },
  { teta: (3 * PI) / 4, type: SPY },
  { teta: (-3 * PI) / 4, type: CARPENTER },
  { teta: -PI / 4, type: CARPENTER },
];
const WATER_COLOR =
  "https://stamen-tiles-b.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg";
const FREE_BASIC = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const PAYANT_DARK_URL =
  "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
const BRIGHT_URL =
  "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";

const URL_FOR_TILES = FREE_BASIC;

const MapContent = ({
  user,
  socket,
  bourses,
  setBourses,
  zone,
  setZone,
  zoom,
  setChests,
  chests,
  setPnjData,
  setBadges,
  invent,
  hiddenLayers,
  mobs,
  setMobs,
  setFightData,
  others,
}) => {
  const iconPlayer = L.icon({
    iconUrl: getUserSkin(user),
    iconSize: [50, 50],
  });

  const playSound = usePlaySound();
  // Latitude
  const [x, setX] = useState(0);
  // longitude
  const [y, setY] = useState(0);
  const [center, setCenter] = useState({ x, y });

  // pharmacies dans la zone :
  const [pharmacies, setPharmacies] = useState([]);

  const map = useMap();
  const zoomDebounced = useDebounce(zoom, 300);

  const onError = (e) => {
    console.error("ERREUR LORS DU GET DE POSITION : ", e);
  };

  const updatePosition = (position) => {
    const nextX = position.coords.latitude;
    const nextY = position.coords.longitude;
    setX(nextX);
    setY(nextY);
    console.log("Position updated");
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      // Watch the user position
      navigator.geolocation.watchPosition(updatePosition, onError, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 20000,
      });
    } else {
      alert("GPS localisation not Available");
    }
  }, []);

  const canPick = (meta, item) => {
    if (user.pv > 0) {
      const dist = Math.floor(getDistance_m({ x, y }, item));
      const delta = dist - user.zoneInfluence;
      if (delta <= 0) {
        if (meta.clickType === "pick") {
          if ((user.inventLevel * INVENT_LINE_SIZE || 1) <= invent.length) {
            toast.error("Votre inventaire est plein", {
              toastId: "FullInvent",
            });
            playSound("error");
            return false;
          }
        }
        return true;
      } else {
        playSound("error");
        const toastID = "itemTooFar" + meta.labelTooFar + delta;
        toast.warn(
          () => (
            <div>
              {meta.labelTooFar + " "}
              <span className="toastStrong">{delta}m</span> trop loin !
            </div>
          ),
          { toastId: toastID }
        );
        // console.log(`${dist} >= ${user.zoneInfluence}`);
        // console.log("dist >= user.zoneInfluence");
        return false;
      }
    } else {
      playSound("error");
      toast.error("Vous ne pouvez rien faire en étant mort !", {
        toastId: "you'reDead",
      });
    }
  };

  const deleteItem = (itemName, item) => {
    switch (itemName) {
      case BOURSE:
        playSound("gold");
        const nextBourses = bourses.filter((b) => b.id !== item.id);
        setBourses(nextBourses);
        break;
      case CHEST:
        playSound("pickUp");
        setBadges((old) => ({
          ...old,
          [INVENT]: old[INVENT] + 1,
        }));
        const nextChests = chests.filter((c) => c.id !== item.id);
        setChests(nextChests);
        break;
      default:
        return;
    }
  };

  const clickItem = (item, metaDatas) => {
    if (canPick(metaDatas, item)) {
      switch (metaDatas.clickType) {
        case "pick":
        case "recolte":
          socket.emit("item.pick", metaDatas.type, item, user);
          deleteItem(metaDatas.type, item);
          break;
        case "talk":
          playSound("click");
          setDataForPNJ(item, metaDatas);
          break;
        case "fight":
          console.log('fight "item" = ', item);
          setFightData({
            monster: { ...item, ...metaDatas[item.level] },
          });
          break;
        case "player":
          console.log("exchange with player = ", item);
          break;
        default:
          return;
      }
    }
  };

  const setDataForPNJ = (pnj, metaDatas) => {
    switch (pnj.type) {
      case PHARMACY:
        setPnjData({
          type: PHARMACY,
          icon: metaDatas[0].icon,
          pnjName: "TOTEM DE VIE",
          txts: [
            "Bonjour {username}, aurais-tu besoin d'une potion de soin ?",
            "Eh bien alors arrête de rêvasser, ... Au boulot :",
          ],
          action: HEAL,
        });
        break;
      case CARPENTER:
        setPnjData({
          type: CARPENTER,
          icon: metaDatas[0].icon,
          pnjName: "Charpentier",
          txts: [
            "Hey {username} ! Je suis le charpentier du coin",
            "Je n'ai malheureusement rien à te proposer aujourd'hui, mais n'hésite pas à repasser !",
          ],
        });
        break;
      case SPY:
        setPnjData({
          icon: metaDatas[0].icon,
          pnjName: "???",
          txts: [
            "Pssssst, ... Psssst ! Toi... Oui toi, viens par ici...",
            "J'ai sûrement des articles qui peuvent t'interesser ...",
          ],
          action: BUY,
        });
        break;
      default:
    }
  };

  // check for near Pharmacies
  useEffect(() => {
    if (!!zone) {
      const filtered = allPharmacies.filter((p, i) => {
        return isInZone(zone, { x: p.x, y: p.y });
      });
      filtered.forEach((p) => {
        p.type = PHARMACY;
      });
      setPharmacies(filtered);
    }
  }, [center, zone]);

  useEffect(() => {
    if (Math.abs(x - center.x) > DELTA_X || Math.abs(y - center.y) > DELTA_Y) {
      const currentCenter = map.getCenter();
      if (currentCenter.lng !== y && currentCenter.lat !== x) {
        console.log("-- UPDATE CENTER --");
        setCenter({ x, y });
        map.setView([x, y]);
        socket.emit("user.updateCenter", { x, y }, (nextZone) => {
          console.log("[updateCenter] new zone = ", nextZone);
          setZone(nextZone);
        });
      }
    }
  }, [x, y, map, setZone, socket, center]);

  useEffect(() => {
    if (map.getZoom() !== zoomDebounced) {
      map.setZoom(zoomDebounced);
    }
  }, [zoomDebounced, map]);

  const renderItems = (tab, type) => {
    return tab.map((item, index) => {
      let metaDatas = null;
      if (!type) {
        if (!item.type) {
          console.log("Erreur : Pas de type pour cet item =", item);
          return <></>;
        }
        metaDatas = ITEMS_METADATAS[item.type];
      } else {
        metaDatas = ITEMS_METADATAS[type];
      }
      return (
        <Marker
          key={item.id || index}
          draggable={false}
          position={[item.x, item.y]}
          icon={metaDatas[item.level || 0].iconLeaflet}
          eventHandlers={{
            click: () => {
              clickItem(item, metaDatas);
            },
          }}
        />
      );
    });
  };

  const renderOthers = () => {
    return others
      .filter((o) => o._id !== user._id)
      .map((other, index) => {
        let metaDatas = ITEMS_METADATAS[OTHERS];
        const icon = L.icon({
          iconUrl: ALL_SKINS_IMG[other.currentSkin],
          iconSize: [40, 40],
        });
        return (
          <Marker
            key={other._id}
            draggable={false}
            position={[other.x, other.y]}
            icon={icon}
            eventHandlers={{
              click: () => {
                clickItem(other, metaDatas);
              },
            }}
          >
            <Tooltip sticky>{other.username}</Tooltip>
          </Marker>
        );
      });
  };

  const renderPNJCircle = () => {
    const all = [];
    PNJS_BY_ANGLES.forEach((pnj, i) => {
      const xPnj = zone.x + (zone.radius / 2) * Math.cos(pnj.teta);
      const yPnj = zone.y + (zone.radius / 2) * Math.sin(pnj.teta);
      all.push({ id: i, x: xPnj, y: yPnj, type: pnj.type });
    });
    return renderItems(all);
  };

  const renderZoneInfluence = () => {
    const all = [];
    for (let i = 0; i < 2 * PI; i += PI / 40) {
      const xCircle = x + zoneInfluence * ONE_METER_COORD * Math.cos(i);
      const yCircle = y + zoneInfluence * ONE_METER_COORD * Math.sin(i);
      all.push(
        <Circle
          key={i}
          center={[xCircle, yCircle]}
          radius={zoom < 17 ? 4 : 2}
          stroke={false}
          pathOptions={{ fillColor: "red" }}
        />
      );
    }
    return all;
  };

  const zoneInfluence = useSlowUpdateValue(user.zoneInfluence, 1000).current;
  if (!zone) {
    return null;
  }
  return (
    <>
      {user.isAdmin && (
        <div className="debug-coord">
          x : {x} / y : {y} / Zoom : {zoom}
        </div>
      )}
      {!!zone && user.isAdmin && (
        <Rectangle
          bounds={[zone.bottomLeft, zone.topRight]}
          pathOptions={{
            fillColor: "blue",
            color: "transparent",
            borderColor: "green",
          }}
        />
      )}
      {/* "Assombri" la carte */}
      <Rectangle
        bounds={[
          [zone.bottomLeft[0] - 1, zone.bottomLeft[1] - 1],
          [zone.topRight[0] + 1, zone.topRight[1] + 1],
        ]}
        pathOptions={{
          fillColor: "black",
          fillOpacity: 0.4,
          color: "transparent",
        }}
      />
      <Marker position={[zone.x, zone.y]} icon={iconInfiniTree} />
      {renderPNJCircle()}
      {renderZoneInfluence()}
      <Marker position={[x, y]} icon={iconPlayer} />
      {!hiddenLayers[HEAL] && renderItems(pharmacies)}
      {!hiddenLayers[CHEST] && renderItems(chests, CHEST)}
      {!hiddenLayers[BOURSE] && renderItems(bourses, BOURSE)}
      {!hiddenLayers[MOB] && renderItems(mobs, MOB)}
      {!hiddenLayers[OTHERS] && renderOthers()}
      <TileLayer
        // attribution='&copy; <a href="http://osm.org/copyright">OSM</a>'
        url={URL_FOR_TILES}
        // urlDefault="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </>
  );
};

export default MapContent;
