import { useRef, useEffect, useState, useCallback } from "react";
import { useSettingsContext } from "../Context/SettingsContextProvider";
import { SOUNDS } from "./Constantes";

export const useInterval = (callback, delay = null) => {
  const savedCallback = useRef(callback);

  // update callback if it changes
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set interval
  useEffect(() => {
    // Called each tick
    const tick = () => {
      savedCallback.current();
    };
    // only if there's a delay
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

// On wrap la promise dans une autre promise pour pouvoir la cancel
const makeCancelable = (promise) => {
  let isCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    // Si la promesse est résolu post-unmount, isCanceled sera à true donc
    // Ni on ne resolve si on ne reject dans ce cas
    promise
      .then((val) => !isCanceled && resolve(val))
      .catch((error) => !isCanceled && reject(error));
  });
  return {
    promise: wrappedPromise,
    // C'est cette fonction que l'on appelle dans le cleanup du useEffect du useCancellablePromise
    cancel: () => {
      // const url = promise?._W?.config?.url || "";
      console.log(`<<< Promise canceled >>>`);
      isCanceled = true;
    },
  };
};

export const useCancellablePromise = () => {
  // Ref qui stock les promises
  const allPromises = useRef();
  useEffect(() => {
    // Récupération de toutes les promises
    allPromises.current = allPromises.current || [];

    // Le return est appelé lors de l'unMount
    return () => {
      // On cancel alors toutes les promises
      allPromises.current.forEach((p) => p.cancel());
      allPromises.current = [];
    };
  }, []);

  const cancellablePromise = (p) => {
    const myPromise = makeCancelable(p);
    // On ajoute cette promesse à la ref qui les stockent toutes
    allPromises.current.push(myPromise);
    // On retourne la promesse qui s'auto-cancel via le cleanUp du useEffect ci-dessus
    return myPromise.promise;
  };
  return { cancellablePromise };
};

export const useDebounce = (value, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value only after delay
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useSlowUpdateValue = (
  value,
  MAX_TIME = 2000,
  NB_MAX_UPDATE = 80,
  debug = false
) => {
  if (isNaN(value)) {
    console.log("Warning : useSlowUpdateValue ne s'utilise qu'avec des int !");
  }
  const UPDATE_INTERVAL = MAX_TIME / NB_MAX_UPDATE;
  const [current, setCurrent] = useState(value || 0);
  const previousValue = useRef(value || 0);
  const originValue = useRef(value || 0);
  const timerId = useRef(null);

  const [step, setStep] = useState(0);
  const [delta, setDelta] = useState(0);

  useEffect(() => {
    if (step !== 0) {
      if (
        (step > 0 && current + step > value) ||
        (step < 0 && current + step < value)
      ) {
        clearTimeout(timerId.current);
        setStep(0);
        setDelta(0);
        originValue.current = value;
        setCurrent(value);
      } else {
        debug && console.log("augmente val de  = ", step);
        clearTimeout(timerId.current);
        timerId.current = setTimeout(
          () =>
            setCurrent((old) => {
              debug && console.log("setcurrent = ", old + step);
              return old + step;
            }),
          UPDATE_INTERVAL
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, step, UPDATE_INTERVAL, value]);

  useEffect(() => {
    debug && console.log("Value = ", value);
    debug && console.log("previousValue = ", previousValue.current);
    if (value !== previousValue.current) {
      previousValue.current = value;
      const deltaToPrint = value - originValue.current;
      const currentDelta = value - current;
      const nextStep = currentDelta / NB_MAX_UPDATE;
      debug && console.log("nextStep = ", nextStep);
      setStep(nextStep);
      setDelta(deltaToPrint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, NB_MAX_UPDATE, current]);

  const setImmediate = (immediate) => {
    clearTimeout(timerId.current);
    setStep(0);
    setDelta(0);
    previousValue.current = immediate;
    originValue.current = immediate;
    setCurrent(immediate);
  };

  return { current: Math.round(current), delta, setImmediate };
};

export const usePlaySound = () => {
  const { canSound, canMusic } = useSettingsContext();

  return useCallback(
    (soundId, isMusic = false, volume = 1) => {
      if ((canSound && !isMusic) || (isMusic && canMusic)) {
        const s = SOUNDS[soundId];
        if (!!s) {
          const audio = new Audio(s);
          audio.volume = volume;
          audio.play();
          return audio;
        } else {
          console.error("Unknow sound : ", soundId);
        }
      } else {
        // console.info(`( ( Sounds are disabled, can't play ${soundId} ) )`);
      }
    },
    [canSound, canMusic]
  );
};
