import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import GoldSVG from "../../SVG/gold.svg";
import { toast } from "react-toastify";

import "./ReviveBtn.less";
import { usePlaySound } from "../../Global/CustomHooks";
import { formatNumber } from "../../Global/Utils";

let timerId = null;
const ReviveBtn = ({ user, socket }) => {
  const playSound = usePlaySound();

  const now = new Date();
  // 60 sc par défaut : ne se trigger que pour les anciens joueur sans respawnDate défini
  const endms = user?.respawnDate || now.getTime() + 65 * 1000;
  const diffms = endms - now.getTime();
  const endS = Math.round(diffms / 1000);
  const [deltaS, setDeltaS] = useState(endS > 0 ? endS : 0);

  useEffect(() => {
    console.log("start timer");
    if (deltaS > 0) {
      timerId = setTimeout(() => {
        setDeltaS((old) => old - 1);
      }, 1000);
    }
  }, [deltaS]);

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const withZero = (t) => {
    if (t > 9) {
      return t;
    } else {
      return "0" + t;
    }
  };

  const renderTime = (t) => {
    if (t > 59) {
      const minutes = Math.floor(t / 60);
      return `${withZero(minutes)}:${withZero(t - minutes * 60)}`;
    } else {
      return `00:${withZero(t)}`;
    }
  };

  const revive = () => {
    if (user.gold >= priceRevive) {
      socket.emit("user.revive", priceRevive);
      playSound("revive");
    } else {
      toast.error("Pas assez de fric ! Reste mort clochard !", {
        toastId: "needMoreMoney",
      });
    }
  };

  const calculus = Math.ceil(deltaS / 10) * 10;
  const priceRevive = calculus > 0 ? calculus : 0;
  return (
    <div className="reviveWrapper">
      <div className="countDown">{renderTime(deltaS)}</div>
      <Button variant="outlined" className="reviveBtn" onClick={revive}>
        <div>Résuciter</div>
        {deltaS > 0 && (
          <div
            className={`goldPriceWrapper ${
              user.gold >= priceRevive ? "okGold" : "koGold"
            }`}
          >
            {formatNumber(priceRevive)}
            <img src={GoldSVG} alt="coin" className="goldPriceIcon" />
          </div>
        )}
      </Button>
    </div>
  );
};

export default ReviveBtn;
