import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import "./SkillButton.less";
import { usePlaySound, useSlowUpdateValue } from "../../Global/CustomHooks";
import SVGNotLearned from "../../SVG/notLearned.svg";

const SkillButton = ({
  socket,
  skillKey,
  skill,
  svg = SVGNotLearned,
  disabled = false,
  className,
  onClick = () => {},
}) => {
  const playSound = usePlaySound();
  const [isUp, setIsUp] = useState(skill.isUp);
  const [percent, setPercent] = useState(0);

  const pingSimulation = 500;
  const duration = skill.coolDown + skill.duration - pingSimulation;
  const slowPercentObj = useSlowUpdateValue(percent, duration, 88);
  const slowPercent = slowPercentObj.current;
  const setImmediatePercent = slowPercentObj.setImmediate;

  const triggerSkill = () => {
    socket.emit(`user.skill.${skillKey}`);
    setIsUp(false);
    playSound(skillKey);
    setPercent(100);
    onClick();
  };

  useEffect(() => {
    if (skill.isUp) {
      setIsUp(skill.isUp);
      setPercent(0);
      setImmediatePercent(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill.isUp]);

  const isDisabled = disabled || !isUp;
  return (
    <div className={`skillWrapper ${className}`}>
      <IconButton
        className={`actionButton ${isDisabled && "disabled"}`}
        color="info"
        onClick={triggerSkill}
        disabled={isDisabled}
        size="large"
      >
        <img src={svg} alt="Skill" className="actionButtonSVG" />
      </IconButton>
      {!isUp && !disabled && (
        <CircularProgress
          className="cooldown"
          variant="determinate"
          size={60}
          thickness={2}
          value={slowPercent}
        />
      )}
    </div>
  );
};

export default SkillButton;
