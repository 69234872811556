import React from "react";
import { IconButton } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import { useSettingsContext } from "../../Context/SettingsContextProvider";
import BottomModal from "../Modals/BottomModal";
import { usePlaySound } from "../../Global/CustomHooks";
import "./SettingsModal.less";
import { useEffect } from "react";

const SettingsModal = ({ areSettingsOpen, setAreSettingsOpen }) => {
  const { canMusic, setCanMusic, canSound, setCanSound } = useSettingsContext();
  const playSound = usePlaySound();

  useEffect(() => {
    if (areSettingsOpen) {
      playSound("click");
    }
  }, [areSettingsOpen, playSound]);

  const toggleMusic = () => {
    setCanMusic(!canMusic);
    playSound("click");
  };

  const toggleSound = () => {
    setCanSound(!canSound);
    playSound("click");
  };

  return (
    <BottomModal
      isOpen={areSettingsOpen}
      onClose={() => setAreSettingsOpen(false)}
    >
      <h1 className="modalSettingsTitle">Paramètres</h1>
      <div className="settingsWrapper">
        <div className="setting">
          <IconButton color="primary" onClick={toggleMusic}>
            {canMusic ? (
              <MusicNoteIcon className="settingsIcon" />
            ) : (
              <MusicOffIcon className="settingsIcon" />
            )}
          </IconButton>
        </div>

        <div className="setting">
          <IconButton color="primary" onClick={toggleSound}>
            {canSound ? (
              <VolumeUpIcon className="settingsIcon" />
            ) : (
              <VolumeMuteIcon className="settingsIcon" />
            )}
          </IconButton>
        </div>
      </div>
    </BottomModal>
  );
};

export default SettingsModal;
