import React from "react";

import "./UserInfos.less";
import GoldSVG from "../../SVG/gold.svg";
import { formatNumber } from "../../Global/Utils";
import { useSlowUpdateValue } from "../../Global/CustomHooks";

const Gold = ({ user }) => {
  const { current, delta } = useSlowUpdateValue(user.gold);
  return (
    <div className="gold">
      <div className="goldTxts">
        <div className="goldTxt">{formatNumber(current)}</div>
        {delta !== 0 && (
          <div className={`goldDelta ${delta > 0 ? "green" : "red"}`}>
            {delta > 0 && "+"}
            {formatNumber(delta)}
          </div>
        )}
      </div>
      <div className="coinWrapper">
        <img src={GoldSVG} alt="coin" className="goldSvg" />
      </div>
    </div>
  );
};

export default Gold;
